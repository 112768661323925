import React from "react";
import {
  Button,
  LinearProgress,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';
import P2 from "literacy/assets/fonts/p2";

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: this.props.rows,
      index: this.props.index ?? 0,
      studentAnswer: "",
      assign: this.props.assignment ?? [],
      score: this.props.score ?? 0
    };
  };

  handleNext = () => {
    const { studentAnswer, index, questions, assign, score } = this.state;
    let addScore = 0;
    if (questions[index].answer === studentAnswer) {
      addScore += 1;
    }
    assign.push({
      question: questions[index].question,
      realAnswer: questions[index].answer,
      studentAnswer
    });
    this.setState({
      score: score + addScore,
      index: index + 1,
      assign,
      studentAnswer: ""
    });
  };

  render() {
    const { questions, index, assign, score, studentAnswer } = this.state;
    const progress = Math.floor((index / questions.length) * 100);
    return (
      <div>
        <Container style={{ marginTop: "5%" }}>
          <Button
            variant="outlined"
            style={{ marginRight: 20, color: "white", backgroundColor: "#107896" }}
            onClick={() =>
              this.props.handleSaveAssignment(index, questions, assign, score)
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{ color: "white", backgroundColor: "#107896" }}
            onClick={() => (window.location = this.props.backURL ?? "/literacy")}
          >
            Quit
          </Button>
        </Container>
        <Container style={{ marginTop: "10%" }}>
          {index !== questions.length ? (
            <div>
              <Markdown className="font-weight-light">{questions[index].question}</Markdown>
              <br />
              <RadioGroup row value={studentAnswer} onChange={(e) => this.setState({studentAnswer: e.target.value})}>
                <FormControlLabel value="true" control={<Radio color="primary" />} label={<P2>True</P2>} />
                <FormControlLabel value="false" control={<Radio color="primary"/>} label={<P2>False</P2>} />                   
              </RadioGroup>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={this.handleNext}
              >
                Next
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={() => this.props.handleNext(score, assign)}
              >
                Finish!
              </Button>
              <br />
            </div>
          )}
          <br />
          <LinearProgress variant="determinate" value={progress} />
        </Container>
      </div>
    );
  }
}
