import React from "react";
import { Container, Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';
import PDF from "appearance/assets/pdf-reviewer";
import withEyeTracking from "../assets/eye-track";

const Story = (props) => {

  return (
    <div>
      <PDF file={process.env.PUBLIC_URL + "/appearance/docs/story1.pdf"} width={800} setPage={props.setPage} />
    </div>
  );
};

export default withEyeTracking(Story);