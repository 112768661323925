import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import Q3Table from "literacy/components/tutor/thread-assign-table/short-answer-table";
import Q2Table from "literacy/components/tutor/thread-assign-table/blank-table";
import Q1Table from "literacy/components/tutor/thread-assign-table/multiple-choice-table";

class MeaningTutorTestOneAssign extends React.Component {
  constructor(props) {
    super(props);
    this.state = { assignment: [] };
  }

  componentDidMount = async () => {
    const doc = await axios.get(
      "/api/literacy/meaning/test/" + this.props.match.params.id
    );
    this.setState({ assignment: doc.data });
  };

  render() {
    const { assignment } = this.state;
    return (
      <div>
        {assignment ? (
          <div>
            <div className="jumbotron">
              <h2>Review Learner' Access Assignment</h2>
              <h3>Learner: {assignment.studentName}</h3>
              <h3>Email: {assignment.studentEmail}</h3>
              <br />
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                href="/literacy/tutor/meaning/test"
              >
                Go back
              </Button>
            </div>

            <Container>
              <h3>Multiple Choice</h3>
              <Q1Table rows={assignment.q1Assign} />
              <br />
              <h3>Fill in the Blank</h3>
              <Q2Table rows={assignment.q2Assign} />
              <br />
              <h3>Short Answer</h3>
              <Q3Table rows={assignment.q3Assign} />
              <br />
              <h4>Learner's old score is {assignment.oldScore}.</h4>
              <h4>Learner's new score is {assignment.newScore}.</h4>
            </Container>
          </div>
        ) : null}
      </div>
    );
  }
}

export default MeaningTutorTestOneAssign;
