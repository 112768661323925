import { useEffect, useState } from "react";
import ScoreSlider from "./score-slider";
import { Paper, Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';

const SliderCard = (props) => {
  const [questions, setQuestions] = useState(props.questions);
  const [index, setIndex] = useState(0);
  const [done, setDone] = useState(false);

  const handleScore = (answer, i) => {
    const newQuestions = [...questions];
    newQuestions[i].answer = answer;
    setQuestions(newQuestions);
    setDone(true);
  };

  const handleNext = () => {
    setIndex(index + 1);
    setDone(false);
  };

  const handleFinish = () => {
    props.handleFinish(questions);
  };

  return (
    <Paper style={{ padding: "50px", marginTop: "100px", position: "relative" }}>
      {
        questions.length !== 0 &&
          questions.length === index ?
          <div>
            <P2>Review</P2>
            <br />
            {questions.map((question, i) => <div><ScoreSlider question={question} onScore={(answer) => handleScore(answer, i)} /><br /></div>)}
            <Button variant="contained" color="primary" onClick={handleFinish} style={{ textTransform: "none", paddingTop: "10px", right: "10px", bottom: "10px", position: "absolute" }}><P3>Finish</P3></Button>
          </div>
          :
          <ScoreSlider question={questions[index]} onScore={(answer) => handleScore(answer, index)} />
      }
      <br />
      {
        done ?
          questions.length > index &&
          <Button variant="outlined" color="primary" onClick={handleNext} style={{ textTransform: "none", paddingTop: "10px", right: "10px", bottom: "10px", position: "absolute" }}><P3>Next</P3></Button>
          :
          null
      }
    </Paper>
  )
};

export default SliderCard;