import React from "react";
import { Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';


const Instruction = (props) => {

    return(
        <div  style={{ borderStyle: "solid", borderColor: "blue", margin: "10%", position: "relative", textAlign: "center" }} >
            <div style={{ margin: "10%" }}>
                <P2>Please place the index finger or thumb of your left hand on the Z key and the index finger or thumb of your right hand on the M key. </P2>
                <br />
                <P2>When the word or image belongs to the left category press the Z key.</P2>
                <P2>When the word or image belongs to the right category press the M key. </P2>
                <br />
                <P2>If you make an error ‘X’ will appear – correct the error by hitting the correct key.</P2>
                <br />
                <P2>Go as fast you can while making as few mistakes as possible.</P2>
                <br />
                <P2>Click "Start" button when you’re ready to start</P2>
            </div>
            <div className="col" style={{position: "fixed", left: "20%", bottom: "20px"}} >
                <div
                    style={{ color: "green", position: "fixed", left: 0, bottom: 0, width: "30%", height: "15%" }}
                >
                    <P1>Z</P1>
                    <P2>non-disfigured faces</P2>
                </div>
            </div>
            <div className="col" style={{position: "fixed", left: "20%", bottom: "20px"}} >
                <Button
                    variant="contained"
                    onClick={props.handleClick}
                    style={{ position: "fixed", left: "45%", bottom: "5%", width: "15%", height: "15%", color: "white", backgroundColor: "#107896" }}
                    >
                    <P1>Start</P1>
                </Button>
            </div>
            <div className="col" style={{position: "fixed", right: "20%", bottom: "20px"}} >
                <div
                    style={{ color: "green", position: "fixed", right: 0, bottom: 0, width: "30%", height: "15%" }}
                    >
                    <P1>M</P1>
                    <P2>disfigured faces</P2>
                </div>
            </div>
        </div>
    )
}

export default Instruction;