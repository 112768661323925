import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { green, yellow, red } from "./image-name";
import { ImageProcessingAWS } from 'assets/keys';

const Classification = (props) => {
    const [index, setIndex] = useState(0);
    const [displayImages, setDisplayImages] = useState([]);

    useEffect(() => {
        const greenImageUrls = all_green_image();
        const yellowImageUrls = all_yellow_image();
        const redImageUrls = all_red_image();
        const imageUrls = [...greenImageUrls, ...yellowImageUrls, ...redImageUrls];
        setDisplayImages(imageUrls);
    }, []);

    useEffect(async () => {
        if (index >= displayImages.length) {
            window.alert("Thank you!")
        }
    });

    const all_green_image = () => {
        const greenImageUrls = [];
        for (let image of green) {
            greenImageUrls.push(`${ImageProcessingAWS}classification/green/${image}`)
        };
        return greenImageUrls
    }

    const all_yellow_image = () => {
        const yellowImageUrls = [];
        for (let image of yellow) {
            yellowImageUrls.push(`${ImageProcessingAWS}classification/yellow/${image}`)
        };
        return yellowImageUrls
    }

    const all_red_image = () => {
        const redImageUrls = [];
        for (let image of red) {
            redImageUrls.push(`${ImageProcessingAWS}classification/red/${image}`)
        };
        return redImageUrls
    }

    return (
        <div>
            <center><h1>Classification</h1></center>
            <br /><br />
            <center>
                <img id="classification-image" width={512} height={512} src={displayImages[index] || "https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"} alt="" />
            </center>
            <br /><br />
            <h3>{displayImages[index]}</h3>
            <br /><br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                <Button disabled={!displayImages[index]} onClick={() => setIndex(index - 1)}>Previous</Button>
                <Button disabled={!displayImages[index]} onClick={() => setIndex(index + 1)}>Next</Button>
            </div>
        </div>
    )
}

export default Classification;