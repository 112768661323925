import React from "react";
import { Container, Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';

export default (props) => {
  return (
    <Container>
      <div
        className="jumbotron"
        style={{
          marginTop: "5%",
        }}
      >
        <div id="modify">
          <P3>In the Fall of 2010, I suffered an accident that changed my life. I woke up in the hospital with injuries to my cheeks and lips. My face was disfigured. It's hard to explain how the change in my face has affected me, so I want to describe it in a way that helps my readers experience first-hand what this journey has been like for me:</P3>
          <ul>
            <li><P3>You wake up in the hospital. You never thought this sort of thing would happen to you. Your face feels swollen, painful, and damaged. You realize your face will never look the same as it did before the accident. At first, getting used to looking at your reflection in the mirror is a big challenge. It’s hard to accept this new reality. Your face is forever changed, but that is no reflection of your personality. The reflection that you see is your new exterior; the eyes of humanity will see you differently now. You reassure yourself that although you have been unlucky to suffer facial damage, you’re still the same person you were before.</P3></li>
            <li><P3>You worry for months that you’ll never be able to pursue the career you’re passionate about or be successful in a workplace.  The thought of meeting new people terrifies you- you fear being rejected because of your facial difference. You worry that finding a supportive and accepting significant other is going to be difficult. In public you notice people staring and often social interactions leave you feeling isolated and unaccepted.</P3></li>
            <li><P3>Despite all the challenges, you are resilient and overcome the adversity that having a facial difference has thrown your way. You land your dream job and excel in your career. You earn the respect of your colleagues and even make work friends. You prove to yourself and those around you that despite your facial difference, you can continue to live a successful and fulfilling life.</P3></li>
            <li><P3>You’ve overcome the worries you felt so strongly at the beginning of your journey to the point where you’re comfortable with your new exterior. Your confidence has grown and you’ve developed self-love strategies to overcome challenges of having an altered face. You can’t control other people’s reactions and sometimes you still notice people’s stares, but your confidence and self-love keep you going no matter what.</P3></li>
          </ul>
        </div>
        <br />
        <Button
          variant="contained"
          size="large"
          style={{ color: "white", backgroundColor: "#107896" }}
          onClick={props.handleClick}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};
