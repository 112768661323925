import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AlertDialog(props) {
  const [stop, setStop] = useState(false);
  const history = useHistory();

  const handleDialogClose = () => props.stopped && !stop;

  const noButton = () => {
    setStop(true);
    props.onClose();
  };

  const handleDatabase = () => {
    history.push("/database");
  };

  const handleMain = () => {
    history.push("/");
  };

  return (
    <div>
      {props.finished ? (
        <Dialog
          open={props.finished}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Thank you. You may now exit the study
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDatabase} color="primary">
              Database
            </Button>
            <Button onClick={handleMain} color="primary">
              Exit
            </Button>
          </DialogActions>
        </Dialog>
      ) : handleDialogClose() ? (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You have stopped the study. Are you sure you want to stop?
          </DialogTitle>
          <DialogActions>
            <Button onClick={props.onStop} color="primary">
              Yes
            </Button>
            <Button onClick={noButton} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
