import React, { useEffect } from 'react';
const GazeCloudAPI = window.GazeCloudAPI;
// https://medium.com/@williamwang15/integrating-gazecloudapi-a-high-accuracy-webcam-based-eye-tracking-solution-into-your-own-web-app-2d8513bb9865
/*
    GazeData.docX // x coordinate w.r.t. html document
    GazeData.docY // y coordinate w.r.t. html document
    GazeData.GazeX // x coordinate w.r.t. your screen 
    GazeData.GazeY // y coordinate w.r.t. your screen
*/

const GazeCloudAPIEyeTracking = () => {
    console.log(GazeCloudAPI);
    const PlotGaze = (GazeData) => {
        const elementGazeData = document.getElementById("GazeData");
        const elementHeadPoseData = document.getElementById("HeadPoseData");
        const elementHeadRotData = document.getElementById("HeadRotData");
        if (elementGazeData) elementGazeData.innerHTML = "GazeX: " + GazeData.GazeX + " GazeY: " + GazeData.GazeY;
        if (elementHeadPoseData) elementHeadPoseData.innerHTML = " HeadX: " + GazeData.HeadX + " HeadY: " + GazeData.HeadY + " HeadZ: " + GazeData.HeadZ;
        if (elementHeadRotData) elementHeadRotData.innerHTML = " Yaw: " + GazeData.HeadYaw + " Pitch: " + GazeData.HeadPitch + " Roll: " + GazeData.HeadRoll;
        var x = GazeData.docX;
        var y = GazeData.docY;
        var gaze = document.getElementById("gaze");
        if (gaze) {
            x -= gaze.clientWidth/2;
            y -= gaze.clientHeight/2;
            gaze.style.left = x + "px";
            gaze.style.top = y + "px";
        }
    }

    useEffect(() => {
        GazeCloudAPI.OnResult = PlotGaze;
        GazeCloudAPI.OnCalibrationComplete = function(){
            console.log('gaze Calibration Complete')
        }
        GazeCloudAPI.OnCamDenied = function(){ 
            console.log('camera access denied')  
        }
        GazeCloudAPI.OnError = function(msg){ console.log('err: ' + msg) }
        GazeCloudAPI.UseClickRecalibration = true;
    }, []);
    
    const StartEyeTracking = () => {
        GazeCloudAPI.StartEyeTracking();
    }

    const StopEyeTracking = () => {
        GazeCloudAPI.StopEyeTracking();
    }
    return (
        <div>
            <h1>Gaze Cloud API</h1>
            <button onClick={StartEyeTracking}>Start</button>
            <button onClick={StopEyeTracking}>Stop</button>
            <div id='GazeData'></div>
            <div id='HeadPoseData'></div>
            <div id='HeadRotData'></div>
            <div id='gaze' style={{ display: "block", borderWidth: 5, borderColor: "black"}}></div>
        </div>
    )
}

export default GazeCloudAPIEyeTracking;