import React from "react";
import axios from "axios";
import { TextField, Button, Container, InputLabel, Select, MenuItem } from "@material-ui/core";
import Table from "literacy/components/tutor/phoneme/phonemetable";

class PhonemeTutorPhonemeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phonemeDataW1: [],
      phonemeDataW2: [],
      phonemeAccess: [],
      word: "",
      phoneme: "",
      section: "w1",
    };
  }

  componentDidMount = async () => {
    const doc1 = await axios.get("/api/literacy/phoneme/phoneme/table/w1");
    const doc2 = await axios.get("/api/literacy/phoneme/phoneme/table/w2");
    const doc3 = await axios.get("/api/literacy/phoneme/phoneme/table/access");
    if (doc1.data) {
      this.setState({ phonemeDataW1: doc1.data });
    }
    if (doc2.data) {
      this.setState({ phonemeDataW2: doc2.data });
    }
    if (doc3.data) {
      this.setState({ phonemeAccess: doc3.data });
    }
  };

  addNewData = async () => {
    const { word, phoneme, section } = this.state;
    await axios.post("/api/literacy/phoneme/phoneme", { word, phoneme, version: section });
    await this.setState({ word: "", phoneme: "" });
    this.componentDidMount();
  };

  deleteData = async (id) => {
    await axios.delete("/api/literacy/phoneme/phoneme/" + id);
    this.componentDidMount();
  };

  handleSectionChange = (e) => {
    this.setState({ section: e.target.value });
  }

  renderTable = () => {
    const { section, phonemeDataW1, phonemeDataW2, phonemeAccess } = this.state;
    switch (section) {
      case "w1":
        return <Container>
          <Table
            rows={phonemeDataW1}
            handleDelete={this.deleteData}
            name="testData"
            one="word"
            two="phoneme"
          />
        </Container>
      case "w2":
        return <Container>
          <Table
            rows={phonemeDataW2}
            handleDelete={this.deleteData}
            name="testData"
            one="word"
            two="phoneme"
          />
        </Container>
      case "access":
        return <Container>
          <Table
            rows={phonemeAccess}
            handleDelete={this.deleteData}
            name="testData"
            one="word"
            two="phoneme"
          />
        </Container>
    }
  }

  render() {
    const { word, phoneme, phonemeDataW1, phonemeDataW2, section } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Modify the Phoneme Data</h2>
          <br />
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} href="/literacy/tutor/phoneme">
            Go back
          </Button>
        </div>
        <Container>
          <InputLabel id="label">Assignment Section</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={section}
            onChange={this.handleSectionChange}
          >
            <MenuItem value="w1">Week 1</MenuItem>
            <MenuItem value="w2">Week 2</MenuItem>
          </Select>
        </Container>
        <Container>
          <TextField
            id="standard-basic"
            label="Word"
            value={word}
            autoComplete="off"
            style={{ marginRight: 20 }}
            onChange={(e) => this.setState({ word: e.target.value })}
          />
          <TextField
            id="standard-basic"
            label="Phoneme"
            value={phoneme}
            autoComplete="off"
            style={{ marginRight: 20 }}
            onChange={(e) => this.setState({ phoneme: e.target.value })}
          />
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={this.addNewData}>
            Add
          </Button>
        </Container>
        <br />
        <br />
        {this.renderTable()}
        <br />
        <br />
      </div>
    );
  }
}

export default PhonemeTutorPhonemeData;
