import React from "react";
import { Button, Container } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";

export default (props) => {
  return (
    <Container>
      <div
        style={{
          textAlign: "center",
          position: "relative",
        }}
      >
        <P1>
          This has been a short demonstration. Please press the button below to
          return to the demonstration homepage.
        </P1>
        <Button
          variant="contained"
          size="large"
          style={{ color: "white", backgroundColor: "#107896" }}
          onClick={() => (window.location = "/hearing/demo/")}
        >
          BACK TO HOMEPAGE
        </Button>
      </div>
    </Container>
  );
};
