import React, { useState, useEffect } from "react";
import "./styles.css";
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { P1, P2, P3 } from 'assets/fonts';


const DecoratedMain = (props) => {

  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/crm' });
  });

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => { props.history.push(page) }, 2000);
  }

  return (
    <div>
      <div style={{ backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)", height: window.innerHeight * 0.4, width: "100%", position: "relative" }}>
        <div style={{ height: "40%" }}>
        </div>
        <div style={{ color: "white", paddingLeft: "10%", fontFamily: "sans-serif" }}>
          <P1><b>Welcome to our collection of hearing experiments</b></P1>
          <P2>Instructions: Please use a desktop or laptop computer with
            Chrome, Edge, Firefox, Opera or Safari to complete any of these evaluations.</P2>
        </div>
      </div>
      <div className="row" style={{ height: window.innerHeight * 0.2, width: "100%", marginLeft: 0, marginRight: 0 }}>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/crm/1')}>
          <div style={{ marginTop: "10%" }}>
            <center style={{ color: "white" }}><P2 >Coordinate Response Measure 1</P2></center>
            <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/crm/2')}>
          <div style={{ marginTop: "10%" }}>
            <center style={{ color: "white" }}><P2 >Coordinate Response Measure 2</P2></center>
            <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} />
          </div>
        </div>
      </div>
      <div className="row" style={{ height: window.innerHeight * 0.2, width: "100%", marginLeft: 0, marginRight: 0 }}>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/crm/3')}>
          <div style={{ marginTop: "10%" }}>
            <center style={{ color: "white" }}><P2 >Coordinate Response Measure 3</P2></center>
            <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/crm/4')}>
          <div style={{ marginTop: "10%" }}>
            <center style={{ color: "white" }}><P2 >Coordinate Response Measure 4</P2></center>
            <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} />
          </div>
        </div>
      </div>
      <div className="row" style={{ height: window.innerHeight * 0.2, backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)" }}>
        <div id="card" className="col-6" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/crm/5')}>
          <div style={{ marginTop: "10%" }}>
            <center style={{ color: "white" }}><P2 >Coordinate Response Measure 5</P2></center>
            <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} />
            <center style={{ color: "white" }}><P3 >CRM 1 with 12-second pause at each 10 trials</P3></center>
          </div>
        </div>
      </div>
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <CircularProgress color="primary" style={{ width: "100px", height: "100px" }} />
      </Backdrop>
      <Button
        variant="outlined"
        onClick={() => window.location = "/hearing"}
        style={{ position: "fixed", top: "10px", left: "10px", color: "white", borderColor: "white" }}
      >
        Back
      </Button>
    </div>
  )
}

export default DecoratedMain;
