import React from "react";
import { Button, Container } from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';

export default (props) => {
  return <Container>
    <div style={{ marginTop: "20%", marginLeft: "10%", marginRight: "10%" }}>
      <P2>You are now entering block {props.section} of four.</P2><br />
      <Button
        variant="contained"
        style={{ margin: 20, width: 150, color: "white", backgroundColor: "#107896" }}
        onClick={props.handleClick}>Next</Button>
    </div>

  </Container>
}