import React from "react";
import {
  Container,
  Button
} from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';

const TestQuestionsIntro = (props) => {

  return (
    <Container>
      <div
        style={{
          position: "relative",
          marginTop: "3%",
          marginBottom: "5%",
          marginLeft: "10%",
          marginRight: "10%"
        }}
      >
        <P2>
          In this study, we are not only interested in assessing your performance 
          but also the experiences you had during the tasks. We are interested in the mental 
          “workload” you experienced while performing the task. We ask you to rate 6 specific 
          categories of mental workload.
        </P2>
        <br />
        <P2>
          Please read the definition of the categories, and click on the slider at the point 
          that better matches your experience.  It is very important that the definitions are 
          clear to you and that you consider each scale individually. 
          If you have any questions, please ask me about it now or at any time.
        </P2>
        <br />
        <Button
          variant="contained"
          size="large"
          onClick={() => props.handleClick()}
          style={{
            color: "white", backgroundColor: "#107896",
            width: 200,
            marginBottom: 10,
          }}
        >
          OK
        </Button>
        <br />
      </div>
    </Container>
  );
}

export default TestQuestionsIntro;
