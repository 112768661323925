import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { P1, P2, P3 } from 'assets/fonts';
import { Container, Button, TextField } from '@material-ui/core';
import PDFReviewer from 'GUI/assets/pdf-reviewer';

const PDF = (props) => {
  const { id } = props;
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/pdf/" + id);
        setUrl(doc.data.url);
        setTitle(doc.data.title);
      })();
    }
  }, [id]);

  return (
    <Container id="icf">
      <br />
      <P2 style={{ fontWeight: "300" }}>{title}</P2>
      <PDFReviewer url={url} />
      <br />
    </Container>
  )
};

export default PDF;