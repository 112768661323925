import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import {
  Container,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import Markdown from "markdown-to-jsx";
import axios from "axios";

const DfdExperiment = (props) => {
  const { id } = props;
  const [optional, setOptional] = useState(false);
  const [noiseFile, setNoiseFile] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/dfdexperiments/" + id);
        setOptional(doc.data.optional);
      })();
    }
  }, [id]);

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/dfdexperiments/" + id, { optional: newBool });
  };

  const handleVersionChange = async (e) => {
    const ver = e.target.value;
    console.log(id);
    await axios.put("/api/gui/dfdexperiments/" + id, { version: ver });
  };

  const handleUploadNoise = async () => {
    const doc = await axios.get("/api/gui/dfdexperiments");
    const uploadConfigs = doc.data;
    await axios
      .put(uploadConfigs.url, noiseFile, {
        headers: {
          "Content-type": "audio/wav",
        },
      })
      .then(() => window.alert("Noise uploaded successfully!"))
      .catch((err) => window.alert("Noise upload failed:", err));
    await axios.put("/api/gui/dfdexperiments/" + id, {
      noise: uploadConfigs.key,
    });
  };

  return (
    <Container>
      <P1>Distinctive Features Difference Experiment</P1>
      <div className="row" style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={
            <Radio
              color="primary"
              checked={optional === true}
              onChange={(e) => handleOptionalChange(true)}
            />
          }
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={
            <Radio
              color="primary"
              checked={optional === false}
              onChange={(e) => handleOptionalChange(false)}
            />
          }
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
        <FormControl
          fullWidth
          style={{ marginTop: "3vh", marginBottom: "3vh" }}
        >
          <InputLabel>Version</InputLabel>
          <Select label="Version" onChange={handleVersionChange}>
            <MenuItem value={1}>Version 1</MenuItem>
            <MenuItem value={2}>Version 2</MenuItem>
          </Select>
        </FormControl>
        <Container>
          <P1>Noise Choice</P1>
          <input
            type="file"
            accept="audio/wav"
            onChange={(e) => setNoiseFile(e.target.files[0])}
          />
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleUploadNoise}
          >
            Upload
          </Button>
          <P3>(Please wait for "Noise uploaded successfully!" message)</P3>
        </Container>
      </div>
    </Container>
  );
};

export default DfdExperiment;
