import React, { useState, useEffect, useRef, useDebugValue } from "react";
import { Container } from "@material-ui/core";
import axios from "axios";
import CountDown from "hearing/assets/digits/count-down";
import { createLogger } from "redux-logger";
import Gkey from "../assets/images/GKey.png";
import Hkey from "../assets/images/HKey.png";
import { Carousel } from "react-bootstrap";
import Slide1 from "../assets/images/LetterIdentification/LetterIdentificationSlide1.png";
import Slide2 from "../assets/images/LetterIdentification/LetterIdentificationSlide2.png";
import Slide3 from "../assets/images/LetterIdentification/LetterIdentificationSlide3.png";
import Slide4 from "../assets/images/LetterIdentification/LetterIdentificationSlide4.png";
import Slide5 from "../assets/images/LetterIdentification/LetterIdentificationSlide5.png";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";
import { useDispatch } from "react-redux";

//TODO: loading to cross fixation point image
// Alphabet feature represent similarity of the Text 1 being highly similar and 3 = not similar.
const alphabetFeature = {
  A: 3,
  a: 3,
  B: 3,
  b: 3,
  C: 1,
  c: 1,
  D: 3,
  d: 3,
  E: 3,
  e: 3,
  F: 2,
  f: 2,
  g: 3,
  G: 3,
  h: 2,
  H: 2,
  i: 3,
  I: 3,
  J: 2,
  j: 2,
  K: 2,
  k: 2,
  L: 2,
  l: 2,
  M: 2,
  m: 2,
  N: 3,
  n: 3,
  o: 1,
  O: 1,
  P: 1,
  p: 1,
  Q: 3,
  q: 3,
  r: 3,
  R: 3,
  S: 1,
  s: 1,
  T: 2,
  t: 2,
  u: 2,
  U: 2,
  V: 1,
  v: 1,
  w: 1,
  W: 1,
  x: 1,
  X: 1,
  y: 2,
  Y: 2,
  z: 1,
  Z: 1,
};

const LoadStage = {
  INSTRUCTION: "instruction",
  LOADING: "loading",
  TEST: "test",
  PAUSE: "pause",
  DONE: "done",
};

const AlphabetExperiment = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [optional, setOptional] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [question, setQuestion] = useState([]);
  const [trial, setTrial] = useState(52);
  const [loadStage, setLoadStage] = useState(LoadStage.INSTRUCTION);
  const Alphabets = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const displayed = useRef([]);
  const similarity = useRef([]);
  const letterTimings = useRef([]); // [{letter: "A", appearTime: "", disappearTime: ""}, ...]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/alphabetexperiment/" + id);
        setOptional(doc.data.optional);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "alphabetexperiment",
      question,
      answer,
      score: getScore(),
      similarity,
      letterTimings: letterTimings.current,
    };
    updateAssign(assign);
    readyToContinue(id);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const [testStarted, setTestStarted] = useState(false);
  const testStartTime = useRef(null);

  useEffect(() => {
    if (!testStarted && loadStage === LoadStage.TEST) {
      testStartTime.current = new Date().getTime(); // Initialize testStartTime only once using useRef
      setTestStarted(true);
    }
  }, [loadStage, testStarted]);

  function getElapsedTime() {
    const currentTime = new Date().getTime();
    const elapsed = (currentTime - testStartTime.current) / 1000; // Using testStartTime.current to access the value
    return elapsed.toFixed(4); // Format the number to have 4 decimal places
  }

  // start timer when the letter is displayed
  useEffect(() => {
    if (loadStage === LoadStage.TEST) {
      const elapsedTime = getElapsedTime();
      letterTimings.current.push({
        letter: question[question.length - 1],
        appearTime: elapsedTime * 1000, // record the elapsed time
        disappearTime: "",
      });
    }
  }, [loadStage]);

  const handleKeyDown = (event) => {
    if (loadStage === LoadStage.TEST) {
      const elapsedTime = getElapsedTime();

      // Update the letter's disappear time
      if (letterTimings.current.length > 0) {
        let lastLetter =
          letterTimings.current[letterTimings.current.length - 1];
        if (
          lastLetter.letter === question[question.length - 1] &&
          !lastLetter.disappearTime
        ) {
          lastLetter.disappearTime = elapsedTime * 1000; // record the elapsed time
        }
      }

      if (event.keyCode === 71) {
        setAnswer([...answer, question[question.length - 1].toLowerCase()]);
        setLoadStage(LoadStage.PAUSE);
        console.log("letter timings: ", letterTimings.current);
      } else if (event.keyCode === 72) {
        setAnswer([...answer, question[question.length - 1].toUpperCase()]);
        setLoadStage(LoadStage.PAUSE);
        console.log("letter timings: ", letterTimings.current);
      }
    }
  };

  useEffect(() => {
    const runSequentially = async () => {
      if (answer.length >= trial) {
        setLoadStage(LoadStage.DONE);
        handleUpdateAssign();
      } else {
        if (loadStage !== LoadStage.INSTRUCTION) {
          new Promise((resolve) =>
            setTimeout(() => {
              // Move the logic for setting new question here
              let newIndex;
              while (true) {
                const index = Math.floor(Math.random() * Alphabets.length);
                if (displayed.current.includes(index) === false) {
                  newIndex = index;
                  displayed.current.push(index);
                  similarity.current.push(alphabetFeature[Alphabets[index]]);
                  break;
                }
              }
              setQuestion([...question, Alphabets[newIndex]]);

              setLoadStage(LoadStage.TEST);
              resolve();
            }, 2000)
          );
        } else {
          let newIndex;
          while (true) {
            const index = Math.floor(Math.random() * Alphabets.length);
            if (displayed.current.includes(index) === false) {
              newIndex = index;
              displayed.current.push(index);
              similarity.current.push(alphabetFeature[Alphabets[index]]);
              break;
            }
          }
          setQuestion([...question, Alphabets[newIndex]]);
        }
      }
    };
    runSequentially();
  }, [answer]);

  const getScore = () => {
    let curScore = 0;
    for (let i = 0; i <= trial; i++) {
      if (question[i] === answer[i] && answer[i] != undefined) {
        curScore = curScore + 1;
      } else {
        // Do nothing
      }
    }
    return curScore;
  };

  const countDownDisplay = () => {
    setLoadStage(LoadStage.TEST);
  };
  const loadingScreen = () => {
    return <h1>loading ... </h1>;
  };
  const alphabetDisplay = () => {
    // Determine the size of each quadrant
    const quadrantWidth = window.innerWidth * 0.5;
    const quadrantHeight = window.innerHeight * 0.4;

    console.log(
      "window.innerWidth and window.innerHeight: ",
      window.innerWidth,
      window.innerHeight
    );
    console.log(
      "quadrantWidth and quadrantHeight: ",
      quadrantWidth,
      quadrantHeight
    );

    const x = Math.floor(Math.random() * 2);
    const y = Math.floor(Math.random() * 2);

    // Calculate the centered position of the letter based on the chosen quadrant
    const left = x * quadrantWidth + quadrantWidth / 3; // center in the quadrant's width
    const top = y * quadrantHeight + quadrantHeight / 2; // center in the quadrant's height

    console.log("left and top: ", left, top);

    return (
      <div>
        <Container style={{ textAlign: "center" }}>
          <h1
            style={{
              fontFamily: '"Times New Roman", Times, serif',
              fontSize: "250px",
              color: "#333",
              position: "absolute",
              left: left,
              top: top,
              transform: "translate(-50%, -50%)", // center the letter
            }}
          >
            {question[question.length - 1 >= 0 ? question.length - 1 : 0]}
          </h1>
        </Container>
        <div
          style={{
            paddingTop: "70vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={Gkey} alt="Gkey" style={{ width: "100px" }} />
            <h3>Lower Case</h3>
          </div>
          <h1 style={{ display: "inline", paddingTop: "2%" }}> or </h1>
          <div>
            <img src={Hkey} alt="Hkey" style={{ width: "100px" }} />
            <h3>Upper Case</h3>
          </div>
        </div>
      </div>
    );
  };

  const finishTestScreen = () => {
    //go to the home page after 4 seconds
    setTimeout(() => {
      window.location.href = "/";
    }, 10000);

    return (
      <div style={{ marginTop: "35vh" }}>
        <h1>Test is now complete</h1>
        <h3 style={{ marginTop: "5vh" }}>
          Thank you for participating 🎉 Please press the "SUBMIT ✅" button in
          the right corner to submit your results.
        </h3>
      </div>
    );
  };

  const renderPages = () => {
    switch (loadStage) {
      case LoadStage.INSTRUCTION:
        return (
          <div style={{}}>
            <Carousel data-bs-theme="dark" indicators={false} pause="hover">
              <Carousel.Item interval={5000}>
                <img
                  src={Slide1}
                  style={{ height: "50vh", width: "100vh" }}
                  alt="Slide1"
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  src={Slide2}
                  style={{ height: "50vh", width: "100vh" }}
                  alt="Slide2"
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  src={Slide3}
                  style={{ height: "50vh", width: "100vh" }}
                  alt="Slide3"
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  src={Slide4}
                  style={{ height: "50vh", width: "100vh" }}
                  alt="Slide4"
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  src={Slide5}
                  style={{ height: "50vh", width: "100vh" }}
                  alt="Slide5"
                />
              </Carousel.Item>
            </Carousel>
            <button
              className="button"
              style={{ marginTop: "10%", height: "6rem", width: "16rem" }}
              onClick={() => setLoadStage(LoadStage.LOADING)}
            >
              <span class="button-content" style={{ fontSize: "200%" }}>
                START
              </span>
            </button>
          </div>
        );
      case LoadStage.LOADING:
        return (
          <div style={{ paddingTop: "200px" }}>
            <h1>Get Ready!</h1>
            <CountDown callback={countDownDisplay} />
          </div>
        );
      case LoadStage.TEST:
        return alphabetDisplay();
      case LoadStage.PAUSE:
        return <div style={{ paddingTop: "300px" }}>{loadingScreen()}</div>;
      case LoadStage.DONE:
        return <div>{finishTestScreen()}</div>;
      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <Container style={{ textAlign: "center" }}>
      <div
        style={{
          textAlign: "center",
          height: "80vh",
        }}
      >
        {renderPages()}
      </div>
    </Container>
  );
};

export default AlphabetExperiment;
