import FluencyHeader from "literacy/components/student/fluency/assets/header";
import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import AssignCard from "literacy/assets/cards/student-assign-card";
import LineChart from "literacy/assets/assignchart";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import axios from "axios";

class Materials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assigns: [],
      score: [],
    };
  }

  componentDidMount = async () => {
    const assigns = [];
    // assignments
    const doc1 = await axios.get("/api/literacy/fluency/test");
    const testAssigns = doc1.data;
    if (testAssigns) {
      testAssigns.forEach((assign) => {
        if (assign.studentId === this.props.currentUser?._id) {
          assigns.push(assign);
        }
      });
    }
    const doc2 = await axios.get("/api/literacy/fluency/assign");
    const realAssigns = doc2.data;
    if (realAssigns) {
      realAssigns.forEach((assign) => {
        if (assign.studentId === this.props.currentUser?._id) {
          assigns.push(assign);
        }
      });
    }
    this.setState({ assigns });

    // score
    const doc3 = await axios.get(
      "/api/literacy/fluency/historyscore/" + this.props.currentUser?._id
    );
    if (doc3.data) {
      this.setState({ score: doc3.data.assignScore });
    }
  };

  render() {
    const { assigns, score } = this.state;
    return (
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
          fontFamily: "raven",
          height: window.innerHeight,
          overflowY: "scroll",
        }}
      >
        <FluencyHeader part="Results" backURL="/literacy/student/fluency" />
        <Container>
          <P2>Assignments</P2>
          <div style={{ display: "flex" }}>
            {assigns.map((assign) => (
              <AssignCard
                assign={assign}
                handleClick={() =>
                  (window.location =
                    "/literacy/student/fluency/results/" + assign._id)
                }
              />
            ))}
          </div>
        </Container>
        <br />
        <Container>
          <P2>Performence</P2>
          {score.length !== 0 ? (
            <LineChart
              data={score}
              title="Fluency Assignment Performence"
              color="#3E517A"
            />
          ) : null}
        </Container>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Materials);
