import React from "react";
import { Button, Container } from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';


export default (props) => {
  return (
    <Container>
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "15%",
        }}
      >
        <P1>
          Thank you for your participation. Press submit to record your responses.{" "}
        </P1>
        <br />
        <Button
          variant="contained"
          size="large"
          onClick={props.handleClick}
          style={{
            color: "white", backgroundColor: "#107896",
            width: 150,
            marginTop: 10,
          }}
        >
          Submit
        </Button>
      </div>
    </Container>
  );
};
