import react, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Page from '../utils/page';
import Pagination from '../utils/pagination';
import { Button } from "@material-ui/core";
import axios from "axios";

const GUI = () => {
  const [pages, setPages] = useState([]);
  const [currPage, setCurrPage] = useState(null);
  const [finalPage, setFinalPage] = useState(null);
  const { app } = useParams();
  const history = useHistory();

  useEffect(() => {
    // get the current app's pages ids
    (async () => {
      if (app) {
        const doc = await axios.get("/api/gui/app/" + app);
        if (doc.data) {
          setPages(doc.data.pages);
          setCurrPage(doc.data.pages[0]);
          setFinalPage(doc.data.final_page);
        } else {
          setPages([]);
          setCurrPage(null);
        }
      }
    })()
  }, [app]);

  const handleBackClick = () => {
    history.push("/gui/admin");
  };

  return (
    <div>
      <Button size="large" variant="contained" color="primary" onClick={handleBackClick} style={{position: "fixed", right: "20px", top: "20px"}}>Back</Button>
      <Page
        currPage={currPage}
      />
      <br />
      <Pagination
        app={app}
        pages={pages}
        currPage={currPage}
        finalPage={finalPage}
        onChange={(page) => setCurrPage(page)}
      />
    </div>
  )
}

export default GUI;