import React from "react";
import Welcome from "hearing-demo/components/welcome";
import SpeechInNoise from "hearing-demo/components/digits-test";
import End from "hearing-demo/components/end";
import axios from 'axios';
import FontComponent from 'assets/font-ui';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      process: "welcome",
      volume: 50,
      SNR: null,
    };
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/demo/digits' });
    setTimeout(() => {
      this.setState({ process: "testing" });
    }, 3000);
  };

  handleTestingClick = (SNR, timer) => {
    this.setState({ SNR, timer, process: "end" });
  };

  handleSubmitClick = async () => {
    window.location = "/hearing/demo/";
  };

  renderProcess = () => {
    const { process, volume } = this.state;
    switch (process) {
      case "welcome":
        return <Welcome />;
      case "testing":
        return (
          <SpeechInNoise
            volume={volume}
            handleClick={this.handleTestingClick}
          />
        );
      case "end":
        return <End handleClick={this.handleSubmitClick} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div style={{
        backgroundColor: "rgb(240,240,240)",
        fontFamily: "raven",
        height: window.innerHeight,
        overflowY: "scroll",
        paddingRight: "5%"
      }}>
          <FontComponent />
          {this.renderProcess()}
      </div>
    )
  }
}

export default Main;
