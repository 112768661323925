import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { setCurrentUser } from "redux/user/user-action";
import ErrorFallback from "assets/error-boundary";
import { ErrorBoundary } from "react-error-boundary";
// Image Process
import ImageProcessMain from "image-process/main";
import ImageProcessApplication from "image-process/application/index";
import ImageProcessClassification from "image-process/classification/index";
import ImageProcessClassificationDatabase from "image-process/classification/database";
import ImageProcessClassificationTest from "image-process/classification/test";

// Hearing
import Main from "main";
import HearingMain from "hearing/pages/main";
import CRMMain from "hearing/pages/crm-main";
import DigitsMain from "hearing/pages/digits-main";
import Digits1 from "hearing/pages/digits1/main";
import Digits2 from "hearing/pages/digits2/main";
import Digits3 from "hearing/pages/digits3/main";
import Digits4 from "hearing/pages/digits4/main";
import Digits5 from "hearing/pages/digits5/main";
import Digits6 from "hearing/pages/digits6/main";
import Digits7 from "hearing/pages/digits7/main";
import CRM1Main from "hearing/pages/crm1/main";
import CRM2Main from "hearing/pages/crm2/main";
import CRM3Main from "hearing/pages/crm3/main";
import CRM4Main from "hearing/pages/crm4/main";
import CRM5Main from "hearing/pages/crm5/main";
import CRM1Data from "hearing/pages/crm1/data";
import CRM2Data from "hearing/pages/crm2/data";
import CRM3Data from "hearing/pages/crm3/data";
import CRM4Data from "hearing/pages/crm4/data";
import CRM5Data from "hearing/pages/crm5/data";
import SignalLossTest from "hearing/pages/crm1/test";
import Questionnaire from "hearing/pages/questionnaire/main";
import Consents from "hearing/components/digits1/database/consents";
import PreTest from "hearing/components/digits1/database/pre-test";
import PostTest1 from "hearing/components/digits1/database/post-test-1";
import PostTest2 from "hearing/components/digits1/database/post-test-2";
import PostTest3 from "hearing/components/digits1/database/post-test-3";
import UserData from "hearing/components/digits1/database/user-data";
import DigitDatabase from "hearing/pages/digits1/database";
import DigitDatabase2 from "hearing/pages/digits2/database";
import DigitDatabase3 from "hearing/pages/digits3/database";
import DigitDatabase4 from "hearing/pages/digits4/database";
import DigitDatabase5 from "hearing/pages/digits5/database";
import DigitDatabase6 from "hearing/pages/digits6/database";
import DigitDatabase7 from "hearing/pages/digits7/database";
import HearingMainDemo from "hearing-demo/main";
import HearingDigitsDemo from "hearing-demo/pages/digits";
import HearingCrmDemo from "hearing-demo/pages/crm";
import HearingQuestionnaireDemo from "hearing-demo/pages/questionnaire";
import CRM1Tutor from "hearing/pages/crm1/tutor";

// iRSM
import IRSMMain from "iRSM/pages/main";
import IRSMData from "iRSM/pages/database";

// Questionnaire
import Aphab from "hearing/pages/questionnaire/aphab";
import SSQ from "hearing/pages/questionnaire/ssq";
import AphabA from "hearing/pages/questionnaire/aphab-a";
import AphabB from "hearing/pages/questionnaire/aphab-b";
import SSQLong from "hearing/pages/questionnaire/ssq-long";
import SSQShort from "hearing/pages/questionnaire/ssq-short";
import SSQComparison from "hearing/pages/questionnaire/ssq-com";
import SSQPrePost from "hearing/pages/questionnaire/ssq-prepost";
import COSI from "hearing/pages/questionnaire/COSI";
import COSIReturn from "hearing/pages/questionnaire/COSI-return";
import SSQDatabase from "hearing/pages/questionnaire/ssq-database";
import AphabDatabase from "hearing/pages/questionnaire/aphab-database";
import COSIDatabase from "hearing/pages/questionnaire/cosi-database";

// Appearance
import Appearance1MainPage from "appearance/pages/IAT1/main";
import AppearanceTestPage from "appearance/pages/test";
import Appearance1Database from "appearance/pages/IAT1/database";
import Appearance2MainPage from "appearance/pages/IAT2/main";
import Appearance2Eye from "appearance/components/IAT2/assets/gaze-cloud-api";
import Appearance2MouseDrawing from "appearance/components/IAT2/assets/mouse-draw";
import Appearance2EyeDrawing from "appearance/components/IAT2/assets/eye-draw";
import Appearance2LabEyeDrawing from "appearance/components/IAT2/assets/lab-data-draw";
// import Appearance2Database from "appearance/pages/IAT2/database";

// Literacy

import LiteracyMain from "literacy/pages/mainpage/mainpage";
// Student pages
// Fluency pages
import FluencyMain from "literacy/pages/studentpage/fluency/main";
import FluencyMaterials from "literacy/pages/studentpage/fluency/materials";
import FluencyAssignment from "literacy/pages/studentpage/fluency/assignment";
import FluencyResults from "literacy/pages/studentpage/fluency/results";
import FluencySingleResult from "literacy/pages/studentpage/fluency/single-result";
// Phoneme pages
import PhonemeMain from "literacy/pages/studentpage/phoneme/main";
import PhonemeMaterials from "literacy/pages/studentpage/phoneme/materials";
import PhonemeAssignment from "literacy/pages/studentpage/phoneme/assignment";
import PhonemeResults from "literacy/pages/studentpage/phoneme/results";
import PhonemeSingleResult from "literacy/pages/studentpage/phoneme/single-result";
// Print pages
import PrintMain from "literacy/pages/studentpage/print/main";
import PrintMaterials from "literacy/pages/studentpage/print/materials";
import PrintAssignment from "literacy/pages/studentpage/print/assignment";
import PrintResults from "literacy/pages/studentpage/print/results";
import PrintSingleResult from "literacy/pages/studentpage/print/single-result";
// Meaning pages
import MeaningMain from "literacy/pages/studentpage/meaning/main";
import MeaningMaterials from "literacy/pages/studentpage/meaning/materials";
import MeaningAssignment from "literacy/pages/studentpage/meaning/assignment";
import MeaningResults from "literacy/pages/studentpage/meaning/results";
import MeaningSingleResult from "literacy/pages/studentpage/meaning/single-result";

// Tutor pages
import TutorSignIn from "literacy/pages/tutorpage/tutor-signin-page";
// Fluency pages
import FluencyTutorMain from "literacy/pages/tutorpage/fluency/main";
import FluencyTutorTestData from "literacy/pages/tutorpage/fluency/test-data";
import FluencyTutorTrainData from "literacy/pages/tutorpage/fluency/train-data";
import FluencyTutorTestAllAssign from "literacy/pages/tutorpage/fluency/all-test";
import FluencyTutorTestOneAssign from "literacy/pages/tutorpage/fluency/one-test";
import FluencyTutorTrainAllAssign from "literacy/pages/tutorpage/fluency/all-assign";
import FluencyTutorTrainOneAssign from "literacy/pages/tutorpage/fluency/one-assign";
import FluencyTutorAllPerformance from "literacy/pages/tutorpage/fluency/all-performance";
import FluencyTutorOnePerformance from "literacy/pages/tutorpage/fluency/one-performance";
import FluencyTutorMaterials from "literacy/pages/tutorpage/fluency/materials";
// Phoneme Page
import PhonemeTutorMain from "literacy/pages/tutorpage/phoneme/main";
import PhonemeTutorPhonemeData from "literacy/pages/tutorpage/phoneme/phoneme-data";
import PhonemeTutorAudioData from "literacy/pages/tutorpage/phoneme/audio-data";
import PhonemeTutorAllAssign from "literacy/pages/tutorpage/phoneme/all-assign";
import PhonemeTutorOneAssign from "literacy/pages/tutorpage/phoneme/one-assign";
import PhonemeTutorAllTest from "literacy/pages/tutorpage/phoneme/all-test";
import PhonemeTutorOneTest from "literacy/pages/tutorpage/phoneme/one-test";
import PhonemeTutorAllPerformance from "literacy/pages/tutorpage/phoneme/all-performance";
import PhonemeTutorOnePerformance from "literacy/pages/tutorpage/phoneme/one-performance";
import PhonemeTutorMaterials from "literacy/pages/tutorpage/phoneme/materials";
import PhonemeTutorMultipleData from "literacy/pages/tutorpage/phoneme/multiple-choice-data";
import PhonemeTutorShortData from "literacy/pages/tutorpage/phoneme/short-answer-data";
import PhonemeTutorBlankData from "literacy/pages/tutorpage/phoneme/blank-data";
import PhonemeTutorTrueFalseData from "literacy/pages/tutorpage/phoneme/true-false-data";
// Print Page
import PrintTutorMain from "literacy/pages/tutorpage/print/main";
import PrintTutorShortData from "literacy/pages/tutorpage/print/short-answer-data";
import PrintTutorMultipleData from "literacy/pages/tutorpage/print/multiple-choice-data";
import PrintTutorBlankData from "literacy/pages/tutorpage/print/blank-data";
import PrintTutorTrueFalseData from "literacy/pages/tutorpage/print/true-false-data";
import PrintTutorAllTest from "literacy/pages/tutorpage/print/all-test";
import PrintTutorOneTest from "literacy/pages/tutorpage/print/one-test";
import PrintTutorAllAssign from "literacy/pages/tutorpage/print/all-assign";
import PrintTutorOneAssign from "literacy/pages/tutorpage/print/one-assign";
import PrintTutorAllPerform from "literacy/pages/tutorpage/print/all-performance";
import PrintTutorOnePerform from "literacy/pages/tutorpage/print/one-performance";
import PrintTutorMaterials from "literacy/pages/tutorpage/print/materials";

// Meaning Page
import MeaningTutorMain from "literacy/pages/tutorpage/meaning/main";
import MeaningTutorShortData from "literacy/pages/tutorpage/meaning/short-answer-data";
import MeaningTutorBlankData from "literacy/pages/tutorpage/meaning/blank-data";
import MeaningTutorTrueFalseData from "literacy/pages/tutorpage/meaning/true-false-data";
import MeaningTutorMultipleChoiceData from "literacy/pages/tutorpage/meaning/multiple-choice-data";
import MeaningTutorAllTest from "literacy/pages/tutorpage/meaning/all-test";
import MeaningTutorOneTest from "literacy/pages/tutorpage/meaning/one-test";
import MeaningTutorAllAssign from "literacy/pages/tutorpage/meaning/all-assign";
import MeaningTutorOneAssign from "literacy/pages/tutorpage/meaning/one-assign";
import MeaningTutorAllPerform from "literacy/pages/tutorpage/meaning/all-performance";
import MeaningTutorOnePerform from "literacy/pages/tutorpage/meaning/one-performance";
import MeaningTutorMaterials from "literacy/pages/tutorpage/meaning/materials";
import { TextField, Button } from "@material-ui/core";

// GUI pages
import GUITutor from "GUI/tutor-app";
import GUITutorApp from "GUI/tutor-app/app";
import GUIUserApp from "GUI/user-app/app";
import GUIUser from "GUI/user-app";
import GUIDatabase from "GUI/database/index";

// Audiogram page
import Audiogram from "audiogram/AudiogramScreen";

// Hearing Test
import Start from "hearing-test/pages/start";
import Id1 from "hearing-test/pages/id";
import Version1 from "hearing-test/pages/version1";
import Version2 from "hearing-test/pages/version2";
import Version3 from "hearing-test/pages/version3";
import Database from "hearing-test/pages/database";
import Test from "hearing-test/pages/test";
import Final from "hearing-test/pages/final";

//rehabapp pages
import RehabAppScreen from "rehabapp-admin/RehabappScreen";

import databaseMain from "database/main";

import { selectIsAuthorized } from "redux/authorized/authorized-selector";

import ProtectedRoute from "protected-route";

import LoginPage from "login-page";

// new main pages

import Evaluation from "main-pages/evaluation";

import Research from "main-pages/research";

import Team from "main-pages/team";

// mail page in admin
import MailListPage from "./GUI/tutor-app/mail-list";

class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     authorized: false,
  //     password: "",
  //   };
  // }

  // componentDidMount = async () => {
  //   const doc = await axios.get("/api/literacy/current_user");
  //   this.props.setCurrentUser(doc.data);
  // };

  // handleCheckPassword = () => {
  //   if (this.state.password === "thisisthepassword") {
  //     this.setState({ authorized: true });
  //   } else {
  //     window.alert("Wrong crendential, please retry!");
  //     this.setState({ password: "" });
  //   }
  // };

  beforeunload = (e) => {
    e.preventDefault();
    e.returnValue = true;
  };

  handleError = () => {
    setTimeout(() => {
      window.location = "/";
    }, 1000);
  };

  render() {
    // const { authorized, password } = this.state;
    const { authorized } = this.props;
    return (
      <BrowserRouter>
        {/* <ErrorBoundary FallbackComponent={ErrorFallback} onError={this.handleError}> */}
        {/* {window.location.href.includes("literacy") && <Header />} */}
        <Switch>
          <Route path="/login" component={LoginPage} />
          {/*hearing test*/}
          <Route exact path="/hearing-test" component={Start} />
          <Route exact path="/hearing-test/id" component={Id1} />
          <Route exact path="/hearing-test/version1/:id" component={Version1} />
          <Route exact path="/hearing-test/version2/:id" component={Version2} />
          <Route exact path="/hearing-test/version3/:id" component={Version3} />
          <Route exact path="/hearing-test/database" component={Database} />
          <Route exact path="/hearing-test/test" component={Test} />
          <Route exact path="/hearing-test/final" component={Final} />
          {/* main routes */}

          {/* Audiogram */}
          <Route exact path="/audiogram" component={Audiogram} />
          {/* Hearing */}
          <Route exact path="/" component={Main} />
          <Route exact path="/hearing/crm" component={CRMMain} />
          <Route exact path="/hearing/crm/1" component={CRM1Main} />
          <Route exact path="/hearing/crm/1/database" component={CRM1Data} />
          <Route exact path="/hearing/crm/2/database" component={CRM2Data} />
          <Route exact path="/hearing/crm/3/database" component={CRM3Data} />
          <Route exact path="/hearing/crm/4/database" component={CRM4Data} />
          <Route exact path="/hearing/crm/5/database" component={CRM5Data} />
          <Route
            exact
            path="/hearing/digits/1/database"
            component={DigitDatabase}
          />
          <Route
            exact
            path="/hearing/digits/2/database"
            component={DigitDatabase2}
          />
          <Route
            exact
            path="/hearing/digits/3/database"
            component={DigitDatabase3}
          />
          <Route
            exact
            path="/hearing/digits/4/database"
            component={DigitDatabase4}
          />
          <Route
            exact
            path="/hearing/digits/5/database"
            component={DigitDatabase5}
          />
          <Route
            exact
            path="/hearing/digits/6/database"
            component={DigitDatabase6}
          />
          <Route
            exact
            path="/hearing/digits/7/database"
            component={DigitDatabase7}
          />
          <Route exact path="/hearing/demo" component={HearingMainDemo} />
          <Route
            exact
            path="/hearing/demo/digits"
            component={HearingDigitsDemo}
          />
          <Route exact path="/hearing/demo/crm" component={HearingCrmDemo} />
          <Route
            exact
            path="/hearing/demo/questionnaire"
            component={HearingQuestionnaireDemo}
          />
          {/* iRSM */}
          <Route exact path="/irsm" component={IRSMMain} />
          <Route exact path="/irsm/database" component={IRSMData} />
          {/* Appearance */}
          <Route exact path="/appearance/" component={Appearance1MainPage} />
          <Route exact path="/appearance/test" component={AppearanceTestPage} />
          <Route
            exact
            path="/appearance/database"
            component={Appearance1Database}
          />
          <Route exact path="/appearance/2/" component={Appearance2MainPage} />
          <Route exact path="/appearance/2/eye" component={Appearance2Eye} />
          <Route
            exact
            path="/appearance/2/draw/mouse"
            component={Appearance2MouseDrawing}
          />
          <Route
            exact
            path="/appearance/2/draw/eye"
            component={Appearance2EyeDrawing}
          />
          <Route
            exact
            path="/appearance/2/draw/labeye"
            component={Appearance2LabEyeDrawing}
          />
          {/* <Route exact path="/appearance/2/database" component={Appearance2Database} /> */}
          {/* Literacy */}
          <Route exact path="/literacy" component={LiteracyMain} />
          <Route exact path="/literacy#" component={LiteracyMain} />
          <Route exact path="/literacy/tutor/signin" component={TutorSignIn} />
          {/* Student Fluency */}
          <Route
            exact
            path="/literacy/student/fluency"
            component={FluencyMain}
          />
          <Route
            exact
            path="/literacy/student/fluency/learning"
            component={FluencyMaterials}
          />
          <Route
            exact
            path="/literacy/student/fluency/assignment"
            component={FluencyAssignment}
          />
          <Route
            exact
            path="/literacy/student/fluency/results"
            component={FluencyResults}
          />
          <Route
            exact
            path="/literacy/student/fluency/results/:id"
            component={FluencySingleResult}
          />
          {/* Student Phoneme */}
          <Route
            exact
            path="/literacy/student/phoneme"
            component={PhonemeMain}
          />
          <Route
            exact
            path="/literacy/student/phoneme/learning"
            component={PhonemeMaterials}
          />
          <Route
            exact
            path="/literacy/student/phoneme/assignment"
            component={PhonemeAssignment}
          />
          <Route
            exact
            path="/literacy/student/phoneme/results"
            component={PhonemeResults}
          />
          <Route
            exact
            path="/literacy/student/phoneme/results/:id"
            component={PhonemeSingleResult}
          />
          {/* Student Meaning */}
          <Route
            exact
            path="/literacy/student/meaning"
            component={MeaningMain}
          />
          <Route
            exact
            path="/literacy/student/meaning/learning"
            component={MeaningMaterials}
          />
          <Route
            exact
            path="/literacy/student/meaning/assignment"
            component={MeaningAssignment}
          />
          <Route
            exact
            path="/literacy/student/meaning/results"
            component={MeaningResults}
          />
          <Route
            exact
            path="/literacy/student/meaning/results/:id"
            component={MeaningSingleResult}
          />
          {/* Student Print */}
          <Route exact path="/literacy/student/print" component={PrintMain} />
          <Route
            exact
            path="/literacy/student/print/learning"
            component={PrintMaterials}
          />
          <Route
            exact
            path="/literacy/student/print/assignment"
            component={PrintAssignment}
          />
          <Route
            exact
            path="/literacy/student/print/results"
            component={PrintResults}
          />
          <Route
            exact
            path="/literacy/student/print/results/:id"
            component={PrintSingleResult}
          />
          {/* Tutor Fluency */}
          <Route
            exact
            path="/literacy/tutor/fluency"
            component={FluencyTutorMain}
          />
          <Route
            exact
            path="/literacy/tutor/fluency/testdata"
            component={FluencyTutorTestData}
          />
          <Route
            exact
            path="/literacy/tutor/fluency/traindata"
            component={FluencyTutorTrainData}
          />
          <Route
            exact
            path="/literacy/tutor/fluency/test"
            component={FluencyTutorTestAllAssign}
          />
          <Route
            path="/literacy/tutor/fluency/test/:id"
            component={FluencyTutorTestOneAssign}
          />
          <Route
            exact
            path="/literacy/tutor/fluency/assign"
            component={FluencyTutorTrainAllAssign}
          />
          <Route
            path="/literacy/tutor/fluency/assign/:id"
            component={FluencyTutorTrainOneAssign}
          />
          <Route
            exact
            path="/literacy/tutor/fluency/performance"
            component={FluencyTutorAllPerformance}
          />
          <Route
            path="/literacy/tutor/fluency/performance/:id"
            component={FluencyTutorOnePerformance}
          />
          {/* Tutor Phoneme */}
          <Route
            exact
            path="/literacy/tutor/phoneme"
            component={PhonemeTutorMain}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/audiodata"
            component={PhonemeTutorAudioData}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/phonemedata"
            component={PhonemeTutorPhonemeData}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/assign"
            component={PhonemeTutorAllAssign}
          />
          <Route
            path="/literacy/tutor/phoneme/assign/:id"
            component={PhonemeTutorOneAssign}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/test"
            component={PhonemeTutorAllTest}
          />
          <Route
            path="/literacy/tutor/phoneme/test/:id"
            component={PhonemeTutorOneTest}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/performance"
            component={PhonemeTutorAllPerformance}
          />
          <Route
            path="/literacy/tutor/phoneme/performance/:id"
            component={PhonemeTutorOnePerformance}
          />
          <Route
            path="/literacy/tutor/phoneme/multiple"
            component={PhonemeTutorMultipleData}
          />
          <Route
            path="/literacy/tutor/phoneme/short"
            component={PhonemeTutorShortData}
          />
          <Route
            path="/literacy/tutor/phoneme/blank"
            component={PhonemeTutorBlankData}
          />
          <Route
            path="/literacy/tutor/phoneme/truefalse"
            component={PhonemeTutorTrueFalseData}
          />
          {/* Tutor Print */}
          <Route
            exact
            path="/literacy/tutor/print/"
            component={PrintTutorMain}
          />
          <Route
            exact
            path="/literacy/tutor/print/short"
            component={PrintTutorShortData}
          />
          <Route
            exact
            path="/literacy/tutor/print/multiple"
            component={PrintTutorMultipleData}
          />
          <Route
            exact
            path="/literacy/tutor/print/blank"
            component={PrintTutorBlankData}
          />
          <Route
            path="/literacy/tutor/print/truefalse"
            component={PrintTutorTrueFalseData}
          />
          <Route
            exact
            path="/literacy/tutor/print/test"
            component={PrintTutorAllTest}
          />
          <Route
            path="/literacy/tutor/print/test/:id"
            component={PrintTutorOneTest}
          />
          <Route
            exact
            path="/literacy/tutor/print/assign"
            component={PrintTutorAllAssign}
          />
          <Route
            path="/literacy/tutor/print/assign/:id"
            component={PrintTutorOneAssign}
          />
          <Route
            exact
            path="/literacy/tutor/print/performance"
            component={PrintTutorAllPerform}
          />
          <Route
            path="/literacy/tutor/print/performance/:id"
            component={PrintTutorOnePerform}
          />
          {/* Tutor Meaning */}
          <Route
            exact
            path="/literacy/tutor/meaning/"
            component={MeaningTutorMain}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/short"
            component={MeaningTutorShortData}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/blank"
            component={MeaningTutorBlankData}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/multiple"
            component={MeaningTutorMultipleChoiceData}
          />
          <Route
            path="/literacy/tutor/meaning/truefalse"
            component={MeaningTutorTrueFalseData}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/test"
            component={MeaningTutorAllTest}
          />
          <Route
            path="/literacy/tutor/meaning/test/:id"
            component={MeaningTutorOneTest}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/assign"
            component={MeaningTutorAllAssign}
          />
          <Route
            path="/literacy/tutor/meaning/assign/:id"
            component={MeaningTutorOneAssign}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/performance"
            component={MeaningTutorAllPerform}
          />
          <Route
            path="/literacy/tutor/meaning/performance/:id"
            component={MeaningTutorOnePerform}
          />
          {/* Tutor Materials */}
          <Route
            exact
            path="/literacy/tutor/fluency/materials"
            component={FluencyTutorMaterials}
          />
          <Route
            exact
            path="/literacy/tutor/print/materials"
            component={PrintTutorMaterials}
          />
          <Route
            exact
            path="/literacy/tutor/meaning/materials"
            component={MeaningTutorMaterials}
          />
          <Route
            exact
            path="/literacy/tutor/phoneme/materials"
            component={PhonemeTutorMaterials}
          />

          {/****************Protected routes start****************/}
          <ProtectedRoute
            exact
            path="/gui/admin"
            component={GUITutor}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/gui/admin/:app"
            component={GUITutorApp}
            authorized={authorized}
          />
          {/****************Protected routes end****************/}

          <Route exact path="/gui" component={GUIUser} />
          <Route exact path="/gui/:app" component={GUIUserApp} />
          <Route exact path="/gui/:app/database" component={GUIDatabase} />

          <Route exact path="/evaluation" component={Evaluation} />

          <Route exact path="/research" component={Research} />

          <Route exact path="/team" component={Team} />

          <Route exact path="/rehabapp" component={RehabAppScreen} />

          <Route exact path="/mail-list" component={MailListPage} />

          {/****************Protected routes****************/}

          <ProtectedRoute
            exact
            path="/database"
            component={databaseMain}
            authorized={authorized}
          />

          {/* Image Process */}
          <ProtectedRoute
            exact
            path="/imageprocess"
            component={ImageProcessMain}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/imageprocess/application"
            component={ImageProcessApplication}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/imageprocess/classification"
            component={ImageProcessClassification}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/imageprocess/classification/database"
            component={ImageProcessClassificationDatabase}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/imageprocess/classification/test"
            component={ImageProcessClassificationTest}
            authorized={authorized}
          />

          {/* Hearing */}
          <ProtectedRoute
            exact
            path="/hearing"
            component={HearingMain}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/1/tutor"
            component={CRM1Tutor}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/2"
            component={CRM2Main}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/3"
            component={CRM3Main}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/4"
            component={CRM4Main}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/5"
            component={CRM5Main}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/crm/test"
            component={SignalLossTest}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits"
            component={DigitsMain}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/1"
            component={Digits1}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/2"
            component={Digits2}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/3"
            component={Digits3}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/4"
            component={Digits4}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/5"
            component={Digits5}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/6"
            component={Digits6}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/7"
            component={Digits7}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/consents"
            component={Consents}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/pretest"
            component={PreTest}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/posttest1"
            component={PostTest1}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/posttest2"
            component={PostTest2}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/posttest3"
            component={PostTest3}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/digits/database/userdata"
            component={UserData}
            authorized={authorized}
          />

          {/* Questionnaire */}
          <ProtectedRoute
            exact
            path="/hearing/questionnaire"
            component={Questionnaire}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/aphab"
            component={Aphab}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq"
            component={SSQ}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/aphab/a"
            component={AphabA}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/aphab/b"
            component={AphabB}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq/long"
            component={SSQLong}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq/short"
            component={SSQShort}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq/comparison"
            component={SSQComparison}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq/prepost"
            component={SSQPrePost}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/ssq/database"
            component={SSQDatabase}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/aphab/database"
            component={AphabDatabase}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/cosi"
            component={COSI}
            authorized={authorized}
          />
          <ProtectedRoute
            path="/hearing/questionnaire/cosi-return/:id"
            component={COSIReturn}
            authorized={authorized}
          />
          <ProtectedRoute
            exact
            path="/hearing/questionnaire/cosi/database"
            component={COSIDatabase}
            authorized={authorized}
          />
        </Switch>
        {/* </ErrorBoundary> */}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  authorized: selectIsAuthorized(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
