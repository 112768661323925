import React, { useState, useEffect } from "react";
import MainCard from "literacy/assets/cards/mainpagecard";
import { connect } from "react-redux";
import SignInHeader from "literacy/components/header/signinheader";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import IntroVideoStepper from "literacy/assets/intro-video-stepper";
import axios from "axios";
import { ExportReactCSV } from "assets/csv";
import Header from "literacy/components/header/header";

const imagePath = process.env.PUBLIC_URL + "/literacy/images/";
const images = [
  "soundmain.png",
  "printmain.jpg",
  "speedmain.jpeg",
  "meaningmain.jpeg",
];

const Main = ({ currentUser }) => {
  const [introVideoOpen, setIntroVideoOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [materialsData, setMaterialsData] = useState([]);

  useEffect(async () => {
    // change title
    document.title = "Success with Literacy";

    // download student materials data
    const doc = await axios.get("/api/literacy/student");
    setHeaders([
      "name",
      "email",
      "fluency_w1",
      "fluency_w2",
      "meaning_w1",
      "meaning_w2",
      "phoneme_w1",
      "phoneme_w2",
      "print_w1",
      "print_w2",
    ]);
    const data = [];
    doc?.data.map((_) => {
      data.push([
        _.displayName,
        _.email,
        String(_.fluency_materials.w1),
        String(_.fluency_materials.w2),
        String(_.meaning_materials.w1),
        String(_.meaning_materials.w2),
        String(_.phoneme_materials.w1),
        String(_.phoneme_materials.w2),
        String(_.print_materials.w1),
        String(_.print_materials.w2),
      ]);
    });
    setMaterialsData(data);
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser.intro_video) {
      setIntroVideoOpen(true);
    }
  }, [currentUser]);

  const handleVideoChange = async () => {
    if (!!file) {
      const doc = await axios.get("/api/literacy/tutor/introvideo");
      const uploadConfigs = doc.data;
      await axios
        .put(uploadConfigs.url, file, {
          headers: {
            "Content-type": "video/mp4",
          },
        })
        .catch((err) => console.log(err));
      window.location = "/literacy";
    }
  };

  const handleFinishIntrovideo = async () => {
    await axios.post("/api/literacy/student/introvideo");
    await setIntroVideoOpen(false);
    window.location = "/literacy";
  };

  const getAssignProgress = () => {
    if (
      currentUser?.phoneme_score?.length === 0 ||
      !currentUser?.phoneme_materials?.w1
    ) {
      return "Sound training, week 1";
    } else if (
      currentUser?.phoneme_score?.length === 1 ||
      !currentUser?.phoneme_materials?.w2
    ) {
      return "Sound training, week 2";
    }

    if (
      currentUser?.print_score?.length === 0 ||
      !currentUser?.print_materials?.w1
    ) {
      return "Print training, week 1";
    } else if (
      currentUser?.print_score?.length === 1 ||
      !currentUser?.print_materials?.w2
    ) {
      return "Print training, week 2";
    }

    if (
      currentUser?.meaning_score?.length === 0 ||
      !currentUser?.meaning_materials?.w1
    ) {
      return "Meaning training, week 1";
    } else if (
      currentUser?.meaning_score?.length === 1 ||
      !currentUser?.meaning_materials?.w2
    ) {
      return "Meaning training, week 2";
    }
    if (currentUser?.fluency_score?.length === 0) {
      return "Speed training, test";
    } else if (
      currentUser?.fluency_score?.length === 1 ||
      !currentUser?.fluency_materials?.w1
    ) {
      return "Speed training, week 1";
    } else if (
      currentUser?.fluency_score?.length === 2 ||
      !currentUser?.fluency_materials?.w1
    ) {
      return "Speed training, week 2";
    }
  };

  const getUserOrLogin = () => {
    switch (currentUser) {
      case null:
        return;
      case false:
        return <SignInHeader />;
      default:
        if (currentUser.role === "tutor") {
          return (
            <div>
              <P1>Welcome to Literacy Training</P1>
              <P3>Upload introduction video</P3>
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
              <Button variant="outlined" onClick={handleVideoChange}>
                Upload
              </Button>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <P3>Download students materials data</P3>
                <ExportReactCSV
                  data={materialsData}
                  headers={headers}
                  fileName="Literacy Materials"
                />
              </div>
            </div>
          );
        }
        return (
          <div>
            <P1>Welcome to Literacy Training</P1>
            <P2>Hello, {currentUser.displayName ?? "tutor"}</P2>
            {currentUser?.intro_video ? (
              <div style={{ display: "flex" }}>
                <P3>
                  <i
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIntroVideoOpen(true)}
                  >
                    Click here
                  </i>{" "}
                  to learn about this website again.
                </P3>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <P3>
                  First time here?{" "}
                  <i
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIntroVideoOpen(true)}
                  >
                    Click here
                  </i>{" "}
                  to learn about this website.
                </P3>
              </div>
            )}
            <br />
            {currentUser && (
              <div>
                <P2>
                  This week, you are working on:{" "}
                  <P3 style={{ color: "blue" }}>{getAssignProgress()}</P3>
                </P2>
              </div>
            )}
            <Dialog
              open={introVideoOpen}
              onClose={() => setIntroVideoOpen(false)}
              fullWidth
              fullScreen
            >
              <DialogTitle id="dialog-title">
                <P1>Introduction videos</P1>
              </DialogTitle>
              <DialogContent>
                <IntroVideoStepper handleFinish={handleFinishIntrovideo} />
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  variant="contained"
                  autoFocus
                  onClick={() => setIntroVideoOpen(false)}
                  color="secondary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
  };

  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
        fontFamily: "raven",
        height: window.innerHeight,
        overflowY: "scroll",
      }}
    >
      <Header />
      <div className="jumbotron row" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="col-8"> {getUserOrLogin()}</div>
        <div className="col-4">
          <img
            src={process.env.PUBLIC_URL + "/literacy/images/rehab.png"}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {currentUser ? (
        currentUser.role === "student" ? (
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "100px" }}
          >
            <MainCard
              title={"Sound Training"}
              page={"/literacy/student/phoneme"}
              image={imagePath + images[0]}
            />
            <MainCard
              title={"Print Training"}
              page={"/literacy/student/print"}
              image={imagePath + images[1]}
            />
            <MainCard
              title={"Meaning Training"}
              page={"/literacy/student/meaning"}
              image={imagePath + images[3]}
            />
            <MainCard
              title={"Speed Training"}
              page={"/literacy/student/fluency"}
              image={imagePath + images[2]}
            />
          </div>
        ) : (
          <div className="row" style={{ justifyContent: "center" }}>
            <MainCard
              title={"Sound Training"}
              page={"/literacy/tutor/phoneme"}
              image={imagePath + images[0]}
            />
            <MainCard
              title={"Print Training"}
              page={"/literacy/tutor/print"}
              image={imagePath + images[1]}
            />

            <MainCard
              title={"Meaning Training"}
              page={"/literacy/tutor/meaning"}
              image={imagePath + images[3]}
            />
            <MainCard
              title={"Speed Training"}
              page={"/literacy/tutor/fluency"}
              image={imagePath + images[2]}
            />
          </div>
        )
      ) : (
        <div className="row" style={{ justifyContent: "center" }}>
          <MainCard
            title={"Sound Training"}
            page={"/literacy"}
            image={imagePath + images[0]}
          />
          <MainCard
            title={"Print Training"}
            page={"/literacy"}
            image={imagePath + images[1]}
          />
          <MainCard
            title={"Meaning Training"}
            page={"/literacy"}
            image={imagePath + images[3]}
          />
          <MainCard
            title={"Speed Training"}
            page={"/literacy"}
            image={imagePath + images[2]}
          />
        </div>
      )}
      <div
        class="row"
        style={{ padding: "5rem", textAlign: "center" }}
        id="contact"
      >
        <P2>
          <b>Contact Us</b>
        </P2>
        <div class="col">
          <P3>Email jcbhlab@ualberta.ca with your questions or concerns.</P3>
          <P3>
            Please include your phone number if you would prefer a return phone
            call.
          </P3>
          <P3>You will receive a response within 2 business days.</P3>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Main);
