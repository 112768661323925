import React, { useState, useEffect } from "react";
import "../styles.css";
import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';
import FontComponent from 'assets/font-ui';


const DecoratedQuestionnaire = (props) => {

  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire' });
  });

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => {props.history.push(page)}, 2000);
  }

  return (
    <div>
      <div style={{  backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)", height: window.innerHeight * 0.45, width: "100%", position: "relative"}}>
        <div style={{ height: "40%"}}>
        </div>
        <div style={{ color: "white", paddingLeft: "10%", fontFamily: "sans-serif"}}>
          <P1><b>Welcome to our Collection of Questionnaires!</b></P1>
        </div>
      </div>
      <div className="row" style={{height: window.innerHeight * 0.4, width: "100%", marginLeft:0, marginRight: 0}}>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/questionnaire/aphab')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >APHAB</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/questionnaire/cosi')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >COSI</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick("/hearing/questionnaire/ssq")}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >SSQ</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
      </div>
      <div style={{height: window.innerHeight * 0.15, backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)" }}>
        <Button style={{ marginLeft: "45%", marginTop: "10px"}} color="primary" size="large" variant="outlined" onClick={() => window.location = "/hearing/questionnaire/cosi/database"}>COSI Database</Button>
      </div>
      <Backdrop style={{zIndex: 2}} open={open}>
        <CircularProgress color="primary" style={{width: "100px", height: "100px"}} />
      </Backdrop>
      <FontComponent />
    </div>
  )
}

export default DecoratedQuestionnaire;
