import React from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import Table from "literacy/components/tutor/thread-data-table/true-false-table";

class TutorPrintData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trueFalseAnswerDataW1: [],
      trueFalseAnswerDataW2: [],
      trueFalseAnswerAccess: [],
      question: "",
      answer: "",
      section: "w1"
    };
  }

  componentDidMount = async () => {
    const doc1 = await axios.get("/api/literacy/print/truefalse/table/w1");
    const doc2 = await axios.get("/api/literacy/print/truefalse/table/w2");
    if (doc1.data) {
      this.setState({ trueFalseAnswerDataW1: doc1.data });
    }
    if (doc2.data) {
      this.setState({ trueFalseAnswerDataW2: doc2.data });
    }
  };

  addNewData = async () => {
    const { answer, question, section } = this.state;
    await axios.post("/api/literacy/print/truefalse", { answer, question, version: section });
    await this.setState({ answer: "", question: "" });
    this.componentDidMount();
  };

  handleEdit = async (id, newRow) => {
    await axios.put("/api/literacy/print/truefalse/" + id, newRow);
    this.componentDidMount();
  }

  deleteData = async (id) => {
    await axios.delete("/api/literacy/print/truefalse/" + id);
    this.componentDidMount();
  };

  handleSectionChange = (e) => {
    this.setState({ section: e.target.value });
  }

  renderTable = () => {
    const { section, trueFalseAnswerDataW1, trueFalseAnswerDataW2 } = this.state;
    switch (section) {
      case "w1":
        return <Container>
          <Table
            rows={trueFalseAnswerDataW1}
            onEdit={this.handleEdit}
            handleDelete={this.deleteData}
          />
        </Container>
      case "w2":
        return <Container>
          <Table
            rows={trueFalseAnswerDataW2}
            onEdit={this.handleEdit}
            handleDelete={this.deleteData}
          />
        </Container>
    }
  }

  render() {
    const { question, answer, section } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Modify the Print True False Question Data</h2>
          <br />
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} href="/literacy/tutor/print">
            Go back
          </Button>
        </div>
        <Container>
          <InputLabel id="label">Assignment Section</InputLabel>
          <Select value={section} onChange={this.handleSectionChange}>
            <MenuItem value="w1">Week 1</MenuItem>
            <MenuItem value="w2">Week 2</MenuItem>
          </Select>
        </Container>
        <Container>
          <TextField
            id="standard-basic"
            label="question"
            value={question}
            autoComplete="off"
            style={{ marginRight: 20, width: 500 }}
            onChange={(e) => this.setState({ question: e.target.value })}
          />
          <br />
          <RadioGroup row value={answer} onChange={(e) => this.setState({ answer: e.target.value })}>
            <FormControlLabel value="true" control={<Radio color="primary" />} label="True" />
            <FormControlLabel value="false" control={<Radio color="primary" />} label="False" />
          </RadioGroup>
          <br />
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} style={{ marginTop: 10 }} onClick={this.addNewData}>
            Add
          </Button>
        </Container>
        <br />
        <br />
        {this.renderTable()}
        <br />
        <br />
      </div>
    );
  }
}

export default TutorPrintData;
