// readyToContinue is a function that is passed to components their id when the one component is complete
// resetReadyComponents is a function that is passed to components to reset the readyComponents list(empty the list)
const initialState = {
  readyComponents: [],
};

const readyComponentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "READY_TO_CONTINUE":
      return {
        ...state,
        readyComponents: [...state.readyComponents, action.payload],
      };
    case "RESET_READY_COMPONENTS":
      return {
        ...state,
        readyComponents: [],
      };
    default:
      return state;
  }
};

export const readyToContinueRedux = (payload) => ({
  type: "READY_TO_CONTINUE",
  payload,
});

export const resetReadyComponents = () => ({
  type: "RESET_READY_COMPONENTS",
});

export default readyComponentReducer;
