import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { P2 } from "assets/fonts";

const EyeDrawing = (props) => {
    const [array, setArray] = useState([]);
    const [index, setIndex] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const fileReader = new FileReader();

    useEffect(() => {
        if (array.length !== 0) {
            drawing();
        }
    }, [array, index]);

    const handleOnSubmit = (e) => {
        const file = e.target.files[0];

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text)
            };
            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = string => {
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        const rawArray = csvRows.map(row => {
            const _ = row.split(",");
            return parseInt(_[10]) ? [parseInt(_[10]), parseInt(_[11]), parseInt(_[12]), parseFloat(_[13])] : null;
        });
        const array = rawArray.filter(data => data !== null);
        setStartTime(array[0][2]);
        setArray(array);
    };

    const drawPoint = (context, x, y, label, color, size) => {
        if (color == null) {
            color = '#000';
        }
        if (size == null) {
            size = 5;
        }

        var radius = 0.5 * size;

        // to increase smoothing for numbers with decimal part
        var pointX = Math.round(x - radius);
        var pointY = Math.round(y - radius);

        context.beginPath();
        context.fillStyle = color;
        context.fillRect(pointX, pointY, size, size);
        context.fill();

        if (!label) {
            var textX = Math.round(x);
            var textY = Math.round(pointY - 5);

            context.font = 'Italic 14px Arial';
            context.fillStyle = color;
            context.textAlign = 'center';
            context.fillText(label, textX, textY);
        }
    }

    const drawing = () => {
        const canvas = document.getElementById("eye-track-canvas");
        const context = canvas.getContext('2d');

        for (let i = 0; i < array.length; i++) {
            (() => {
                const data = array[i];
                setTimeout(() => {
                    drawPoint(context, data[0], data[1], i.toString(), "white", 5 * data[3]);
                }, (data[2] - startTime));
            })();
        }
    }

    return (
        <div style={{ textAlign: "center" }}>

            <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                <input type={"file"} accept={".csv"} onChange={handleOnSubmit} />
            </div>

            <canvas id="eye-track-canvas" width="750px" height="500" style={{ backgroundColor: "black" }}></canvas>
        </div>
    );
}

export default EyeDrawing;