import React from "react";
import styles from "./OurIdea.module.css";

const ImageGrid = ({ images, gridClass }) => {
  return (
    <div className={`${styles.imageGrid} ${styles[gridClass]}`}>
      <div className={styles.imageRow}>
        {images.map((image, index) => (
          <div key={index} className={styles.imageColumn}>
            <img
              loading="lazy"
              src={image}
              alt={`Grid image ${index + 1}`}
              className={styles.gridImage}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
