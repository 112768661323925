import React, { Component } from "react";
import Process from "literacy/assets/process";
import { connect } from "react-redux";
import PrintHeader from "literacy/components/student/print/assets/header";
import { Container } from "@material-ui/core";
import TrainCard from "literacy/assets/cards/trainpagecard";

class PrintTrain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTrain: false,
    };
  }

  render() {
    const { currentUser } = this.props;
    return (
      <div style={{  backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        <PrintHeader part="Training Main Page" />
        <Container style={{ paddingBottom: 50 }}>
          {currentUser ? (
            <div className="row">
              <TrainCard
                title="Learning"
                page="/literacy/student/print/learning"
                description="In this section, you will learn about different print patterns in English language."
              />
              <TrainCard
                title="Assignment"
                page="/literacy/student/print/assignment"
                description="In this section, we will test your awareness of print patterns."
              />
              <TrainCard
                title="Results"
                page="/literacy/student/print/results"
                description="In this section, you can take a look at the results of your assignments."
              />
            </div>
          ) : (
            <Process />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(PrintTrain);
