import React, { useState, useEffect } from "react";
import {
  Radio,
  FormGroup,
  FormControlLabel,
  Container,
} from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const MultipleChoice = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/radio/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(() => {
    handleUpdateAssign();
    if (!!answer) {
      readyToContinue(id);
    }
  }, [answer]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "radio",
      question,
      options,
      answer,
    };
    updateAssign(assign);
  };

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>{question}</P2>
      <br />
      <FormGroup>
        {options.map((opt, i) => (
          <div key={i}>
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={answer === opt}
                  onChange={(e) => setAnswer(opt)}
                />
              }
              label={
                <P3 style={{ marginLeft: "10px", marginTop: "10px" }}>{opt}</P3>
              }
            />
          </div>
        ))}
      </FormGroup>
      <br />
    </Container>
  );
};

export default MultipleChoice;
