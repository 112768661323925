import React from "react";
import styles from "./JoinTeam.module.css";
import bcal from "../assets/images/bcal.png";

const Position = ({ title, duration, startYear }) => (
  <>
    {title} <br />
    <span className={styles.positionDuration}>
      {duration} - {startYear ? `Starting ${startYear}` : "open"}
    </span>
    <br />
    <br />
  </>
);

const JoinTeam = () => {
  const positions = [
    { title: "Master Student", duration: "2 years", startYear: null },
    { title: "Post-Doctoral Fellow", duration: "2 years", startYear: 2024 },
  ];

  return (
    <section className={styles.joinUs}>
      <div className={styles.container}>
        <div className={styles.imageColumn}>
          <img
            loading="lazy"
            src={bcal}
            className={styles.teamImage}
            alt="Join Our Team"
          />
        </div>
        <div className={styles.contentColumn}>
          <div className={styles.content}>
            <h1 className={styles.title}>Join Our Team</h1>
            <div className={styles.positions}>
              {positions.map((position, index) => (
                <Position key={index} {...position} />
              ))}
            </div>
            <div className={styles.contactInfo}>
              <span className={styles.emailLabel}>Email Us: </span>
              <a
                href="mailto:bcal@ualberta.ca"
                className={styles.emailLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                bcal@ualberta.ca
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinTeam;
