import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
// common
import CheckBox from "GUI/tutor-components/checkbox";
import ConsentCheckbox from "GUI/tutor-components/consent-check";
import MultipleChoice from "GUI/tutor-components/multiple-choice";
import DatePicker from "GUI/tutor-components/date-picker";
import TextField from "GUI/tutor-components/text-field";
import PureText from "GUI/tutor-components/pure-text";
import Select from "GUI/tutor-components/select";
import AgeSelect from "GUI/tutor-components/age-select";
import PDF from "GUI/tutor-components/pdf";
import Image from "GUI/tutor-components/image";
import ImageRandom from "GUI/tutor-components/image-random";
import Slider from "GUI/tutor-components/slider";
import VolumeControl from "GUI/tutor-components/volume-control";
// digits in noise
import DigitsDemo from "GUI/tutor-components/digits-demo";
import Digits from "GUI/tutor-components/digits";
import RecoveryStimulus from "GUI/tutor-components/recovery-stimulus";
// crm
import CRM from "GUI/tutor-components/crm";
import AlphabetExperiment from "GUI/tutor-components/alphabet-experiment";
import DfdExperiment from "GUI/tutor-components/dfd";
// others
import OHHIS from "GUI/tutor-components/OHHIS";
import axios from "axios";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { DrawerState } from "GUI/utils/page";
import RandomId from "./random-id";
import StudyId from "./study-id";

const RowContainer = (props) => {
  const { id, drawerState, handleInsertComponent, update, updateContainers } =
    props;
  const [children, setChildren] = useState([]);
  const [amount, setAmount] = useState([]);
  const [deleteComponentDialog, setDeleteComponentDialog] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/row/" + id);
        setChildren(doc.data.children);
        setAmount(new Array(doc.data.amount).fill(""));
      })();
    }
  }, [id, update]);

  const handleDeleteComponent = async () => {
    const index = deleteComponentDialog;
    setDeleteComponentDialog(false);
    const com = children[index];
    await axios.delete("/api/gui/" + com.type + "/" + com.id);
    const newChildren = [
      ...children.slice(0, index),
      {},
      ...children.slice(index + 1),
    ];
    await axios.put("/api/gui/row/" + id, { children: newChildren });
    updateContainers();
  };

  const renderComponent = (com) => {
    switch (com?.type) {
      // common
      case "checkbox":
        return <CheckBox id={com.id} />;
      case "consentcheck":
        return <ConsentCheckbox id={com.id} />;
      case "radio":
        return <MultipleChoice id={com.id} />;
      case "select":
        return <Select id={com.id} />;
      case "ageselect":
        return <AgeSelect id={com.id} />;
      case "textfield":
        return <TextField id={com.id} />;
      case "puretext":
        return <PureText id={com.id} />;
      case "datepicker":
        return <DatePicker id={com.id} />;
      case "pdf":
        return <PDF id={com.id} />;
      case "image":
        return <Image id={com.id} />;
      case "imagerandom":
        return <ImageRandom id={com.id} />;
      case "slider":
        return <Slider id={com.id} />;
      case "volumecontrol":
        return <VolumeControl id={com.id} />;
      case "randomid":
        return <RandomId id={com.id} />;
      // digits in noise
      case "digitsdemo":
        return <DigitsDemo id={com.id} />;
      case "digits":
        return <Digits id={com.id} />;
      case "recoverystimulus":
        return <RecoveryStimulus id={com.id} />;
      // crn
      case "crm":
        return <CRM id={com.id} />;
      case "alphabetexperiment":
        return <AlphabetExperiment id={com.id} />;
      // others
      case "ohhis":
        return <OHHIS id={com.id} />;
      case "dfdexperiments":
        return <DfdExperiment id={com.id} />;
      case "studyids":
        return <StudyId id={com.id} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <div className="row" style={{ marginRight: "5px" }}>
        {amount.map((_, index) => (
          <div className={"col-" + String(12 / amount.length)}>
            {!!renderComponent(children[index]) ? (
              <div
                style={{
                  border: "solid",
                  borderWidth: 1,
                  position: "relative",
                }}
              >
                <DeleteForeverIcon
                  onClick={() => setDeleteComponentDialog(index)}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 10,
                    color: "red",
                    width: 30,
                    height: 30,
                    border: "solid",
                  }}
                />
                {renderComponent(children[index])}
              </div>
            ) : drawerState === DrawerState.SELECT ? (
              <div
                onClick={() => handleInsertComponent("row", id, index)}
                style={{
                  height: 100,
                  border: "dotted",
                  paddingTop: 30,
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                Insert Component
              </div>
            ) : (
              <div
                style={{
                  height: 100,
                  paddingTop: 30,
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                container
              </div>
            )}
          </div>
        ))}
      </div>
      <Dialog
        open={deleteComponentDialog !== false}
        onClose={() => setDeleteComponentDialog(false)}
      >
        <DialogTitle>{"Are you sure deleting this component?"}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setDeleteComponentDialog(false)}
            color="primary"
          >
            No
          </Button>
          <Button onClick={handleDeleteComponent} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RowContainer;
