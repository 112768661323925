import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const Datepicker = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState(null);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/datepicker/" + id);
        setQuestion(doc.data.question);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(() => {
    handleUpdateAssign();
    if (!!answer) {
      readyToContinue(id);
    }
  }, [answer]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "datepicker",
      question,
      answer,
    };
    updateAssign(assign);
  };

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>{question}</P2>
      <DatePicker selected={answer} onChange={setAnswer} />
      <br />
      <br />
    </Container>
  );
};

export default Datepicker;
