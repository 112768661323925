import React from "react";
import styles from "./OurIdea.module.css";
import ImageGrid from "./image-grid";
import idea1 from "../assets/images/idea1.png";
import idea2 from "../assets/images/idea2.png";
import idea3 from "../assets/images/idea3.png";
import idea4 from "../assets/images/idea4.png";
import idea5 from "../assets/images/idea5.png";
import idea6 from "../assets/images/idea6.png";
import ideaText from "../assets/images/idea-text.svg";
import text2 from "../assets/images/These Are Our Ideas.png";

const OurIdeaSection = () => {
  const firstGridImages = [idea1, idea2];

  const secondGridImages = [idea3, idea4];

  const thirdGridImages = [idea5, idea6];

  return (
    <section className={styles.ourIdeaSection}>
      <img
        loading="lazy"
        src={text2}
        alt="Our Idea header"
        className={styles.headerImage}
      />
      <ImageGrid images={firstGridImages} gridClass="imageGrid" />
      <ImageGrid images={secondGridImages} gridClass="secondGrid" />
      <ImageGrid images={thirdGridImages} gridClass="thirdGrid" />
    </section>
  );
};

export default OurIdeaSection;
