import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from "@material-ui/core";

export default function MyApp(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    let canvasWidth = document.getElementsByClassName("react-pdf__Page")?.[0]?.offsetWidth;
    if (canvasWidth) setWidth(canvasWidth);
  });

  useEffect(() => {
    if (pageNumber === numPages && props.showConsents) props.showConsents();
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handleNext = () => {
    if (props.setPage) props.setPage(pageNumber + 1);
    setPageNumber(pageNumber + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const handlePrev = () => {
    if (props.setPage) props.setPage(pageNumber - 1);
    setPageNumber(pageNumber - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div id="pdf" >
      <Document
        file={props.file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page width={width} pageNumber={pageNumber} />
      </Document>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        {pageNumber > 1 &&
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={handlePrev}>Previous</Button>}
        <p>Page {pageNumber} of {numPages}</p>
        {pageNumber < numPages &&
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={handleNext} >Next</Button>}
      </div>
    </div>
  );
}