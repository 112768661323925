import React, { useEffect, useState } from "react";
import "./styles.css";
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';


const DecoratedMain = (props) => {

  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing' });
  });

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => {props.history.push(page)}, 2000);
  }

  const handleAppearanceTestDemo = () => {
    setOpen(true);
    setTimeout(() => {props.history.push("/hearing/demo/digits")}, 2000);
  }

  const handleHearingTestDemo = () => {
    setOpen(true);
    setTimeout(() => {props.history.push("/hearing/demo/crm")}, 2000);
  }

  const handleQuestionnaireDemo = () => {
    setOpen(true);
    setTimeout(() => {props.history.push("/hearing/demo/questionnaire")}, 2000);
  }

  //styles 
  const rowStyle = {
    display: "block",
    maxWidth: "100%",
    color: "white",
    margin: "0 auto",
  };

  return (
    <div className="container-fluid">
      <div style={{backgroundColor: "#333", padding:"2rem"}} className="row first-row">
          <div id="logo">
            <img src={process.env.PUBLIC_URL + "/UA.png"} />
          </div>
        </div>

        <div className="row" style={{padding:"20rem 2rem", backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)"}}>
        <div style={{ color: "white"}}>
          <P1><b style={{textDecoration:"underline"}}>Welcome to our Collection of Hearing Experiments</b></P1>
          <P2 style={{fontWeight:"400"}}>Instructions: Please use a desktop or laptop computer with
          Chrome, Edge, Firefox, Opera or Safari to complete any of these evaluations.</P2>
        </div>
      </div>
      <div className="row" style={{height: window.innerHeight * 0.35, width: "100%", marginLeft:0, marginRight: 0}}>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/digits')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >Digits in Noise</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/crm')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >Coordinate Response Measure</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/questionnaire')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >Questionnaires</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
      </div>
      <div className="row" style={{padding:"5rem 0", backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)"}}>
        <div className="col-4"><center><Button variant="outlined" style={{color: "white", borderColor: "white"}} onClick={handleAppearanceTestDemo}>Digits in Noise DEMO</Button></center></div>
        <div className="col-4"><center><Button variant="outlined" style={{color: "white", borderColor: "white"}} onClick={handleHearingTestDemo}>Coordinate Response Measure Demo</Button></center></div>
        <div className="col-4"><center><Button variant="outlined" style={{color: "white", borderColor: "white"}} onClick={handleQuestionnaireDemo}>Questionnaire DEMO</Button></center></div>
      </div>
      <div className="row footer">
        <div id="logo">
          <img src={process.env.PUBLIC_URL + "/UA.png"} />
        </div>
      </div>
      {/* <Backdrop style={{zIndex: 2}} open={open}>
        <CircularProgress color="primary" style={{width: "100px", height: "100px"}} />
      </Backdrop>
      */}
       {/* <Button 
        variant="outlined"
        onClick={() => window.location = "/"}
        style={{ position: "relative", top: "100px", left: "10px", color: "white", borderColor: "white" }}
      >
        Back
      </Button> */}
    </div>
  )
}

export default DecoratedMain;
