import React, { useEffect, useState, useContext } from "react";
import "../styles/style.css";
import { ChartDataContext } from "../states/ChartDataState";
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function InputTable() {
  const [chartData, setChartData] = useState(null);
  const { data, setData } = useContext(ChartDataContext);
  const image = require("audiogram/images/audiogram_symbols.png");

  const labels = [
    { value: "AC_Unmmasked R", label: "O" },
    { value: "AC_Unmmasked L", label: "X" },
    { value: "AC_Masked R", label: "△" },
    { value: "AC_Masked L", label: "□" },
    { value: "BC_Unmasked R", label: "<" },
    { value: "BC_Unmasked L", label: ">" },
    { value: "BC_Masked R", label: "[" },
    { value: "BC_Masked L", label: "]" },
    { value: "No Response R", label: "↙" },
    { value: "No Response L", label: "↘" },
    { value: "BC Forehead", label: "v" },
    { value: "BC Forehead Masked R", label: "⸣" },
    { value: "BC Forehead Masked L", label: "⸢" },
    { value: "MCL", label: "M" },
    { value: "UCL", label: "U" },
    { value: "Soundfield Unaided", label: "S" },
    { value: "Soundfield Aided", label: "A" },
    { value: "Soundfield", label: "SF" },
    { value: "Earphones/inserts", label: "EP/IN" },
  ];

  const frequencies = [
    125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000,
  ];

  const handleCellValueChange = (rowIndex, columnIndex, value) => {
    const updatedData = [...data];
    if (columnIndex === "value" && isNaN(value)) {
      updatedData[rowIndex][columnIndex] = "";
    } else {
      updatedData[rowIndex][columnIndex] = value;
    }
    setData(updatedData);
    updateChartData(updatedData);
  };

  const updateChartData = (updatedData) => {
    const labels = updatedData.map((row) => row.label);
    const y = updatedData.map((row) => row.y);
    const x = updatedData.map((row) => row.x);

    const updatedChartData = {
      labels: labels,
      datasets: [
        {
          label: "Values",
          data: updatedData.map((row) => ({ x: row.x, y: row.value })),
          fill: false,
          borderColor: "rgba(75,192,192,1)",
          tension: 0.1,
        },
      ],
    };

    setChartData(updatedChartData);
  };

  const addRow = () => {
    const newRow = { label: "O", y: "70", x: "1000" };
    const updatedData = [...data, newRow];
    setData(updatedData);
    updateChartData(updatedData);
  };

  const removeRow = (rowIndex) => {
    const updatedData = data.filter((row, index) => index !== rowIndex);
    setData(updatedData);
    updateChartData(updatedData);
  };

  useEffect(() => {
    updateChartData(data);
    console.log(data);
  }, [data]);

  return (
    <div className="table_and_image">
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>Labels</th>
              <th>Frequency (Hz)</th>
              <th>Hearing Level (db)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <select
                    onChange={(e) =>
                      handleCellValueChange(rowIndex, "label", e.target.value)
                    }
                  >
                    {labels.map((label, index) => (
                      <option key={index} value={label.label}>
                        {label.value}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    className="x-input"
                    type="number"
                    value={row.x}
                    onChange={(e) =>
                      handleCellValueChange(rowIndex, "x", e.target.value)
                    }
                  >
                    {frequencies.map((index) => (
                      <option key={index}>{index}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    className="y-input"
                    type="number"
                    value={row.y}
                    step="5"
                    onChange={(e) =>
                      handleCellValueChange(rowIndex, "y", e.target.value)
                    }
                  />
                </td>
                <td>
                  <button onClick={() => removeRow(rowIndex)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="AddButton"
          style={{ marginTop: 30 }}
          onClick={addRow}
        >
          <span class="button__text">Add Item</span>
          <span class="button__icon">
            <AiOutlinePlusCircle
              style={{
                fontSize: "50px",
                backgroundColor: "#34974d",
                height: "100%",
              }}
            />
          </span>
        </button>
      </div>
      <img className="key_symbols_image" src={image} alt="audiogram symbols" />
    </div>
  );
}
