import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from "chart.js";

// Register components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController
);

// LineChart Component
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "line",
      data: {
        labels: this.props.data.map((d) => d.label),
        datasets: [
          {
            label: this.props.title,
            data: this.props.data.map((d) => d.value),
            fill: "none",
            backgroundColor: this.props.color,
            pointRadius: 2,
            borderColor: this.props.color,
            borderWidth: 1,
            tension: 0.3, // Updated to use `tension` as `lineTension` is deprecated
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        scales: {
          x: {
            type: "category",
          },
          y: {
            type: "linear",
          },
        },
      },
    });
  }

  componentWillUnmount() {
    // Properly disposing of the chart instance when the component unmounts
    if (this.myChart) {
      this.myChart.destroy();
    }
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default LineChart;
