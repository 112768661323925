import { P1, P2, P3 } from 'assets/fonts';
import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import axios from 'axios';

const RandomId = (props) => {
  const { id, randomid, updateAssign } = props;
  const [name, setName] = useState(null);
  const [randomId, setRandomId] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/randomid/" + id);
        setName(doc.data.name);
        updateAssign({
          id,
          name: doc.data.name,
          type: "randomid"
        });
      })();
    }
  }, [id]);

  useEffect(() => {
    console.log(name, randomid)
    if (name && randomid) {
      randomid.map(ele => {
        if (ele.name == name) {
          setRandomId(ele.randomId);
          updateAssign({
            id,
            name,
            type: "randomid",
            answer: ele.randomId
          });
        }
      });
    }
  }, [name, randomid])

  return (
    <Container>
      <br />
      <P2>Your participant ID is: {randomId}</P2>
    </Container>
  )
}

export default RandomId;