import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@material-ui/core";
import { P1, P2 } from "assets/fonts";
import { TextField, Button } from "@material-ui/core";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const StudyId = (props) => {
  const { id, updateAssign } = props;
  const [studyId, setStudyId] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [experimentId, setExperimentId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    for (let i = 0; i < 2; i++) {
      dispatch(addMandatoryComponent(id));
    }
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/studyids/" + id);
        setStudyId(doc.data.studyId);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (participantId && experimentId) {
      updateAssign({
        id,
        participantId: participantId,
        studyId: studyId,
        experimentId: experimentId,
        type: "studyids",
      });
    }
  }, [participantId, experimentId]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setParticipantId(newText);
  };

  const handleExperimentChange = (e) => {
    const newText = e.target.value;
    setExperimentId(newText);
  };

  const handleSubmit = async () => {
    dispatch(readyToContinueRedux(id));
    await axios.put("/api/gui/studyids/" + id, {
      studyId,
      participantId,
      experimentId,
    });
  };

  const handleEdit = () => {
    const currentPath = window.location.pathname;
    // Check if the current path already includes "/admin" to prevent duplicate addition
    if (!currentPath.includes("/admin")) {
      const newPath = currentPath.replace("/gui/", "/gui/admin/");
      window.location.pathname = newPath;
    }
  };

  const handleBlur = () => {
    console.log("blur");
    handleSubmit();
  };

  return (
    <Container style={{}}>
      <h1>Study Participation ID Form</h1>
      <p>
        Welcome! Please enter your details below to participate in the study.
      </p>

      <section style={{ marginBottom: "20px" }}>
        <h2>Study Information</h2>
        <h5>
          <strong>Study ID:</strong> {studyId}
        </h5>

        <Button
          onClick={handleEdit}
          variant="contained"
          color="grey"
          style={{ marginRight: "20px" }}
        >
          Edit
        </Button>
        <strong>
          Please do not press edit button if you are not administrator
        </strong>
      </section>

      <section style={{ marginBottom: "20px" }}>
        <h3 style={{ marginTop: "50px" }}>Please enter the Experiment ID</h3>
        <TextField
          id="participantId"
          value={experimentId}
          onBlur={handleBlur}
          onChange={handleExperimentChange}
          style={{ width: "100%", marginBottom: "10px" }}
          placeholder="Enter your Experiment ID"
        />
        <h3 style={{ marginTop: "20px" }}>Please enter the Participant ID</h3>
        <TextField
          id="participantId"
          value={participantId}
          onBlur={handleBlur}
          onChange={handleChange}
          style={{ width: "100%", marginBottom: "10px" }}
          placeholder="Enter your Participant ID"
        />
        {/* <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          // style={{ width: "100%" }}
        >
          Submit
        </Button> */}
      </section>
    </Container>
  );
};

export default StudyId;
