import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { P1, P2, P3 } from "assets/fonts";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: "90%",
    marginLeft: "5%"
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));

export default function DenseTable(props) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table}  component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow style={{ backgroundColor: "#e9ecef"}}>
            <TableCell align="center"><P2><b>HEARING AID EXPERIENCE:</b></P2></TableCell>
            <TableCell align="center"><P2><b>DAILY HEARING AID USE:</b></P2></TableCell>
            <TableCell align="center"><P2><b>DEGREE OF HEARING<br/>DIFFICULTY<br/>(without wearing a hearing aid):</b></P2></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="row">
            <TableCell align="left">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["1"] === "None"}
                    onChange={e => props.onCheckBoxClick(1, "None")} />}
                    label={<P2>None</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["1"] === "Less than 6 weeks"}
                    onChange={e => props.onCheckBoxClick(1, "Less than 6 weeks")} />}
                    label={<P2>Less than 6 weeks</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["1"] === "6 weeks to 11 months"}
                    onChange={e => props.onCheckBoxClick(1, "6 weeks to 11 months")} />}
                    label={<P2>6 weeks to 11 months</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["1"] === "1 to 10 years"}
                    onChange={e => props.onCheckBoxClick(1, "1 to 10 years")} />}
                    label={<P2>1 to 10 years</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["1"] === "Over 10 years"}
                    onChange={e => props.onCheckBoxClick(1, "Over 10 years")} />}
                    label={<P2>Over 10 years</P2>}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
            <TableCell align="left">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["2"] === "None"}
                    onChange={e => props.onCheckBoxClick(2, "None")} />}
                    label={<P2>None</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["2"] === "Less than 1 hour per day"}
                    onChange={e => props.onCheckBoxClick(2, "Less than 1 hour per day")} />}
                    label={<P2>Less than 1 hour per day</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["2"] === "1 to 4 hours per day"}
                    onChange={e => props.onCheckBoxClick(2, "1 to 4 hours per day")} />}
                    label={<P2>1 to 4 hours per day</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["2"] === "4 to 8 hours per day"}
                    onChange={e => props.onCheckBoxClick(2, "4 to 8 hours per day")} />}
                    label={<P2>4 to 8 hours per day</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["2"] === "8 to 16 hours per day"}
                    onChange={e => props.onCheckBoxClick(2, "8 to 16 hours per day")} />}
                    label={<P2>8 to 16 hours per day</P2>}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
            <TableCell align="left">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["3"] === "None"}
                    onChange={e => props.onCheckBoxClick(3, "None")} />}
                    label={<P2>None</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["3"] === "Mild"}
                    onChange={e => props.onCheckBoxClick(3, "Mild")} />}
                    label={<P2>Mild</P2>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={props.answers["3"] === "Moderate"}
                    onChange={e => props.onCheckBoxClick(3, "Moderate")} />}
                    label={<P2>Moderate</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["3"] === "Moderately-Severe"}
                    onChange={e => props.onCheckBoxClick(3, "Moderately-Severe")} />}
                    label={<P2>Moderately-Severe</P2>}
                  />
                   <FormControlLabel
                    control={<Checkbox checked={props.answers["3"] === "Severe"}
                    onChange={e => props.onCheckBoxClick(3, "Severe")} />}
                    label={<P2>Severe</P2>}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
