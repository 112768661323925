import React, { Component } from "react";
import { connect } from "react-redux";
import Process from "literacy/assets/process";
import PhonemeHeader from "literacy/components/student/phoneme/assets/header";
import TrainCard from "literacy/assets/cards/trainpagecard";
import { Container } from "@material-ui/core";

class PhonemeTrain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTrain: false,
      evaluationDone: true,
    };
  }

  render() {
    const { currentUser } = this.props;
    return (
      <div style={{  backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        <PhonemeHeader part="Training Main Page"/>
        <Container>
          {currentUser ? (
            <div className="row">
              <TrainCard
                title="Learning"
                page="/literacy/student/phoneme/learning"
                description="In this section, you will learn about different sound patterns in English language."
              />
              <TrainCard
                title="Assignment"
                page="/literacy/student/phoneme/assignment"
                description="In this section, we will test your awareness of sound patterns."
              />
              <TrainCard
                title="Results"
                page="/literacy/student/phoneme/results"
                description="In this section, you can take a look at the results of your assignments."
              />
            </div>
          ) : (
            <Process />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(PhonemeTrain);
