import React from "react";
import styles from "./TeamMembers.module.css";
import PersonCard from "./PersonCard";
import SectionTitle from "./SectionTitle.jsx";
import defaultFace from "../assets/images/default-face.png";
import bill from "../assets/images/bill-hodgetts.png";
import jacqueline from "../assets/images/jacqueline-cummine 1.png";
import amber from "../assets/images/amber.png";

const academicPersonnel = [
  {
    name: "Bill Hodgett, Ph.D, R.Aud",
    role: "Principle Investigator, Professor, Communication Science & Disorders, College of Health Science, Faculty of Rehabilitation Medicine & Institute for Reconstructive Science in Medicine",
    image: bill,
  },
  {
    name: "Jacqueline Cummine, Ph.D",
    role: "Co-Investigator, Professor, Communication Science & Disorders, College of Health Sciences, Faculty of Rehabilitation Medicine",
    image: jacqueline,
  },
];

const researchCoordinator = {
  name: "Amberley Ostevik, M.Sc., R.Aud",
  role: "Research Coordinator, Communication Science & Disorders, College of Health Sciences, Faculty of Rehabilitation Medicine",
  image: amber,
};

const phdStudents = [
  { name: "Craig St. Jean, M.S., Ph.D, Candidate", image: defaultFace },
  { name: "Lindsay McHutchion, M.S., Ph.D Student", image: defaultFace },
  { name: "Alex Gascon, M.S., Ph.D Student", image: defaultFace },
  {
    name: "Syahidatul Huda Binti Mustaffar Kamal (Huda), M.S., Ph.D Student",
    image: defaultFace,
  },
  {
    name: "Kathleen Jones, AuD, RAud, Aud(C), Ph.D. Student",
    image: defaultFace,
  },
];

const mscStudents = [
  { name: "Kristen Mulderrig", image: defaultFace },
  { name: "Lindsay Chopiuk", image: defaultFace },
  { name: "Nadia Semenoff", image: defaultFace },
  { name: "Sarah Sweneya", image: defaultFace },
];

const softwareTeam = [
  { name: "Qi (Kay) Song, Software Engineer", image: defaultFace },
  { name: "Yongbin Kim, Computer Science Student", image: defaultFace },
];

const researchAffiliates = [
  { name: "Daniel Aalto, PhD", image: defaultFace },
  { name: "Kelly Nisbet, PhD", image: defaultFace },
  { name: "Angela Cullum, PhD Candidate", image: defaultFace },
  { name: "Kulpreet Cheema, PhD", image: defaultFace },
];

function TeamMembers() {
  return (
    <main className={styles.teamMembers}>
      <SectionTitle>Who We Are</SectionTitle>

      <section>
        <SectionTitle>Current Academic Personnel</SectionTitle>
        <div className={styles.cardGrid}>
          {academicPersonnel.map((person, index) => (
            <PersonCard key={index} {...person} />
          ))}
        </div>
      </section>

      <section className={styles.centeredSection}>
        <SectionTitle>Research Coordinator</SectionTitle>
        <div className={styles.centeredCard}>
          <PersonCard {...researchCoordinator} />
        </div>
      </section>

      <section>
        <SectionTitle>Current Ph.D. Graduate Personnel</SectionTitle>
        <div className={styles.cardGrid}>
          {phdStudents.map((student, index) => (
            <PersonCard key={index} {...student} />
          ))}
        </div>
      </section>

      <section>
        <SectionTitle>Current M.Sc. Graduate Students</SectionTitle>
        <div className={styles.cardGrid}>
          {mscStudents.map((student, index) => (
            <PersonCard key={index} {...student} />
          ))}
        </div>
      </section>

      <section>
        <SectionTitle>Software Engineering Team</SectionTitle>
        <div className={styles.cardGrid}>
          {softwareTeam.map((member, index) => (
            <PersonCard key={index} {...member} />
          ))}
        </div>
      </section>

      <section>
        <SectionTitle>Research Affiliates</SectionTitle>
        <div className={styles.cardGrid}>
          {researchAffiliates.map((affiliate, index) => (
            <PersonCard key={index} {...affiliate} />
          ))}
        </div>
      </section>
    </main>
  );
}

export default TeamMembers;
