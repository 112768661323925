import React, { useEffect, useContext, useRef, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  PointElement,
  LinearScale,
  CategoryScale,
  Chart,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dragDataPlugin from "chartjs-plugin-dragdata";
import "../styles/style.css";
import { chartDataReducer } from "../states/ChartDataState";
import { ChartDataContext } from "../states/ChartDataState";

export default function AudiogramChart() {
  const { data, setData } = useContext(ChartDataContext);
  const [groupedData, setGroupedData] = React.useState([]);
  const updatedDataRef = useRef([]);
  const [datasets, setDatasets] = React.useState([]);
  const chartRef = React.useRef(null);
  const dataref = React.useRef(data);

  const downloadImage = useCallback(() => {
    // Set the background color of the chart
    const link = document.createElement("a");
    link.download = "audiogram.png";
    link.href = chartRef.current.toBase64Image();
    link.click();
  }, []);

  const downloadCSV = useCallback(() => {
    const csvRows = dataref.current.map((item) => [item.label, item.x, item.y]);
    const csvContent = "Label, Frequency, Hearing Level\n" + csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "audiogram.csv";
    link.click();
  }, []);

  useEffect(() => {
    dataref.current = data;
  }, [data]);

  Chart.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    dragDataPlugin
  );

  function getColorForLabel(label) {
    // color for each label
    console.log("label", label);

    const colors = [
      { label: "O", color: "#FF0000" },
      { label: "X", color: "#0000FF" },
      { label: "△", color: "#FF0000" },
      { label: "□", color: "#0000FF" },
      { label: "<", color: "#FF0000" },
      { label: ">", color: "#0000FF" },
      { label: "[", color: "#FF0000" },
      { label: "]", color: "#0000ff" },
      { label: "↙", color: "#FF0000" },
      { label: "↘", color: "#0000FF" },
      { label: "v", color: "#000000" },
      { label: "⸣", color: "#FF0000" },
      { label: "⸢", color: "#0000FF" },
      { label: "M", color: "#000000" },
      { label: "U", color: "#000000" },
      { label: "S", color: "#000000" },
      { label: "A", color: "#000000" },
      { label: "SF", color: "#000000" },
      { label: "EP/IN", color: "#000000" },
    ];

    //find the color for the label
    return colors.find((item) => item.label === label).color;
  }

  const generatePointStyle = useCallback((label, color = "#000000") => {
    const size = 20; // You can adjust this as needed
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = color;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `${size * 0.8}px Arial`; // Adjust the multiplier as needed
    ctx.fillText(label, size / 2, size / 2);
    return canvas;
  });

  useEffect(() => {
    if (data) {
      const dataWithId = data.map((item, index) => {
        return { ...item, id: index };
      });
      dataref.current = dataWithId;
      const grouped = dataWithId.reduce((acc, cur) => {
        const found = acc.find((item) => item.label === cur.label);
        const color = getColorForLabel(cur.label);

        if (found) {
          found.data.push({ x: cur.x, y: cur.y, id: cur.id });
        } else {
          acc.push({
            label: cur.label,
            data: [{ x: cur.x, y: cur.y, id: cur.id }],
            draggable: true,
            pointRadius: 10, // Adjust this if needed
            pointHoverRadius: 12,
            fill: false,
            backgroundColor: color,
            borderColor: color,
            pointStyle: generatePointStyle(cur.label, color), // Use the canvas with label as pointStyle
            datalabels: {
              align: "center",
              anchor: "center",
              color: color,
              font: {
                size: 16,
              },
              formatter: (value, context) => {
                return context.dataset.label;
              },
            },
          });
        }
        return acc;
      }, []);
      setGroupedData(grouped);
      updatedDataRef.current = grouped;
    }
  }, [data]);

  const chartData = {
    plugins: [ChartDataLabels],
    datasets: groupedData,
  };

  const chartOptions = {
    layout: {
      padding: 30,
    },
    plugins: {
      dragData: {
        dragX: true,
        onDragEnd: (event, datasetIndex, dataIndex, value) => {
          // Create a copy of the datasets
          const newDatasets = [...updatedDataRef.current];

          // Update the specific data point
          newDatasets[datasetIndex].data[dataIndex].x = Math.round(value.x);
          newDatasets[datasetIndex].data[dataIndex].y = Math.round(value.y);

          // Update the datasets state
          setGroupedData(newDatasets);
          updatedDataRef.current = newDatasets; // Add this line

          const originalDataItem = dataref.current.find(
            (item) => item.id === newDatasets[datasetIndex].data[dataIndex].id
          );

          if (originalDataItem) {
            originalDataItem.x = newDatasets[datasetIndex].data[dataIndex].x;
            originalDataItem.y = newDatasets[datasetIndex].data[dataIndex].y;
          }
          setData([...dataref.current]);
        },
      },
      datalabels: {
        display: true,
      },
    },
    scales: {
      x: {
        type: "linear",
        min: 0,
        max: 9000,
        ticks: {
          // tick 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000
          stepSize: 125,
          callback: function (value, index, values) {
            const ticks = [
              250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000,
            ];
            if (ticks.includes(value)) {
              return value;
            }
          },
        },
        title: {
          display: true,
          text: "Frequency (Hz)",
          font: {
            size: 24,
            weight: "bold",
          },
        },
      },
      y: {
        type: "linear",
        min: -10,
        max: 120,
        reverse: true,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: true,
          text: "Hearing Level (dB HL)",
          font: {
            size: 24,
            weight: "bold",
          },
        },
      },
    },
  };

  useEffect(() => {
    console.log("data changed");
    console.log(data);
    console.log(groupedData);
  }, [data]);

  return (
    <div className="chart" style={{ margin: "auto" }}>
      <h2>Audiogram Generator</h2>
      {<Line ref={chartRef} data={chartData} options={chartOptions} />}
      <div className="buttons">
        <button className="button" onClick={downloadImage}>
          <span class="button-content">Download Chart </span>
        </button>
        <button
          className="button"
          style={{ marginTop: 10 }}
          onClick={downloadCSV}
        >
          <span class="button-content">Download CSV</span>
        </button>
      </div>
    </div>
  );
}

// const colors = [
//   "#ff0000",
//   "#0000ff",
//   "#00ff00",
//   "#ff00ff",
//   "#00ffff",
//   "#eab676",
//   "#ff8000",
//   "#8000ff",
//   "#0080ff",
//   "#ff0080",
//   "#80ff00",
//   "#063970",
//   "#e28743",
//   "#436770",
//   "#c5b576",
//   "#7c4dff",
//   "#ff7c4d",
//   "#33B8FF",
//   "#4dff7c",
//   "#4d7cff",
//   "#ff4d7c",
//   "#7A33FF",
// ];
