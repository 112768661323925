import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { P1, P2, P3 } from "assets/fonts";
import { Container, Button } from "@material-ui/core";
import { HearingAWS } from "assets/keys";
import VolumeSlider from "../assets/volume-slider";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";
import { useDispatch } from "react-redux";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";

const VolumeControl = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  // const [audio, setAudio] = useState(null);
  const [audioPlay, setAudioPlay] = useState(false);
  const [volume, setVolume] = useState(0);
  const audio = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/volumecontrol/" + id);
        setUrl(doc.data.url);
        // setAudio(new Audio(HearingAWS + doc.data.url));
        audio.current = new Audio(HearingAWS + doc.data.url);
        setTitle(doc.data.title);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (audioPlay && audio.current) {
      audio.current.play();
    } else if (audio.current) {
      audio.current.pause();
    }
  }, [audioPlay]);

  useEffect(() => {
    if (audio.current) {
      audio.current.volume = volume / 100;
    }
    if (volume != 0) {
      dispatch(readyToContinueRedux(id));
    }
    updateAssign({
      id,
      type: "volumecontrol",
      url,
      title,
      audio,
      answer: volume,
    });
  }, [volume]);

  // used useEffect to make a componentWillUnmount; It has the same function;
  useEffect(() => {
    return () => {
      audio.current.pause();
    };
  }, []);

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>{title}</P2>
      <VolumeSlider
        handleVolume={(volume) => setVolume(volume)}
        style={{ marginLeft: "30%" }}
      />
      {audioPlay ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAudioPlay(false)}
          style={{ margin: 5, width: 150 }}
        >
          Stop
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAudioPlay(true)}
          style={{
            margin: 5,
            width: 150,
          }}
        >
          Play
        </Button>
      )}
      <br />
      <br />
    </Container>
  );
};

export default VolumeControl;
