import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import Q2Table from "literacy/components/tutor/thread-assign-table/multiple-choice-table";
import Q3Table from "literacy/components/tutor/thread-assign-table/blank-table";
import Q4Table from "literacy/components/tutor/thread-assign-table/short-answer-table";

class PhonemeTutorAllAssign extends React.Component {
  constructor() {
    super();
    this.state = { assignment: null, newScore: 0 };
  }

  componentDidMount = async () => {
    const doc = await axios.get(
      "/api/literacy/phoneme/assign/" + this.props.match.params.id
    );
    if (doc.data) {
      this.setState({ assignment: doc.data });
    }
  };

  render() {
    const { assignment } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Check Students' Weekly Assignment</h2>
          <br />
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#107896" }}
            href="/literacy/tutor/phoneme/assign"
          >
            Go back
          </Button>
        </div>
        <Container>
          {assignment ? (
            <div>
              <br />
              <h3>Multiple Choice</h3>
              <Q2Table rows={assignment.q2Assign} />
              <br />
              <h3>Fill in the Blank</h3>
              <Q3Table rows={assignment.q3Assign} />
              <br />
              <h3>Short Answer</h3>
              <Q4Table rows={assignment.q4Assign} />

              {assignment.status === "pending" ? (
                <div>
                  <h3>Old Score: {assignment.oldScore}</h3>
                  <h3>New Score: {assignment.newScore}</h3>
                </div>
              ) : null}
            </div>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default PhonemeTutorAllAssign;
