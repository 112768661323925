import React from "react";
import { P1, P2, P3 } from 'assets/fonts';
import { Container } from "@material-ui/core";
import { ExportReactCSV } from 'hearing/components/partials/csv';
import axios from "axios";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aHeader: [],
      aData: [],
      aChoiceQuestion: [],
      bHeader: [],
      bData: [],
      bChoiceQuestion: []
    }
  }

  componentDidMount = async () => {
    const aDoc = await axios.get('/api/hearing/aphaba/user');
    const bDoc = await axios.get('/api/hearing/aphabb/user');

    // a
    const aHeader = ['participantID', 'studyID', 'Date'];
    for (let i = 1; i <= 3; i++) {
      aHeader.push("Checkbox-" + String(i));
    }
    const aChoiceQuestionL = Object.keys(aDoc.data?.[0]?.choiceQuestion ?? {}).length;
    for (let i = 1; i <= aChoiceQuestionL; i++) {
      aHeader.push("Choice-" + String(i) + "-with");
      aHeader.push("Choice-" + String(i) + "-without");;
    }

    const aData = [];
    const aChoiceQuestion = [];
    aDoc.data?.map(data => {
      const row = [data.participantID, data.studyID, data.date?.split('T')?.[0]];
      Object.keys(data.formQuestion).map(key => {
        row.push(data.formQuestion[key]);
      });
      Object.keys(data.choiceQuestion).map(key => {
        row.push(data.choiceQuestion[key].with);
        row.push(data.choiceQuestion[key].without);
        aChoiceQuestion.push(key);
      });
      aData.push(row);
    });

    // b
    const bHeader = ['participantID', 'studyID', 'Date', "Birth", 'Address', 'Home Phone', 'Work Phone', 'SSN'];
    for (let i = 1; i <= 3; i++) {
      bHeader.push("Checkbox-" + String(i));
    }
    const bChoiceQuestionL = Object.keys(bDoc.data?.[0]?.choiceQuestion ?? {}).length;
    for (let i = 1; i <= bChoiceQuestionL; i++) {
      bHeader.push("Choice-" + String(i) + "-with");
      bHeader.push("Choice-" + String(i) + "-without");
    }

    const bData = [];
    const bChoiceQuestion = [];
    bDoc.data?.map(data => {
      const row = [data.participantID, data.studyID, data.date?.split('T')?.[0], data.birth?.split('T')?.[0], data.address, data.homePhone, data.workPhone, data.SSN];
      Object.keys(data.formQuestion).map(key => {
        row.push(data.formQuestion[key]);
      });
      Object.keys(data.choiceQuestion).map(key => {
        row.push(data.choiceQuestion[key].with);
        row.push(data.choiceQuestion[key].without);
        bChoiceQuestion.push(key);
      });
      bData.push(row);
    });

    this.setState({
      aHeader, aData, aChoiceQuestion,
      bHeader, bData, bChoiceQuestion,
    });
  }


  render() {
    const {
      aHeader, aData, aChoiceQuestion,
      bHeader, bData, bChoiceQuestion
    } = this.state;
    return (
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <Container>
          <P1>Aphab A database</P1>
          <ExportReactCSV data={aData} headers={aHeader} fileName="Aphab_A_Data" />
          <br /><br />
          <P3>Checkbox-1: HEARING AID EXPERIENCE</P3>
          <P3>Checkbox-2: DAILY HEARING AID USE</P3>
          <P3>Checkbox-3: DEGREE OF HEARING DIFFICULTY (without wearing a hearing aid)</P3>
          {aChoiceQuestion.map((q, i) => <P3>Choice-{i + 1}: {q}</P3>)}
        </Container>
        <br />
        <Container>
          <P1>Aphab B database</P1>
          <ExportReactCSV data={bData} headers={bHeader} fileName="Aphab_B_Data" />
          <br /><br />
          <P3>Checkbox-1: HEARING AID EXPERIENCE</P3>
          <P3>Checkbox-2: DAILY HEARING AID USE</P3>
          <P3>Checkbox-3: EMPLOYMENT</P3>
          {bChoiceQuestion.map((q, i) => <P3>Choice-{i + 1}: {q}</P3>)}
        </Container>
        <br />
      </div>
    )
  }
}

export default Questionnaire;
