import React from "react";
import Welcome from "hearing/components/digits5/pre-test/welcome";
import VolumeAdjustment from "hearing/components/digits5/pre-test/adjustment";
import MidPage from "hearing/components/digits5/test/mid-page";
import SpeechInNoise1 from "hearing/components/digits5/test/speech-in-noise-1";
import SpeechInNoise2 from "hearing/components/digits5/test/speech-in-noise-2";
import TestDemo from "hearing/components/digits5/test/test-demo";
import Environment from "hearing/components/digits5/pre-test/environment";
import Submit from "hearing/components/digits5/post-test/submit";
import axios from "axios";
import FontComponent from 'assets/font-ui';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participantId: null,
      studyId: null,
      withAids: null,
      process: "welcome",
      email: "",
      volume: 0,
      output: null
    };
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/crm/digits5' });
  };

  handleChangeId = (participantId, studyId) => {
    this.setState({participantId, studyId, process: "adjustment"});
  }

  handleAdjustmentClick = (volume, output, withAids) => {
    this.setState({ process: "demo", volume, output, withAids });
  };

  handleDemoClick = () => {
    this.setState({ process: "testing1" });
  };

  handleTesting1Click = (SNR1, reactTimer1, realTimer1, questions1, answers1, dbs1) => {
    this.setState({ SNR1, reactTimer1, realTimer1, questions1, answers1, dbs1, process: "midpage" });
  };

  handleMidPageClick = () => {
    this.setState({process: "testing2"});
  }

  handleTesting2Click = (SNR2, reactTimer2, realTimer2, questions2, answers2, dbs2) => {
    this.setState({ SNR2, reactTimer2, realTimer2, questions2, answers2, dbs2, process: "submit" });
  };

  handleSubmitClick = async () => {
    const {
      participantId, studyId, output, withAids, volume,
      SNR1, reactTimer1, realTimer1, questions1, answers1, dbs1,
      SNR2, reactTimer2, realTimer2, questions2, answers2, dbs2
    } = this.state;
    await axios.post("/api/hearing/digits5/user", {
      participantId, studyId, output, withAids, volume,
      SNR1, reactTimer1, realTimer1, questions1, answers1, dbs1,
      SNR2, reactTimer2, realTimer2, questions2, answers2, dbs2
    });
    window.location = "/";
  };

  renderProcess = () => {
    const { process, volume } = this.state;
    switch (process) {
      case "welcome":
        return <Welcome onChangeId={this.handleChangeId}/>;
      case "adjustment":
        return (
          <VolumeAdjustment
            handleClick={this.handleAdjustmentClick}
          />
        );
      case "demo":
        return <TestDemo volume={volume} handleClick={this.handleDemoClick} />;
      case "testing1":
        return (
          <SpeechInNoise1
            volume={volume}
            handleClick={this.handleTesting1Click}
          />
        );
      case "midpage":
        return <MidPage handleClick={this.handleMidPageClick} />;
      case "testing2":
        return (
          <SpeechInNoise2
            volume={volume}
            handleClick={this.handleTesting2Click}
          />
        );
      case "submit":
        return <Submit handleClick={this.handleSubmitClick} />;
      default:
        return null;
    }
  };

  render() {
    return (
    <div style={{
      backgroundColor: "rgb(240,240,240)",
      fontFamily: "raven",
      height: window.innerHeight,
      overflowY: "scroll",
      paddingRight: "5%"
    }}>
      <FontComponent />
      {this.renderProcess()}
    </div>
    )
  }
}

export default Main;
