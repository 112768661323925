import React from "react";
import { Container, Button } from "@material-ui/core";


class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trials: 10,
      b1WordAndPicture: [],
      b2WordAndPicture: [],
      b3WordAndPicture: [],
      b4WordAndPicture: [],
      b5WordAndPicture: [],
      b6WordAndPicture: [],
      b7WordAndPicture: [],
      b1RealAnswer: [],
      b2RealAnswer: [],
      b3RealAnswer: [],
      b4RealAnswer: [],
      b5RealAnswer: [],
      b6RealAnswer: [],
      b7RealAnswer: [],
      b3WordLeftKey: null, b3ImageLeftKey: null,
      b4WordLeftKey: null, b4ImageLeftKey: null,
      b6WordLeftKey: null, b6ImageLeftKey: null,
      b7WordLeftKey: null, b7ImageLeftKey: null
    };
  }

  componentDidMount = async () => {
    // "no facial difference + positive words" (condition A)
    // "no facial difference + negative words" (condition B)
    // assign the left key and right key for each block
    const wordLeftKey = Math.floor(Math.random() * 2);
    const imageLeftKey = Math.floor(Math.random() * 2);
    this.setState({
      b3WordLeftKey: wordLeftKey, b3ImageLeftKey: imageLeftKey,
      b4WordLeftKey: wordLeftKey, b4ImageLeftKey: imageLeftKey,
      b6WordLeftKey: wordLeftKey, b6ImageLeftKey: 1 - imageLeftKey,
      b7WordLeftKey: wordLeftKey, b7ImageLeftKey: 1 - imageLeftKey
    });

    // generate test set for block 1 2 3 4 5 6 7 (about 20ms)
    const time = new Date().getTime();
    const block1 = await this.generateBlockWith20Images();
    const block2 = await this.generateBlockWith20Words();
    const block3 = await this.generateBlockWith20Trials();
    const block4 = await this.generateBlockWith40Trials();
    const block5 = await this.generateBlockWith30Images();
    const block6 = await this.generateBlockWith20Trials();
    const block7 = await this.generateBlockWith40Trials();
    this.setState({
      timer: new Date().getTime() - time,
      b1WordAndPicture: block1.wordAndPicture, b1RealAnswer: block1.realAnswer,
      b2WordAndPicture: block2.wordAndPicture, b2RealAnswer: block2.realAnswer,
      b3WordAndPicture: block3.wordAndPicture, b3RealAnswer: block3.realAnswer,
      b4WordAndPicture: block4.wordAndPicture, b4RealAnswer: block4.realAnswer,
      b5WordAndPicture: block5.wordAndPicture, b5RealAnswer: block5.realAnswer,
      b6WordAndPicture: block6.wordAndPicture, b6RealAnswer: block6.realAnswer,
      b7WordAndPicture: block7.wordAndPicture, b7RealAnswer: block7.realAnswer
    });
  };

  generateBlockWith20Words = () => {
    const words = [
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy",
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"
    ];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = words.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        if (positiveWord.includes(wordAndPicture[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 19; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith20Images = () => {
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = images.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        if (nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 19; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith30Images = () => {
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = images.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 30; i++) {
        if (nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
      };
      for (let i = 0; i < 26; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 29; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith20Trials = () => {
    // generate 2 sets, set 1 for block3, set 2,1 for block 4, set 2 for block 6, set 1/2 for block 7.
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"];
    const images = ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    // const negativeWord = ["Awkward", "Misfit", "Dislike", "Unfriendly", "Grumpy"];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    // const disfiguredImage = ["2.jpg", "4.jpg", "6.jpg", "8.jpg", "10.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const shuffledWords = words.sort(() => Math.random() - 0.5);
      const shuffledImages = images.sort(() => Math.random() - 0.5);
      const wordAndPicture = [];
      const realAnswer = [];
      for (let i = 0; i < 10; i++) {
        wordAndPicture.push(shuffledWords[i]);
        wordAndPicture.push(shuffledImages[i]);
        if (positiveWord.includes(shuffledWords[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
        if (nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        }
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith40Trials = () => {
    // generate 2 sets, set 1 for block3, set 2,1 for block 4, set 2 for block 6, set 1/2 for block 7.
    const words = [
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy",
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"
    ];
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    // const negativeWord = ["Awkward", "Misfit", "Dislike", "Unfriendly", "Grumpy"];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    // const disfiguredImage = ["2.jpg", "4.jpg", "6.jpg", "8.jpg", "10.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const shuffledWords = words.sort(() => Math.random() - 0.5);
      const shuffledImages = images.sort(() => Math.random() - 0.5);
      const wordAndPicture = [];
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        wordAndPicture.push(shuffledWords[i]);
        wordAndPicture.push(shuffledImages[i]);
        if (positiveWord.includes(shuffledWords[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
        if (nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push(1);
        } else {
          realAnswer.push(0);
        };
      };
      for (let i = 0; i < 36; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlock3 = () => {
    const wordAndPicture = [];
    const realAnswer = [];
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike",
      "Friendly", "Unfriendly", "Happy", "Grumpy"];
    const wordNum = [];
    const imageNum = [];
    let turn = "word";
    let i = 0;
    while (i < this.state.trials * 2) {

      let num, modulus;
      while (true) {
        num = Math.ceil(Math.random() * 10);
        modulus = num % 2;
        if ((turn === "pic" && imageNum.includes(num)) || (turn === "word" && wordNum.includes(num))) {
          continue;
        };
        break;
      };

      let valid = this.check4Attribute(modulus, realAnswer);
      if (valid) {
        if (turn === "word") {
          turn = "pic"
          wordAndPicture.push(words[num - 1])
        } else if (turn === "pic") {
          turn = "word"
          wordAndPicture.push(String(num) + ".jpg");
        }
        realAnswer.push(modulus);
        i += 1;
      }
    }
    this.setState({ b3WordAndPicture: wordAndPicture, b3RealAnswer: realAnswer });
  };

  generateBlock4 = () => {
    const wordAndPicture = [];
    const realAnswer = [];
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike",
      "Friendly", "Unfriendly", "Happy", "Grumpy"];
    let turn = "word";
    let i = 0;
    while (i < this.state.trials * 4) {
      const num = Math.ceil(Math.random() * 10);
      const modulus = num % 2;
      let valid = this.check4Attribute(modulus, realAnswer);
      if (valid) {
        if (turn === "word") {
          turn = "pic"
          wordAndPicture.push(words[num - 1])
        } else if (turn === "pic") {
          turn = "word"
          wordAndPicture.push(String(num) + ".jpg");
        }
        realAnswer.push(modulus);
        i += 1;
      }
    }
    this.setState({ b4WordAndPicture: wordAndPicture, b4RealAnswer: realAnswer });
  }

  generateBlock6 = () => {
    const wordAndPicture = [];
    const realAnswer = [];
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike",
      "Friendly", "Unfriendly", "Happy", "Grumpy"];
    let turn = "word";
    let i = 0;
    while (i < this.state.trials * 2) {
      const num = Math.ceil(Math.random() * 10);
      const modulus = num % 2;
      let valid = this.check4Attribute(modulus, realAnswer);
      if (valid) {
        if (turn === "word") {
          turn = "pic"
          wordAndPicture.push(words[num - 1])
        } else if (turn === "pic") {
          turn = "word"
          wordAndPicture.push(String(num) + ".jpg");
        }
        realAnswer.push(modulus);
        i += 1;
      }
    }
    this.setState({ b6WordAndPicture: wordAndPicture, b6RealAnswer: realAnswer });
  };

  generateBlock7 = () => {
    const wordAndPicture = [];
    const realAnswer = [];
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike",
      "Friendly", "Unfriendly", "Happy", "Grumpy"];
    let turn = "word";
    let i = 0;
    while (i < this.state.trials * 4) {
      const num = Math.ceil(Math.random() * 10);
      const modulus = num % 2;
      let valid = this.check4Attribute(modulus, realAnswer);
      if (valid) {
        if (turn === "word") {
          turn = "pic"
          wordAndPicture.push(words[num - 1])
        } else if (turn === "pic") {
          turn = "word"
          wordAndPicture.push(String(num) + ".jpg");
        }
        realAnswer.push(modulus);
        i += 1;
      }
    }
    this.setState({ b7WordAndPicture: wordAndPicture, b7RealAnswer: realAnswer });
  }

  check4Attribute = (curr, realAnswer) => {
    // check whether 4 images with same attributes are continous
    const length = realAnswer.length;
    if (length < 4) return true;
    return !(realAnswer[length - 1] === curr &&
      realAnswer[length - 2] === curr &&
      realAnswer[length - 3] === curr &&
      realAnswer[length - 4] === curr)
  };

  render() {
    const {
      b1WordAndPicture,
      b2WordAndPicture,
      b3WordAndPicture,
      b4WordAndPicture,
      b5WordAndPicture,
      b6WordAndPicture,
      b7WordAndPicture,
      b3WordLeftKey,
      b3ImageLeftKey,
      b4WordLeftKey,
      b4ImageLeftKey,
      b6WordLeftKey,
      b6ImageLeftKey,
      b7WordLeftKey,
      b7ImageLeftKey,
      b1RealAnswer,
      b2RealAnswer,
      b3RealAnswer,
      b4RealAnswer,
      b5RealAnswer,
      b6RealAnswer,
      b7RealAnswer,
      timer
    } = this.state;
    let b3PositiveWord = 0;
    let b4PositiveWord = 0;
    let b6PositiveWord = 0;
    let b7PositiveWord = 0;
    let b3NegativeWord = 0;
    let b4NegativeWord = 0;
    let b6NegativeWord = 0;
    let b7NegativeWord = 0;
    let b3NonDisfiguredImage = 0;
    let b4NonDisfiguredImage = 0;
    let b6NonDisfiguredImage = 0;
    let b7NonDisfiguredImage = 0;
    let b3DisfiguredImage = 0;
    let b4DisfiguredImage = 0;
    let b6DisfiguredImage = 0;
    let b7DisfiguredImage = 0;

    let positiveWordLeft = 0;
    let nonDisfiguredImageLeft = 0;
    let positiveWordRight = 0;
    let nonDisfiguredImageRight = 0;
    let negativeWordLeft = 0;
    let disfiguredImageLeft = 0;
    let negativeWordRight = 0;
    let disfiguredImageRight = 0;

    b3RealAnswer.map((q, i) => {
      if (i % 2 === 0) {
        if (q === 1) {
          b3PositiveWord++;
          if (b3WordLeftKey === 1) {
            positiveWordLeft++;
          } else {
            positiveWordRight++;
          }
        } else {
          b3NegativeWord++;
          if (b3WordLeftKey === 1) {
            negativeWordRight++;
          } else {
            negativeWordLeft++;
          }
        }
      } else {
        if (q === 1) {
          b3NonDisfiguredImage++;
          if (b3ImageLeftKey === 1) {
            nonDisfiguredImageLeft++;
          } else {
            nonDisfiguredImageRight++;
          }
        } else {
          b3DisfiguredImage++;
          if (b3ImageLeftKey === 1) {
            disfiguredImageRight++;
          } else {
            disfiguredImageLeft++;
          }
        }
      }
    });

    b4RealAnswer.map((q, i) => {
      if (i % 2 === 0) {
        if (q === 1) {
          b4PositiveWord++;
          if (b4WordLeftKey === 1) {
            positiveWordLeft++;
          } else {
            positiveWordRight++;
          }
        } else {
          b4NegativeWord++;
          if (b4WordLeftKey === 1) {
            negativeWordRight++;
          } else {
            negativeWordLeft++;
          }
        }
      } else {
        if (q === 1) {
          b4NonDisfiguredImage++;
          if (b4ImageLeftKey === 1) {
            nonDisfiguredImageLeft++;
          } else {
            nonDisfiguredImageRight++;
          }
        } else {
          b4DisfiguredImage++;
          if (b4ImageLeftKey === 1) {
            disfiguredImageRight++;
          } else {
            disfiguredImageLeft++;
          }
        }
      }
    });

    b6RealAnswer.map((q, i) => {
      if (i % 2 === 0) {
        if (q === 1) {
          b6PositiveWord++;
          if (b6WordLeftKey === 1) {
            positiveWordLeft++;
          } else {
            positiveWordRight++;
          }
        } else {
          b6NegativeWord++;
          if (b6WordLeftKey === 1) {
            negativeWordRight++;
          } else {
            negativeWordLeft++;
          }
        }
      } else {
        if (q === 1) {
          b6NonDisfiguredImage++;
          if (b6ImageLeftKey === 1) {
            nonDisfiguredImageLeft++;
          } else {
            nonDisfiguredImageRight++;
          }
        } else {
          b6DisfiguredImage++;
          if (b6ImageLeftKey === 1) {
            disfiguredImageRight++;
          } else {
            disfiguredImageLeft++;
          }
        }
      }
    });

    b7RealAnswer.map((q, i) => {
      if (i % 2 === 0) {
        if (q === 1) {
          b7PositiveWord++;
          if (b7WordLeftKey === 1) {
            positiveWordLeft++;
          } else {
            positiveWordRight++;
          }
        } else {
          b7NegativeWord++;
          if (b7WordLeftKey === 1) {
            negativeWordRight++;
          } else {
            negativeWordLeft++;
          }
        }
      } else {
        if (q === 1) {
          b7NonDisfiguredImage++;
          if (b7ImageLeftKey === 1) {
            nonDisfiguredImageLeft++;
          } else {
            nonDisfiguredImageRight++;
          }
        } else {
          b7DisfiguredImage++;
          if (b7ImageLeftKey === 1) {
            disfiguredImageRight++;
          } else {
            disfiguredImageLeft++;
          }
        }
      }
    });

    const positiveWord = b3PositiveWord + b4PositiveWord + b6PositiveWord + b7PositiveWord;
    const negativeWord = b3NegativeWord + b4NegativeWord + b6NegativeWord + b7NegativeWord;
    const disfiguredImage = b3DisfiguredImage + b4DisfiguredImage + b6DisfiguredImage + b7DisfiguredImage;
    const nonDisfiguredImage = b3NonDisfiguredImage + b4NonDisfiguredImage + b6NonDisfiguredImage + b7NonDisfiguredImage;

    // Condition A
    const positiveWordAndNonDisfiguredImageLeft = positiveWordLeft + nonDisfiguredImageLeft;
    const positiveWordAndNonDisfiguredImageRight = positiveWordRight + nonDisfiguredImageRight;
    const negativeWordAndDisfiguredImageLeft = negativeWordLeft + disfiguredImageLeft;
    const negativeWordAndDisfiguredImageRight = negativeWordRight + disfiguredImageRight;

    // Condition B
    const negativeWordAndNonDisfiguredImageLeft = negativeWordLeft + nonDisfiguredImageLeft;
    const negativeWordAndNonDisfiguredImageRight = negativeWordRight + nonDisfiguredImageRight;
    const positiveWordAndDisfiguredImageLeft = positiveWordLeft + disfiguredImageLeft;
    const positiveWordAndDisfiguredImageRight = positiveWordRight + disfiguredImageRight;

    return (
      <div style={{ width: "100%", backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <Container>
          <br />
          <h4>Spending {timer} ms to generate this test.</h4><br /><br />
          <Button variant="contained" size="large" color="primary" onClick={this.componentDidMount}>Generate new test</Button>
          <br /><br />
          <h3>Condition A</h3>
          <h4>positiveWordAndNonDisfiguredImage correct on Left: {positiveWordAndNonDisfiguredImageLeft}, positiveWordAndNonDisfiguredImage correct on Right: {positiveWordAndNonDisfiguredImageRight}</h4>
          <h4>negativeWordAndDisfiguredImage correct on Left: {negativeWordAndDisfiguredImageLeft}, negativeWordAndDisfiguredImage correct on Right: {negativeWordAndDisfiguredImageRight}</h4>
          <br />
          <h3>Condition B</h3>
          <h4>negativeWordAndNonDisfiguredImage correct on Left: {negativeWordAndNonDisfiguredImageLeft}, negativeWordAndNonDisfiguredImage correct on Right: {negativeWordAndNonDisfiguredImageRight}</h4>
          <h4>positiveWordAndDisfiguredImage correct on Left: {positiveWordAndDisfiguredImageLeft}, positiveWordAndDisfiguredImage correct on Right: {positiveWordAndDisfiguredImageRight}</h4>
          <h4>
            positiveWord: {positiveWord}, negativeWord: {negativeWord}, disfiguredImage: {disfiguredImage}, nonDisfiguredImage: {nonDisfiguredImage}
          </h4>
          <h3>Block1</h3>
          {
            b1WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b1RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block2</h3>
          {
            b2WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b2RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block5</h3>
          {
            b5WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b5RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block3</h3>
          <h4>Word left key: {b3WordLeftKey}, Positive words: {b3PositiveWord}, Negative words: {b3NegativeWord}.</h4>
          <h4>Image left key: {b3ImageLeftKey}, Non-disfigured images: {b3NonDisfiguredImage}, disfigured images: {b3DisfiguredImage}.</h4>
          {
            b3WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b3RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block4</h3>
          <h4>Word left key: {b4WordLeftKey}, Positive words: {b4PositiveWord}, Negative words: {b4NegativeWord}.</h4>
          <h4>Image left key: {b4ImageLeftKey}, Non-disfigured images: {b4NonDisfiguredImage}, disfigured images: {b4DisfiguredImage}.</h4>
          {
            b4WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b4RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block6</h3>
          <h4>Word left key: {b6WordLeftKey}, Positive words: {b6PositiveWord}, Negative words: {b6NegativeWord}.</h4>
          <h4>Image left key: {b6ImageLeftKey}, Non-disfigured images: {b6NonDisfiguredImage}, disfigured images: {b6DisfiguredImage}.</h4>
          {
            b6WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b6RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
          <h3>Block7</h3>
          <h4>Word left key: {b7WordLeftKey}, Positive words: {b7PositiveWord}, Negative words: {b7NegativeWord}.</h4>
          <h4>Image left key: {b7ImageLeftKey}, Non-disfigured images: {b7NonDisfiguredImage}, disfigured images: {b7DisfiguredImage}.</h4>
          {
            b7WordAndPicture.map((q, i) => {
              return <div className="row">
                <div className="col-3"><h4>{q}</h4></div>
                <div className="col-9"><h4>{b7RealAnswer[i]}</h4></div>
              </div>;
            })
          }
          <br />
        </Container>
      </div>
    );
  }
}

export default Test;