import React from "react";
import styles from "./publication.module.css";
import PaperItem from "./paper-item";
import publication from "../assets/images/publication.svg";

const featuredPapers = [
  {
    title:
      "Development of a Novel Bone Conduction Verification Tool using a Surface Microphone: Validation with Percutaneous Bone Conduction Users. Ear and Hearing.",
    authors: "Hodgetts, W.E., Scott, D.K., Maas, P., Westover, L. (2018)",
  },
  {
    title:
      "DSL Prescriptive Targets for Bone Conduction Devices: Adaptation and Comparison to Clinical Fittings. International Journal of Audiology.",
    authors: "Hodgetts, W.E., & Scollie, S.D. (2017)",
  },
  {
    title:
      "Changing Hearing Performance and Sound Preference with Words and Expectations: Meaning Responses in Audiology. Ear and Hearing.",
    authors: "Hodgetts, W.E., Aalto, D., Ostevik, A., Cummine, J. (2018)",
  },
  {
    title:
      "From lollipops to lidocaine: The need for a universal print-to-speech framework. Canadian Journal of Experimental Psychology/Revue canadienne de psychologie expérimentale, 75(3), 279–298.",
    authors:
      "Cummine, J., Cullum, A., Aalto, D., Sereda, T., Fleming, C., Reed, A., Ostevik, A., Cashion-Dextrase, S., Jeffery, C. C., & Hodgetts, W. E. (2021)",
  },
];

const significantContributions = [
  {
    date: "2017/01",
    description:
      "Temporal autocorrelations and between subject heterogeneity in resting state functional connectivity. This paper advances methodology with respect to functional connectivity approaches to analyzing brain imaging data. The approach is validated in a group of individuals with dyslexia and a typical reading group. The approach is rated as one of the top 3 methods for conducting functional connectivity in neuroimaging data.",
  },
  {
    date: "2017/02",
    description:
      "Graphical modelling of the reading network in skilled and impaired readers: The role of the basal ganglia in the print to speech network. This contribution represents a submitted publication to Journal of Cognitive Neuroscience that 1) outlines the neural basis of the reading network in both skilled and impaired readers, 2) demonstrates the effectiveness of the functional connectivity approach to studying brain networks and 3) advances models of written communication by providing novel ideas about the role of speech production in reading.",
  },
  {
    date: "2015/11",
    description:
      "Understanding the role of speech production in reading: Evidence for a print-to-speech network using graphical analysis. This paper provides a framework for understanding the role of speech production in reading. It is a first demonstration of the application of graphical modelling analysis techniques to understanding print.",
  },
];

const journalArticles = [
  {
    title:
      "Perils & pitfalls of fNIRS: A systematic investigation. Frontiers in Human Neuroscience.",
    authors: "Holmes, M., Aalto, D., Cummine, J. (2023)",
  },
  {
    title:
      "Exploring the impact of non-linguistic factors on single word recognition in skilled adult readers. Canadian Psychology.",
    authors:
      "Jacqueline Cummine, Julia Craig, Cory McKenzie, Mikayla Toal, Emilie Lefebvre, Alexis Baird, Meghan Edgson, Amberley V. Ostevik, Kulpreet Cheema, William Hodgetts. (2023)",
  },
  {
    title:
      "The role of the IFG and STG in overt vs. covert speech production: An fNIRS approach. Brain and Language.",
    authors:
      "Jacqueline Cummine, Dima Alsaigh, Talah Hasanni, Mitchell Holmes, Kulpreet Cheema, Truc Huynh, Sarah Saju, Torrey Loucks, Daniel Aalto. (2023)",
  },
  {
    title:
      "An invisible disability: A practical guide to capturing the consequences of fluctuating hearing in school-age children. Canadian Journal of Speech Language Pathology and Audiology.",
    authors:
      "Cassandra Cowan, Kathleen Jones, Amberley V. Ostevik, Sara Al Souqi, William Hodgetts, Jacqueline Cummine. (2023)",
  },
  {
    title:
      "Tissue classification following bone anchored hearing implant surgery: A machine learning approach to monitoring skin response. Otology & Neurotology",
    authors: "Cummine, J., Ostevik, A., Song, Q, Kim, Y., Hodgetts, W. (2023)",
  },
  {
    title:
      "Structural lateralization of subcortical regions differentiates skilled and impaired readers: Towards a better understanding of the role of the caudate, putamen and thalamus in reading. Neuroscience.",
    authors: "Cummine, Jacqueline, Ngo, Tiffany, Nisbett, Kelly. (2023)",
  },
  {
    title:
      "Comparison of the Efficiency and Reliability for Two Methods of Coding Language Samples: SALT Versus The ObserverXT. International Journal of Language & Communication Disorders",
    authors:
      "Trelani MILBURN, Leah AUCH, Alexandra HENDERSON, Anka CHAN, & Jacqueline CUMMINE. (2023)",
  },
  {
    title:
      "Differential grey matter structure of the pars orbitalis, triangularis and opercularis in individuals with dyslexia: A volumetric asymmetry study. Journal of Neuroscience Research",
    authors:
      "Kelly Nisbet, Avary Kostiw, Thi Kim Truc Huynh, Sukhmani Kaur Saggu, Dev Patel, Jacqueline Cummine. (2023)",
  },
  {
    title:
      "Adult Learner Perspectives on Skill- and Life-Based Outcomes Following Literacy Remediation. Social Sciences.",
    authors:
      "Jacqueline Cummine *, Amberley Ostevik, Kulpreet Cheema, Angela Cullum. (2023)",
  },
  {
    title: "Aphantasia, memory, and mood: A mixed-methods analysis. Cortex.",
    authors: "Angela Cullum, Jaylyn Loewen, Jacqueline Cummine. (2023)",
  },
  {
    title:
      "Phonological, Orthographic and Morphological Skills are Related to Structural Properties of Ventral and Motor White Matter Pathways In Skilled and Impaired Readers. Journal of Neurolinguistics.",
    authors:
      "Alesha Reed, *Truc Huynh, Amberley V. Ostevik, Kulpreet Cheema, Sarah Sweneya, Julia Craig, Jacqueline Cummine. (2023)",
  },
  {
    title:
      "Reading and Spelling Profiles of Adults with and without Dyslexia: Phonological, Orthographic and Morphological Considerations. Dyslexia.",
    authors:
      "Kulpreet Cheema, Cassidy Fleming, Julia Craig, William E. Hodgetts, and Jacqueline Cummine. (2022)",
  },
  {
    title:
      "White Matter Diffusion Properties in Chronic Temporomandibular Disorders: An Exploratory Analysis. Frontiers in Pain Research.",
    authors:
      "Alexandra S. Budd, Thi K. T. Huynh, Peter Seres, Christian Beaulieu, Susan Armijo-Olivo, and Jacqueline Cummine. (2022)",
  },
  {
    title:
      "An investigation of white matter properties as they relate to spelling behaviour in skilled and impaired readers. Neuropsychological Rehabilitation.",
    authors:
      "Kulpreet Cheema, Sarah Sweneya, Julia Craig, Truc Huynh, Amberley V. Ostevik, Alesha Reed, Jacqueline Cummine. (2022)",
  },
  {
    title:
      "The Crisis Study: COVID-19 & Reading Impairments - Survey Investigating Socializing. Papers of 4th Canadian International Conference on Humanities & Social Sciences 2022 (978-1-988652-50-4).",
    authors:
      "Samira Zabian, Dr. Cassidy Fleming, Amberly V. Ostevik, Dr. Jacqueline Cummine. (2022)",
  },
  {
    title:
      "Is the letter 't' in the word 'gourmet'? A characterization of spelling networks between skilled and impaired readers. NeuroSci.",
    authors:
      "Cheema, Kulpreet; Hodgetts, Williams; Cummine, Jacqueline. (2021)",
  },
  {
    title:
      "On a Vector towards a Novel Hearing Aid Feature: What Can We Learn from Modern Family, Voice Classification and Deep Learning Algorithms. Applied Sciences.",
    authors:
      "William Hodgetts 1,2,3, Qi Song 4, Xinyue Xiang 4 and Jacqueline Cummine 1,3,*. (2021)",
  },
  {
    title:
      "Chew on this! Oral stereognosis predicts visual word recognition in typical adults. Current Psychology.",
    authors:
      "Jacqueline Cummine, Thi Kim Truc Huynh, Angela Cullum, Amberley Ostevik, William Hodgetts. (2021)",
  },
  {
    title:
      "Changes in Intermuscular Coherence as a Function of Age and Phase of Speech Production During an Overt Reading Task. Motor control.",
    authors:
      "Alesha Reed, Jacqueline Cummine, Neesha Bhat, Shivraj Jhala, Reyhaneh Bakhtiari, and Carol A. Boliek. (2021)",
  },
  {
    title:
      "Resting-state networks and reading in adults with and without reading impairments. Journal of Neurolinguistics.",
    authors:
      "Cheema, K., Westover, L., Ostevik, A., Hodgetts, W., & Cummine, J. (2021)",
  },
  {
    title:
      "From Lollipops to Lidocaine: Changes in Somatosensory Feedback Modifies Silent Word Recognition Performance in Adults. Canadian Journal of Experimental Psychology.",
    authors:
      "Jacqueline Cummine, *Angela Cullum, Daniel Aalto, *Tyson Sereda, *Cassidy Fleming, *Alesha Reed, Amberley Ostevik, Caroline Jeffery, & William E. Hodgetts. (2021)",
  },
  {
    title:
      "Be Part of the Conversation: Audiology Messaging During a Hearing Screening. Ear and Hearing.",
    authors:
      "Craig St. Jean, Jacqueline Cummine, Gurjit Singh, William Hodgetts. (2020)",
  },
  {
    title:
      "An Investigation of the Impact of Somatosensory Feedback on Reading Performance in Children with Childhood Apraxia of Speech: A Case-Control Series. Journal of Communication Disorders",
    authors:
      "Cassidy Fleming1, Teresa Paslawski1,2, Torrey Loucks1,3,4, Angela Cullum1, Daniel Aalto1,2, Amber Ostevik1, William E. Hodgetts1,5, & Jacqueline Cummine. (2020)",
  },
  {
    title:
      "Listen Before You Drive: The Effect of Voice Familiarity on Listening Comprehension and Driving Performance. International Journal of Audiology",
    authors:
      "Cory McKenzie, William Hodgetts, Amber Ostevik, Jacqueline Cummine. (2020)",
  },
  {
    title:
      "A study of null effects for the use of transcranial direct current stimulation (tDCS) plus skill-based training in adults with and without reading impairment. Neurobiology of Language.",
    authors:
      "Jacqueline Cummine, Miya Villarena, Taylor Onysk, Joseph Devlin. (2020)",
  },
  {
    title:
      "Measuring Intermuscular Coherence of Speech Motor Control in Younger and Older Adults on an Overt Reading Task. Motor Control.",
    authors:
      "Reed, Alesha; Cummine, Jacqueline; *Bhat, Neesha; Jhala, Shivraj; Boliek, Carol A. PhD. (2020)",
  },
  {
    title:
      "Evaluation of Word Recognition and Word Recall with Bone Conduction Devices: Do Directional Microphones Free up Cognitive Resources?. International Journal for Audiology.",
    authors:
      "Lindsey Westover, Ph.D. Amberley V Ostevik, M.Sc. Daniel Aalto, Ph.D Jacqueline Cummine, Ph,D William E Hodgetts, Ph.D. (2020)",
  },
  {
    title:
      "Comparison of Health Insurance Coverage for Hearing Aids and Other Services in Alberta. Healthcare Policy.",
    authors:
      "Amberley V. Ostevik *Lindsey Westover Haley Gynane Jordan Herst Jacqueline Cummine William E. Hodgetts. (2018). (2019)",
  },
  {
    title:
      "Cerebellar Activation During Reading Tasks: Exploring the Dichotomy between Motor vs. Language Functions in Adults of Varying Reading Proficiency. The Cerebellum",
    authors:
      "*Angela Cullum, William Hodgetts, Trelani Chapman, Jacqueline Cummine. (2019)",
  },
  {
    title:
      "To Go or Not to Go: Exploring brain activation during response inhibition reading tasks. Spectrum.",
    authors:
      "*Julia Craig, Amberley V. Ostevik, Lindsey Westover, Bill Hodgetts, Jacqueline Cummine. (2019)",
  },
  {
    title:
      "Meaning Responses in Audiology: Changing Hearing Performance and Sound Preference with Words and Expectations. Ear and Hearing.",
    authors: "Hodgetts, W.E. Aalto, D. Ostevik, A. Cummine, J. (2018)",
  },
  {
    title:
      "The Relationship Between White Matter and Reading Acquisition, Refinement and Maintenance. Developmental Neuroscience.",
    authors: "*Kulpreet Cheema Jacqueline Cummine. (2018)",
  },
  {
    title:
      "To name or not to name: The role of response inhibition in reading. Journal of Psycholinguistics.",
    authors:
      "Jacqueline Cummine, Daniel Aalto, Amberley Ostevik, Kulpreet Cheema, William Hodgetts. (2018)",
  },
  {
    title:
      "Processing Spoken and Written Metaphors: Comparison Using the Metaphor Interference Effect. Discourse Processes.",
    authors:
      "Brea Chouinard, Joanne Volden, John Hollinger, Jacqueline Cummine. (2018)",
  },
  {
    title:
      "Differential Cortical Control of Chest Wall Muscles during Pressure- and Volume-Related Expiratory Tasks and the Effects of Acute Expiratory Threshold Loading. Motor Control.",
    authors:
      "Carol A Boliek, Reyhaneh Bakhatiari, Lauren Pedersen, *Julia R Esch, and Jacqueline Cummine. (2018)",
  },
  {
    title:
      "Transcranial direct current stimulation (tDCS) selectively modulates semantic information during reading. Brain and Language.",
    authors:
      "Jacqueline Cummine, Carol A. Boliek, Tessa McKibben, Aamn Jaswal, Marc F. Joanisse. (2018)",
  },
];

const PublicationSection = () => {
  return (
    <section className={styles.publication}>
      <img
        loading="lazy"
        src={publication}
        className={styles.headerImage}
        alt="Publication header image"
      />

      <h2 className={styles.sectionTitle}>Featured Papers</h2>
      {featuredPapers.map((paper, index) => (
        <React.Fragment key={index}>
          <PaperItem title={paper.title} authors={paper.authors} />
          <hr className={styles.divider} />
        </React.Fragment>
      ))}

      <h2 className={styles.sectionTitle}>Most Significant Contributions</h2>
      <div className={styles.contributionsContainer}>
        {significantContributions.map((contribution, index) => (
          <article key={index} className={styles.significantContribution}>
            <time className={styles.contributionDate}>{contribution.date}</time>
            <p className={styles.contributionDescription}>
              {contribution.description}
            </p>
          </article>
        ))}
      </div>

      <h2 className={styles.sectionTitle}>Journal Articles</h2>
      {journalArticles.map((article, index) => (
        <React.Fragment key={index}>
          <PaperItem title={article.title} authors={article.authors} />
          <hr className={styles.divider} />
        </React.Fragment>
      ))}
    </section>
  );
};

export default PublicationSection;
