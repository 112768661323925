export const green = [
    'G123.jpg',
    'G137.jpg',
    'G37.jpg',
    'G23.jpg',
    'G22.jpg',
    'G36.jpg',
    'G136.jpg',
    'G122.jpg',
    'G108.jpg',
    'G134.jpg',
    'G120.jpg',
    'G20.jpg',
    'G34.jpg',
    'G35.jpg',
    'G21.jpg',
    'G121.jpg',
    'G135.jpg',
    'G109.jpg',
    'G131.jpg',
    'G125.jpg',
    'G119.jpg',
    'G19.jpg',
    'G25.jpg',
    'G31.jpg',
    'G30.jpg',
    'G24.jpg',
    'G18.jpg',
    'G118.jpg',
    'G124.jpg',
    'G130.jpg',
    'G126.jpg',
    'G132.jpg',
    'G32.jpg',
    'G26.jpg',
    'G27.jpg',
    'G33.jpg',
    'G133.jpg',
    'G127.jpg',
    'G3.jpg',
    'G03.jpg',
    'G168.jpg',
    'G140.jpg',
    'G154.jpg',
    'G54.jpg',
    'G40.jpg',
    'G68.jpg',
    'G97.jpg',
    'G83.jpg',
    'G82.jpg',
    'G96.jpg',
    'G69.jpg',
    'G41.jpg',
    'G55.jpg',
    'G155.jpg',
    'G141.jpg',
    'G169.jpg',
    'G2.jpg',
    'G157.jpg',
    'G143.jpg',
    'G43.jpg',
    'G57.jpg',
    'G94.jpg',
    'G95.jpg',
    'G81.jpg',
    'G56.jpg',
    'G42.jpg',
    'G142.jpg',
    'G156.jpg', 'G1.jpg', 'G5.jpg', 'G152.jpg', 'G146.jpg', 'G46.jpg',
    'G52.jpg', 'G85.jpg', 'G91.jpg', 'G90.jpg', 'G84.jpg', 'G53.jpg', 'G47.jpg',
    'G147.jpg', 'G153.jpg', 'G4.jpg', 'G6.jpg', 'G145.jpg', 'G151.jpg', 'G79.jpg',
    'G51.jpg', 'G45.jpg', 'G92.jpg', 'G86.jpg', 'G87.jpg', 'G93.jpg', 'G44.jpg',
    'G50.jpg', 'G78.jpg', 'G150.jpg', 'G144.jpg', 'G7.jpg', 'G149.jpg', 'G161.jpg',
    'G75.jpg', 'G61.jpg', 'G49.jpg', 'G48.jpg', 'G60.jpg', 'G74.jpg', 'G160.jpg',
    'G148.jpg', 'G9.jpg', 'G162.jpg', 'G62.jpg', 'G89.jpg', 'G88.jpg',
    'G77.jpg', 'G63.jpg', 'G163.jpg', 'G8.jpg', 'G167.jpg', 'G67.jpg', 'G73.jpg',
    'G98.jpg', 'G99.jpg', 'G72.jpg', 'G66.jpg', 'G166.jpg', 'G164.jpg', 'G170.jpg',
    'G158.jpg', 'G58.jpg', 'G70.jpg', 'G64.jpg', 'G65.jpg', 'G71.jpg', 'G59.jpg',
    'G159.jpg', 'G165.jpg', 'G102.jpg', 'G116.jpg', 'G16.jpg', 'G17.jpg', 'G117.jpg',
    'G103.jpg', 'G129.jpg', 'G115.jpg', 'G101.jpg', 'G15.jpg', 'G29.jpg', 'G28.jpg',
    'G14.jpg', 'G100.jpg', 'G114.jpg', 'G128.jpg', 'G110.jpg', 'G104.jpg', 'G138.jpg',
    'G38.jpg', 'G10.jpg', 'G11.jpg', 'G39.jpg', 'G139.jpg', 'G105.jpg', 'G111.jpg',
    'G107.jpg', 'G113.jpg', 'G13.jpg', 'G12.jpg', 'G112.jpg', 'G106.jpg'];

export const yellow = [
    'Y157.jpg', 'Y143.jpg', 'Y6.jpg', 'Y84.jpg', 'Y47.jpg', 'Y53.jpg', 'Y52.jpg',
    'Y46.jpg', 'Y91.jpg', 'Y85.jpg', 'Y142.jpg', 'Y7.jpg', 'Y156.jpg', 'Y5.jpg', 'Y140.jpg',
    'Y154.jpg', 'Y93.jpg', 'Y87.jpg', 'Y78.jpg', 'Y50.jpg', 'Y44.jpg', 'Y45.jpg', 'Y51.jpg',
    'Y79.jpg', 'Y86.jpg', 'Y92.jpg', 'Y155.jpg', 'Y4.jpg', 'Y141.jpg', 'Y145.jpg', 'Y151.jpg',
    'Y96.jpg', 'Y82.jpg', 'Y55.jpg', 'Y41.jpg', 'Y69.jpg', 'Y68.jpg', 'Y40.jpg', 'Y54.jpg',
    'Y83.jpg', 'Y97.jpg', 'Y150.jpg', 'Y144.jpg', 'Y1.jpg', 'Y152.jpg', 'Y3.jpg', 'Y146.jpg',
    'Y81.jpg', 'Y95.jpg', 'Y42.jpg', 'Y56.jpg', 'Y57.jpg', 'Y43.jpg', 'Y94.jpg', 'Y080.jpg',
    'Y2.jpg', 'Y147.jpg', 'Y153.jpg', 'Y108.jpg', 'Y134.jpg', 'Y120.jpg', 'Y18.jpg', 'Y24.jpg',
    'Y30.jpg', 'Y31.jpg', 'Y25.jpg', 'Y19.jpg', 'Y121.jpg', 'Y135.jpg', 'Y109.jpg', 'Y123.jpg',
    'Y137.jpg', 'Y33.jpg', 'Y27.jpg', 'Y26.jpg', 'Y32.jpg', 'Y136.jpg', 'Y122.jpg', 'Y126.jpg',
    'Y132.jpg', 'Y36.jpg', 'Y22.jpg', 'Y23.jpg', 'Y37.jpg', 'Y133.jpg', 'Y127.jpg', 'Y131.jpg',
    'Y125.jpg', 'Y119.jpg', 'Y21.jpg', 'Y35.jpg', 'Y34.jpg', 'Y20.jpg', 'Y118.jpg', 'Y124.jpg',
    'Y130.jpg', 'Y129.jpg', 'Y115.jpg', 'Y101.jpg', 'Y39.jpg', 'Y11.jpg', 'Y10.jpg', 'Y38.jpg',
    'Y100.jpg', 'Y114.jpg', 'Y128.jpg', 'Y102.jpg', 'Y116.jpg', 'Y12.jpg', 'Y13.jpg', 'Y117.jpg',
    'Y103.jpg', 'Y107.jpg', 'Y113.jpg', 'Y17.jpg', 'Y16.jpg', 'Y112.jpg', 'Y106.jpg', 'Y110.jpg',
    'Y104.jpg', 'Y138.jpg', 'Y14.jpg', 'Y28.jpg', 'Y29.jpg', 'Y15.jpg', 'Y139.jpg', 'Y105.jpg',
    'Y111.jpg', 'Y99.jpg', 'Y66.jpg', 'Y72.jpg', 'Y73.jpg', 'Y67.jpg', 'Y149.jpg',
    'Y59.jpg', 'Y71.jpg', 'Y65.jpg', 'Y64.jpg', 'Y70.jpg', 'Y58.jpg', 'Y148.jpg', 'Y9.jpg',
    'Y74.jpg', 'Y60.jpg', 'Y48.jpg', 'Y49.jpg', 'Y61.jpg',
    'Y75.jpg', 'Y8.jpg', 'Y88.jpg', 'Y63.jpg', 'Y77.jpg', 'Y76.jpg', 'Y076.jpg', 'Y62.jpg', 'Y89.jpg'
];

export const red = [
    'R10.jpg', 'R38.jpg', 'R39.jpg', 'R11.jpg', 'R13.jpg', 'R12.jpg', 'R16.jpg', 'R17.jpg',
    'R29.jpg', 'R15.jpg', 'R14.jpg', 'R28.jpg', 'R2.jpg', 'R67.jpg', 'R73.jpg', 'R72.jpg',
    'R66.jpg', 'R1.jpg', 'R70.jpg', 'R64.jpg', 'R58.jpg', 'R59.jpg', 'R65.jpg',
    'R71.jpg', 'R4.jpg', 'R49.jpg', 'R75.jpg', 'R61.jpg', 'R60.jpg', 'R74.jpg', 'R48.jpg',
    'R5.jpg', 'R7.jpg', 'R62.jpg', 'R76.jpg', 'R77.jpg', 'R63.jpg', 'R6.jpg', 'R46.jpg',
    'R52.jpg', 'R53.jpg', 'R47.jpg', 'R8.jpg', 'R51.jpg', 'R45.jpg', 'R44.jpg', 'R50.jpg',
    'R68.jpg', 'R54.jpg', 'R40.jpg', 'R41.jpg', 'R55.jpg', 'R69.jpg', 'R43.jpg', 'R57.jpg',
    'R56.jpg', 'R42.jpg', 'R25.jpg', 'R31.jpg', 'R19.jpg', 'R18.jpg', 'R30.jpg', 'R24.jpg',
    'R32.jpg', 'R26.jpg', 'R27.jpg', 'R33.jpg', 'R37.jpg',
    'R23.jpg', 'R22.jpg', 'R36.jpg', 'R20.jpg', 'R34.jpg', 'R35.jpg', 'R21.jpg', 'R080.jpg', 'R090.jpg'
];