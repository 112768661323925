import React, { useEffect, useRef, useState } from "react";
import { Input, Container, Fab, Button } from "@material-ui/core";
import CountDown from "hearing/assets/digits/count-down";
import { P1, P2, P3 } from "assets/fonts";
import { HearingAWS } from "assets/keys";
import triples from "GUI/assets/triplets";
import axios from "axios";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const LoadStage = {
  LOADED: "loaded",
  READY: "ready",
  RECOVERY_PLAY: "recovery_play",
  RECOVERY_RESPONSE: "recovery_response",
  REPEAT: "repeat",
  DIN: "din",
  DONE: "done",
};

const RecoveryStimulus = (props) => {
  const { id, updateAssign, volumecontrol, participantId } = props;
  const [noiseVolume, setNoiseVolume] = useState(volumecontrol.answer / 100);
  const [audioVolume, setAudioVolume] = useState(volumecontrol.answer / 100);
  const [realAnswer, setRealAnswer] = useState("");
  const [focus, setFocus] = useState(0);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [step, setStep] = useState(1);
  const [loadStage, setLoadStage] = useState(LoadStage.LOADED);
  const [dbs, setDbs] = useState([0]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [okButton, setOkButton] = useState(true);
  const [initStart, setInitStart] = useState(true);
  const [digitsAudios, setDigitsAudios] = useState([]);
  const [sentenceAudios, setSentenceAudios] = useState([]);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [numOfTrial, setNumOfTrial] = useState(null);
  const [numOfSNR, setNumOfSNR] = useState(null);
  const [easier, setEasier] = useState({
    select: null,
    numOfTrial: null,
    after: null,
  });
  const [harder, setHarder] = useState({
    select: null,
    numOfTrial: null,
    before: null,
    after: null,
  });
  const [useTriplets, setUseTriples] = useState(null);
  const [stillCorrect, setStillCorrect] = useState(true);
  const [timestamps, setTimestamps] = useState([]);
  const [sentenceAnswers, setSentenceAnswers] = useState([]);
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const audioRef = useRef(null);
  const [digitEnable, setDigitEnable] = useState(false);
  //audio visualizer
  // const [blob, setBlob] = useState < Blob > null;
  const recorder = useAudioRecorder();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const recordNumberRef = useRef(1);
  const [usedIndex, setUsedIndex] = useState([]);

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  const uploadRecording = async (audioBlob, trial, block) => {
    try {
      // Get the signed URL from the backend
      const response = await axios.get(
        `/api/gui/recoverystimulus/recordedAudio/${participantId}/${block}/${trial}`
      );
      const { url } = response.data;
      // Use the signed URL to upload the audio file
      const result = await axios.put(url, audioBlob, {
        headers: {
          "Content-Type": "audio/wav",
        },
      });
      console.log("Upload successful", result);
    } catch (error) {
      console.error("Error uploading recording", error);
    }
  };

  useEffect(() => {
    async function getMicrophoneAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = async (event) => {
          if (event.data.size > 0) {
            console.log(
              "Data available: size",
              event.data.size,
              "type",
              event.data.type
            );
            const audioBlob = event.data;

            await uploadRecording(
              audioBlob,
              recordNumberRef.current,
              numOfCurrBlock
            );
            recordNumberRef.current++;
            console.log("Recording uploaded");
          } else {
            console.log("No data available after stop.");
          }
        };

        setMediaRecorder(recorder);
      } catch (error) {
        console.error("Error accessing the microphone", error);
      }
    }

    getMicrophoneAccess();
  }, [numOfTrial, numOfCurrBlock]); // Removed loadStage to simplify

  useEffect(() => {
    // Start recording
    if (loadStage === LoadStage.REPEAT && mediaRecorder && !isRecording) {
      console.log(
        "Attempting to start recording, current state:",
        mediaRecorder.state
      );
      mediaRecorder.start();
      setIsRecording(true);
    }
  }, [loadStage, mediaRecorder, isRecording]);

  useEffect(() => {
    // Stop recording with additional check for mediaRecorder's state
    if (
      loadStage !== LoadStage.REPEAT &&
      mediaRecorder &&
      isRecording &&
      mediaRecorder.state === "recording"
    ) {
      console.log(
        "Stopping recording, current state before stop:",
        mediaRecorder.state
      );
      mediaRecorder.stop();
      setIsRecording(false);
    }
  }, [loadStage, mediaRecorder, isRecording]);

  useEffect(() => {
    (async () => {
      const doc = await axios.get("/api/gui/recoverystimulus/" + id);
      await setNumOfCurrBlock(doc.data.numOfCurrBlock);
      await setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
      await setNumOfTrial(doc.data.numOfTrial);
      // await setNoise(new Audio(HearingAWS + doc.data.noise));
      audioRef.current = new Audio(HearingAWS + doc.data.noise);
      audioRef.current.preload = "auto";
      audioRef.current.oncanplaythrough = function () {
        setLoadStage(LoadStage.LOADED);
      };
      await setDigitsAudios(doc.data.digitsAudios);
      await setSentenceAudios(doc.data.sentenceAudios);
      await setNumOfSNR(parseInt(doc.data.numOfSNR));
      await setEasier(doc.data.easier);
      await setHarder(doc.data.harder);
      await setDigitEnable(doc.data.digitEnable);
      setUseTriples(doc.data.useTriplets);
    })();
  }, [id]);

  // change recovery stimulus to repeat directly
  useEffect(() => {
    if (loadStage === LoadStage.RECOVERY_RESPONSE) {
      if (!digitEnable) {
        setLoadStage(LoadStage.REPEAT);
      }
    }
  }, [loadStage, digitEnable]);

  useEffect(() => {
    if (typeof useTriplets == "boolean" && loadStage === LoadStage.LOADED) {
      if (useTriplets) {
        setQuestions(triples.sort(() => Math.random() - 0.5));
      } else {
        showCountDown();
      }
    }
  }, [useTriplets, loadStage]);

  useEffect(() => {
    if (questions.length > 0 && loadStage === LoadStage.LOADED) {
      showCountDown();
    }
  }, [questions, loadStage]);

  useEffect(() => {
    if (step !== 1 && step <= numOfTrial) {
      setLoadStage(LoadStage.RECOVERY_PLAY);
    }
  }, [step]);

  // Inside your component
  useEffect(() => {
    if (loadStage === LoadStage.RECOVERY_RESPONSE) {
      if (!digitEnable) {
        // If digitEnable is false, move to REPEAT stage after user responds
        setTimeout(() => {
          setLoadStage(LoadStage.REPEAT);
        }, 2000); // Delay before moving to repeat stage, adjust as needed
      } else {
        // If digitEnable is true, proceed as before
        setLoadStage(LoadStage.DIN);
      }
    }
  }, [loadStage, digitEnable]);

  useEffect(() => {
    if (loadStage === LoadStage.REPEAT) {
      // Delay before moving back to RECOVERY_PLAY or transitioning based on the trial number
      const timeoutId = setTimeout(() => {
        // Always increment step since the first RECOVERY_PLAY has been shown
        setStep((prevStep) => prevStep + 1);

        // Determine whether to move to the next stage or finish
        if (step < numOfTrial) {
          setLoadStage(LoadStage.RECOVERY_PLAY);
        } else {
          setLoadStage(LoadStage.DONE);
        }
      }, 8000); // Example delay

      return () => clearTimeout(timeoutId);
    }
  }, [loadStage, step, numOfTrial]);

  const submittedRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // recovery experiment is before DIN, so no need to verify
    if (
      step === numOfTrial ||
      (dbs.length === numOfTrial + 1 && answers.length === numOfTrial)
    ) {
      const SNR = calculateSNR();
      updateAssign({
        id: props.id,
        block: numOfTotalBlocks,
        trial: numOfTrial,
        type: "recoverystimulus",
        answer: { SNR, questions, answers, dbs, sentenceAnswers, timestamps },
      });
      // reset();
      if (!submittedRef.current) {
        submittedRef.current = true;
        dispatch(readyToContinueRedux(id));
      }
      setLoadStage(LoadStage.DONE);
    }
  }, [step, numOfTotalBlocks, numOfTrial, dbs, answers]);

  useEffect(() => {
    if (loadStage === LoadStage.DIN) {
      switch (focus) {
        case 0:
          return input1Ref.current.focus();
        case 1:
          return input2Ref.current.focus();
        case 2:
          return input3Ref.current.focus();
      }
    }
  }, [loadStage, focus]);

  useEffect(() => {
    if (loadStage === LoadStage.DIN) {
      handleDINPlay();
    } else if (loadStage === LoadStage.RECOVERY_PLAY) {
      handleRecoveryPlay();
    }
  }, [loadStage]);

  const calculateSNR = () => {
    let sum = 0;
    for (let i = numOfSNR ? numOfSNR : 4; i < dbs.length; i++) {
      sum += dbs[i];
    }
    return Number(sum / (dbs.length - (numOfSNR ? numOfSNR : 4))).toFixed(3);
  };

  const showCountDown = () => {
    setLoadStage(LoadStage.READY);
    setTimeout(() => {
      setLoadStage(LoadStage.RECOVERY_PLAY);
    }, 3000);
  };

  const reset = () => {
    // setNoise(new Audio());
    audioRef.current = new Audio();
    setNoiseVolume(volumecontrol.answer / 100);
    setAudioVolume(volumecontrol.answer / 100);
    setRealAnswer("");
    setFocus(0);
    setInput1("");
    setInput2("");
    setInput3("");
    setStep(1);
    setLoadStage(LoadStage.LOADED);
    setDbs([0]);
    setDigitsAudios([]);
    setQuestions([]);
    setAnswers([]);
    setOkButton(true);
    setInitStart(true);
    setNumOfCurrBlock(0);
    setNumOfTotalBlocks(0);
    setNumOfTrial(0);
    setNumOfSNR(null);
    setEasier({ select: null, numOfTrial: null, before: null, after: null });
    setHarder({ select: null, numOfTrial: null, before: null, after: null });
    setUseTriples(null);
    setStillCorrect(true);
  };

  const goEasier = () => {
    switch (easier?.select) {
      case "linear":
        setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.num)]);
        if (audioVolume * 10 ** (parseInt(easier.num) / 20) > 1) {
          return 1;
        } else {
          return audioVolume * 10 ** (parseInt(easier.num) / 20);
        }
      case "change":
        if (step <= easier.numOfTrial) {
          setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.before)]);
          if (audioVolume * 10 ** (parseInt(easier.before) / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (parseInt(easier.before) / 20);
          }
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.after)]);
          if (audioVolume * 10 ** (parseInt(easier.after) / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (parseInt(easier.after) / 20);
          }
        }
      default:
        if (step <= 4) {
          setDbs((arr) => [...arr, dbs[step - 1] + 4]);
          if (audioVolume * 10 ** (4 / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (4 / 20);
          }
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] + 2]);
          if (audioVolume * 10 ** (2 / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (2 / 20);
          }
        }
    }
  };

  const goHarder = () => {
    switch (harder?.select) {
      case "linear":
        setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.num)]);
        return audioVolume * 10 ** (-parseInt(harder.num) / 20);
      case "change":
        if (step <= harder.numOfTrial) {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.before)]);
          return audioVolume * 10 ** (-parseInt(harder.before) / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.after)]);
          return audioVolume * 10 ** (-parseInt(harder.after) / 20);
        }
      case "incorrect":
        if (stillCorrect) {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.before)]);
          return audioVolume * 10 ** (-parseInt(harder.before) / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.after)]);
          return audioVolume * 10 ** (-parseInt(harder.after) / 20);
        }
      default:
        if (step <= 4) {
          setDbs((arr) => [...arr, dbs[step - 1] - 4]);
          return audioVolume * 10 ** (-4 / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - 2]);
          return audioVolume * 10 ** (-2 / 20);
        }
    }
  };

  const changeAnswer = (value) => {
    const nums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (!nums.includes(value)) return null;
    if (initStart) {
      setInitStart(false);
    }
    switch (focus) {
      case 0:
        recordCurrTimestamp();
        setInput1(value);
        if (value.length === 1) setFocus(1);
        break;
      case 1:
        recordCurrTimestamp();
        setInput2(value);
        if (value.length === 1) setFocus(2);
        break;
      case 2:
        recordCurrTimestamp();
        setInput3(value);
        break;
    }
  };

  const recordCurrTimestamp = () => {
    const ts = new Date().getTime();
    const lastTimestamps = [...timestamps[timestamps.length - 1]];
    lastTimestamps.push(ts);
    const newTimestamps = [
      ...timestamps.slice(0, timestamps.length - 1),
      lastTimestamps,
    ];
    setTimestamps(newTimestamps);
  };

  const handleRecoveryPlay = () => {
    // randomize one audio
    let index;
    let attempts = 0;
    do {
      if (attempts > sentenceAudios.length) {
        throw new Error("No more available sentence audios");
      }
      index = Math.floor(Math.random() * sentenceAudios.length);
      attempts++;
    } while (usedIndex.includes(index));
    usedIndex.push(index);
    const sentenceAudio = sentenceAudios[index];
    // immediately play the audio
    const audio = new Audio(HearingAWS + sentenceAudio.url);
    audio.volume = audioVolume;
    audio.play();
    setTimeout(() => {
      setLoadStage(LoadStage.RECOVERY_RESPONSE);
      audio.pause();
    }, sentenceAudio.length * 1000 + 5000);
    // record all timestamps
    const tsAudioStart = new Date().getTime();
    const tsTargetStart = new Date(
      tsAudioStart + sentenceAudio.targetStart * 1000
    ).getTime();
    const tsTargetEnd = new Date(
      tsAudioStart + sentenceAudio.targetEnd * 1000
    ).getTime();
    const tsAudioEnd = new Date(
      tsAudioStart + sentenceAudio.length * 1000
    ).getTime();
    setTimestamps([
      ...timestamps,
      [tsAudioStart, tsTargetStart, tsTargetEnd, tsAudioEnd],
    ]);
    // sentenceAnswers
    const newSentenceAnswers = [...sentenceAnswers, sentenceAudio];
    setSentenceAnswers(newSentenceAnswers);
  };

  const handleRecoveryClick = (answer) => {
    // record current timestamp
    recordCurrTimestamp();
    // update sentence audio
    const sentence = { ...sentenceAnswers[step - 1] };
    sentence["answer"] = answer;
    const newSentenceAnswers = [
      ...sentenceAnswers.slice(0, step - 1),
      sentence,
      ...sentenceAnswers.slice(step),
    ];
    setSentenceAnswers(newSentenceAnswers);
    // switch to DIN
    setLoadStage(LoadStage.DIN);
  };

  const handleDINPlay = async () => {
    const audios = [];
    let question = "";
    if (useTriplets) {
      question = questions[step - 1];
      for (let i = 0; i < 3; i++) {
        audios.push(
          new Audio(
            process.env.PUBLIC_URL +
              "/hearing/digits-audio/" +
              question.split("")[i] +
              ".wav"
          )
        );
      }
    } else {
      for (let i = 0; i < 3; i++) {
        const digit = Math.floor(Math.random() * 10);
        question += digit.toString();
        audios.push(new Audio(HearingAWS + digitsAudios[digit]));
      }
      await setQuestions((arr) => [...arr, question]);
    }
    await setRealAnswer(question);

    setTimeout(() => {
      // noise.volume = noiseVolume;
      audioRef.current.volume = noiseVolume;
      recordCurrTimestamp();
      // noise.play();
      audioRef.current.play();
    }, 0);
    setTimeout(() => {
      const audio = audios[0];
      audio.volume = audioVolume;
      audio.play();
    }, 500);
    setTimeout(() => {
      const audio = audios[1];
      audio.volume = audioVolume;
      audio.play();
    }, 1460);
    setTimeout(() => {
      const audio = audios[2];
      audio.volume = audioVolume;
      audio.play();
    }, 2420);
    setTimeout(() => {
      // noise.pause();
      audioRef.current.pause();
    }, 3720);
  };

  const checkAnswer = async () => {
    setOkButton(false);
    recordCurrTimestamp();
    const userAnswer = input1 + input2 + input3;
    setAnswers((arr) => [...arr, userAnswer]);
    if (realAnswer !== userAnswer) {
      setStillCorrect(false);
      setAudioVolume(goEasier(step));
      console.log("WRONG, increasing audio volume to " + audioVolume);
    } else {
      setAudioVolume(goHarder(step));
      console.log("RIGHT, decreasing audio volume to " + audioVolume);
    }
    if (step !== numOfTrial) {
      const currTimestamp = timestamps[timestamps.length - 1];
      const reactTime =
        currTimestamp[currTimestamp.length - 2] -
        currTimestamp[currTimestamp.length - 3];
      const realTime =
        currTimestamp[currTimestamp.length - 1] -
        currTimestamp[currTimestamp.length - 2];
      const usedTime = reactTime + realTime;
      const gaps = [1000, 1500, 2000];
      const randomGap = Math.floor(Math.random() * 3);
      const realGap = gaps[randomGap];
      let moreTime = 0;
      if (usedTime <= 3400) {
        moreTime = 3400 + realGap - usedTime;
      } else {
        moreTime = realGap;
      }
      setTimeout(async () => {
        await setRealAnswer("");
        await setInput1("");
        await setInput2("");
        await setInput3("");
        await setFocus(0);
        await setInitStart(true);
        await setOkButton(true);
        setStep(step + 1);
      }, moreTime);
    }
  };

  const renderInputs = () => {
    return (
      <div>
        <Input
          value={input1}
          required={true}
          inputProps={{ maxLength: 1 }}
          inputRef={(input) => (input1Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, marginRight: 10, paddingLeft: 13 }}
        />
        <Input
          value={input2}
          required={true}
          inputProps={{ maxLength: 1 }}
          inputRef={(input) => (input2Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, paddingLeft: 13 }}
        />
        <Input
          value={input3}
          required={true}
          inputProps={{ maxLength: 1 }}
          onKeyDown={handleKeyEnter}
          inputRef={(input) => (input3Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, marginLeft: 10, paddingLeft: 13 }}
        />
      </div>
    );
  };

  const renderKeys = () => {
    return (
      <div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("1")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            1
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("2")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            2
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("3")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            3
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("4")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            4
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("5")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            5
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("6")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            6
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("7")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            7
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("8")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            8
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("9")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            9
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("0")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            0
          </Fab>
          <Fab
            color="default"
            onClick={checkAnswer}
            disabled={renderOKButton()}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            OK
          </Fab>
        </div>
      </div>
    );
  };

  const renderOKButton = () => {
    if (!!input1 && !!input2 && !!input3 && okButton) return false;
    return true;
  };

  const handleKeyEnter = (e) => {
    if (!!input1 && !!input2 && !!input3 && okButton && e.keyCode === 13) {
      checkAnswer();
    }
  };

  const renderProgress = () => (
    <>
      <P1 className="font-weight-lighter">
        Block {numOfCurrBlock} of {numOfTotalBlocks}
      </P1>
      <P1 className="font-weight-lighter">
        Step {step} of {numOfTrial}
      </P1>
    </>
  );

  const renderDIN = () => {
    return (
      <>
        {renderProgress()}
        <br />
        {renderInputs()}
        <br />
        {renderKeys()}
      </>
    );
  };

  const renderRecovery = () => {
    return (
      <>
        {renderProgress()}
        <br />
        <P2>Did you hear the mispronounced word?</P2>
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => handleRecoveryClick("no")}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => handleRecoveryClick("yes")}
          >
            Yes
          </Button>
        </div>
      </>
    );
  };

  const renderCountDown = () => {
    switch (loadStage) {
      case LoadStage.READY:
        return <CountDown />;
      case LoadStage.DIN:
        return renderDIN();
      case LoadStage.RECOVERY_PLAY:
        return (
          <>
            {renderProgress()}
            <br />
            <img src="https://j.gifs.com/mlVL8P.gif" />
          </>
        );
      case LoadStage.REPEAT:
        return (
          <>
            {renderProgress()}
            <br />
            <P2>Please repeat what you heard.</P2>

            <div>
              {/* <AudioRecorder
                onRecordingComplete={(blob) => {
                  // Handle the completed recording blob here
                  console.log(blob);
                  // Set state or perform actions as needed
                }}
                recorderControls={recorder}
              /> */}
              {mediaRecorder && (
                <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder}
                  width={200}
                  height={75}
                />
              )}
            </div>
          </>
        );
      case LoadStage.RECOVERY_RESPONSE:
        return renderRecovery();
      case LoadStage.DONE:
        return <P1>You have finished this part, please click on NEXT</P1>;
      default:
        return null;
    }
  };

  return (
    <Container style={{ textAlign: "center" }}>
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "10%",
        }}
      >
        {renderCountDown()}
      </div>
    </Container>
  );
};

export default RecoveryStimulus;
