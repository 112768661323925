import React, { useEffect, useState } from "react";
import styles from "./ContactSection.module.css";
import customer from "../assets/images/customer-service.png";
import participate from "../assets/images/participate.svg";

function ContactSection() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Fetch emails from the API for later use
  useEffect(() => {
    fetch("/api/gui/emails")
      .then((response) => response.json())
      .then((data) => console.log(data));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Sending...");

    try {
      const response = await fetch("/api/gui/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage(
          "Thank you for subscribing! Please check your email for confirmation."
        );
        setEmail("");
      } else {
        setMessage("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.imageColumn}>
            <img
              loading="lazy"
              src={customer}
              className={styles.mainImage}
              alt="Contact section illustration"
            />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formWrapper}>
              <img
                loading="lazy"
                src={participate}
                className={styles.logo}
                alt="Company logo"
              />
              <form className={styles.emailForm} onSubmit={handleSubmit}>
                <label
                  htmlFor="emailInput"
                  className={styles["visually-hidden"]}
                >
                  Enter your email
                </label>
                <input
                  type="email"
                  id="emailInput"
                  className={styles.emailInput}
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className={styles.submitButton}>
                  Join
                </button>
              </form>
              {message && <p className={styles.message}>{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
