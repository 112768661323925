import React, { useState } from "react";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
} from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const CheckBox = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(async () => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/checkbox/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(() => {
    handleUpdateAssign();
    if (answer.length !== 0) {
      readyToContinue(id);
    }
  }, [answer]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "checkbox",
      question,
      options,
      answer,
    };
    updateAssign(assign);
  };

  const handleClick = (choice) => {
    if (answer.includes(choice)) {
      const newAnswer = [];
      answer.map((ans) => {
        if (ans !== choice) newAnswer.push(ans);
      });
      setAnswer(newAnswer);
    } else {
      const newAnswer = [...answer];
      newAnswer.push(choice);
      setAnswer(newAnswer);
    }
  };

  return (
    <Container>
      <br />
      <div className="row">
        <P2
          style={{ marginRight: "20px", marginLeft: "15px", fontWeight: "300" }}
        >
          {question}
        </P2>
      </div>
      <br />
      <FormGroup>
        {options.map((opt, i) => (
          <div key={i}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={answer.includes(opt)} />
              }
              onChange={(e) => handleClick(opt)}
              label={
                <P3 style={{ marginLeft: "10px", marginTop: "10px" }}>{opt}</P3>
              }
            />
          </div>
        ))}
      </FormGroup>
      <br />
    </Container>
  );
};

export default CheckBox;
