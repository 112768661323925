import React from "react";
import styles from "./testTile.module.css";

function TestTile({ name, type, onAppClick, updateLayer }) {
  const handleClick = () => {
    if (type === "app") {
      onAppClick();
    } else {
      updateLayer();
    }
  };

  return (
    <button className={styles.tile} onClick={handleClick}>
      <div className={styles.tileChild}>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.admin}>{type}</div>
    </button>
  );
}

export default TestTile;
