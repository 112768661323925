import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { P1, P2, P3 } from 'assets/fonts';
import { Container, Button, TextField } from '@material-ui/core';

const VolumeControl = (props) => {
  const { id } = props;
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/volumecontrol/" + id );
        setUrl(doc.data.url);
        setTitle(doc.data.title);
      })();
    }
  }, [id]);

  const handleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
  };

  const handleBlur = async () => {
    await axios.put("/api/gui/volumecontrol/" + id, { title });
  }

  const handleUploadAudio = async () => {
    const doc = await axios.get("/api/gui/volumecontrol");
    const uploadConfigs = doc.data;
    await setUrl(uploadConfigs.key);
    await axios
      .put(uploadConfigs.url, file, {
        headers: {
          "Content-type": 'audio/wav',
        },
      })
      .then(() => window.alert('Audio upload successfully!'))
      .catch((err) => window.alert('Audio upload failed:', err));
    await axios.put("/api/gui/volumecontrol/" + id, { url: uploadConfigs.key });
  }

  return (
    <Container id="icf">
      <P1>Volume Control</P1>
      <div className="row"><P2 style={{fontWeight: "300"}}>Text: </P2><TextField value={title} onChange={handleChange} onBlur={handleBlur} style={{width: "80%"}}/></div>
      <div className="row">
        <P2 style={{fontWeight: "300"}}>Audio: {url}</P2>
        <input
          type="file"
          accept="audio/wav"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <Button size="small" color="primary" variant="outlined" onClick={handleUploadAudio}>Upload</Button>
      </div>
      <P3>(Please wait for "Audio upload successfully!" message)</P3>
      <br />
    </Container>
  )
};

export default VolumeControl;