import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Tooltip } from '@material-ui/core';
import { P1, P2, P3 } from 'assets/fonts';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: "90%",
    marginLeft: "5%",
    marginBottom: "5%"
  },
});

export default function DenseTable(props) {
  const classes = useStyles();
  const [withAids, setWithAids] = useState(false);

  const getAnswerText = (answer) => {
    switch(answer){
      case "A":
        return "A: Always(99%)";
      case "B":
        return "B: Almost Always (87%)";
      case "C":
        return "C: Generally (75%)";
      case "D":
        return "D: Half-the-time (50%)";
      case "E":
        return "E: Occasionally (25%)";
      case "F":
        return "F: Seldom (12%)";
      case "G":
        return "G: Never (1%)";
      default:
        return null;
    }
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center" colSpan={3}><Checkbox color="primary" checked={!withAids} onChange={() => setWithAids(!withAids)} /><P3><b>Without</b><br/>Hearing Aids</P3></TableCell>
            <TableCell align="center" colSpan={3}><Checkbox color="primary" checked={withAids} onChange={() => setWithAids(!withAids)} /><P3><b>With</b><br/>Hearing Aids</P3></TableCell>
            <TableCell align="center"><P3>Answer</P3></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(props.questions).map((q, key) => {
                return <TableRow key={key}>
                <TableCell align="left"><P2>{key+1}.</P2></TableCell>
                <TableCell align="left"><P2>{q}</P2></TableCell>
                <TableCell align="left" colSpan={6}>
                  <div className="row">
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Always(99%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "A")}><P3>A</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Almost Always (87%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "B")}><P3>B</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Generally (75%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "C")}><P3>C</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Half-the-time (50%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "D")}><P3>D</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Occasionally (25%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "E")}><P3>E</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Seldom (12%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "F")}><P3>F</P3></button></Tooltip>
                    <Tooltip disableFocusListener disableTouchListener title={<P3>Never (1%)</P3>}><button style={{border: "none", backgroundColor: "white"}} onClick={() => props.onChoiceClick(q, withAids ? "with" : "without", "G")}><P3>G</P3></button></Tooltip>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <P3>{withAids ? getAnswerText(props.questions[q].with) : getAnswerText(props.questions[q].without)}</P3>
                </TableCell>
              </TableRow>
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
