import { FontActionTypes } from "./font-types";

export const increaseFont = () => ({
  type: FontActionTypes.INCREASE_FONT,
  payload: null,
});

export const decreaseFont = () => ({
  type: FontActionTypes.DECREASE_FONT,
  payload: null,
});