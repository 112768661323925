import {P1, P2, P3} from 'assets/fonts';
import React from "react";
import { Container } from "@material-ui/core";

const OHHIS = () => {

  return (
    <Container>
      <P1>OHHIS</P1><br />
    </Container>
  )
}

export default OHHIS;