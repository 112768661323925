import React from "react";
import { Button, LinearProgress, Container, Checkbox } from "@material-ui/core";
import Markdown from "literacy/assets/fonts/markdown";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: this.props.rows,
      index: this.props.index ?? 0,
      curr_answer: {},
      assign: this.props.assignment ?? [],
      score: this.props.score ?? 0,
      times: {},
    };
  }

  componentDidMount = () => {
    const times = {};
    this.state.questions.map((question) => {
      times[JSON.stringify(question)] = 1;
    });
    this.setState({ times });
  };

  handleChange = (value, num) => {
    this.setState((state) => {
      const curr_answer = state.curr_answer;
      curr_answer[num] = value;
      return { ...state, curr_answer };
    });
  };

  calculateScore = () => {
    const { index, questions, times } = this.state;
    const currentTimes = times[JSON.stringify(questions[index])];
    switch (currentTimes) {
      case 1:
        return 1;
      case 2:
        return 0.7;
      case 3:
        return 0.5;
    }
  };

  handleNext = () => {
    const { curr_answer, index, questions, assign, score, times } = this.state;
    let addScore = 0;
    // extract user's answer from curr_answer object
    const studentAnswer = [];
    for (let an in curr_answer) {
      if (curr_answer[an]) {
        studentAnswer.push(an);
      }
    }
    if (
      JSON.stringify(studentAnswer.sort()) ===
      JSON.stringify(questions[index].answers.sort())
    ) {
      addScore += this.calculateScore();
    } else if (
      !!questions[index].hint &&
      times[JSON.stringify(questions[index])] < 3
    ) {
      times[JSON.stringify(questions[index])] =
        times[JSON.stringify(questions[index])] + 1;
      questions.push(questions[index]);
      this.setState({ questions, times });
    }
    assign.push({
      question: questions[index].question,
      choices: questions[index].choices,
      hint: questions[index].hint,
      realAnswer: questions[index].answers,
      studentAnswer,
    });
    this.setState({
      score: score + addScore,
      index: index + 1,
      assign,
      curr_answer: {},
    });
  };

  render() {
    const { questions, index, assign, score, curr_answer, times } = this.state;
    const progress = Math.floor((index / questions.length) * 100);
    return (
      <div>
        <Container style={{ marginTop: "5%" }}>
          <Button
            variant="outlined"
            style={{
              marginRight: 20,
              color: "white",
              backgroundColor: "#107896",
            }}
            onClick={() => {
              this.props.handleSaveAssignment(index, questions, assign, score);
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{ color: "white", backgroundColor: "#107896" }}
            onClick={() =>
              (window.location = this.props.backURL ?? "/literacy")
            }
          >
            Quit
          </Button>
        </Container>
        <Container style={{ marginTop: "10%" }}>
          {index !== questions.length ? (
            <div>
              <Markdown className="font-weight-light">
                {questions[index].question}
              </Markdown>
              {questions[index].choices.map((choice) => (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={curr_answer[choice] ?? false}
                    color="primary"
                    onChange={(e) =>
                      this.handleChange(e.target.checked, choice)
                    }
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <P2 style={{ marginTop: "5px" }}>{choice}</P2>
                </div>
              ))}
              {times[JSON.stringify(questions[index])] >= 3 && (
                <P3>Hint: {questions[index].hint}</P3>
              )}
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={this.handleNext}
              >
                Next
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={() => this.props.handleNext(score, assign)}
              >
                Go "Fill in Blank" section
              </Button>
              <br />
            </div>
          )}
          <br />
          <LinearProgress variant="determinate" value={progress} />
        </Container>
      </div>
    );
  }
}
