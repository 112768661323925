import React from "react";
import {
    Container,
    Button,
    MenuItem,
    FormControl,
    Select,
    Slider
} from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';


class Questionnaire extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            q1: null,
            q2: null,
            q3: null,
            q4: null,
            q5: null,
            q6: null,
            q7: null,
            q8: null,
            q9: {
                "They are more happy, confident, assured, and cheerful than others": null,
                "They are more sad, shy, and miserable than others": null,
                "They are more attractive, desirable, and eligible than others": null,
                "They are more unattractive, undesirable, and unsuitable than others": null,
                "They are more easy-going, approachable, likeable, and friendly than others": null,
                "They are more awkward, unlikeable, unapproachable, and unfriendly than others": null,
                "They are more successful, motivated, accomplished, and more likely to succeed than others": null
            },
            q10: {
                'Sad/Happy': 4,
                'Unconfident/Confident': 4,
                'Incompetent/Competent': 4,
                'Shy/Assured': 4,
                'Miserable/Cheerful': 4,
                'Unattractive/Attractive': 4,
                'Undesirable/Desirable': 4,
                'Ugly/Gorgeous': 4,
                'Stupid/Intelligent': 4,
                'Unsuitable/Eligible': 4,
                'Awkward/Easy-going': 4,
                'Untrustworthy/Trustworthy': 4,
                'Unapproachable/Approachable': 4,
                'Unfriendly/Friendly': 4,
                'Non-Achiever/Achiever': 4,
                'Ordinary/Accomplished': 4,
                'Unmotivated/Motivated': 4,
            }
        }
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    checkValidation = () => {
        const { q1, q2, q3, q4, q5, q6, q7, q8, q9} = this.state;
        if (!q1 || !q2 || !q3 || !q4 || !q5 || !q6 || !q7 || !q8) {
            return false;
        }
        for (let key in q9) {
            if (!q9[key]) return false;
        }
        return true;
    }

    renderDropdownQuestions = (question) => {
        const { q9 } = this.state;
        return <Container >
            <div className="row">
                <P2 style={{marginRight: '15px', marginTop: '10px'}}>{question}</P2>
                <FormControl>
                    <Select value={q9[question]} onChange={(e) => {
                        q9[question] = e.target.value;
                        this.setState({ q9 });
                    }}>
                        <MenuItem value={"strongly agree"}>strongly agree</MenuItem>
                        <MenuItem value={"moderately agree"}>moderately agree</MenuItem>
                        <MenuItem value={"slightly agree"}>slightly agree</MenuItem>
                        <MenuItem value={"neither agree nor disagree"}>neither agree nor disagree</MenuItem>
                        <MenuItem value={"slightly disagree"}>slightly disagree</MenuItem>
                        <MenuItem value={"moderately disagree"}>moderately disagree</MenuItem>
                        <MenuItem value={"strongly disagree"}>strongly disagree</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </Container>
    }

    

    renderScaleQuestions = (question) => {
        const handleValueChange = (value, question) => {
            const { q10 } = this.state;
            q10[question] = value;
            this.setState({ q10 });
        }

        const left = question.split("/")[0];
        const right = question.split("/")[1];

        return <Container >
            <div className="row">
                <div className="col-4">
                    <P2>{left}</P2>
                </div>
                <div className="col-4">
                    <P2>{right}</P2>
                </div>
            </div>
            <Slider
                defaultValue={4}
                onChange={(e, value) => handleValueChange(value, question)}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={7}
                style={{width: "40%"}}
            />
            <br /><br />
        </Container>
    }

    render(){
        const { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10 } = this.state;
        return (
            <Container style={{ margin: "5%" }}>
                 <div id="q1">
                    <P2>1. How often have you encountered or do you interact with a person with a facial disfigurement (marks, rashes, scars, asymmetry, paralysis, etc.)?</P2>
                    <FormControl>
                        <Select value={q1} style={{ width: 150 }} onChange={(e) => this.setState({ q1: e.target.value })}>
                            <MenuItem value={"Often"}>Often</MenuItem>
                            <MenuItem value={"Quite often"}>Quite often</MenuItem>
                            <MenuItem value={"Not that often"}>Not that often</MenuItem>
                            <MenuItem value={"Rarely"}>Rarely</MenuItem>
                            <MenuItem value={"Very Rarely"}>Very Rarely</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q2">
                    <P2>2. Do you have a disability?</P2>
                    <FormControl>
                        <Select value={q2} style={{ width: 150 }} onChange={(e) => this.setState({ q2: e.target.value })}>
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q3">
                    <P2>3. Do you have a facial disfigurement?</P2>
                    <FormControl>
                        <Select value={q3} style={{ width: 150 }} onChange={(e) => this.setState({ q3: e.target.value })}>
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q4">
                    <P2>4. Do you have a close friend of family member with a disability?</P2>
                    <FormControl>
                        <Select value={q4} style={{ width: 150 }} onChange={(e) => this.setState({ q4: e.target.value })}>
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q5">
                    <P2>5. Do you have a close friend of family member with a facial disfigurement?</P2>
                    <FormControl>
                        <Select value={q5} style={{ width: 150 }} onChange={(e) => this.setState({ q5: e.target.value })}>
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q6">
                    <P2>6. Which statement best describes you?</P2>
                    <FormControl>
                        <Select value={q6} onChange={(e) => this.setState({ q6: e.target.value })}>
                            <MenuItem value={"I strongly prefer people without facial disfigurements to people with facial disfigurements"}>I strongly prefer people without facial disfigurements to people with facial disfigurements</MenuItem>
                            <MenuItem value={"I moderately prefer people without facial disfigurements to people with facial disfigurements"}>I moderately prefer people without facial disfigurements to people with facial disfigurements</MenuItem>
                            <MenuItem value={"I slightly prefer people without facial disfigurements to people with facial disfigurements"}>I slightly prefer people without facial disfigurements to people with facial disfigurements</MenuItem>
                            <MenuItem value={"I like people without facial disfigurements and people with facial disfigurements equally"}>I like people without facial disfigurements and people with facial disfigurements equally</MenuItem>
                            <MenuItem value={"I slightly prefer people with facial disfigurement to people without facial disfigurement "}>I slightly prefer people with facial disfigurement to people without facial disfigurement </MenuItem>
                            <MenuItem value={"I moderately prefer people with facial disfigurements to people without facial disfigurements"}>I moderately prefer people with facial disfigurements to people without facial disfigurements</MenuItem>
                            <MenuItem value={"I strongly prefer people with facial disfigurement to people without facial disfigurement "}>I strongly prefer people with facial disfigurement to people without facial disfigurement </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q7">
                    <P2>7. How warm or cold do you feel towards people with facial disfigurements?</P2>
                    <FormControl>
                        <Select value={q7} onChange={(e) => this.setState({ q7: e.target.value })}>
                            <MenuItem value={"Extremely warm"}>Extremely warm</MenuItem>
                            <MenuItem value={"Very warm"}>Very warm</MenuItem>
                            <MenuItem value={"Moderately warm"}>Moderately warm</MenuItem>
                            <MenuItem value={"Somewhat warm"}>Somewhat warm</MenuItem>
                            <MenuItem value={"Slightly warm"}>Slightly warm</MenuItem>
                            <MenuItem value={"Neither warm nor cold"}>Neither warm nor cold</MenuItem>
                            <MenuItem value={"Slightly cold"}>Slightly cold</MenuItem>
                            <MenuItem value={"Somewhat cold"}>Somewhat cold</MenuItem>
                            <MenuItem value={"Moderately cold"}>Moderately cold</MenuItem>
                            <MenuItem value={"Very cold"}>Very cold</MenuItem>
                            <MenuItem value={"Extremely cold"}>Extremely cold</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q8">
                    <P2>8. How warm or cold do you feel towards people without facial disfigurements?</P2>
                    <FormControl>
                        <Select value={q8} onChange={(e) => this.setState({ q8: e.target.value })}>
                            <MenuItem value={"Extremely warm"}>Extremely warm</MenuItem>
                            <MenuItem value={"Very warm"}>Very warm</MenuItem>
                            <MenuItem value={"Moderately warm"}>Moderately warm</MenuItem>
                            <MenuItem value={"Somewhat warm"}>Somewhat warm</MenuItem>
                            <MenuItem value={"Slightly warm"}>Slightly warm</MenuItem>
                            <MenuItem value={"Neither warm nor cold"}>Neither warm nor cold</MenuItem>
                            <MenuItem value={"Slightly cold"}>Slightly cold</MenuItem>
                            <MenuItem value={"Somewhat cold"}>Somewhat cold</MenuItem>
                            <MenuItem value={"Moderately cold"}>Moderately cold</MenuItem>
                            <MenuItem value={"Very cold"}>Very cold</MenuItem>
                            <MenuItem value={"Extremely cold"}>Extremely cold</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div id="q9">
                    <P2>9. Below is a list of statements about people with facial disfigurements. Please state how strongly you agree or disagree with each of the following statements. (for all answers: strongly agree, moderately agree, slightly agree, neither agree nor disagree, strongly disagree, moderately disagree, slightly disagree)</P2>
                    {
                        Object.keys(q9).map(question => this.renderDropdownQuestions(question))
                    }
                </div>
                <br />
                <div id="q10">
                    <P2>10. Please indicate how you would describe people with facial disfigurements on the following scales.</P2>
                    {
                        Object.keys(q10).map(question => this.renderScaleQuestions(question))
                    }
                </div>
                <br />
                <div id="button">
                    {
                        this.checkValidation() ?
                        <Button color="primary" variant="contained" onClick={() => this.props.handleClick(q1, q2, q3, q4, q5, q6, q7, q8, q9, q10)} >Next</Button>
                        :
                        <div>
                            <Button variant="contained" disabled style={{color: "white", backgroundColor: "#107896"}}>Next</Button>
                            <P3>You cannot progress to the next step because you have not completed the questions.</P3>
                        </div>
                    }
                </div>
                
            </Container>
        )
    }
}

export default Questionnaire;