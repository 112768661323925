import React, {useState, useEffect, useRef} from "react";
import useMouse from '@react-hook/mouse-position'
import { P1, P2, P3 } from "assets/fonts";

const withMouseTrack = (WrappedComponent) => {
    return (props) => {
        const mouseRef = useRef(null)
        const mouse = useMouse(mouseRef, {
            enterDelay: 0,
            leaveDelay: 0,
        });
        const [mouseTrack, setMouseTrack] = useState([]);

        useEffect(() => {
            setMouseTrack(data => [...data, [
                mouse?.x,
                mouse?.y,
                new Date().getTime(),
                null
            ]]);
        }, [mouse.x, mouse.y]);

        const saveMouseEvent = (timestamp, event) => {
            setMouseTrack(data => [...data, [
                mouse?.x,
                mouse?.y,
                timestamp,
                event
            ]]);
        };

        return (
            <div
                ref={mouseRef}
                style={{borderColor: "black", borderStyle: "dashed", width: window.innerWidth, height: window.innerHeight}}
            >
                <div>
                    <P2>Mouse Position: [X, Y]: [{mouse.x ?? "null"}, {mouse.y ?? "null"}] </P2>
                    <P3>Please make sure your screen captures the whole dashed box</P3>
                </div>
                <WrappedComponent {...props} mouseTrack={mouseTrack} onMouseEvent={saveMouseEvent}/>
            </div>
        )
    }
};

export default withMouseTrack;