import React from "react";
import styles from "./research.module.css";
import Ellipse2 from "../assets/images/Ellipse 2.svg";
import Ellipse3 from "../assets/images/Ellipse 3.svg";
import { Header } from "../main-components/header";
import { FooterCom } from "../main-components/footer";
import ContactSection from "../main-components/contact-us";
import OurIdeaSection from "../main-components/our-idea";
import PublicationSection from "../main-components/publication-section";

const Research = () => {
  return (
    <div className={styles.main}>
      <div className={styles.background}>
        <div className={styles.overlap}>
          <img className={styles.ellipse} alt="Ellipse" src={Ellipse3} />
        </div>
        <div className={styles.overlap2}>
          <img className={styles.ellipse2} alt="Ellipse" src={Ellipse2} />
          <div className={styles.headerInstance}>
            <Header
              LOGOBcalLogoRemovebg={require("../assets/images/bcal-logo-removebg-preview-1.png")}
            />
          </div>
        </div>
        <div className={styles.contentArea}>
          <div className={styles.contactSectionWrapper}>
            <ContactSection />
          </div>
          <div className={styles.ouridea}>
            <OurIdeaSection />
          </div>
          <div className={styles.publicationSection}>
            <PublicationSection />
          </div>
        </div>
      </div>
      <div className={styles.footerInstance}>
        <FooterCom
          img={require("../assets/images/screenshot-20240423-at-9021.png")}
          screenshot={require("../assets/images/screenshot-20240423-at-9031.png")}
        />
      </div>
    </div>
  );
};

export default Research;
