import React from 'react';
import { P1 } from 'assets/fonts';
import { Button } from "@material-ui/core";

export default (props) => <div style={{marginTop: "10%", marginLeft: "2%", marginRight: "2%"}}>
    <i><P1>This study will explore whether people express more negative attitudes towards people with facial disfigurements.
    It will also look at whether people subconsciously associate negative words with facial disfigurements.
    Research has demonstrated that facial disfigurements can sometimes lead to negative perceptions of people with facial differences.
    We are interested in whether the conscious and unconscious attitudes that people have towards facial difference are the same.</P1></i>
    <Button size="large" style={{width: "20%", marginLeft: "5%%", marginTop: "10%", color: "white", backgroundColor: "#107896"}} variant="contained" onClick={props.handleClick} >Back</Button>
  </div>
  
  
  
  
