import React from "react";
import { Button, Container } from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';


export default (props) => {
  const onSubmitClick = () => {
    props.handleClick();
  }

  return (
    <Container>
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "20%",
        }}
      >
        <P1>
          Thank you for participating in this test!
        </P1>
        <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={onSubmitClick} >Submit</Button>
      </div>
    </Container>
  );
};
