import React from "react";
import axios from "axios";
import Welcome from "hearing/components/crm2/welcome";
import Environment from "hearing/components/crm2/environment";
import Adjustment from "hearing/components/crm2/adjustment";
import Test from "hearing/components/crm2/test";
import MidPage from "hearing/components/crm2/mid-page";
import Source1 from "hearing/components/crm2/source1";
import Source2 from "hearing/components/crm2/source2";
import End from "hearing/components/crm2/end";
import FontComponent from 'assets/font-ui';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participantId: null,
      studyId: null,
      studyId: null,
      process: "welcome",
      volume: 0.1,
      output: null,
      withAids: null,
      SNR: [],
      timer1: [],
      timer2: [],
      dbs1: [],
      dbs2: [],
      answers1: [],
      answers2: [],
      correct1: [],
      correct2: [],
      reversals: null
    };
  }

  shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/crm/2' });
    try {
      const doc = await axios.get("/api/hearing/crm2/reversals");
      await this.setState({reversals: doc.data.reversals});
    } catch {
      this.setState({reversals: 25});
    }
    const order = ["Source1", "Source2"];
    this.shuffle(order);
    this.setState({ order });
  }

  handleChangeId = (participantId, studyId) => {
    this.setState({participantId, studyId, process: "environment"});
  }

  handleEnvironmentClick = () => {
    this.setState({process: "adjust"});
  }

  handleAdjustClick = (volume, output, withAids) => {
    this.setState({ volume, output, withAids, process: "test"});
  }

  handleTest = () => {
    this.setState({ process: "CRM1" });
  }

  handleCRM1Click = (SNR, timer, dbs, questions, answers, correct) => {
    const newSNR = this.state.SNR;
    newSNR.push(SNR);
    this.setState({ SNR: newSNR, timer1: timer, dbs1: dbs, questions1: questions, answers1: answers, correct1: correct, process: "midpage1" })
  }

  handleMidPage1Click = () => {
    this.setState({ process: "CRM2" })
  }
  
  handleCRM2Click = (SNR, timer, dbs, questions, answers, correct) => {
    const newSNR = this.state.SNR;
    newSNR.push(SNR);
    this.setState({ SNR: newSNR, timer2: timer, dbs2: dbs, questions2: questions, answers2: answers, correct2: correct, process: "end"});
  }

  handleSubmit = async () => {
    const { participantId, studyId, reversals, SNR, output, withAids, timer1, timer2, dbs1, dbs2, questions1, questions2, answers1, answers2, correct1, correct2 } = this.state;
    const trials1 = timer1.length;
    const trials2 = timer2.length;
    await axios.post("/api/hearing/crm2/user/data", { participantId, studyId, output, withAids, SNR, reversals, timer1, timer2, dbs1, dbs2, questions1, questions2, answers1, answers2, correct1, correct2, trials1, trials2 });
    window.location = "/"
  }

  returnTrainingMode = (num, func) => {
    const { order, volume, reversals } = this.state;
    if (order[num] === "Source1") {
      return <Source1 reversals={reversals} volume={volume} handleClick={func} block={num} />;
    } else if (order[num] === "Source2") {
      return <Source2 reversals={reversals} volume={volume} handleClick={func} block={num} />;
    }
  }

  renderProcess = () => {
    const { process, volume, ID } = this.state;
    switch (process) {
      case "welcome":
        return <Welcome onChangeId={this.handleChangeId} />;
      case "environment":
        return <Environment onEnvironmentClick={this.handleEnvironmentClick} />;
      case "adjust":
        return <Adjustment notShowPlay={true} handleClick={this.handleAdjustClick} />
      case "test":
        return <Test volume={volume} handleClick={this.handleTest} />
      case "CRM1":
        return this.returnTrainingMode(0, this.handleCRM1Click);
      case "midpage1":
        return <MidPage handleClick={this.handleMidPage1Click} section={"two"} />;
      case "CRM2":
        return this.returnTrainingMode(1, this.handleCRM2Click);
      case "end":
        return <End ID={ID} handleClick={this.handleSubmit}/>
      default:
        return null;
    }
  };

  render() {
    const bottomFontLists = ["test", "CRM1", "CRM2"];
    const { process } = this.state;
    return (
      <div style={{
        backgroundColor: "rgb(240,240,240)",
        fontFamily: "raven",
        height: window.innerHeight,
        overflowY: "scroll",
        paddingRight: "5%"
      }}>
        <FontComponent position={bottomFontLists.includes(process) ? "bottomRight" : "topRight"}/>
        {this.renderProcess()}
      </div>
    )}
}

export default Main;
