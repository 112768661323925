import React from "react";
import VolumeAdjustment from "../components/volume-adjust/main";
import SpeechInNoise from "../components/speech-in-noise/main";
import Environment from "../components/helpers/environment";
import Final from "./final";
import { Container } from "@material-ui/core";
import axios from "axios";

class Version2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      process: "environment",
      volume: 10,
      v: 2,
      id: this.props.match.params.id,
    };
  }

  componentDidMount = async () => {};

  handleAudioAdjust = async () => {
    const { volume, id } = this.state;
    await axios.post("/api/hearingtest/data/volume", { volume, id });
    this.setState({ process: "testing" });
  };

  renderProcess = () => {
    const { process } = this.state;
    switch (process) {
      case "environment":
        return (
          <Environment
            handleNext={() => this.setState({ process: "adjustment" })}
          />
        );
      case "adjustment":
        return (
          <VolumeAdjustment
            handleVolume={(volume) => this.setState({ volume })}
            handleNext={this.handleAudioAdjust}
          />
        );
      case "testing":
        return (
          <SpeechInNoise
            id={this.state.id}
            volume={this.state.volume}
            handleFinish={() => this.setState({ process: "final" })}
          />
        );
      case "final":
        return <Final />;
      default:
        return <process />;
    }
  };

  render() {
    return (
      <Container>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <h5 className="text-left font-weight-bold">
            BE PART OF THE CONVERSATION
          </h5>
          <h5 className="font-weight-bold text-left text-muted">
            GET IN TOUCH WITH AN AUDIOLOGIST
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {this.renderProcess()}
        </div>
        <div>
          <img
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "50%",
            }}
            src={require("../assets/pictures/poster2.png")}
            alt=""
          />
        </div>
      </Container>
    );
  }
}

export default Version2;
