import React from "react";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";

export default () => {
  return (
    <div>
      <P1>Welcome to Literacy Training</P1>
      <P2 className="text-danger">
        You have not signed-in yet. Please sign in by clicking on the "Goal
        Based Learner" or "Skill Based Learner" button above.
      </P2>
    </div>
  );
};
