import React from "react";
import { TextField, Button, Checkbox, Grid, Slider } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';
import FontComponent from 'assets/font-ui';
import End from "hearing-demo/components/end";


class SSLShort extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ParticipantID: null,
      StudyID: null,
      end: false,
      age: null,
      date: null,
      notes: null,
      leftYears: 0,
      leftMonths: 0,
      leftWeeks: 0,
      rightYears: 0,
      rightMonths: 0,
      rightWeeks: 0,
      checkboxQuestion: null,
      scaleQuestion: {
        "You are talking with one other person and there is a TV on in the same room. Without turning the TV down, can you follow what the person you're talking to says?": null,
        "You are listening to someone talking to you, while at the same time trying to follow the news on TV. Can you follow what both people are saying?": null,
        "You are in conversation with one person in a room where there are many other people talking. Can you follow what the person you are talking to is saying?": null,
        "You are in a group of about five people in a busy restaurant. You can see everyone else in the group. Can you follow the conversation?": null,
        "You are with a group and the conversation switches from one person to another. Can you easily follow the conversation without missing the start of what each new speaker is saying?": null
      },
      errorText: []
    }
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire/ssq/short' });
  };

  handleSubmit = async () => {
    this.setState({end: true});
  }

  render(){
    const { end, notes, errorText, ParticipantID, StudyID, age, date, checkboxQuestion, scaleQuestion, leftYears, leftMonths, leftWeeks, rightMonths, rightWeeks, rightYears } = this.state;
    const marks = [
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' }
    ];
    const values = [null];
    for (let i=0; i<10; i+=0.1) {
      values.push(Number(i.toFixed(1)));
    }

    return(
      end ?
      <End />
      :
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll", paddingRight: "5%"}}>
        <div style={{margin: "3%"}}>
          <div id="instruction" style={{ border: "solid", borderColor: "#add8e6", backgroundColor: "#EEEEEE"}}>
            <center id="header">
              <P1>SSQ12 Instructions</P1>
            </center>
            <div>
              <P2 style={{margin: "2%"}}><b>The following questions inquire about aspects of your ability and 
                experience hearing and listening in different situations.</b>
              </P2>
              <P2 style={{margin: "2%"}}>For each question, put a mark{" "}
                <b>anywhere</b> on the scale shown against each question that runs from 0.0 to 10.0. 
                Putting a mark at <b>10</b> means that you would be <b>perfectly</b>{" "}
                able to do or experience what is described in the question. 
                Putting a mark at 0 means you would be quite <b>unable</b>{" "}
                to do or experience what is described.
              </P2>
              <P2 style={{margin: "2%"}}>
                As an example, question 1 asks about having a conversation with someone while the TV 
                is on at the same time. If you are well able to do this then put a mark up toward the 
                right-hand end of the scale. If you could follow about half the conversation 
                in this situation put the mark around the mid-point, and so on.
              </P2>
              <P2 style={{margin: "2%"}}>
                We expect that all the questions are relevant to your everyday experience, 
                but if a question describes a situation that does not apply to you, select the "not applicable" box.
                Please also write a note next to that question explaining why it does not apply in your case
              </P2>
            </div>
          </div>
          <br/><br/><br/><br/><br/>
          <div id="demo-info" style={{ display: "flex", flexDirection: "column", marginTop: "3%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly"  }}>
              <div className="row" style={{border: "solid", width: "23%", padding: "1%", margin: "1%"}}><P2>Participant ID: </P2><TextField id="ID" value={ParticipantID} onChange={e => this.setState({ParticipantID: e.target.value})} style={{width: "40%", marginBottom: "10px", marginLeft: "5px"}}/></div>
              <div className="row" style={{border: "solid", width: "23%", padding: "1%", margin: "1%"}}><P2>Study ID: </P2><TextField id="ID" value={StudyID} onChange={e => this.setState({StudyID: e.target.value})} style={{width: "40%", marginBottom: "10px", marginLeft: "5px"}}/></div>
              <div style={{border: "solid", width: "23%", padding: "1%", margin: "1%"}}>
                <center><P2><b>Today's date</b></P2></center>
                <center>
                  <DatePicker selected={date} onChange={date => this.setState({date})} />
                </center>
              </div>
              <div style={{border: "solid", width: "23%", padding: "1%", margin: "1%"}}>
                <center><P2><b>Your age</b></P2></center>
                <TextField id="age" type="number" value={age} onChange={e => this.setState({age: e.target.value})} style={{width: "90%", marginBottom: "10px", marginLeft: "5%"}}/>
              </div>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly"  }}>
              <div style={{border: "solid", width: "32%", paddingLeft: "1%", paddingRight: "1%" }}>
                <center><P2><b><i>Please check one of these options:</i></b></P2></center><br />
                <div className="row" style={{marginLeft: "5%"}}>
                  <P3 className="col-6">I have no hearing aid/s</P3>
                  <div className="col-6">
                  <Checkbox
                    checked={checkboxQuestion === "I have no hearing aid/s"}
                    onChange={() => {
                      this.setState({ checkboxQuestion: "I have no hearing aid/s" });
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  </div>
                </div>
                <div className="row" style={{marginLeft: "5%"}}>
                    <P3 className="col-6">I use one hearing aid (left ear )</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use one hearing aid (left ear )"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use one hearing aid (left ear )" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
                  <div className="row" style={{marginLeft: "5%"}}>
                    <P3 className="col-6">I use one hearing aid (right ear)</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use one hearing aid (right ear)"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use one hearing aid (right ear)" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
                  <div className="row" style={{marginLeft: "5%"}}>
                    <P3 className="col-6">I use two hearing aids (both ears)</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use two hearing aids (both ears)"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use two hearing aids (both ears)" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
              </div>
              <div style={{border: "solid", width: "32%", paddingLeft: "1%", paddingRight: "1%"}}>
                <center><P2><b><i>If you have been using hearing aid/s, for how long?</i></b></P2></center><br />
                <div className="row" style={{margin: "2%"}}>
                  <center className="col-6">
                    <P2 style={{marginRight: "50%"}}><b><i>Left ear</i></b></P2>
                    <div className="row" style={{marginLeft: "10%"}}><TextField id="leftYears" type="number" value={leftYears} onChange={e => this.setState({leftYears: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> year</P2></div>
                    <div className="row" style={{marginLeft: "10%"}}><TextField id="leftMonths" type="number" value={leftMonths} onChange={e => this.setState({leftMonths: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> months</P2></div>
                    <br/><P2 style={{ marginLeft: "10%"}}>or</P2>
                    <div className="row" style={{marginLeft: "10%"}}><TextField id="leftWeeks" type="number" value={leftWeeks} onChange={e => this.setState({leftWeeks: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> weeks</P2></div>
                  </center>
                  <center className="col-6">
                    <P2 style={{marginRight: "50%"}}><b><i>Right ear</i></b></P2>
                    <div className="row" style={{marginLeft: "10%"}}><TextField id="rightYears" type="number" value={rightYears} onChange={e => this.setState({rightYears: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> year</P2></div>
                  <div className="row" style={{marginLeft: "10%"}}><TextField id="rightMonths" type="number" value={rightMonths} onChange={e => this.setState({rightMonths: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> months</P2></div>
                  <br/><P2 style={{ marginLeft: "10%"}}>or</P2>
                  <div className="row" style={{marginLeft: "10%"}}><TextField id="rightWeeks" type="number" value={rightWeeks} onChange={e => this.setState({rightWeeks: e.target.value})} style={{width: "20%", marginBottom: "10px", marginLeft: "5px"}}/><P2> weeks</P2></div>
                  </center>
                </div>
              </div>
              <div id="notes" style={{border: "solid", width: "32%", paddingLeft: "1%", paddingRight: "1%"}}>
                <P2>Notes</P2>
                <TextField multiline value={notes} onChange={(e) => this.setState({notes: e.target.value})} style={{width: "90%"}} />
              </div>
            </div>
          </div>
          <br /><br /><br /><br /><br /><br />
          <div id="question">
            {
              Object.keys(scaleQuestion).map((q, i) => {
                return <div className="col">
                  <div className="row" style={{ marginBottom: "2%", border: "solid", borderColor: "#add8e6", backgroundColor: "#EEEEEE" }}>
                    <div className="col-1"><P2><b>{i+1}</b></P2></div>
                    <div className="col-11"><P2>{q}</P2></div>
                  </div>
                  <div style={{marginBottom: "2%"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={3} style={{ textAlign: "right" }}>
                        {
                          i === 8 ?
                          <P2><b><i>Jumbled</i></b></P2>
                          :
                          i === 11 ?
                          <P2><b><i>Concentrate hard</i></b></P2>
                          :
                          <P2><b><i>Not at all</i></b></P2>
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Slider
                          value={scaleQuestion[q] ?? 5}
                          onChange={(e, value) => {
                            scaleQuestion[q] = value;
                            this.setState({ scaleQuestion });
                          }}
                          aria-labelledby="continuous-slider"
                          style={{ marginRight: "5%"}}
                          valueLabelDisplay="auto"
                          step={0.1}
                          marks={marks}
                          min={0}
                          max={10}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "left" }}>  
                      {
                          i === 8 ?
                          <P2><b><i>Not jumbled</i></b></P2>
                          :
                          i === 11 ?
                          <P2><b><i>No need to concentrate</i></b></P2>
                          :
                          <P2><b><i>Perfectly</i></b></P2>
                        }
                      </Grid>
                    </Grid>
                  </div>
                  <div className="row" style={{marginBottom: "2%", marginLeft: "7%"}}>
                    <P2 style={{marginTop: "25px"}}><i><b>Not applicable</b></i></P2>
                    <Checkbox checked={!values.includes(scaleQuestion[q])} color="primary" style={{marginBottom: "8%"}} onChange={(e) => {
                      if (e.target.checked) {
                        scaleQuestion[q] = " ";
                      } else {
                        scaleQuestion[q] = null;
                      }
                      this.setState({ scaleQuestion });
                    }}/>
                    <P3 style={{marginTop: "25px", marginRight: "5px"}}>Explanation:</P3>
                    <TextField
                      label="Please explain why this situation is not applicable to you here."
                      value={!values.includes(scaleQuestion[q]) ? scaleQuestion[q] : ""}
                      style={{width: "50%", marginBottom: "10%"}}
                      onChange={(e) => {
                        scaleQuestion[q] = e.target.value;
                        this.setState({ scaleQuestion });
                      }}
                    />
                  </div>
                </div>
              })
            }
          </div>
          <div id="error" style={{color: "red", margin: "5%"}}>
            {errorText.length !== 0 && <P2>These questions are not completed.</P2>}
            <ul>
              {
                errorText.map(error => <li><P3>{error}</P3></li>)
              }
            </ul>
          </div>
          <div id="submit" style={{margin: "5%"}}>
            <Button style={{ color: "white", backgroundColor: "#107896" }} variant="contained" size="large" onClick={this.handleSubmit}>Submit</Button>
          </div>
        </div>
        <FontComponent />
      </div>
    )
  }
}

export default SSLShort;