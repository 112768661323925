import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { HearingAWS } from 'assets/keys';

const ImageRandom = (props) => {
  const { id, updateAssign } = props;
  const [sections, setSections] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/imagerandom/" + id);
        setSections(doc.data.sections);
        doc.data.sectionId == null ? setSectionId(false) : setSectionId(doc.data.sectionId);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (sections && sectionId != null) {
      /* 
        make sure same section id has same image(s) show up
        use current timestamp in 1000 ms + section id
      */
      let index;
      if (sectionId != false) {
        index = ((new Date()).getSeconds() + sectionId) % sections.length;
      } else {
        index = Math.floor(Math.random() * sections.length);
      }
      setUrl(sections[index]);

      const assign = {
        id,
        type: "imagerandom",
        image: index + 1
      };
      updateAssign(assign);
    };
  }, [sections, sectionId]);

  return (
    <Container id="image">
      <br />
      {url?.map(image => <img key={image} src={HearingAWS + image} style={{ maxHeight: "100%", maxWidth: "100%" }} />)}
      <br /><br />
    </Container>
  )
};

export default ImageRandom;