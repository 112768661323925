import React from "react";
import { connect } from "react-redux";
import SignInHeader from "literacy/components/header/signinheader";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import { Button } from "@material-ui/core";

const MeaningHeader = (props) => {
  const { currentUser } = props;
  const renderPage = () => {
    switch (currentUser) {
      case null:
        return;
      case false:
        return <SignInHeader />;
      default:
        return (
          <div>
            <P1>Welcome to Meaning {props.part}</P1>
            {currentUser.meaning_score.length === 0 ? null : (
              <P3>
                Your current meaning score is{" "}
                <b>
                  {
                    Number(currentUser.meaning_score[currentUser.meaning_score.length - 1]["value"]) >= 1 ?
                      Number(currentUser.meaning_score[currentUser.meaning_score.length - 1]["value"])?.toFixed(2) :
                      (String(currentUser.meaning_score[currentUser.meaning_score.length - 1]["value"])?.split(".")?.[1] ?? 0) + "%"
                  }
                </b>
              </P3>
            )}
            <Button style={{ color: "white", backgroundColor: "#107896" }} onClick={() => window.location = props.backURL ?? "/literacy"} color="primary" variant="outlined">Back</Button>
          </div>
        );
    }
  };
  return <div className="jumbotron" style={{ backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)" }}>{renderPage()}</div>;
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(MeaningHeader);
