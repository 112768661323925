import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

const useStyles = makeStyles({
  root: {
    width: 380,
    margin: 10,
  },
  media: {
    height: 170,
  },
});

export default function TrainCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" style={{ backgroundColor: 'transparent', border: 'solid', borderColor: 'rgb(240,240,240)' }}>
      <CardActionArea
        onClick={() => props.handleClick()}
        style={{ textDecoration: "none" }}
      >
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="h3">
            <P3>Assignment: {props.assign.version === "w1" ? "Week 1" : "Week 2"}</P3>
          </Typography>
          <Typography variant="h6" color="textSecondary" component="h3">
            <P3>Time: {props.assign.createAt}</P3>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
