import React, { useState, useEffect, useCallback } from "react";
import { Select, MenuItem, Container } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const DropdownSelect = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/select/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(
    (e) => {
      handleUpdateAssign();
      if (!!answer) {
        readyToContinue(id);
      }
    },
    [answer]
  );

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "select",
      question,
      options,
      answer,
    };
    updateAssign(assign);
  };

  return (
    <Container>
      <br />
      <P2
        style={{ marginRight: "20px", marginLeft: "15px", fontWeight: "300" }}
      >
        {question}
      </P2>
      <br />
      <Select
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        style={{ minWidth: "100px" }}
      >
        {options?.map((opt) => (
          <MenuItem key={opt} value={opt}>
            <P3>{opt}</P3>
          </MenuItem>
        ))}
      </Select>
      <br />
      <br />
    </Container>
  );
};

export default DropdownSelect;
