import React from "react";
import {
  Button,
  LinearProgress,
  Container,
  TextField
} from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';
import P3 from "literacy/assets/fonts/p3";

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: this.props.rows,
      index: this.props.index ?? 0,
      studentAnswer: "",
      assign: this.props.assignment ?? [],
      score: this.props.score ?? 0,
      times: {}
    };
  }

  componentDidMount = () => {
    const times = {};
    this.state.questions.map(question => {
      times[JSON.stringify(question)] = 1;
    });
    this.setState({ times });
  }

  calculateScore = () => {
    const { index, questions, times } = this.state;
    const currentTimes = times[JSON.stringify(questions[index])];
    switch(currentTimes){
      case 1:
        return 1;
      case 2:
        return 0.7;
      case 3:
        return 0.5;
      case 4:
        return 0.3;
    }
  }

  handleNext = () => {
    const { studentAnswer, index, questions, assign, score, times } = this.state;
    let addScore = 0;
    if (questions[index].answer === studentAnswer) {
      addScore += this.calculateScore();
    } else if (!!questions[index].hint && times[JSON.stringify(questions[index])] < 3) {
      times[JSON.stringify(questions[index])] = times[JSON.stringify(questions[index])]+1;
      questions.push(questions[index]);
      this.setState({ questions, times });
    }
    assign.push({
      question: questions[index].question,
      hint: questions[index].hint,
      realAnswer: questions[index].answer,
      studentAnswer,
    });
    this.setState({
      score: score + addScore,
      index: index + 1,
      assign,
      studentAnswer: "",
    });
  };

  render() {
    const { questions, index, assign, score, times, studentAnswer } = this.state;
    const progress = Math.floor((index / questions.length) * 100);
    return (
      <div>
        <Container style={{ marginTop: "5%" }}>
          <Button
            variant="outlined"
            style={{ marginRight: 20, color: "white", backgroundColor: "#107896" }}
            onClick={() =>
              this.props.handleSaveAssignment(index, questions, assign, score)
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{ color: "white", backgroundColor: "#107896" }}
            onClick={() => (window.location = this.props.backURL ?? "/literacy")}
          >
            Quit
          </Button>
        </Container>
        <Container style={{ marginTop: "10%" }}>
          {index !== questions.length ? (
            <div>
              <Markdown className="font-weight-light">{questions[index].question}</Markdown>
              <br />
              <TextField value={studentAnswer} label="Answer" style={{width: 200}} onChange={(e) => this.setState({studentAnswer: e.target.value})}/>
              {
                times[JSON.stringify(questions[index])] >= 3 && 
                <P3>Hint: {questions[index].hint}</P3>
              }
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={this.handleNext}
              >
                Next
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={() => this.props.handleNext(score, assign)}
              >
                Go "True and False" Section
              </Button>
              <br />
            </div>
          )}
          <br />
          <LinearProgress variant="determinate" value={progress} />
        </Container>
      </div>
    );
  }
}
