import React from "react";
import styles from "./publication.module.css";

const PaperItem = ({ title, authors }) => (
  <article>
    <h3 className={styles.paperTitle}>{title}</h3>
    <p className={styles.paperAuthors}>{authors}</p>
  </article>
);

export default PaperItem;
