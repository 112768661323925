// common
import CheckBox from "GUI/tutor-components/checkbox";
import ConsentCheckbox from "GUI/tutor-components/consent-check";
import MultipleChoice from "GUI/tutor-components/multiple-choice";
import DatePicker from "GUI/tutor-components/date-picker";
import TextField from "GUI/tutor-components/text-field";
import PureText from "GUI/tutor-components/pure-text";
import Select from "GUI/tutor-components/select";
import AgeSelect from "GUI/tutor-components/age-select";
import PDF from "GUI/tutor-components/pdf";
import Image from "GUI/tutor-components/image";
import ImageRandom from "GUI/tutor-components/image-random";
import Slider from "GUI/tutor-components/slider";
import VolumeControl from "GUI/tutor-components/volume-control";
// digits in noise
import DigitsDemo from "GUI/tutor-components/digits-demo";
import Digits from "GUI/tutor-components/digits";
// crm
import CRM from "GUI/tutor-components/crm";
// others
import OHHIS from "GUI/tutor-components/OHHIS";
import DfdExperiment from "GUI/tutor-components/dfd";
import StudyId from "GUI/tutor-components/study-id";

import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// icons
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import CloseIcon from "@material-ui/icons/Close";
import RowContainer from "GUI/tutor-components/row";
import ColumnContainer from "GUI/tutor-components/column";
import AlphabetExperiment from "GUI/tutor-components/alphabet-experiment";

const table = {
  "Common Section": {
    "General Checkbox": "checkbox",
    "Consent Checkbox": "consentcheck",
    "Multiple Choice": "radio",
    "Dropdown Select": "select",
    "Age Select": "ageselect",
    "Text Field": "textfield",
    "Pure Text": "puretext",
    "Date Picker": "datepicker",
    PDF: "pdf",
    Slider: "slider",
    "Volume Control": "volumecontrol",
  },
  "Media Section": {
    Image: "image",
    "Image Random": "imagerandom",
  },
  "Global Section": {
    "Random Id": "randomid",
    "Study Id": "studyids",
  },
  "CRM Section": {
    CRM: "crm",
  },
  "Digits Section": {
    "Digits Demo": "digitsdemo",
    "Digits in Noise": "digits",
    "Recovery Stimulus": "recoverystimulus",
  },
  "Eye Tracking": {
    "Alphabet Experiment": "alphabetexperiment",
  },
  Experiment: {
    "DFD Experiment": "dfdexperiments",
  },
  Others: {
    OHHIS: "ohhis",
  },
  "Organizer Section": {
    "Horizontal line": "hrline",
  },
};

const containers = {
  row: {
    "1 container in a row": 1,
    "2 containers in a row": 2,
    "3 containers in a row": 3,
    "4 containers in a row": 4,
  },
  column: {
    "1 container in a column": 1,
    "2 containers in a column": 2,
    "3 containers in a column": 3,
    "4 containers in a column": 4,
  },
};

export const DrawerState = {
  CLOSED: "CLOSED",
  OPEN: "OPEN",
  CONTAINERS: "CONTAINERS",
  COMPONENTS: "COMPONENTS",
  SELECT: "SELECT",
};

const Page = (props) => {
  const [pageId, setPageId] = useState("");
  const [components, setComponents] = useState([]);
  const [dragState, setDragState] = useState(false);
  const [drawerState, setDrawerState] = useState(DrawerState.CLOSED);
  const [insertPosition, setInsertPosition] = useState(0);
  const [deleteComponentIndex, setDeleteComponentIndex] = useState(null);
  const [currContainer, setCurrContainer] = useState({});
  const [dummyIndex, setDummyIndex] = useState(0);

  useEffect(() => {
    if (props.currPage) {
      updateContainers();
    }
  }, [props.currPage]);

  const updateContainers = async () => {
    const doc = await axios.get("/api/gui/page/" + props.currPage);
    if (doc.data) {
      setComponents(doc.data.components);
      setPageId(doc.data.id);
      setDummyIndex(dummyIndex + 1);
    }
  };

  const handleAddComponent = async (comType, comId) => {
    if (props.currPage) {
      const newCom = {
        type: comType,
        id: comId,
      };
      const { id, type, index } = currContainer;
      // create component
      await axios.post("/api/gui/" + comType + "/" + comId);
      // update container
      const doc = await axios.get("/api/gui/" + type + "/" + id);
      const children = doc.data.children;
      children[index] = newCom;
      await axios.put("/api/gui/" + type + "/" + id, { children });
      setDrawerState(DrawerState.CLOSED);
      setDummyIndex(dummyIndex + 1);
    }
  };

  const handleAddContainer = async (type, amount, id) => {
    if (props.currPage) {
      const body = {
        amount,
        children: new Array(amount).fill({}),
      };
      const newCom = {
        type,
        id,
      };
      const newComponents = [
        ...components.slice(0, insertPosition),
        newCom,
        ...components.slice(insertPosition),
      ];
      // create component
      await axios.post("/api/gui/" + type + "/" + id, body);
      // update page components
      await axios.put("/api/gui/page/" + pageId, { components: newComponents });
      await setComponents(newComponents);
      setDrawerState(DrawerState.CLOSED);
    }
  };

  const handleDelete = async () => {
    const index = deleteComponentIndex;
    setDeleteComponentIndex(null);
    const newComponents = [
      ...components.slice(0, index),
      ...components.slice(index + 1),
    ];
    const component = components[index];
    // update page components
    await axios.put("/api/gui/page/" + pageId, { components: newComponents });
    setComponents(newComponents);

    const doc = await axios.get(
      "/api/gui/" + component.type + "/" + component.id
    );
    console.log(doc.data);
    // delete container
    await axios.delete("/api/gui/" + component.type + "/" + component.id);
    // delete components inside container
    if (component.type === "row" || component.type === "column") {
      const children = doc.data.children;
      const deleteCalls = await children.map(
        async (com) => await axios.delete("/api/gui/" + com.type + "/" + com.id)
      );
      await Promise.all(deleteCalls);
    }
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
    setDragState(true);
  };

  const handleDragEnd = () => {
    setDragState(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, key) => {
    const id = e.dataTransfer.getData("id");
    const com = components.find((com) => com.id === id);
    const newComponents = [];
    let i = 0;
    while (i < components.length) {
      if (i === key) {
        newComponents.push(com);
      }
      if (components[i].id !== id) {
        newComponents.push(components[i]);
      }
      i++;
    }
    // check if in the last slot
    if (i === key) {
      newComponents.push(com);
    }
    // only need to update the components in page
    await axios.put("/api/gui/page/" + pageId, { components: newComponents });
    setDragState(false);
    setComponents(newComponents);
  };

  const handleInsertContainerClick = (index) => {
    setInsertPosition(index);
    setDrawerState(DrawerState.CONTAINERS);
  };

  const handleInsertComponentClick = (type, id, index) => {
    setCurrContainer({
      type,
      id,
      index,
    });
    setDrawerState(DrawerState.COMPONENTS);
  };

  const drawerComponentList = () => (
    <div style={{ paddingLeft: 20 }} role="presentation">
      <P2>{"Components"}</P2>
      {Object.keys(table).map((section) => (
        <List>
          <P3>{section}</P3>
          {Object.keys(table[section]).map((text) => (
            <ListItem
              button
              onClick={() => handleAddComponent(table[section][text], uuidv4())}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      ))}
    </div>
  );

  const drawerContainerList = () => (
    <div style={{ paddingLeft: 20, paddingRight: 20 }} role="presentation">
      <P2>{"Container"}</P2>
      {Object.keys(containers).map((container) => {
        return (
          <div>
            <P3>{container}</P3>
            {Object.keys(containers[container]).map((item) => (
              <List>
                <ListItem
                  button
                  onClick={() =>
                    handleAddContainer(
                      container,
                      containers[container][item],
                      uuidv4()
                    )
                  }
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              </List>
            ))}
          </div>
        );
      })}
    </div>
  );

  const renderComponent = (com) => {
    switch (com.type) {
      // common
      case "checkbox":
        return <CheckBox id={com.id} />;
      case "consentcheck":
        return <ConsentCheckbox id={com.id} />;
      case "radio":
        return <MultipleChoice id={com.id} />;
      case "select":
        return <Select id={com.id} />;
      case "ageselect":
        return <AgeSelect id={com.id} />;
      case "textfield":
        return <TextField id={com.id} />;
      case "puretext":
        return <PureText id={com.id} />;
      case "datepicker":
        return <DatePicker id={com.id} />;
      case "pdf":
        return <PDF id={com.id} />;
      case "image":
        return <Image id={com.id} />;
      case "imagerandom":
        return <ImageRandom id={com.id} />;
      case "slider":
        return <Slider id={com.id} />;
      case "volumecontrol":
        return <VolumeControl id={com.id} />;
      // digits in noise
      case "digitsdemo":
        return <DigitsDemo id={com.id} />;
      case "digits":
        return <Digits id={com.id} />;
      // crn
      case "crm":
        return <CRM id={com.id} />;
      case "alphabetexperiment":
        return <AlphabetExperiment id={com.id} />;
      // others
      case "ohhis":
        return <OHHIS id={com.id} />;
      case "dfdexperiments":
        return <DfdExperiment id={com.id} />;
      // layout
      case "hrline":
        return <hr />;
      case "studyids":
        return <StudyId id={com.id} />;
      case "row":
        return (
          <RowContainer
            id={com.id}
            updateContainers={updateContainers}
            drawerState={drawerState}
            handleInsertComponent={handleInsertComponentClick}
            update={dummyIndex}
          />
        );
      case "column":
        return (
          <ColumnContainer
            id={com.id}
            updateContainers={updateContainers}
            drawerState={drawerState}
            handleInsertComponent={handleInsertComponentClick}
            update={dummyIndex}
          />
        );
    }
  };

  const FAB = () => (
    <React.Fragment key={"drawer"}>
      {drawerState === DrawerState.CLOSED ? (
        <Fab
          color="primary"
          onClick={() => setDrawerState(DrawerState.SELECT)}
          style={{ fontSize: 100, position: "fixed", left: 20, bottom: 100 }}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fab
          color="secondary"
          onClick={() => setDrawerState(DrawerState.CLOSED)}
          style={{ fontSize: 100, position: "fixed", left: 20, bottom: 100 }}
        >
          <CloseIcon />
        </Fab>
      )}
      {drawerState == DrawerState.CONTAINERS && (
        <Drawer
          anchor={"left"}
          open={true}
          onClose={() => setDrawerState(DrawerState.CLOSED)}
        >
          {drawerContainerList()}
        </Drawer>
      )}
      {drawerState == DrawerState.COMPONENTS && (
        <Drawer
          anchor={"left"}
          open={true}
          onClose={() => setDrawerState(DrawerState.CLOSED)}
        >
          {drawerComponentList()}
        </Drawer>
      )}
    </React.Fragment>
  );

  const deleteContainerDialog = () => (
    <Dialog
      open={deleteComponentIndex != null}
      onClose={() => setDeleteComponentIndex(null)}
    >
      <DialogTitle>{"Are you sure deleting this container?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => setDeleteComponentIndex(null)} color="primary">
          No
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container
      style={{
        marginTop: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        width: window.innerWidth * 0.9,
        backgroundColor: "rgb(240,240,240)",
      }}
    >
      {!dragState && drawerState != DrawerState.SELECT ? (
        <div style={{ height: "50px", margin: "10px" }}></div>
      ) : (
        <Paper
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, 0)}
          onClick={() => handleInsertContainerClick(0)}
          style={{ border: "dotted", height: "50px", margin: "10px" }}
        >
          <P2
            key={0}
            style={{ fontWeight: "300", paddingLeft: "40%", paddingTop: "8px" }}
          >
            {drawerState == DrawerState.SELECT ? "Insert Container" : "Drop"}
          </P2>
        </Paper>
      )}

      {components.map((com, i) => (
        <div>
          <Paper
            style={{ margin: "10px", position: "relative" }}
            draggable
            onDragStart={(e) => handleDragStart(e, com.id)}
            onDragEnd={handleDragEnd}
          >
            <DeleteForeverIcon
              onClick={() => setDeleteComponentIndex(i)}
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "red",
                width: 30,
                height: 30,
                border: "solid",
              }}
            />
            {renderComponent(com)}
          </Paper>
          {!dragState && drawerState != DrawerState.SELECT ? (
            <div style={{ height: "50px", margin: "10px" }}></div>
          ) : (
            <Paper
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, i + 1)}
              onClick={() => handleInsertContainerClick(i + 1)}
              style={{ border: "dotted", height: "50px", margin: "10px" }}
            >
              <P2
                key={i + 1}
                style={{
                  fontWeight: "300",
                  paddingLeft: "40%",
                  paddingTop: "8px",
                }}
              >
                {drawerState == DrawerState.SELECT
                  ? "Insert Container"
                  : "Drop"}
              </P2>
            </Paper>
          )}
        </div>
      ))}
      <br />
      {FAB()}
      {deleteContainerDialog()}
      <br />
    </Container>
  );
};

export default Page;
