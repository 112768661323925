import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

export const Tile = ({ title, icon, nav }) => {
  const history = useHistory();

  const handleClick = () => {
    console.log("Navigating to:", nav);
    if (nav) {
      history.push(nav);
    }
  };

  return (
    <button className="tile" onClick={handleClick}>
      <div className="tileChild">
        <img className="icon" alt={title} src={icon} />
      </div>
      <div className="admin">{title}</div>
    </button>
  );
};
