import React from "react";
import { Container } from "@material-ui/core";
import Footer from "appearance/components/IAT1/partials/footer";
import { P1, P2, P3 } from 'assets/fonts';

export default () => {
  return (
    <Container>
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "24%",
        }}
      >
        <P1>Welcome</P1>
        <Footer />
      </div>
    </Container>
  );
};
