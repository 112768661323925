import React from "react";
import styles from "./PersonCard.module.css";

function PersonCard({ name, role, image }) {
  return (
    <article className={styles.personCard}>
      <img src={image} alt={name} className={styles.personImage} />
      <h3 className={styles.personName}>{name}</h3>
      {role && <p className={styles.personRole}>{role}</p>}
    </article>
  );
}

export default PersonCard;
