import React from "react";
import Welcome from "iRSM/components/pre-test/welcome";
import Consent from "iRSM/components/pre-test/consent";
import Hearing from "iRSM/components/pre-test/hearing";
import DemoInfo from "iRSM/components/pre-test/demo-info";
import VolumeAdjustment from "iRSM/components/pre-test/adjustment";
import SpeechInNoise1 from "iRSM/components/test/speech-in-noise-1";
import SpeechInNoise2 from "iRSM/components/test/speech-in-noise-2";
import TestDemo from "iRSM/components/test/test-demo";
import Environment from "iRSM/components/pre-test/environment";
import PostQuestionIntro from "iRSM/components/post-test/test-question-intro";
import PostQuestions1 from "iRSM/components/post-test/post-test-questions1";
import PostQuestions2 from "iRSM/components/post-test/post-test-questions2";
import Submit from "iRSM/components/post-test/submit";
import axios from "axios";
import FontComponent from 'assets/font-ui';
import WearAids from 'iRSM/components/test/wear-aids';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participantId: null,
      studyId: null,
      withAids1: null,
      withAids2: null,
      process: "welcome",
      consent: {},
      email: "",
      hearing: {},
      volume: 0,
      output: null,
      SNR1: null,
      SNR2: null,
      timestamp1: null,
      timestamp2: null,
      reactTimer1: null,
      realTimer1: null,
      reactTimer2: null,
      realTimer2: null,
      postTestQuestions1: null,
      postTestQuestions2: null
    };
  }

  componentDidMount = () => {
    const withAids1 = !!(Math.round(Math.random()));
    const withAids2 = !withAids1;
    this.setState({ withAids1, withAids2 });
  }

  handleChangeId = (participantId, studyId) => {
    this.setState({ participantId, studyId, process: "environment" });
  }

  handleConsentClick = (email) => {
    this.setState({ email, process: "demo-info" });
  };

  handleBirthClick = (birth) => {
    this.setState({ birth, process: "hearing" });
  };

  handleHearClick = async (hearing) => {
    await this.setState({ hearing, process: "environment" });
  };

  handleEnvironmentClick = () => {
    this.setState({ process: "adjustment" });
  };

  handleAdjustmentClick = (volume, output) => {
    this.setState({ process: "demo", volume, output });
  };

  handleDemoClick = () => {
    this.setState({ process: "with-aids1" });
  };

  handleWithAids1Click = () => {
    this.setState({ process: "testing1" });
  }

  handleTesting1Click = (SNR1, timestamp1, reactTimer1, realTimer1, questions1, answers1, dbs1) => {
    this.setState({ SNR1, timestamp1, reactTimer1, realTimer1, questions1, answers1, dbs1, process: "post-questions-intro" });
  };

  handlePostQuestionsIntroClick = () => {
    this.setState({ process: "post-questions1" });
  }

  handlePostQuestions1Click = (postTestQuestions) => {
    this.setState({ process: "with-aids2", postTestQuestions1: postTestQuestions });
  }

  handleWithAids2Click = () => {
    this.setState({ process: "testing2" });
  }

  handleTesting2Click = (SNR2, timestamp2, reactTimer2, realTimer2, questions2, answers2, dbs2) => {
    this.setState({ SNR2, timestamp2, reactTimer2, realTimer2, questions2, answers2, dbs2, process: "post-questions2" });
  };

  handlePostQuestions2Click = (postTestQuestions) => {
    this.setState({ process: "submit", postTestQuestions2: postTestQuestions });
  }

  handleSubmitClick = async () => {
    const {
      participantId, studyId, output, volume,
      withAids1, SNR1, timestamp1, reactTimer1, realTimer1, questions1, answers1, dbs1,
      withAids2, SNR2, timestamp2, reactTimer2, realTimer2, questions2, answers2, dbs2,
      postTestQuestions1, postTestQuestions2
    } = this.state;
    await axios.post("/api/hearing/irsm/user", {
      participantId, studyId, output, volume,
      withAids1, SNR1, timestamp1, reactTimer1, realTimer1, questions1, answers1, dbs1,
      withAids2, SNR2, timestamp2, reactTimer2, realTimer2, questions2, answers2, dbs2,
      postTestQuestions1, postTestQuestions2
    });
    window.location = "/";
  };

  renderProcess = () => {
    const { process, volume, withAids1, withAids2 } = this.state;
    switch (process) {
      case "welcome":
        return <Welcome onChangeId={this.handleChangeId} />;
      case "consent":
        return <Consent handleClick={this.handleConsentClick} />;
      case "demo-info":
        return <DemoInfo handleClick={this.handleBirthClick} />;
      case "hearing":
        return <Hearing handleClick={this.handleHearClick} />;
      case "environment":
        return <Environment handleClick={this.handleEnvironmentClick} />;
      case "adjustment":
        return (
          <VolumeAdjustment
            handleClick={this.handleAdjustmentClick}
          />
        );
      case "demo":
        return <TestDemo volume={volume} handleClick={this.handleDemoClick} />;
      case "with-aids1":
        return <WearAids withAids={withAids1} handleClick={this.handleWithAids1Click} />
      case "testing1":
        return (
          <SpeechInNoise1
            volume={volume}
            withAids={withAids1}
            handleClick={this.handleTesting1Click}
          />
        );
      case "post-questions-intro":
        return (
          <PostQuestionIntro handleClick={this.handlePostQuestionsIntroClick} />
        );
      case "post-questions1":
        return (
          <PostQuestions1 handleClick={this.handlePostQuestions1Click} />
        );
      case "with-aids2":
        return <WearAids withAids={withAids2} handleClick={this.handleWithAids2Click} />
      case "testing2":
        return (
          <SpeechInNoise2
            volume={volume}
            withAids={withAids2}
            handleClick={this.handleTesting2Click}
          />
        );
      case "post-questions2":
        return (
          <PostQuestions2 handleClick={this.handlePostQuestions2Click} />
        );
      case "submit":
        return <Submit handleClick={this.handleSubmitClick} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div style={{
        backgroundColor: "rgb(240,240,240)",
        fontFamily: "raven",
        height: window.innerHeight,
        overflowY: "scroll",
        paddingRight: "5%"
      }}>
        <FontComponent />
        {this.renderProcess()}
      </div>
    )
  }
}

export default Main;
