import React, { useState, useEffect } from "react";
import "./styles.css";
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { P1, P2, P3 } from 'assets/fonts';


const DecoratedMain = (props) => {

  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/digits' });
  });

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => { props.history.push(page) }, 2000);
  }


  return (
    <div class="container-fluid">
      <div style={{backgroundColor: "#333", padding:"2rem"}} className="row first-row">
          <div id="logo">
            <img src={process.env.PUBLIC_URL + "/UA.png"} />
          </div>
        </div>

        <div className="row" style={{padding:"20rem 2rem", backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)"}}>
        <div style={{ color: "white"}}>
          <P1><b style={{textDecoration:"underline"}}>Welcome to our Collection of Hearing Experiments</b></P1>
          <P2 style={{fontWeight:"400"}}>Instructions: Please use a desktop or laptop computer with
          Chrome, Edge, Firefox, Opera or Safari to complete any of these evaluations.</P2>
        </div>
      </div>

      <div className="col components-card">


      <div className="row">
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/digits/1')}>
          <div style={{padding: "5rem 1rem" }}>
            <center style={{ color: "white" }}><P2 >Digits in noise 1</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>2 (23) blocks with babble</P3></center>
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/digits/2')}>
          <div style={{ padding: "5rem 1rem"}}>
            <center style={{ color: "white" }}><P2 >Digits in noise 2</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>1 (23) block with babble</P3></center>
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/digits/3')}>
          <div style={{ padding: "5rem 1rem" }}>
            <center style={{ color: "white" }}><P2 >Digits in noise 3</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>Normal accent with babble noise</P3></center>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/digits/4')}>
          <div style={{ padding: "5rem 1rem" }}>
            <center style={{ color: "white" }}><P2 >Digits in noise 4</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <P3>Normal accent with pink noise</P3>
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/digits/5')}>
          <div style={{ padding: "5rem 1rem" }}>
            <center style={{ color: "white" }}><P2 >Digits in noise 5</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>Indian accent</P3></center>
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/digits/6')}>
          <div style={{ padding: "5rem 1rem"}}>
            <center style={{ color: "white" }}><P2 >Digits in noise 6</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>South Africa accent</P3></center>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="card" className="col-12" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/digits/7')}>
          <div style={{ padding: "5rem 1rem" }}>
            <center style={{ color: "white" }}><P2 >Digits in noise 7</P2></center>
            {/* <hr style={{ backgroundColor: "white", width: "50%", height: "2px" }} /> */}
            <center><P3>2 (23) blocks with babble, without 0 and 7 digits, with pause each 6 trials</P3></center>
          </div>
        </div>
      </div>
      </div>

      <div className="row footer">
        <div id="logo">
          <img src={process.env.PUBLIC_URL + "/UA.png"} />
        </div>
      </div>
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <CircularProgress color="primary" style={{ width: "100px", height: "100px" }} />
      </Backdrop>
      <Button
        variant="outlined"
        onClick={() => window.location = "/hearing"}
        style={{position: "absolute", top: "10px", left: "10px", color: "white", borderColor: "white" }}
      >
        Back
      </Button>
    </div>
  )
}

export default DecoratedMain;
