import React from "react";
import { Button, Container } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";

export default (props) => {
  return (
    <Container>
      <div
        style={{
          textAlign: "center",
          position: "relative",
        }}
      >
        <P1>Thank you for participating in this test!</P1>
        <P2>Your participant ID is: {props.ID}</P2>
        <P2>
          You may contact the investigators at jcbhlab@ualberta.ca and use this
          number to withdraw your data up to one week after you have completed
          the experiment.
        </P2>
        <Button
          variant="contained"
          style={{ color: "white", backgroundColor: "#107896" }}
          onClick={() => (window.location = "/hearing/crm")}
        >
          Back to Main Page
        </Button>
      </div>
    </Container>
  );
};
