import React from "react";
import Welcome from "hearing-demo/components/welcome";
import Test from "hearing-demo/components/crm-test";
import End from "hearing-demo/components/end";
import axios from 'axios';
import FontComponent from 'assets/font-ui';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      process: "welcome",
      volume: 0.5,
      reversals: 3
    };
  }
  
  shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/demo/crm' });
    setTimeout(() => {
      this.setState({ process: "CRM" });
    }, 3000);
  }

  handleCRMClick = () => {
    this.setState({ process: "end" })
  }

  renderProcess = () => {
    const { process, volume, reversals, ID } = this.state;
    switch (process) {
      case "welcome":
        return <Welcome />;
      case "CRM":
        return <Test reversals={reversals} volume={volume} handleClick={this.handleCRMClick} cycle={"AudioLoss"} block={1} />;
      case "end":
        return <End ID={ID}/>
      default:
        return null;
    }
  };

  render() {
    const bottomFontLists = ["test", "CRM"];
    const { process } = this.state;
    return (
      <div style={{
        backgroundColor: "rgb(240,240,240)",
        fontFamily: "raven",
        height: window.innerHeight,
        overflowY: "scroll",
        paddingRight: "5%"
      }}>
        <FontComponent position={bottomFontLists.includes(process) ? "bottomRight" : "topRight"}/>
        {this.renderProcess()}
      </div>
    )
  }
}

export default Main;
