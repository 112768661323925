import React from "react";
import { Container } from "@material-ui/core";
import Welcome from "appearance/components/IAT2/pre-test/welcome";
import Consent from "appearance/components/IAT2/pre-test/consent";
import Intro from "appearance/components/IAT2/pre-test/introduction";
import Instruction from "appearance/components/IAT2/pre-test/instruction";
import Story1 from "appearance/components/IAT2/pre-test/story1";
import Block1 from "appearance/components/IAT2/test/block1";
import Block2 from "appearance/components/IAT2/test/block2";
import Block3 from "appearance/components/IAT2/test/block3";
import Block4 from "appearance/components/IAT2/test/block4";
import Block5 from "appearance/components/IAT2/test/block5";
import Block6 from "appearance/components/IAT2/test/block6";
import Block7 from "appearance/components/IAT2/test/block7";
import Questionnaire from "appearance/components/IAT2/post-test/questionnaire";
import DemoInfo from "appearance/components/IAT2/post-test/demo-info";
import Submit from "appearance/components/IAT2/post-test/submit";
import End from "appearance/components/IAT2/post-test/end";
import axios from "axios";
import FontComponent from "assets/font-ui";

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "welcome",
      id: Math.ceil(Math.random() * 10000),
      email: null,
      block1: null,
      block2: null,
      block3: null,
      block4: null,
      block5: null,
      block6: null,
      block7: null,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6: null,
      q7: null,
      q8: null,
      q9: null,
      q10: null,
      age: null,
      gender: null,
      education: null,
      income: null,
      location: { country: "", province: "" },
      city: null,
      times: 0,
      trials: 10,
      modifying: false,
      b1WordAndPicture: [],
      b2WordAndPicture: [],
      b3WordAndPicture: [],
      b4WordAndPicture: [],
      b5WordAndPicture: [],
      b6WordAndPicture: [],
      b7WordAndPicture: [],
      b1RealAnswer: [],
      b2RealAnswer: [],
      b3RealAnswer: [],
      b4RealAnswer: [],
      b5RealAnswer: [],
      b6RealAnswer: [],
      b7RealAnswer: [],
      wordLeftKey: 0,
      imageLeftKey: 0,
      story1EyeTrackData: [],
      story2EyeTrackData: [],
      story3EyeTrackData: [],
      boxSize: [],
      distance: [470, 700]
    };
  }

  componentDidMount = async () => {
    await axios.post('/api/appearance/visit', { page: 'appearance/2' });
    // assign the left key and right key for each block
    const wordLeftKey = Math.floor(Math.random() * 2);
    const imageLeftKey = Math.floor(Math.random() * 2);

    const b1ImageLeftKey = imageLeftKey;
    const b2WordLeftKey = wordLeftKey;
    const b3WordLeftKey = wordLeftKey;
    const b3ImageLeftKey = imageLeftKey;
    const b4WordLeftKey = wordLeftKey;
    const b4ImageLeftKey = imageLeftKey;
    const b5ImageLeftKey = 1 - imageLeftKey;
    const b6WordLeftKey = wordLeftKey;
    const b6ImageLeftKey = 1 - imageLeftKey;
    const b7WordLeftKey = wordLeftKey;
    const b7ImageLeftKey = 1 - imageLeftKey;

    // generate test set for block 1/2/3/4/5/6/7 (about 50ms)
    const block1 = await this.generateBlockWith20Images(b1ImageLeftKey);
    const block2 = await this.generateBlockWith20Words(b2WordLeftKey);
    const block3 = await this.generateBlockWith20Trials(b3WordLeftKey, b3ImageLeftKey);
    const block4 = await this.generateBlockWith40Trials(b4WordLeftKey, b4ImageLeftKey);
    const block5 = await this.generateBlockWith30Images(b5ImageLeftKey);
    const block6 = await this.generateBlockWith20Trials(b6WordLeftKey, b6ImageLeftKey);
    const block7 = await this.generateBlockWith40Trials(b7WordLeftKey, b7ImageLeftKey);

    // distance and box size
    const distance = this.state.distance;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const boxHeight = (height - distance[1]) / 2;
    const boxWidth = (width - distance[0] * 2) / 3;

    this.setState({
      boxSize: [boxWidth, boxHeight],
      b1WordAndPicture: block1.wordAndPicture, b1RealAnswer: block1.realAnswer,
      b2WordAndPicture: block2.wordAndPicture, b2RealAnswer: block2.realAnswer,
      b3WordAndPicture: block3.wordAndPicture, b3RealAnswer: block3.realAnswer,
      b4WordAndPicture: block4.wordAndPicture, b4RealAnswer: block4.realAnswer,
      b5WordAndPicture: block5.wordAndPicture, b5RealAnswer: block5.realAnswer,
      b6WordAndPicture: block6.wordAndPicture, b6RealAnswer: block6.realAnswer,
      b7WordAndPicture: block7.wordAndPicture, b7RealAnswer: block7.realAnswer,
    });

    setTimeout(() => this.setState({
      page: "consent",
      wordLeftKey,
      imageLeftKey
    }), 3000);
  };

  generateBlockWith20Images = (imageLeftkey) => {
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = images.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        if (imageLeftkey === 0 && !nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("left");
        } else if (imageLeftkey === 0 && nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else if (imageLeftkey === 1 && !nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 19; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith20Words = (wordLeftKey) => {
    const words = [
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy",
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"
    ];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = words.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        if (wordLeftKey === 0 && !positiveWord.includes(wordAndPicture[i])) {
          realAnswer.push("left");
        } else if (wordLeftKey === 0 && positiveWord.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else if (wordLeftKey === 1 && !positiveWord.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 19; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith30Images = (imageLeftKey) => {
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const wordAndPicture = images.sort(() => Math.random() - 0.5);
      const realAnswer = [];
      for (let i = 0; i < 30; i++) {
        if (imageLeftKey === 0 && !nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("left");
        } else if (imageLeftKey === 0 && nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else if (imageLeftKey === 1 && !nonDisfiguredImage.includes(wordAndPicture[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }
      };
      for (let i = 0; i < 26; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      for (let i = 0; i < 29; i++) {
        if (wordAndPicture[i] === wordAndPicture[i + 1]) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith20Trials = (wordLeftKey, imageLeftKey) => {
    // generate 2 sets, set 1 for block3, set 2,1 for block 4, set 2 for block 6, set 1/2 for block 7.
    const words = ["Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"];
    const images = ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    // const negativeWord = ["Awkward", "Misfit", "Dislike", "Unfriendly", "Grumpy"];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    // const disfiguredImage = ["2.jpg", "4.jpg", "6.jpg", "8.jpg", "10.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const shuffledWords = words.sort(() => Math.random() - 0.5);
      const shuffledImages = images.sort(() => Math.random() - 0.5);
      const wordAndPicture = [];
      const realAnswer = [];
      for (let i = 0; i < 10; i++) {
        wordAndPicture.push(shuffledWords[i]);
        wordAndPicture.push(shuffledImages[i]);

        if (wordLeftKey === 0 && !positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("left");
        } else if (wordLeftKey === 0 && positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("right");
        } else if (wordLeftKey === 1 && !positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }

        if (imageLeftKey === 0 && !nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("left");
        } else if (imageLeftKey === 0 && nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("right");
        } else if (imageLeftKey === 1 && !nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }
      };
      for (let i = 0; i < 16; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  generateBlockWith40Trials = (wordLeftKey, imageLeftKey) => {
    // generate 2 sets, set 1 for block3, set 2,1 for block 4, set 2 for block 6, set 1/2 for block 7.
    const words = [
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy",
      "Easy-going", "Awkward", "Approachable", "Misfit", "Like", "Dislike", "Friendly", "Unfriendly", "Happy", "Grumpy"
    ];
    const images = [
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg",
      "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"
    ];
    const positiveWord = ["Easy-going", "Approachable", "Like", "Friendly", "Happy"];
    // const negativeWord = ["Awkward", "Misfit", "Dislike", "Unfriendly", "Grumpy"];
    const nonDisfiguredImage = ["1.jpg", "3.jpg", "5.jpg", "7.jpg", "9.jpg"];
    // const disfiguredImage = ["2.jpg", "4.jpg", "6.jpg", "8.jpg", "10.jpg"];
    let whileContinue = true;
    while (whileContinue) {
      whileContinue = false;
      const shuffledWords = words.sort(() => Math.random() - 0.5);
      const shuffledImages = images.sort(() => Math.random() - 0.5);
      const wordAndPicture = [];
      const realAnswer = [];
      for (let i = 0; i < 20; i++) {
        wordAndPicture.push(shuffledWords[i]);
        wordAndPicture.push(shuffledImages[i]);

        if (wordLeftKey === 0 && !positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("left");
        } else if (wordLeftKey === 0 && positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("right");
        } else if (wordLeftKey === 1 && !positiveWord.includes(shuffledWords[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }

        if (imageLeftKey === 0 && !nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("left");
        } else if (imageLeftKey === 0 && nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("right");
        } else if (imageLeftKey === 1 && !nonDisfiguredImage.includes(shuffledImages[i])) {
          realAnswer.push("right");
        } else {
          realAnswer.push("left");
        }
      };
      for (let i = 0; i < 36; i++) {
        if (
          realAnswer[i] === realAnswer[i + 1] &&
          realAnswer[i + 1] === realAnswer[i + 2] &&
          realAnswer[i + 2] === realAnswer[i + 3] &&
          realAnswer[i + 3] === realAnswer[i + 4]
        ) {
          whileContinue = true;
        };
      };
      if (!whileContinue) {
        return { wordAndPicture, realAnswer };
      };
    };
  };

  handleConsentClick = (email) => {
    this.setState({ email, page: "story1" });
  };

  handleStory1Click = (eyeTrackData) => {
    this.setState({ page: "intro", story1EyeTrackData: eyeTrackData });
  }

  handleIntroClick = () => {
    this.setState({ page: "instruction" });
  };

  handleInstructionClick = () => {
    this.setState({ page: "block1" });
  };

  handleTest1Click = (block1) => {
    this.setState({ page: "block2", block1 });
  }

  handleTest2Click = (block2) => {
    this.setState({ page: "block3", block2 });
  }

  handleTest3Click = (block3) => {
    this.setState({ page: "block4", block3 });
  }

  handleTest4Click = (block4) => {
    this.setState({ page: "block5", block4 });
  }

  handleTest5Click = (block5) => {
    this.setState({ page: "block6", block5 });
  }

  handleTest6Click = (block6) => {
    this.setState({ page: "block7", block6 });
  }

  handleTest7Click = async (block7) => {
    this.setState({ page: "questionnaire", block7 });
  }

  handleQuestionnaireClick = (q1, q2, q3, q4, q5, q6, q7, q8, q9, q10) => {
    this.setState({ page: "demo", q1, q2, q3, q4, q5, q6, q7, q8, q9, q10 });
  }

  handleDemoInfoClick = (
    age,
    gender,
    education,
    income,
    country,
    province,
    city,
    times
  ) => {
    this.setState({
      age,
      gender,
      education,
      income,
      country,
      province,
      city,
      times,
      page: "submit"
    });
  };

  handleSubmit = async () => {
    const {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      id, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10,
      email,
      age,
      gender,
      education,
      income,
      country,
      province,
      city,
      times,
      modifying,
      wordLeftKey,
      imageLeftKey,
      story1EyeTrackData
    } = this.state;
    await axios.post("/api/appearance/2/data", {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      id, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10,
      email,
      age,
      gender,
      education,
      income,
      country,
      province,
      city,
      times,
      modifying,
      wordLeftKey,
      imageLeftKey,
      story1EyeTrackData
    });
    await this.setState({ page: "end" });
  };

  handleEnd = () => {
    window.location = "/";
  }

  renderPage = () => {
    const {
      wordLeftKey,
      imageLeftKey,
      trials,
      id,
      b1WordAndPicture,
      b2WordAndPicture,
      b3WordAndPicture,
      b4WordAndPicture,
      b5WordAndPicture,
      b6WordAndPicture,
      b7WordAndPicture,
      b1RealAnswer,
      b2RealAnswer,
      b3RealAnswer,
      b4RealAnswer,
      b5RealAnswer,
      b6RealAnswer,
      b7RealAnswer,
      boxSize
    } = this.state;
    switch (this.state.page) {
      case "welcome":
        return <Welcome />;
      case "consent":
        return <Consent handleClick={this.handleConsentClick} />;
      case "story1":
        return <Story1 handleClick={this.handleStory1Click} />;
      case "intro":
        return <Intro id={id} handleClick={this.handleIntroClick} />;
      case "instruction":
        return <Instruction handleClick={this.handleInstructionClick} />
      case "block1":
        return <Block1 boxSize={boxSize} wordAndPicture={b1WordAndPicture} realAnswer={b1RealAnswer} leftKey={imageLeftKey} handleTestClick={this.handleTest1Click} trials={trials} />;
      case "block2":
        return <Block2 boxSize={boxSize} wordAndPicture={b2WordAndPicture} realAnswer={b2RealAnswer} leftKey={wordLeftKey} handleTestClick={this.handleTest2Click} trials={trials} />;
      case "block3":
        return <Block3 boxSize={boxSize} wordAndPicture={b3WordAndPicture} realAnswer={b3RealAnswer} wordLeftKey={wordLeftKey} imageLeftKey={imageLeftKey} handleTestClick={this.handleTest3Click} trials={trials} />;
      case "block4":
        return <Block4 boxSize={boxSize} wordAndPicture={b4WordAndPicture} realAnswer={b4RealAnswer} wordLeftKey={wordLeftKey} imageLeftKey={imageLeftKey} handleTestClick={this.handleTest4Click} trials={trials} />;
      case "block5":
        return <Block5 boxSize={boxSize} wordAndPicture={b5WordAndPicture} realAnswer={b5RealAnswer} leftKey={1 - imageLeftKey} handleTestClick={this.handleTest5Click} trials={trials} />;
      case "block6":
        return <Block6 boxSize={boxSize} wordAndPicture={b6WordAndPicture} realAnswer={b6RealAnswer} wordLeftKey={wordLeftKey} imageLeftKey={1 - imageLeftKey} handleTestClick={this.handleTest6Click} trials={trials} />;
      case "block7":
        return <Block7 boxSize={boxSize} wordAndPicture={b7WordAndPicture} realAnswer={b7RealAnswer} wordLeftKey={wordLeftKey} imageLeftKey={1 - imageLeftKey} handleTestClick={this.handleTest7Click} trials={trials} />;
      case "questionnaire":
        return <Questionnaire handleClick={this.handleQuestionnaireClick} />;
      case "demo":
        return <DemoInfo handleClick={this.handleDemoInfoClick} />;
      case "submit":
        return <Submit id={id} handleSubmit={this.handleSubmit} />;
      case "end":
        return <End handleClick={this.handleEnd} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div style={{
        backgroundColor: "rgb(240,240,240)",
        fontFamily: "raven"
      }}>
        <FontComponent />
        <div>
          {this.renderPage()}
        </div>
      </div>
    );
  }
}

export default MainPage;