import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper, Button, TextField } from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();
  const [editState, setEditState] = useState(-1);
  const [question, setQuestion] = useState("");
  const [choices, setChoices] = useState("");
  const [answers, setAnswers] = useState("");
  const [hint, setHint] = useState("");

  const handleEdit = (index) => {
    const { question, choices, answers, hint } = props.rows[index];
    setQuestion(question);
    setChoices(choices);
    setAnswers(answers);
    setEditState(index);
    setHint(hint);
  }

  const handleConfirm = (id) => {
    const newRow = {
      question,
      choices: String(choices).split(","),
      answers: String(answers).split(","),
      hint
    };
    props.onEdit(id, newRow);
    setEditState(-1);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Question</TableCell>
            <TableCell align="left">Choices</TableCell>
            <TableCell align="left">Answers</TableCell>
            <TableCell align="left">Hint</TableCell>
            <TableCell align="left">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            editState === index ?
              <TableRow key={index}>
                <TableCell align="left"><TextField value={question} onChange={(e) => setQuestion(e.target.value)} /></TableCell>
                <TableCell align="left"><TextField value={choices} onChange={(e) => setChoices(e.target.value)} /></TableCell>
                <TableCell align="left"><TextField value={answers} onChange={(e) => setAnswers(e.target.value)} /></TableCell>
                <TableCell align="left"><TextField value={hint} onChange={(e) => setHint(e.target.value)} /></TableCell>
                <TableCell align="left">
                  <Button
                    key={row._id}
                    style={{ color: "white", backgroundColor: "#107896", marginBottom: "10px" }}
                    onClick={() => handleConfirm(row._id)}
                  >
                    Confirm
                  </Button>
                  <Button
                    key={row._id}
                    style={{ color: "white", backgroundColor: "#107896" }}
                    onClick={() => props.handleDelete(row._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
              :
              <TableRow key={index}>
                <TableCell align="left"><Markdown>{row.question}</Markdown></TableCell>
                <TableCell align="left">{JSON.stringify(row.choices)}</TableCell>
                <TableCell align="left">{JSON.stringify(row.answers)}</TableCell>
                <TableCell align="left">{row.hint}</TableCell>
                <TableCell align="left">
                  <Button
                    style={{ color: "white", backgroundColor: "#107896", marginRight: "10px" }}
                    onClick={() => handleEdit(index)}
                  >
                    Edit
                  </Button>
                  <Button
                    key={row._id}
                    style={{ color: "white", backgroundColor: "#107896" }}
                    onClick={() => props.handleDelete(row._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
