import { P1, P2, P3 } from 'assets/fonts';
import React, { useState, useEffect } from "react";
import { Container, TextField } from "@material-ui/core";
import axios from 'axios';

const RandomId = (props) => {
  const { id } = props;
  const [name, setName] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/randomid/" + id);
        setName(doc.data.name);
      })();
    }
  }, [id]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setName(newText);
  }

  const handleBlur = async () => {
    await axios.put("/api/gui/randomid/" + id, { name });
  }

  return (
    <Container>
      <P1>Random id</P1>
      <p>If you want to show this random id again or want to use previous random id, please give them same name.</p>
      <div>
        <p>Name: </p>
        <TextField value={name} onChange={handleChange} onBlur={handleBlur} style={{ width: "60%" }} />
      </div>
      <br /><br />
    </Container>
  )
}

export default RandomId;