import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Button, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Table from "./table";
import html2canvas from "html2canvas";
import P1 from "assets/p1";
import P2 from "assets/p2";
import { data } from "jquery";

const ExportReactCSV = ({ data, headers, fileName }) => {
  const noDotFileName = () => {
    let updatedFileName = fileName;
    if (updatedFileName.includes(".")) {
      // Remove all dots from the filename
      updatedFileName = updatedFileName.replace(/\./g, "");
    }
    return updatedFileName;
  };

  return (
    <Button variant="contained" style={{ color: "white" }}>
      <CSVLink data={data} headers={headers} filename={noDotFileName()}>
        Export All Data (CSV file)
      </CSVLink>
    </Button>
  );
};

const Database = (props) => {
  const [assigns, setAssigns] = useState(null);
  const [cleanData, setCleanData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [header, setHeader] = useState(null);
  const [allComponents, setAllComponents] = useState(null);
  const { app } = useParams();
  const [studyId, setStudyId] = useState(null);
  const participantId = useRef(null);
  const experimentId = useRef(null);
  const localStudyId = useRef(null);

  useEffect(() => {
    (async () => {
      const doc = await axios.get(`/api/gui/assign/${app}`);
      setAssigns(doc.data);
    })();
  }, []);

  const findStudyId = (data) => {
    for (let item of data) {
      const studyId = item.assigns.find((assign) => assign.type === "studyids");
      if (studyId) {
        return studyId.id;
      }
    }
    return null;
  };

  const findParticipantId = (data) => {
    data.assigns.map((assign) => {
      if (assign.type === "studyids") {
        participantId.current = assign.participantId;
      }
    });
  };

  const findExperimentId = (data) => {
    data.assigns.map((assign) => {
      if (assign.type === "studyids") {
        experimentId.current = assign.experimentId;
      }
    });
  };

  useEffect(() => {
    localStudyId.current = studyId;
  }, [studyId]);

  useEffect(async () => {
    if (!assigns) return;

    // get app information
    const appDoc = await axios.get(`/api/gui/app/${app}`);

    const studyidsId = findStudyId(assigns);

    if (studyidsId != null) {
      const studyids = await axios.get(`/api/gui/studyids/${studyidsId}`);
      setStudyId(studyids.data.studyId);
    } else {
      setStudyId(appDoc.data.id);
    }

    // get pages information
    const pages = appDoc.data.pages;
    const _pages = pages.map((page) => axios.get(`/api/gui/page/${page}`));
    const pageDocs = await Promise.all(_pages);

    // get containers
    const allComponents = [];
    const containers = [];
    const containerTypes = ["row", "column"];
    pageDocs.map((pageDoc) => {
      const components = pageDoc.data.components;
      components.map((component) => {
        if (containerTypes.includes(component.type)) {
          containers.push(component);
        } else {
          allComponents.push(component);
        }
      });
    });

    await setAllComponents(allComponents);

    // get all components
    const _containers = containers.map((container) =>
      axios.get(`/api/gui/${container.type}/${container.id}`)
    );
    const containerDocs = await Promise.all(_containers);
    containerDocs.map((containerDoc) => {
      const components = containerDoc.data.children;
      components.map((component) => {
        allComponents.push(component);
      });
    });

    // get components id array
    const ids = [];
    const types = [];
    const ignoredTypes = ["image", "pdf", "consentcheck", "puretext"];
    allComponents.map((component) => {
      if (!ignoredTypes.includes(component.type)) {
        ids.push(component.id);
        types.push(component.type);
      }
    });

    // write header
    const header = [
      "study-name",
      "study-id",
      "participant-id",
      "experiment-id",
      "start-time",
      "end-time",
    ];
    const promises = types.map(async (type, i) => {
      switch (type) {
        case "radio":
          header.push(`${i}-radio-question`, `${i}-radio-answer`);
          break;
        case "select":
        case "ageselect":
          header.push(`${i}-select-question`, `${i}-select-answer`);
          break;
        case "textfield":
          header.push(`${i}-textfield-question`, `${i}-textfield-answer`);
          break;
        case "datepicker":
          header.push(`${i}-date-question`, `${i}-date-answer`);
          break;
        case "imagerandom":
          header.push(`${i}-random-image`);
          break;
        case "slider":
          header.push(`${i}-slider-question`, `${i}-slider-answer`);
          break;
        case "ohhis":
          header.push(`${i}-OHHIS`);
          break;
        case "checkbox":
          header.push(
            `${i}-checkbox-question`,
            `${i}-checkbox-choices`,
            `${i}-checkbox-answer`
          );
          break;
        case "randomid":
          header.push(`${i}-random-id`);
          break;
        case "volumecontrol":
          header.push(`${i}-volumecontrol-title`, `${i}-volumecontrol-answer`);
          break;
        case "recoverystimulus":
          const doc1 = await axios.get(`/api/gui/recoverystimulus/${ids[i]}`);
          const numOfTrial1 = doc1.data.numOfTrial;
          header.push(`${i}-DIN-SNR`);
          for (let j = 1; j <= numOfTrial1; j++) {
            header.push(`${i}-${j}-recovery-name`, `${i}-${j}-recovery-answer`);
            header.push(
              `${i}-${j}-DIN-question`,
              `${i}-${j}-DIN-answer`,
              `${i}-${j}-audio-db`
            );
            header.push(
              `${i}-${j}-recovery-audio-start`,
              `${i}-${j}-recovery-target-start`,
              `${i}-${j}-recovery-target-end`,
              `${i}-${j}-recovery-response-start`,
              `${i}-${j}-recovery-response-end`
            );
            header.push(
              `${i}-${j}-DIN-audio-start`,
              `${i}-${j}-DIN-first-digit`,
              `${i}-${j}-DIN-second-digit`,
              `${i}-${j}-DIN-third-digit`,
              `${i}-${j}-DIN-response-end`
            );
          }
          break;
        case "digits":
          const doc2 = await axios.get(`/api/gui/digits/${ids[i]}`);
          const numOfTrial2 = doc2.data.numOfTrial;
          header.push(`${i}-DIN-SNR`);
          for (let j = 1; j <= numOfTrial2; j++) {
            header.push(
              `${i}-${j}-DIN-reacttime`,
              `${i}-${j}-DIN-realtime`,
              `${i}-${j}-DIN-question`,
              `${i}-${j}-DIN-answer`,
              `${i}-${j}-DIN-db`
            );
          }
          break;
        case "dfdexperiments":
          header.push(`playedAudio`, `clicked-button`, `decibel`, `reactTimer`);
          break;
        case "alphabetexperiment":
          header.push("question", "answer", "score", "resTime", "similarity");
          break;
        case "crm":
          break;
      }
    });
    await Promise.all(promises);
    setHeader(header);

    // get data
    const cleanData = [];
    const tableData = [];

    assigns.map((assignData) => {
      findParticipantId(assignData);
      findExperimentId(assignData);
      const oneAssign = [
        appDoc.data.name,
        localStudyId.current,
        participantId?.current || assignData.id,
        experimentId?.current,
        assignData.startAt
          ? new Date(assignData.startAt).toLocaleString("en-US", {
              timeZone: "America/Denver",
            })
          : "Not recorded",
        assignData.endAt
          ? new Date(assignData.endAt).toLocaleString("en-US", {
              timeZone: "America/Denver",
            })
          : "Not recorded",
      ];

      // sort assign via the ids order
      const sortedAssign = [];
      const components = assignData.assigns;
      for (const id of ids) {
        let found = false;
        for (const component of components) {
          if (component.id == id) {
            sortedAssign.push(component);
            found = true;
          }
        }
        if (!found) {
          sortedAssign.push({
            id,
            type: types[ids.indexOf(id)],
            code: "Not recorded",
          });
        }
      }

      // write clean data
      sortedAssign.map((component) => {
        if (component.answer === undefined) {
          // Skip this component if answer is undefined
          return;
        }
        switch (component.type) {
          case "radio":
          case "textfield":
          case "slider":
          case "datepicker":
          case "ageselect":
          case "select":
            oneAssign.push(component.question, component.answer);
            break;
          case "imagerandom":
            oneAssign.push(component.image);
            break;
          case "ohhis":
            oneAssign.push(component.code);
            break;
          case "checkbox":
            oneAssign.push(
              component.question,
              String(component.options),
              String(component.answer)
            );
            break;
          case "randomid":
            oneAssign.push(component.randomId);
            break;
          case "volumecontrol":
            oneAssign.push(component.title, component.answer);
            break;
          case "recoverystimulus":
            const { sentenceAnswers, timestamps } = component.answer;
            const SNR1 = component.answer.SNR;
            const questions1 = component.answer.questions;
            const answers1 = component.answer.answers;
            const dbs1 = component.answer.dbs;
            oneAssign.push(SNR1);
            for (let i = 0; i < answers1.length; i++) {
              const { name, answer } = sentenceAnswers[i];
              oneAssign.push(
                name,
                answer,
                questions1[i] + String.fromCharCode(8203),
                answers1[i] + String.fromCharCode(8203),
                dbs1[i]
              );
              const ts = timestamps[i];
              for (let _ of ts) {
                oneAssign.push(_);
              }
            }
            break;
          case "digits":
            if (component.answer === undefined) {
              break;
            }
            const { reactTimer, realTimer } = component?.answer;
            const SNR2 = component.answer.SNR;
            const questions2 = component.answer.questions;
            const answers2 = component.answer.answers;
            const dbs2 = component.answer.dbs;
            oneAssign.push(SNR2);
            for (let i = 0; i < answers2.length; i++) {
              oneAssign.push(
                reactTimer[i],
                realTimer[i],
                questions2[i] + String.fromCharCode(8203),
                answers2[i] + String.fromCharCode(8203),
                dbs2[i + 1]
              );
            }
            break;
          case "dfdexperiments":
            const playedAudio = component.answer.question;
            const clickedButton = component.answer.answer;
            const decibel = component.answer.decibel;
            const ReactTimer = component.answer.reactTimer;
            oneAssign.push(playedAudio, clickedButton, decibel, ReactTimer);
            break;
          case "crm":
            break;
        }
      });
      cleanData.push(oneAssign);

      // if (participantId.current == null) {
      //   participantId.current = assignData.id;
      // }

      // write table data
      tableData.push({
        experimentId: experimentId.current,
        participantId: participantId.current,
        id: assignData.id,
        endAt: assignData.endAt,
      });
    });

    setCleanData(cleanData);
    setTableData(tableData);
  }, [assigns]);

  const handleDeleteData = async (id) => {
    await axios.delete("/api/gui/assign/" + id);
    window.location.reload();
  };

  const renderConfusionMatrixTable = (confusionMatrix, id) => {
    const buttonLabels = [
      "aBil",
      "aCHil",
      "aDil",
      "aFil",
      "aGil",
      "aHil",
      "aJil",
      "aKil",
      "aLil",
      "aMil",
      "aNil",
      "aPil",
      "aRil",
      "aSHil",
      "aSil",
      "aTHil",
      "aTil",
      "aVil",
      "aWil",
      "aYil",
      "aZil",
    ];

    if (!confusionMatrix) {
      return <p>No confusion matrix data available.</p>;
    }

    // Convert the object-based confusionMatrix to a two-dimensional array
    const matrixData = buttonLabels.map((label) => confusionMatrix[label]);

    return (
      <table id={id} style={styles.confusionMatrixTable}>
        <thead>
          <tr>
            <th style={styles.confusionMatrixHeader}></th>
            {buttonLabels.map((label, i) => (
              <th key={`header-${i}`} style={styles.confusionMatrixHeader}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {buttonLabels.map((label, i) => (
            <tr
              key={`row-${i}`}
              style={
                i % 2 === 0
                  ? styles.confusionMatrixEvenRow
                  : styles.confusionMatrixOddRow
              }
            >
              <td style={styles.confusionMatrixCell}>{label}</td>
              {matrixData[i].map((value, j) => (
                <td key={`cell-${i}-${j}`} style={styles.confusionMatrixCell}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const saveConfusionMatrixImage = (tableId, componentId) => {
    const table = document.getElementById(tableId); // Add an ID to the table element
    html2canvas(table).then(function (canvas) {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `confusion_matrix_${componentId}.png`; // Use the componentId as the filename
      link.click();
    });
  };

  const handleDFD = (assigns) => {
    if (!assigns) {
      return null; // Handle the case when `assigns` is undefined
    }

    const dfdExperiments = [];

    // Loop over assigns and sort components
    assigns.forEach((assignData) => {
      assignData.assigns.forEach((assignComponent) => {
        if (assignComponent.type === "dfdexperiments") {
          dfdExperiments.push({
            id: assignData.id,
            component: assignComponent,
          });
        } else {
          return null;
        }
      });
    });

    const confusionMatrixElements = [];

    dfdExperiments.forEach((dfdExperiment) => {
      const { component: sortedComponent, id: componentId } = dfdExperiment;
      const confusionMatrix = sortedComponent.confusionMatrix;
      const tableId = `confusion-matrix-${componentId}`; // Create a unique ID for the table element

      const accordionStyle = {
        border: "1px solid #ddd", // Add a light grey border
        borderRadius: "5px", // Rounded corners for the border
        marginBottom: "20px", // Space between each accordion item
      };

      const detailsStyle = {
        padding: "10px", // Padding inside the accordion for content
      };

      const tableStyle = {
        width: "100%", // Ensure the table uses the full width
        marginBottom: "20px", // Space below the table
        borderCollapse: "collapse", // Collapse borders between table cells
      };

      const thTdStyle = {
        border: "1px solid #ddd", // Add a light grey border around each cell
        padding: "8px", // Padding inside each cell
        textAlign: "left", // Align text to the left
      };

      const renderStyledConfusionMatrixTable = (confusionMatrix, tableId) => {
        // Assume this function renders the table with the provided styles
        // You would need to implement the styling within this function,
        // applying the tableStyle and thTdStyle to the table, th, and td elements respectively.
        return renderConfusionMatrixTable(confusionMatrix, tableId); // Placeholder
      };

      const element = (
        <div
          key={sortedComponent.id}
          style={{ ...accordionStyle, marginTop: "20px" }}
        >
          <details>
            <summary>
              <h5 style={{ marginLeft: "25px" }}>
                Confusion Matrix {componentId}
              </h5>
            </summary>
            <div style={detailsStyle}>
              {renderStyledConfusionMatrixTable(confusionMatrix, tableId)}
              <button
                style={styles.button}
                onClick={() => saveConfusionMatrixImage(tableId, componentId)}
              >
                Download Confusion Matrix
              </button>
            </div>
          </details>
        </div>
      );

      confusionMatrixElements.push(element);
    });

    return confusionMatrixElements;
  };

  const handleSubsequentAudio = (assigns) => {
    const subsequentErrors = [];

    // Loop over assigns and sort components
    assigns?.forEach((assignData) => {
      assignData.assigns.forEach((assignComponent) => {
        if (assignComponent.type === "recoverystimulus") {
          subsequentErrors.push({
            id: assignData.id,
            block: assignComponent?.block,
            trial: assignComponent?.trial,
          });
        } else {
          return null;
        }
      });
    });

    const handleDownloadAll = async (rid) => {
      try {
        // Construct the URL for the zip download endpoint
        const zipUrl = `/api/gui/recoverystimulus/downloadAll/${rid}`;

        // Trigger the zip file download
        window.location.href = zipUrl;
      } catch (error) {
        console.error("Error when trying to download the zip file:", error);
      }
    };

    return (
      <div>
        {subsequentErrors.map((subsequentError) => {
          const { id, block, trial } = subsequentError;
          return (
            <div key={id}>
              <h3>Subsequent Error Audios </h3>
              <p>
                <strong>Assign ID:</strong> {id}
              </p>
              <p>
                <strong>Block:</strong> {block}
              </p>
              <p>
                <strong>Trial:</strong> {trial}
              </p>
              {/* <button onClick={() => handleDownload(id, block, trial)}>
                Download Audio
              </button> */}
              <button onClick={() => handleDownloadAll(id)}>
                Download All Audios
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Container style={{ marginBottom: "50px", paddingTop: "5vh" }}>
      {tableData && (
        <Table
          style={{ marginTop: "20px" }}
          app={app}
          StudyId={studyId}
          data={tableData}
          deleteData={handleDeleteData}
          handleSubsequentAudio={handleSubsequentAudio}
        />
      )}
      {cleanData && header ? (
        <ExportReactCSV
          data={cleanData}
          headers={header}
          fileName={props.match.params.app}
        />
      ) : null}
      <div>{handleSubsequentAudio(assigns)}</div>
      <div>{assigns && handleDFD(assigns)}</div>
    </Container>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "60%",
    margin: "0 auto",
    marginTop: "-5%",
    tableLayout: "fixed", // Add this
  },
  tableData: {
    border: "1px solid #ccc",
    padding: "8px", // Reduce padding from 8px to 4px
    textAlign: "center",
    verticalAlign: "middle",
    width: "33.33%", // Set width to 33.33%
  },
  button: {
    backgroundColor: "#007a33", // UofA green
    border: "none",
    color: "white",
    padding: "10px 24px", // Adjust padding to 10px 24px
    textAlign: "center",
    textDecoration: "none",
    fontSize: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%", // Set button width to 100%
  },
  redButton: {
    backgroundColor: "#b34f4a",
    border: "none",
    color: "white",
    padding: "10px 24px", // Adjust padding to 10px 24px
    textAlign: "center",
    textDecoration: "none",
    fontSize: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%", // Set button width to 100%
  },
  playButton: {
    backgroundColor: "#f2cd00", // UofA green
    border: "none",
    color: "white",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex", // Change display to flex
    justifyContent: "center", // Add justifyContent for centering
    alignItems: "center", // Add alignItems for vertical alignment
    fontSize: "25px",
    cursor: "pointer",
    borderRadius: "4px",
    marginLeft: "10px", // Set marginLeft to create space between buttons
    marginRight: "auto",
    marginTop: "20px", // Reduce marginTop value to adjust height
    width: "200px", // Set a fixed width
  },

  confusionMatrixTable: {
    borderCollapse: "collapse",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    width: "80%",
    border: "1px solid #ccc",
  },
  confusionMatrixHeader: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    fontWeight: "bold",
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
  confusionMatrixEvenRow: {
    backgroundColor: "#ffffff",
  },
  confusionMatrixOddRow: {
    backgroundColor: "#f9f9f9",
  },
  confusionMatrixCell: {
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
};

export default Database;

//Archive
// const handleDownload = async (id, block, trial) => {
//   try {
//     // Request the signed URL for downloading the audio file
//     const response = await axios.get(
//       `/api/gui/recoverystimulus/downloadAudio/${id}/${block}/2`
//     );
//     const { downloadUrl } = response.data;

//     // Use the signed URL to trigger the file download
//     const link = document.createElement("a");
//     link.href = downloadUrl;
//     link.setAttribute("download", `audio_${id}_${block}_${trial}.wav`);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error("Error when trying to download the file:", error);
//   }
// };
