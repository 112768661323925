import React from "react";
import { TextField, Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExampleTable from "hearing/components/questionnaire/aphab-a/example-table";
import QuestionTable from "hearing/components/questionnaire/aphab-a/question-table";
import FillInFormTable from "hearing/components/questionnaire/aphab-a/fill-in-form-table";
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';
import FontComponent from 'assets/font-ui';
import Modal from 'hearing/components/partials/modal';


class AphabA extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      participantID: null,
      studyID: null,
      date: null,
      modalOpen: false,
      choiceQuestion: {
        "When I am in a crowded grocery store, talking with the cashier, I can follow the conversation.": {
          with: null,
          without: null
        },
        "I miss a lot of information when I’m listening to a lecture.": {
          with: null,
          without: null
        },
        "Unexpected sounds, like a smoke detector or alarm bell are uncomfortable.": {
          with: null,
          without: null
        },
        "I have difficulty hearing a conversation when I’m with one of my family at home.": {
          with: null,
          without: null
        },
        "I have trouble understanding the dialogue in a movie or at the theater.": {
          with: null,
          without: null
        },
        "When I am listening to the news on the car radio, and family members are talking, I have trouble hearing the news.": {
          with: null,
          without: null
        },
        "When I’m at the dinner table with several people, and am trying to have a conversation with one person, understanding speech is difficult.": {
          with: null,
          without: null
        },
        "Traffic noises are too loud.": {
          with: null,
          without: null
        },
        "When I am talking with someone across a large empty room, I understand the words.": {
          with: null,
          without: null
        },
        "When I am in a small office, interviewing or answering questions, I have difficulty following the conversation.": {
          with: null,
          without: null
        },
        "When I am in a theater watching a movie or play, and the people around me are whispering and rustling paper wrappers, I can still make out the dialogue.": {
          with: null,
          without: null
        },
        "When I am having a quiet conversation with a friend, I have difficulty understanding.": {
          with: null,
          without: null
        },
        "The sounds of running water, such as a toilet or shower, are uncomfortably loud.": {
          with: null,
          without: null
        },
        "When a speaker is addressing a small group, and everyone is listening quietly, I have to strain to understand.": {
          with: null,
          without: null
        },
        "When I’m in a quiet conversation with my doctor in an examination room, it is hard to follow the conversation.": {
          with: null,
          without: null
        },
        "I can understand conversations even when several people are talking.": {
          with: null,
          without: null
        },
        "The sounds of construction work are uncomfortably loud.": {
          with: null,
          without: null
        },
        "It’s hard for me to understand what is being said at lectures or church services.": {
          with: null,
          without: null
        },
        "I can communicate with others when we are in a crowd.": {
          with: null,
          without: null
        },
        "The sound of a fire engine siren close by is so loud that I need to cover my ears.": {
          with: null,
          without: null
        },
        "I can follow the words of a sermon when listening to a religious service.": {
          with: null,
          without: null
        },
        "The sound of screeching tires is uncomfortably loud.": {
          with: null,
          without: null
        },
        "I have to ask people to repeat themselves in one-on-one conversation in a quiet room.": {
          with: null,
          without: null
        },
        "I have trouble understanding others when an air conditioner or fan is on.": {
          with: null,
          without: null
        }
      },
      formQuestion: {
        "1": null,
        "2": null,
        "3": null
      },
      errorText: []
    }
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire/aphab/a' });
  };

  handleChoiceClick = (question, type, answer) => {
    const { choiceQuestion } = this.state;
    choiceQuestion[question][type] = answer;
    this.setState({ choiceQuestion });
  }

  handleCheckBoxClick = (num, choice) => {
    const { formQuestion } = this.state;
    formQuestion[String(num)] = choice;
    this.setState({ formQuestion });
  }

  checkValidation = () => {
    const { choiceQuestion, formQuestion, date } = this.state;
    const errorText = [];
    
    if (!date) {
      errorText.push("Today's Date");
    }

    Object.keys(choiceQuestion).map((q, i) => {
      if (!choiceQuestion[q].with) {
        errorText.push("#"+(i+1)+" 'with hearing aids' question");
      };
      if (!choiceQuestion[q].without) {
        errorText.push("#"+(i+1)+" 'without hearing aids' question");
      };
    });
    
    Object.keys(formQuestion).map((q, i) => {
      if (!formQuestion[q]) {
        errorText.push("#"+(i+1)+" checkbox question");
      }
    });
    
    this.setState({ errorText });
  }

  handleModalClose = () => {
    window.location = "/hearing/questionnaire";
  }

  handleSubmit = async () => {
    await axios.post('/api/hearing/aphaba/user', this.state);
    this.setState({ modalOpen: true });
  }


  render(){
    const { participantID, studyID, date, choiceQuestion, formQuestion, errorText, modalOpen } = this.state;
    return(
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        <div style={{margin: "3%"}}>
          <div id="header" style={{marginTop: "50px"}}>
            <div id="title" style={{textAlign: "center"}}>
              <P1>ABBREVIATED PROFILE OF HEARING AID BENEFIT</P1>
              <P2>FORM A</P2>
            </div>
            <div className="col" id="nameAndDate" style={{marginLeft: "30px"}}>
              <div className="col-8 row">
                <P2>Participant ID: </P2><TextField id="id" value={participantID} onChange={e => this.setState({participantID: e.target.value})} style={{width: "50%", marginBottom: "10px", marginLeft: "3px"}}/>
              </div>
              <div className="col-8 row" style={{marginTop: "10px"}}>
                <P2 style={{marginRight: "48px"}}>Study ID: </P2><TextField id="id" value={studyID} onChange={e => this.setState({studyID: e.target.value})} style={{width: "50%", marginBottom: "10px", marginLeft: "3px"}}/>
              </div>
              <div className="col-8 row" style={{marginTop: "10px"}}>
                <P2 style={{marginRight: "18px"}}>Today's date: </P2><DatePicker selected={date} onChange={date => this.setState({date})} />  
              </div>
            </div>
          </div>
          <div className="jumbotron" style={{margin: "20px"}}>
            <div id="instruction">
            <P2>INSTRUCTIONS</P2>
              <div className="row">
                <div className="col-8">
                  <P2>
                    Please circle the answers that come closest to your
                    everyday experience. Notice that each choice includes a
                    percentage. You can use this to help you decide on your
                    answer. For example, if the statement is true about 75%
                    of the time, circle C for that item. If you have not
                    experienced the situation we describe, try to think of a
                    similar situation that you have been in and respond for
                    that situation. If you have no idea, leave that item blank.
                  </P2>
                </div>
                <div className="col-4">
                  <ul  style={{ fontSize: "15px", outlineWidth: "2px", outlineColor: "black", outlineStyle: "auto" }}>
                    <P2><li><b>A</b> Always (99%)</li></P2>
                    <P2><li><b>B</b> Almost Always (87%)</li></P2>
                    <P2><li><b>C</b> Generally (75%)</li></P2>
                    <P2><li><b>D</b> Half-the-time (50%)</li></P2>
                    <P2><li><b>E</b> Occasionally (25%)</li></P2>
                    <P2><li><b>F</b> Seldom (12%)</li></P2>
                    <P2><li><b>G</b> Never (1%)</li></P2>
                  </ul>
                </div>
              </div>
            </div>
            <div id="example">
              <P2>EXAMPLE</P2>
              <P2>
                For some items, an answer of “Always (99%)” indicates few problems. Other items are
                written so that an answer of “Always (99%)” indicates a lot of problems. Here is an example.
                In item (a), below, an answer of “Always (99%)” means that you often have problems. In
                item (b), below, the same answer means that you seldom have problems.
              </P2>
              <ExampleTable />
            </div>
          </div>
          <div id="questions">
            <div id="multiple-choice">
              <QuestionTable questions={choiceQuestion} onChoiceClick={this.handleChoiceClick}/>
            </div>
            <br />
            <div id="fill-in-form">
              <P3 style={{marginLeft: "10%"}}><b><i>Please fill out these additional items.</i></b></P3>
              <FillInFormTable answers={formQuestion} onCheckBoxClick={this.handleCheckBoxClick} />
            </div>
          </div>
          <div id="error" style={{color: "red", margin: "5%"}}>
            {errorText.length !== 0 && <P2>These questions are not completed.</P2>}
            <ul>
              {
                errorText.map(error => <li><P3>{error}</P3></li>)
              }
            </ul>
          </div>
          <div id="submitButton">
            <Button 
              size="large" 
              varian="contained" 
              style={{
                color: "white", backgroundColor: "#107896",
                marginLeft: "45%", marginTop: "20px", marginBottom: "20px"
              }}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
        <FontComponent />
        <Modal
          open={modalOpen}
          onClose={this.handleModalClose}
        />
      </div>
    )
  }
}

export default AphabA;