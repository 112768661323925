const initialState = {
  isAuthorized: false,
};

const authorizedReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_AUTHORIZED":
      return {
        ...state,
        isAuthorized: action.payload,
      };
    default:
      return state;
  }
};

export const setUserAuthorized = (isAuthorized) => ({
  type: "SET_USER_AUTHORIZED",
  payload: isAuthorized,
});

export default authorizedReducer;
