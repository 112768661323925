import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Container } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

export default function CheckBox(props) {
  const { id, updateAssign, readyToContinue } = props;
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/consentcheck/" + id);
        setQuestions(doc.data.questions);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(() => {
    handleUpdateAssign();
  }, [answer]);

  useEffect(() => {
    if (answer.length !== 0 && answer.length === questions.length) {
      readyToContinue(id);
    }
  }, [answer]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "consentcheck",
      questions,
      answer,
    };
    updateAssign(assign);
  };

  const handleClick = (choice) => {
    if (answer.includes(choice)) {
      const newAnswer = [];
      answer.map((ans) => {
        if (ans !== choice) newAnswer.push(ans);
      });
      setAnswer(newAnswer);
    } else {
      const newAnswer = [...answer];
      newAnswer.push(choice);
      setAnswer(newAnswer);
    }
  };

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>
        Please check all consent questions below:
      </P2>
      <br />
      {questions.map((q, i) => (
        <div key={i}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={answer.includes(q)}
                onClick={() => handleClick(q)}
              />
            }
            label={
              <P3 style={{ marginLeft: "10px", marginTop: "10px" }}>{q}</P3>
            }
          />
        </div>
      ))}
      <br />
    </Container>
  );
}
