import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { decreaseFont, increaseFont } from 'redux/font/font-actions';


const FontComponent = (props) => {
  switch(props.position) {
    case "bottomRight":
      return (
        <div style={{ position: "fixed", zIndex: 1, right: "10px", bottom: "10px"}}>
          <h5>Font Size</h5>
          <Button size="small" variant="outlined" onClick={props.decreaseFont}>-</Button><br />
          <Button size="small" variant="outlined" onClick={props.increaseFont}>+</Button>
        </div>
      );
    default:
      return (
        <div style={{ position: "fixed", zIndex: 1, right: "10px", top: "10px"}}>
          <h5>Font Size</h5>
          <Button size="small" variant="outlined" onClick={props.decreaseFont}>-</Button><br />
          <Button size="small" variant="outlined" onClick={props.increaseFont}>+</Button>
        </div>
      );
  };
};

const mapDispatchToProps = (dispatch) => ({
  decreaseFont: () => dispatch(decreaseFont()),
  increaseFont: () => dispatch(increaseFont()),
});

export default connect(null, mapDispatchToProps)(FontComponent);
