import React, { useState } from 'react';
import { CircularProgress, Avatar, Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';
import { HearingAWS } from 'assets/keys';
import { useEffect } from 'react';
import Progress from 'GUI/assets/progress';
import axios from 'axios';

const LoadStage = {
  LOADING: "loading",
  MIDDLE_PAGE: "middle_page",
  PAUSE: "pause",
  START: "start"
};

const CRMExperiment = (props) => {
  const { id, updateAssign, onNext, blocksOrder, updateBlocksOrder, volumecontrol } = props;
  // data from backend
  const [numOfReversal, setNumOfReversal] = useState(null);
  const [numOfRow, setNumOfRow] = useState(null);
  const [numOfColumn, setNumOfColumn] = useState(null);
  const [colors, setColors] = useState("");
  const [numOfUp, setNumOfUp] = useState(null);
  const [numOfDown, setNumOfDown] = useState(null);
  const [initLossAudios, setInitLossAudios] = useState(null);
  const [lossAudios, setLossAudios] = useState(null);
  const [initOriginalAudios, setInitOriginalAudios] = useState(null);
  const [originalAudios, setOriginalAudios] = useState(null);
  const [initMaskAudios, setInitMaskAudios] = useState(null);
  const [maskAudios, setMaskAudios] = useState(null);
  const [singleAudio, setSingleAudio] = useState(null);
  const [isSingleMask, setIsSingleMask] = useState(null);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [blockTypes, setBlockTypes] = useState(null);
  const [withPause, setWithPause] = useState(false);

  // local variables
  const [questions, setQuestions] = useState([]);
  const [userStart, setUserStart] = useState(false);
  const [pause, setPause] = useState(false);
  const [reversal, setReversal] = useState(1);
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [correct, setCorrect] = useState([]);
  const [lastCorrectness, setLastCorrectness] = useState(null);
  const [dbs, setDbs] = useState([0]);
  const [sourceVolume, setSourceVolume] = useState(volumecontrol.answer);
  const [loadStage, setLoadStage] = useState(LoadStage.LOADING);
  const [SNR, setSNR] = useState(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  const resetStates = () => {
    setNumOfReversal(null);
    setNumOfRow(null);
    setNumOfColumn(null);
    setColors("");
    setNumOfUp(null);
    setNumOfDown(null);
    setLossAudios(null);
    setOriginalAudios(null);
    setMaskAudios(null);
    setSingleAudio(null);
    setIsSingleMask(null);
    setBlockTypes(null);
    setNumOfCurrBlock(null);
    setNumOfTotalBlocks(null);
    setQuestions([]);
    setUserStart(false);
    setPause(false);
    setReversal(1);
    setTime(0);
    setTimer([]);
    setAnswers([]);
    setCorrect([]);
    setLastCorrectness(null);
    setDbs([0]);
    setSourceVolume(volumecontrol.answer);
    setLoadStage(LoadStage.LOADING);
    setSNR(null);
    setHasPlayed(false);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        // save all audios and variables
        const doc = await axios.get("/api/gui/crm/" + id);
        setNumOfReversal(doc.data.numOfReversal);
        setNumOfRow(doc.data.numOfRow);
        setColors(doc.data.colors);
        setNumOfColumn(doc.data.numOfColumn);
        setNumOfUp(doc.data.numOfUp);
        setNumOfDown(doc.data.numOfDown);
        setInitLossAudios(doc.data.lossAudios);
        setInitOriginalAudios(doc.data.originalAudios);
        setInitMaskAudios(doc.data.maskAudios);
        setSingleAudio(new Audio(HearingAWS + doc.data.singleAudio));
        setIsSingleMask(doc.data.isSingleMask);
        setBlockTypes(doc.data.blockTypes);
        setNumOfCurrBlock(doc.data.numOfCurrBlock);
        setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
        setWithPause(doc.data.withPause ?? false);
      })();
    }
  }, [id]);

  useEffect(async () => {
    if (loadStage == LoadStage.LOADING) {
      if (initLossAudios && initOriginalAudios) {
        if (isSingleMask === true) {
          if (singleAudio) {
            const newLossAudios = [];
            initLossAudios.map((row) => {
              const newRow = [];
              row.map((audioUrl) => {
                if (audioUrl != "") {
                  const audio = new Audio(HearingAWS + audioUrl);
                  newRow.push(audio);
                  audio.preload = "on";
                }
              });
              newLossAudios.push(newRow);
            });
            const newOriginalAudios = [];
            initOriginalAudios.map((row) => {
              const newRow = [];
              row.map((audioUrl) => {
                if (audioUrl != "") {
                  const audio = new Audio(HearingAWS + audioUrl);
                  newRow.push(audio);
                  audio.preload = "on";
                }
              });
              newOriginalAudios.push(newRow);
            });
            const newSingleAudio = singleAudio;
            newSingleAudio.preload = "on";
            // set loading to false first
            await setLoadStage(LoadStage.START);
            // then update all audios
            await setSingleAudio(newSingleAudio)
            await setLossAudios(newLossAudios);
            await setOriginalAudios(newOriginalAudios);
          }
        } else if (isSingleMask === false) {
          if (initMaskAudios) {
            const newMaskAudios = [];
            initMaskAudios.map((row) => {
              const newRow = [];
              row.map((audioUrl) => {
                if (audioUrl != "") {
                  const audio = new Audio(HearingAWS + audioUrl);
                  newRow.push(audio);
                  audio.preload = "on";
                }
              });
              newMaskAudios.push(newRow);
            });
            const newLossAudios = [];
            initLossAudios.map((row) => {
              const newRow = [];
              row.map((audioUrl) => {
                if (audioUrl != "") {
                  const audio = new Audio(HearingAWS + audioUrl);
                  newRow.push(audio);
                  audio.preload = "on";
                }
              });
              newLossAudios.push(newRow);
            });
            const newOriginalAudios = [];
            initOriginalAudios.map((row) => {
              const newRow = [];
              row.map((audioUrl) => {
                if (audioUrl != "") {
                  const audio = new Audio(HearingAWS + audioUrl);
                  newRow.push(audio);
                  audio.preload = "on";
                }
              });
              newOriginalAudios.push(newRow);
            });
            // set loading to false first
            await setLoadStage(LoadStage.START);
            // then update all audios
            await setLossAudios(newLossAudios);
            await setOriginalAudios(newOriginalAudios);
            await setMaskAudios(newMaskAudios);
          }
        }
      }
    }
  }, [initLossAudios, initOriginalAudios, isSingleMask, initMaskAudios, singleAudio, loadStage]);

  useEffect(() => {
    // generate the order of 4 sections
    if (blockTypes) {
      const types = blockTypes.split(",");
      if (types[types.length - 1].includes("?")) {
        updateBlocksOrder(shuffle(types));
      } else {
        updateBlocksOrder(types);
      }
    }
  }, [blockTypes]);

  useEffect(async () => {
    if (blocksOrder && numOfCurrBlock && !hasPlayed && loadStage == LoadStage.START && lossAudios?.[0]?.[0] && originalAudios?.[0]?.[0] && (maskAudios?.[0]?.[0] || (isSingleMask && singleAudio))) {
      console.log("how many times, the audio played?");
      setHasPlayed(true);
      playAudio();
    }
  }, [lossAudios, originalAudios, maskAudios, singleAudio, isSingleMask, loadStage, hasPlayed, blocksOrder, numOfCurrBlock]);

  useEffect(async () => {
    if (SNR) {
      console.log({
        type: blocksOrder[numOfCurrBlock - 1],
        SNR,
        timer,
        dbs,
        questions,
        answers,
        correct
      });
      await updateAssign({
        id,
        type: "crm",
        answer: {
          type: blocksOrder[numOfCurrBlock - 1],
          SNR,
          timer,
          dbs,
          questions,
          answers,
          correct
        }
      });
      resetStates()
      await onNext();
      setLoadStage(LoadStage.LOADING);
    }
  }, [SNR]);

  const startTimer = () => {
    setTime(new Date().getTime());
  };

  const stopTimer = (isSave) => {
    const timeGap = new Date().getTime() - time;
    setTime(timeGap);
    if (isSave) {
      setTimer(timer => [...timer, timeGap]);
    }
  };

  const shuffle = (array) => {
    const newArray = [...array];
    let currentIndex = newArray.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = newArray[currentIndex];
      newArray[currentIndex] = newArray[randomIndex];
      newArray[randomIndex] = temporaryValue;
    }
    return newArray;
  };

  const handlePause = () => {
    setPause(true);
    if (userStart) {
      stopTimer(false);
    };
  };

  const handleResume = () => {
    setPause(false);
    if (userStart) {
      setTime(time => (new Date().getTime()) - time);
    }
  }

  const playAudio = async () => {
    // randomize an audio and save the question
    const row = Math.ceil(Math.random() * 4).toString();
    const col = Math.ceil(Math.random() * 8).toString();
    const newQuestions = [...questions];
    newQuestions.push(row + col)
    setQuestions(newQuestions);

    let sourceAudio, maskAudio;
    // play source and mask audios
    if (blocksOrder[numOfCurrBlock - 1] === "original" || blocksOrder[numOfCurrBlock - 1] === "original?") {
      sourceAudio = originalAudios[row - 1][col - 1];
    } else {
      sourceAudio = lossAudios[row - 1][col - 1];
    }
    sourceAudio.volume = sourceVolume / 100;
    if (isSingleMask) {
      maskAudio = singleAudio;
    } else {
      maskAudio = maskAudios[row - 1][col - 1];
    }
    maskAudio.volume = volumecontrol.answer / 100;
    await sourceAudio.play();
    await maskAudio.play();

    setTimeout(() => {
      sourceAudio.pause();
      if (isSingleMask) {
        maskAudio.pause();
      } else {
        maskAudio.load();
      }
      startTimer();
      setUserStart(true);
    }, sourceAudio.duration * 1000);
  };

  const goEasier = () => {
    setDbs(dbs => [...dbs, dbs[dbs.length - 1] + numOfUp]);
    if (sourceVolume * 10 ** (numOfUp / 20) >= 100) {
      return 100;
    } else {
      return sourceVolume * 10 ** (numOfUp / 20);
    }
  };

  const goHarder = () => {
    setDbs(dbs => [...dbs, dbs[dbs.length - 1] - numOfDown]);
    return sourceVolume * 10 ** (-numOfDown / 20);
  };

  const handleClick = async (num) => {
    // stop timer and push time and answer
    setUserStart(false);
    stopTimer(true);
    setAnswers(answers => [...answers, num]);

    // decide how to change decibel
    if (String(questions[questions.length - 1]) === String(num)) {
      setCorrect(correct => [...correct, true]);
      if (lastCorrectness === false) {
        console.log("RIGHT, reverse", reversal + 1, "times!")
        setReversal(reversal => reversal + 1);
      }
      setLastCorrectness(true);
      setSourceVolume(goHarder());
    } else {
      setCorrect(correct => [...correct, false]);
      if (lastCorrectness === true) {
        console.log("WRONG, reverse", reversal + 1, "times!");
        setReversal(reversal => reversal + 1);
      }
      setLastCorrectness(false);
      setSourceVolume(goEasier());
    }
    if (reversal >= numOfReversal) {
      let sum = 0;
      if (dbs.length >= 10) {
        for (let i = dbs.length - 10; i < dbs.length; i++) {
          sum += dbs[i];
        }
      } else {
        for (let i = 0; i < dbs.length; i++) {
          sum += dbs[i];
        }
      }
      const SNR = Number(sum / 10).toFixed(2);
      setSNR(SNR);
    } else if (withPause && (answers.length + 1) % 10 == 0) {
      setTimeout(() => {
        setLoadStage(LoadStage.PAUSE);
      }, 1000);
      setTimeout(() => {
        setLoadStage(LoadStage.START);
      }, 12000 + 1000);
      setTimeout(() => {
        this.playAudio();
      }, 12000 + 2000);
    } else {
      setTimeout(() => {
        playAudio();
      }, 2000)
    }
  }

  const renderLoadStage = () => {
    switch (loadStage) {
      case LoadStage.LOADING:
        return (
          <div style={{
            textAlign: "center",
            position: "relative",
            marginTop: "10%",
          }}><P1>Loading audios ...</P1></div>
        );
      case LoadStage.PAUSE:
        return (
          <div style={{
            textAlign: "center",
            position: "relative",
            marginTop: "10%",
          }}><P1>Please take a break</P1></div>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      {
        loadStage === LoadStage.START
          ?
          <div>
            <div id="buttons" style={{ position: "fixed", top: 0, left: 0, height: "80%", width: "100%", backgroundColor: "#C2CAD0" }}>
              {(Array(numOfRow).fill("")).map((ele, i) => {
                return <div className="row" key={i} style={{ height: 100 / numOfRow + "%" }}>
                  {(Array(numOfColumn).fill("")).map((ele, j) => <button key={j} disabled={!userStart || pause} value={String(i + 1) + String(j + 1)} onClick={(e) => handleClick(e.target.value)} style={{ color: colors.split(",")[i], border: "none", background: "none", width: 100 / numOfColumn + "%", fontSize: 80 }}>{j + 1}</button>)}
                </div>;
              })}
            </div>
            <div id="progress" className="row" style={{ position: "fixed", left: "5%", right: "5%", bottom: "50px" }}>
              <Progress value={reversal / numOfReversal * 100} />
              {/* {Array(numOfReversal).fill("").map((e, i) => <Avatar key={i} style={{ width: 20, height: 20, border: "solid", borderColor: "#107896", backgroundColor: reversal > i ? "#107896" : "white" }}>⠀</Avatar>)} */}
              <P3 style={{ marginLeft: "2%" }}>{numOfCurrBlock}/{numOfTotalBlocks} Block</P3>
              <div style={{ marginLeft: "2%", marginBottom: "2%" }}>
                {
                  userStart ?
                    pause ?
                      <Button style={{ marginLeft: 10, marginBottom: 10, color: "white", backgroundColor: "#107896" }} variant="outlined" onClick={handleResume}>Resume</Button>
                      :
                      <Button style={{ marginLeft: 10, marginBottom: 10, color: "white", backgroundColor: "#107896" }} variant="outlined" onClick={handlePause}>Pause</Button>
                    :
                    <Button style={{ marginLeft: 10, marginBottom: 10, color: "white", backgroundColor: "#107896" }} disabled variant="outlined" >Pause</Button>
                }
              </div>
            </div>
            <div style={{ position: "fixed", left: "5%", right: "5%", bottom: "10px" }}>
              <P3>The progress bar may not advance with each trial. This is normal. Please do not adjust your computer volume, or hearing aids (if applicable), during the test.</P3>
            </div>
          </div>
          :
          renderLoadStage()
      }
    </div>
  )
};

export default CRMExperiment;
