import React from "react";
import {
  Container,
  Button
} from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';


const WearAids = (props) => {

  return (
    <Container>
      <div style={{ marginTop: "20%", paddingLeft: "10%", paddingRight: "10%" }}>
        <P2>{props.withAids ? "Please wear hearing aids." : "Please do not wear hearing aids."}</P2>
        <P2>Please click NEXT when you are ready to go with the experiment.</P2>
        <br />
        <Button size="large" color="primary" variant="contained" onClick={props.handleClick}>Next</Button>
      </div>
      
    </Container>
  );
}

export default WearAids;
