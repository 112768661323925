//TODO: beta test dfd is okay but actual dfd plays more than 21 audios

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CountDown from "hearing/assets/digits/count-down";
import { Container, Button, Fab } from "@material-ui/core";
import { AiOutlineSound } from "react-icons/ai"; //sound icon
import { SlArrowRightCircle } from "react-icons/sl";
import { FaStop } from "react-icons/fa";
import Dialog from "GUI/assets/dialog";
import { HearingAWS } from "assets/keys";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";
import { useDispatch } from "react-redux";

const RenderTable = ({
  answer,
  setAnswer,
  handleUpdateAssign,
  isTimerActive,
  stopReactTimer,
  setLoadStage,
  LoadStage,
  clickedButtons,
  setClickedButtons,
  updateConfusionMatrix,
}) => {
  const buttonLabels = [
    "aBil",
    "aCHil",
    "aDil",
    "aFil",
    "aGil",
    "aHil",
    "aJil",
    "aKil",
    "aLil",
    "aMil",
    "aNil",
    "aPil",
    "aRil",
    "aSHil",
    "aSil",
    "aTHil",
    "aTil",
    "aVil",
    "aWil",
    "aYil",
    "aZil",
  ];

  const rows = 7;
  const cols = 3;

  let table = [];

  useEffect(() => {
    if (clickedButtons.length >= 21) {
      updateConfusionMatrix();
      setLoadStage(LoadStage.DONE);
      handleUpdateAssign();
    }
  }, [clickedButtons]);

  const handleButtonClick = (index) => {
    setClickedButtons((prevClickedButtons) => {
      const updatedButtons = [...prevClickedButtons, index];
      setAnswer((prevAnswer) => [...prevAnswer, buttonLabels[index]]);
      if (isTimerActive) {
        stopReactTimer();
      }
      return updatedButtons;
    });
  };

  const [tempClickedButton, setTempClickedButton] = useState(null);

  useEffect(() => {
    let timer;
    if (tempClickedButton !== null) {
      timer = setTimeout(() => {
        setTempClickedButton(null);
      }, 2000);
    }
    return () => clearTimeout(timer); // This is the cleanup function to clear the timeout if the component unmounts.
  }, [tempClickedButton]);

  for (let i = 0; i < rows; i++) {
    let row = [];
    for (let j = 0; j < cols; j++) {
      const index = i * cols + j;
      if (index < buttonLabels.length) {
        const style =
          index === tempClickedButton ? styles.pressedButton : styles.button;
        row.push(
          <td key={`${i}-${j}`} style={styles.tableData}>
            <Button
              style={style}
              onClick={() => {
                setTempClickedButton(index);
                handleButtonClick(index);
              }}
            >
              {buttonLabels[index]}
            </Button>
          </td>
        );
      }
    }
    table.push(<tr key={i}>{row}</tr>);
  }

  return (
    <table style={styles.table}>
      <tbody>{table}</tbody>
    </table>
  );
};

const DfdExperiment = (props) => {
  const dispatch = useDispatch();

  const { id, updateAssign, digits } = props;

  const [audioVolume, setAudioVolume] = useState(0);
  const [noiseVolume, setNoiseVolume] = useState(0);

  const [version, setVersion] = useState(0);

  const [decibel, setDecibel] = useState(0);
  const [question, setQuestion] = useState([]);
  const [answer, setAnswer] = useState([]);

  const LoadStage = {
    INSTRUCTION: "instruction",
    LOADING: "loading",
    DECIBEL: "decibel",
    TEST: "test",
    DONE: "done",
  };

  const [loadStage, setLoadStage] = useState(LoadStage.INSTRUCTION);

  const [optional, setOptional] = useState(true);

  const [reactTimer, setReactTimer] = useState([]);

  const [time, setTime] = useState(null);

  const [isTimerActive, setIsTimerActive] = useState(false);

  const [noiseCount, setNoiseCount] = useState(0);

  const [noiseUrl, setNoiseUrl] = useState(null);

  const startTimer = () => {
    setTime(new Date().getTime());
    setIsTimerActive(true);
  };

  const stopReactTimer = () => {
    setReactTimer((arr) => [...arr, new Date().getTime() - time]);
    console.log("reactTimer", reactTimer);
    setIsTimerActive(false);
    startTimer();
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/dfdexperiments/" + id);
        setVersion(doc.data.version);
        setOptional(doc.data.optional);
        setNoiseUrl(doc.data.noise);
      })();
    }
  }, [id]);

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (answer.length === 21 && question.length === 21) {
      handleUpdateAssign();
    }
  }, [answer]);

  const submittedRef = useRef(false);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "dfdexperiments",
      confusionMatrix,
      answer: { question, answer, decibel, reactTimer },
    };
    updateAssign(assign);
    if (!submittedRef.current) {
      submittedRef.current = true; // Update ref immediately
      dispatch(readyToContinueRedux(id));
    }
  };

  const buttonLabels = [
    "aBil",
    "aCHil",
    "aDil",
    "aFil",
    "aGil",
    "aHil",
    "aJil",
    "aKil",
    "aLil",
    "aMil",
    "aNil",
    "aPil",
    "aRil",
    "aSHil",
    "aSil",
    "aTHil",
    "aTil",
    "aVil",
    "aWil",
    "aYil",
    "aZil",
  ];

  const audioRef = useRef();

  //noiseaudioref
  const noiseAudioRef = useRef();

  const decibelToSigmoid = (decibel) => {
    // Sigmoid function that maps decibel to a scale between 0 and 1
    return 1 / (1 + Math.exp(-0.1 * (decibel - 15))); // This centers the "steep part" of the sigmoid around 15 dB
  };

  const decibelToLinear = (decibel, audioType) => {
    let multiplier = decibelToSigmoid(decibel);
    let calculatedVolume = 0.2 * multiplier;
    calculatedVolume = Math.min(calculatedVolume, 1); // Cap the volume at 1

    console.log("multiplier", multiplier);
    console.log("calculated volume", calculatedVolume);
    if (audioType === "audio") {
      if (decibel > 15) {
        return setAudioVolume(1);
      } else if (decibel <= 15 && decibel > 0) {
        return setAudioVolume(calculatedVolume);
      } else if (decibel <= 0) {
        return setAudioVolume(0);
      }
    }
    if (audioType === "noise") {
      if (decibel > 15) {
        return setNoiseVolume(1);
      } else if (decibel <= 15 && decibel > 0) {
        return setNoiseVolume(calculatedVolume);
      } else if (decibel <= 0) {
        return setNoiseVolume(0);
      }
    }
  };

  // handle decibel change function
  const handleNoiseChange = (event) => {
    //get the input value
    const decibel = event.target.value;
    console.log("decibel", decibel);
    //convert decibel to linear
    decibelToLinear(decibel, "noise");
  };

  const handleAudioChange = (event) => {
    //get the input value
    const decibel = event.target.value;
    decibelToLinear(decibel, "audio");
  };

  useEffect(() => {
    console.log("audioVolume", audioVolume);
    console.log("noiseVolume", noiseVolume);
  }, [audioVolume, noiseVolume]);

  // handle decibel submit function
  const handleDecibelSubmit = (event) => {
    event.preventDefault();
    setLoadStage(LoadStage.TEST);
  };

  // play noise file function
  const playNoise = () => {
    if (noiseUrl != null) {
      noiseAudioRef.current = new Audio(HearingAWS + noiseUrl);
      noiseAudioRef.current.loop = true; // Enable looping
      noiseAudioRef.current.volume = noiseVolume;
    } else {
      if (!noiseAudioRef.current) {
        const noiseFiles = "noise.wav";
        const noise =
          process.env.PUBLIC_URL + "/hearing/functional-audio/" + noiseFiles;
        noiseAudioRef.current = new Audio(noise);
        noiseAudioRef.current.loop = true; // Enable looping
        noiseAudioRef.current.volume = noiseVolume;
      }
    }

    noiseAudioRef.current.play();
  };

  //stop noise function
  const stopNoise = () => {
    if (noiseAudioRef.current) {
      noiseAudioRef.current.pause();
      noiseAudioRef.current.currentTime = 0;
    }
  };

  const audioFiles = {
    1: "ABILF.WAV",
    2: "ACHILF.WAV",
    3: "ADILF.WAV",
    4: "AFILF.WAV",
    5: "AGILF.WAV",
    6: "AHILF.WAV",
    7: "AJILF.WAV",
    8: "AKILF.WAV",
    9: "ALILF.WAV",
    10: "AMILF.WAV",
    11: "ANILF.WAV",
    12: "APILF.WAV",
    13: "ARILF.WAV",
    14: "ASHILF.WAV",
    15: "ASILF.WAV",
    16: "ATHILF.WAV",
    17: "ATILF.WAV",
    18: "AVILF.WAV",
    19: "AWILF.WAV",
    20: "AYILF.WAV",
    21: "AZILF.WAV",
  };
  const [playedAudio, setPlayedAudio] = useState([]);

  const [confusionMatrix, setConfusionMatrix] = useState(() => {
    const matrix = {};
    buttonLabels.forEach((label) => {
      matrix[label] = Array(buttonLabels.length).fill(0);
    });
    return matrix;
  });

  useEffect(() => {
    console.log("playedAudio", playedAudio);
  }, [playedAudio]);

  const updateConfusionMatrix = () => {
    const newConfusionMatrix = { ...confusionMatrix };
    clickedButtons.forEach((clickedButtonIndex, i) => {
      const playedAudioIndex = parseInt(playedAudio[i]) - 1;
      newConfusionMatrix[buttonLabels[playedAudioIndex]][clickedButtonIndex]++;
    });
    setConfusionMatrix(newConfusionMatrix);
    console.log("confusionMatrix", confusionMatrix);
  };

  const [clickedButtons, setClickedButtons] = useState([]);

  const playAudio = async () => {
    //play audio file
    if (playedAudio.length === 0 || playedAudio.length >= 21) {
      return;
    } else {
      const selectedAudio = audioFiles[playedAudio[playedAudio.length - 1]];
      console.log("selectedAudio", selectedAudio);
      audioRef.current = new Audio();
      const audio =
        process.env.PUBLIC_URL + "/hearing/dfd-stimulus/" + selectedAudio;
      audioRef.current.src = audio;
      if (decibel == 0) {
        var AudioVolume = digits.answer.audioVolume;
        audioRef.current.volume = AudioVolume;
      } else {
        audioRef.current.volume = audioVolume;
      }
      audioRef.current.play();
      startTimer();
    }
  };

  const playNoiseAndRandomAudio = async () => {
    // only the first time play noise
    if (noiseCount === 0) {
      if (noiseAudioRef.current != null) {
        await stopNoise();
      }
      console.log("play noise");
      await playNoise();
      setNoiseCount(1);
    }

    //wait for 1 second
    await new Promise((resolve) => setTimeout(resolve, 2000));

    //play audio files
    await playAudio();

    await new Promise((resolve) => setTimeout(resolve, 2000));
    setNoiseCount(0);
  };

  const countDownDisplay = () => {
    //handle decibel value from digits
    if (digits == null) {
      setDecibel(null);
      setLoadStage(LoadStage.DECIBEL);
    } else {
      const last_value = digits.answer.dbs.length - 1; // Or any default value you want to assign when digits.answer or digits.answer.dbs is not available
      console.log(
        "Decibel value acquired from digits: ",
        digits.answer.dbs[last_value]
      );
      setDecibel(digits.answer.dbs[last_value]);
      setLoadStage(LoadStage.TEST);
    }
  };

  const finishTestScreen = () => {
    return (
      <div style={{ marginTop: "0vh" }}>
        <h1>Test is now complete</h1>
        <h3 style={{ marginTop: "5vh" }}>
          Thank you for participating 🎉 Please press the "SUBMIT ✅" button in
          the right corner to submit your results.
        </h3>
      </div>
    );
  };

  const StartHandler = () => {
    setLoadStage(LoadStage.LOADING);
  };

  const finishTest = () => {
    stopNoise();
    updateConfusionMatrix();
    setLoadStage(LoadStage.DONE);
  };

  const displayAnswersCount = () => {
    return (
      <div style={{ marginTop: "1vh" }}>
        <h3>Button Pressed</h3>
        <h3>{answer.length} of 21</h3>
      </div>
    );
  };
  const displaySoundPlayed = () => {
    return (
      <div style={{ marginTop: "1vh" }}>
        <h3>Sound Played</h3>
        <h3>{playedAudio.length} of 21</h3>
      </div>
    );
  };

  const handleNextSound = () => {
    displaySoundPlayed();
    if (playedAudio.length >= 21 && answer.length >= 21) {
      finishTest();
    } else {
      if (version === 1) {
        const audioKeys = Object.keys(audioFiles);
        const availableKeys = audioKeys.filter(
          (key) => !playedAudio.includes(key)
        );
        if (availableKeys.length === 0) {
          console.log("All audios have been played");
          return;
        }
        const randomKey =
          availableKeys[Math.floor(Math.random() * availableKeys.length)];
        setPlayedAudio((prevPlayedAudio) => [...prevPlayedAudio, randomKey]);
        setQuestion((prevQuestion) => [
          ...prevQuestion,
          buttonLabels[randomKey - 1],
        ]);
      } else if (version === 2) {
        const audioKeys = Object.keys(audioFiles);
        const randomKey =
          audioKeys[Math.floor(Math.random() * audioKeys.length)];
        setPlayedAudio((prevPlayedAudio) => [...prevPlayedAudio, randomKey]);
        setQuestion((prevQuestion) => [
          ...prevQuestion,
          buttonLabels[randomKey - 1],
        ]);
      } else {
        const audioKeys = Object.keys(audioFiles);
        const randomKey =
          audioKeys[Math.floor(Math.random() * audioKeys.length)];
        setPlayedAudio((prevPlayedAudio) => [...prevPlayedAudio, randomKey]);
        setQuestion((prevQuestion) => [
          ...prevQuestion,
          buttonLabels[randomKey - 1],
        ]);
      }
    }
  };

  useEffect(async () => {
    if (playedAudio.length > 0 && playedAudio.length <= 21) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await playAudio();
    }
  }, [playedAudio]);

  const testStage = {
    start: "start",
    play: "play",
    stop: "stop",
  };

  const [test, setTest] = useState(testStage.start);

  const handleClose = () => {
    setTest(testStage.play);
  };

  const userController = () => {
    switch (test) {
      case testStage.start:
        return (
          <div className="startButton" style={{ marginTop: "18vh" }}>
            <button
              className="button"
              style={{ height: "8vh", width: "20vh", fontSize: "3vh" }}
              onClick={() => {
                setTest(testStage.play);
                playNoiseAndRandomAudio();
                handleNextSound();
              }}
            >
              <span class="button-content">Start</span>
            </button>
          </div>
        );

      case testStage.play:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              flexDirection: "column",
            }}
          >
            <div
              className="nextButtons"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alightItems: "center",
                marginTop: "16vh",
              }}
            >
              <div>
                <Fab
                  onClick={handleNextSound}
                  style={{
                    transform: "scale(1.4)",
                    fontSize: "30px",
                    backgroundColor: "#f2cd00",
                  }}
                >
                  <SlArrowRightCircle></SlArrowRightCircle>
                </Fab>
                <h6 style={{ marginTop: "2vh" }}>Next Item</h6>
              </div>
              <div style={{ marginLeft: "5vh" }}>
                <Fab
                  style={{
                    transform: "scale(1.4)",
                    fontSize: "30px",
                    backgroundColor: "#f2cd00",
                  }}
                  onClick={playNoiseAndRandomAudio}
                >
                  <AiOutlineSound style={{ fontSize: "30px", padding: "" }} />
                </Fab>
                <h6 style={{ marginLeft: "0.4vh", marginTop: "2vh" }}>
                  Play Sound
                </h6>
              </div>
            </div>
            <div
              className="stopFabButton"
              style={{
                justifyItems: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "15vh",
              }}
            >
              <Fab
                onClick={() => setTest(testStage.stop)}
                style={{ backgroundColor: "red" }}
              >
                <FaStop style={{ fontSize: "30px" }} />
              </Fab>
              <h6>Stop Test</h6>
            </div>
          </div>
        );
      case testStage.stop:
        return (
          <div>
            <Dialog stopped={true} onStop={finishTest} onClose={handleClose}>
              STOP
            </Dialog>
          </div>
        );
    }
  };

  const renderPages = () => {
    switch (loadStage) {
      case LoadStage.INSTRUCTION:
        return (
          <div>
            <ul
              style={{
                fontFamily: "Arial",
                fontSize: "28px",
                textAlign: "left",
                fontWeight: "bold",
                marginLeft: "50px",
                color: "#333",
                marginTop: "-100px",
              }}
            >
              <li>Press the 'play' button to hear a sound.</li>
              <li>
                Select the button that corresponds to the sound you heard.
              </li>
              <li>
                If you're unsure, you can press the 'play' button again to hear
                the sound again.
              </li>
              <li>Press 'next' to move to the next sound.</li>
              <li>
                Continue this process until you've identified all 21 sounds.
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <div style={{ marginTop: "10%" }}>
                <button
                  className="button"
                  onClick={StartHandler}
                  style={{ height: "8vh" }}
                >
                  <span style={{ fontSize: "3vh" }} class="button-content">
                    Start the test!
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      case LoadStage.LOADING:
        return <CountDown callback={countDownDisplay} />;
      // case decibel stage
      case LoadStage.DECIBEL:
        return (
          // render decibel stage
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              padding: "40px",
              backgroundColor: "#f4f4f4",
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "#333", marginBottom: "10px" }}>
              Decibel Control
            </h1>
            <h2
              style={{ color: "#666", fontSize: "20px", marginBottom: "10px" }}
            >
              Adjust the decibel level to your preference
            </h2>
            <div>
              <h3>Noise Volume</h3>
              <input
                style={{
                  width: "100px",
                  height: "50px",
                  fontSize: "20px", // Assuming you meant for this to be 20px
                  textAlign: "center",
                  marginRight: "10px",
                  marginTop: "10px", // Reduced the marginTop for better spacing.
                  borderRadius: "5%",
                }}
                type="number"
                id="decibel"
                name="decibel"
                min="-40"
                max="30"
                onChange={handleNoiseChange}
              />
            </div>
            <div style={{}}>
              <h3>Audio Volume</h3>
              <input
                style={{
                  width: "100px",
                  height: "50px",
                  fontSize: "20px", // Assuming you meant for this to be 20px
                  textAlign: "center",
                  marginRight: "10px",
                  marginTop: "10px", // Reduced the marginTop for better spacing.
                  borderRadius: "5%",
                }}
                type="number"
                id="decibel"
                name="decibel"
                min="-40"
                max="30"
                onChange={handleAudioChange}
              />
            </div>
            <div>
              <Button
                onClick={handleDecibelSubmit}
                style={{
                  backgroundColor: "#007a33",
                  fontSize: "15px",
                  color: "white",
                  padding: "12px 20px",
                  margin: "8px 0",
                  border: "none",
                  cursor: "pointer",
                  width: "200px",
                  borderRadius: "5px",
                  marginLeft: "70%",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        );
      case LoadStage.TEST:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RenderTable
              setAnswer={setAnswer}
              answer={answer}
              handleUpdateAssign={handleUpdateAssign}
              isTimerActive={isTimerActive}
              stopReactTimer={stopReactTimer}
              LoadStage={LoadStage}
              setLoadStage={setLoadStage}
              setClickedButtons={setClickedButtons}
              clickedButtons={clickedButtons}
              updateConfusionMatrix={updateConfusionMatrix}
            />
            <div
              style={{
                border: "2px solid #e0e0e0",
                borderRadius: "10px",
                marginLeft: "-5vh",
                marginRight: "6vh",
                display: "flex",
                flexDirection: "column",
                marginTop: "-6vh",
                width: "25%",
              }}
            >
              {/* display number of sound played */}
              {displaySoundPlayed()}
              {displayAnswersCount()}
              {userController()}
            </div>
          </div>
        );
      case LoadStage.DONE:
        return <div>{finishTestScreen()}</div>;
    }
  };
  return (
    <Container
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div>{renderPages()}</div>
    </Container>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "60%",
    margin: "0 auto",
    marginTop: "-5%",
    tableLayout: "fixed", // Add this
  },
  tableData: {
    border: "1px solid #ccc",
    padding: "8px", // Reduce padding from 8px to 4px
    textAlign: "center",
    verticalAlign: "middle",
    width: "33.33%", // Set width to 33.33%
  },
  button: {
    backgroundColor: "#000000", // black colour
    border: "none",
    color: "white",
    padding: "10px 24px", // Adjust padding to 10px 24px
    textAlign: "center",
    textDecoration: "none",
    fontSize: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%", // Set button width to 100%
  },
  pressedButton: {
    backgroundColor: "#808080",
    border: "none",
    color: "white",
    padding: "10px 24px", // Adjust padding to 10px 24px
    textAlign: "center",
    textDecoration: "none",
    fontSize: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%", // Set button width to 100%
  },
  playButton: {
    backgroundColor: "#f2cd00", // UofA green
    display: "flex", // Change display to flex
    justifyContent: "center", // Add justifyContent for centering
    alignItems: "center", // Add alignItems for vertical alignment
  },
  confusionMatrixTable: {
    borderCollapse: "collapse",
    marginLeft: "-70px",
    width: "50%",
    marginTop: "-8%",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  confusionMatrixHeader: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    fontWeight: "bold",
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
  confusionMatrixEvenRow: {
    backgroundColor: "#ffffff",
  },
  confusionMatrixOddRow: {
    backgroundColor: "#f9f9f9",
  },
  confusionMatrixCell: {
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
};

export default DfdExperiment;
