import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';
import { HearingAWS } from 'assets/keys';


export default function MyApp(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    let canvasWidth = document.getElementsByClassName("react-pdf__Page")?.[0]?.offsetWidth;
    if (canvasWidth) setWidth(canvasWidth);
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handleNext = () => {
    setPageNumber(pageNumber + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    props.showConsents();
  }

  const handlePrev = () => {
    setPageNumber(pageNumber - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div id="pdf" >
      <Document
        file={HearingAWS + "crm1/docs/icf.pdf"}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page width={width} pageNumber={pageNumber} />
      </Document>


      <div style={{ textAlign: "center" }}>
        {
          pageNumber > 1 ?
            <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={handlePrev}>Previous</Button>
            :
            null
        }
        <P3>Page {pageNumber} of {numPages}</P3>
        {pageNumber < numPages ?
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={handleNext} >Next</Button>
          :
          null
        }
      </div>
    </div>
  );
}