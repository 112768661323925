import React, {createContext, useReducer} from 'react';

const initialState = { data: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'update':
      return { ...state, data: action.data };
    default:
      return state;
  }
}

export const ChartDataContext = createContext();

export function ChartDataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function setData(data) {
    dispatch({ type: 'update', data: data });
    }

  return (
    <ChartDataContext.Provider value={{ data: state.data, setData}}>
      {children}
    </ChartDataContext.Provider>
  );
}