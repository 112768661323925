import React, { useEffect, useRef, useState } from "react";
import { Input, Container, Fab } from "@material-ui/core";
import CountDown from "hearing/assets/digits/count-down";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { P1, P2, P3 } from "assets/fonts";
import { HearingAWS } from "assets/keys";
import triples from "GUI/assets/triplets";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";

const LoadStage = {
  LOADING: "loading",
  READY: "ready",
  START: "start",
  DONE: "done",
};

const DigitsInNoise = (props) => {
  const { id, updateAssign, volumecontrol } = props;
  const [noise, setNoise] = useState();
  const [noiseVolume, setNoiseVolume] = useState(volumecontrol.answer / 100);
  const [audioVolume, setAudioVolume] = useState(volumecontrol.answer / 100);
  const [realAnswer, setRealAnswer] = useState("");
  const [focus, setFocus] = useState(0);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [step, setStep] = useState(1);
  const [loadStage, setLoadStage] = useState(LoadStage.LOADING);
  const [time, setTime] = useState(0);
  const [reactTimer, setReactTimer] = useState([]);
  const [realTimer, setRealTimer] = useState([]);
  const [dbs, setDbs] = useState([0]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [okButton, setOkButton] = useState(true);
  const [initStart, setInitStart] = useState(true);
  const [digitsAudios, setDigitsAudios] = useState([]);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [numOfTrial, setNumOfTrial] = useState(null);
  const [numOfSNR, setNumOfSNR] = useState(null);
  const [easier, setEasier] = useState({
    select: null,
    numOfTrial: null,
    before: null,
    after: null,
  });
  const [harder, setHarder] = useState({
    select: null,
    numOfTrial: null,
    before: null,
    after: null,
  });
  const [useTriplets, setUseTriples] = useState(null);
  const [stillCorrect, setStillCorrect] = useState(true);
  const [readyToGo, setReadyToGo] = useState(false);
  const [gapTime, setGapTime] = useState(null);
  const [countDown, setCountDown] = useState(null);
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    (async () => {
      setLoadStage(LoadStage.LOADING);
      const doc = await axios.get("/api/gui/digits/" + props.id);
      await setNumOfCurrBlock(doc.data.numOfCurrBlock);
      await setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
      await setNumOfTrial(doc.data.numOfTrial);
      await setNoise(new Audio(HearingAWS + doc.data.noise));
      await setDigitsAudios(doc.data.digitsAudios);
      await setNumOfSNR(parseInt(doc.data.numOfSNR));
      await setEasier(doc.data.easier);
      await setHarder(doc.data.harder);
      await setGapTime(doc.data.gapTime);
      setUseTriples(doc.data.useTriplets);
    })();
  }, [props.id]);

  useEffect(() => {
    if (typeof useTriplets == "boolean" && loadStage === LoadStage.LOADING) {
      if (useTriplets) {
        setQuestions(triples.sort(() => Math.random() - 0.5));
      } else {
        showCountDown();
      }
    }
  }, [useTriplets, loadStage]);

  useEffect(() => {
    console.log("questions", questions);
    if (questions.length > 0 && loadStage === LoadStage.LOADING) {
      showCountDown();
    }
  }, [questions, loadStage]);

  useEffect(() => {
    if (step !== 1 && step <= numOfTrial) handlePlay();
  }, [step]);

  useEffect(() => {
    if (
      step === numOfTrial &&
      dbs.length === numOfTrial + 1 &&
      answers.length === numOfTrial
    ) {
      const SNR = calculateSNR();
      (async () => {
        await updateAssign({
          id: props.id,
          type: "digits",
          answer: {
            SNR,
            reactTimer,
            realTimer,
            questions,
            answers,
            dbs,
            audioVolume,
            noiseVolume,
          },
        });
        setReadyToGo(true);
      })();
    }
  }, [step, numOfTrial, dbs, answers]);

  useEffect(() => {
    if (readyToGo) {
      (async () => {
        await reset();
        dispatch(readyToContinueRedux(id));
      })();
    }
  }, [readyToGo]);

  useEffect(() => {
    if (loadStage === LoadStage.START) {
      switch (focus) {
        case 0:
          return input1Ref.current.focus();
        case 1:
          return input2Ref.current.focus();
        case 2:
          return input3Ref.current.focus();
      }
    }
  }, [loadStage, focus]);

  const calculateSNR = () => {
    let sum = 0;
    for (let i = numOfSNR ? numOfSNR : 4; i < dbs.length; i++) {
      sum += dbs[i];
    }
    return Number(sum / (dbs.length - (numOfSNR ? numOfSNR : 4))).toFixed(3);
  };

  const reset = () => {
    setNoise(new Audio());
    setNoiseVolume(props.audioVolume / 100);
    setAudioVolume(props.audioVolume / 100);
    setRealAnswer("");
    setFocus(0);
    setInput1("");
    setInput2("");
    setInput3("");
    setStep(1);
    setLoadStage(LoadStage.DONE);
    setTime(0);
    setReactTimer([]);
    setRealTimer([]);
    setDbs([0]);
    setDigitsAudios([]);
    setQuestions([]);
    setAnswers([]);
    setOkButton(true);
    setInitStart(true);
    setNumOfCurrBlock(0);
    setNumOfTotalBlocks(0);
    setNumOfTrial(0);
    setNumOfSNR(null);
    setEasier({ select: null, numOfTrial: null, before: null, after: null });
    setHarder({ select: null, numOfTrial: null, before: null, after: null });
    setUseTriples(null);
    setStillCorrect(true);
    setReadyToGo(false);
  };

  const showCountDown = () => {
    setLoadStage("ready");
    setTimeout(() => {
      setLoadStage(LoadStage.START);
      handlePlay();
    }, 3000);
  };

  const startTimer = () => {
    setTime(new Date().getTime());
  };

  const stopReactTimer = () => {
    setReactTimer((arr) => [...arr, new Date().getTime() - time]);
    startTimer();
  };

  const stopRealTimer = () => {
    setRealTimer((arr) => [...arr, new Date().getTime() - time]);
    startTimer();
  };

  const goEasier = () => {
    switch (easier?.select) {
      case "linear":
        setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.num)]);
        if (audioVolume * 10 ** (parseInt(easier.num) / 20) > 1) {
          return 1;
        } else {
          return audioVolume * 10 ** (parseInt(easier.num) / 20);
        }
      case "change":
        if (step <= easier.numOfTrial) {
          setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.before)]);
          if (audioVolume * 10 ** (parseInt(easier.before) / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (parseInt(easier.before) / 20);
          }
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] + parseInt(easier.after)]);
          if (audioVolume * 10 ** (parseInt(easier.after) / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (parseInt(easier.after) / 20);
          }
        }
      default:
        if (step <= 4) {
          setDbs((arr) => [...arr, dbs[step - 1] + 4]);
          if (audioVolume * 10 ** (4 / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (4 / 20);
          }
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] + 2]);
          if (audioVolume * 10 ** (2 / 20) > 1) {
            return 1;
          } else {
            return audioVolume * 10 ** (2 / 20);
          }
        }
    }
  };

  const goHarder = () => {
    switch (harder?.select) {
      case "linear":
        setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.num)]);
        return audioVolume * 10 ** (-parseInt(harder.num) / 20);
      case "change":
        if (step <= harder.numOfTrial) {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.before)]);
          return audioVolume * 10 ** (-parseInt(harder.before) / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.after)]);
          return audioVolume * 10 ** (-parseInt(harder.after) / 20);
        }
      case "incorrect":
        if (stillCorrect) {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.before)]);
          return audioVolume * 10 ** (-parseInt(harder.before) / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - parseInt(harder.after)]);
          return audioVolume * 10 ** (-parseInt(harder.after) / 20);
        }
      default:
        if (step <= 4) {
          setDbs((arr) => [...arr, dbs[step - 1] - 4]);
          return audioVolume * 10 ** (-4 / 20);
        } else {
          setDbs((arr) => [...arr, dbs[step - 1] - 2]);
          return audioVolume * 10 ** (-2 / 20);
        }
    }
  };

  const changeAnswer = (value) => {
    const nums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (!nums.includes(value)) return null;
    if (initStart) {
      stopReactTimer();
      setInitStart(false);
    }
    switch (focus) {
      case 0:
        setInput1(value);
        if (value.length === 1) setFocus(1);
        break;
      case 1:
        setInput2(value);
        if (value.length === 1) setFocus(2);
        break;
      case 2:
        setInput3(value);
        break;
    }
  };

  const handleDelete = () => {
    switch (focus) {
      case 0:
        setInput1("");
        break;
      case 1:
        setInput2("");
        if (input2.length !== 1) {
          setFocus(0);
        }
        break;
      case 2:
        setInput3("");
        if (input3.length !== 1) {
          setFocus(1);
        }
        break;
    }
  };

  const handlePlay = async () => {
    startTimer();
    const audios = [];
    let question = "";
    if (useTriplets) {
      question = questions[step - 1];
      for (let i = 0; i < 3; i++) {
        audios.push(
          new Audio(
            process.env.PUBLIC_URL +
              "/hearing/digits-audio/" +
              question.split("")[i] +
              ".wav"
          )
        );
      }
    } else {
      for (let i = 0; i < 3; i++) {
        let digit;
        do {
          digit = Math.floor(Math.random() * 10);
        } while (digit === 0 || digit === 7);
        question += digit.toString();
        audios.push(new Audio(HearingAWS + digitsAudios[digit]));
      }
      await setQuestions((arr) => [...arr, question]);
    }
    await setRealAnswer(question);

    // if (step % 5 === 0 && gapTime > 0) {
    //   await new Promise((resolve) => setTimeout(resolve, gapTime * 1000));
    // }

    setTimeout(() => {
      noise.volume = noiseVolume;
      startTimer();
      noise.play();
    }, 0);
    setTimeout(() => {
      const audio = audios[0];
      audio.volume = audioVolume;
      audio.play();
    }, 500);
    setTimeout(() => {
      const audio = audios[1];
      audio.volume = audioVolume;
      audio.play();
    }, 1460);
    setTimeout(() => {
      const audio = audios[2];
      audio.volume = audioVolume;
      audio.play();
    }, 2420);
    setTimeout(() => {
      noise.pause();
    }, 3720);
  };

  const checkAnswer = async () => {
    setOkButton(false);
    stopRealTimer();
    const userAnswer = input1 + input2 + input3;
    setAnswers((arr) => [...arr, userAnswer]);
    if (realAnswer !== userAnswer) {
      setStillCorrect(false);
      setAudioVolume(goEasier(step));
      // This value dbs constrolls the volume of the audio
      console.log("dbs: " + dbs[step - 1]);
      console.log("WRONG, increasing audio volume to " + audioVolume);
    } else {
      setAudioVolume(goHarder(step));
      console.log("dbs: " + dbs[step - 1]);
      console.log("RIGHT, decreasing audio volume to " + audioVolume);
    }
    if (step !== numOfTrial) {
      const usedTime =
        reactTimer[reactTimer.length - 1] + realTimer[realTimer.length - 1];
      const gaps = [1000, 1500, 2000];
      const randomGap = Math.floor(Math.random() * 3);
      const realGap = gaps[randomGap];
      let moreTime = 0;
      if (usedTime <= 3400) {
        moreTime = 3400 + realGap - usedTime;
      } else {
        moreTime = realGap;
      }
      if (step % 5 === 0 && gapTime > 0) {
        moreTime += gapTime * 1000;

        for (let i = 1; i <= gapTime; i++) {
          setTimeout(() => {
            setCountDown(gapTime - i);
          }, i * 1000);
        }
      }

      setTimeout(async () => {
        await setRealAnswer("");
        await setInput1("");
        await setInput2("");
        await setInput3("");
        await setFocus(0);
        await setInitStart(true);
        await setOkButton(true);
        setStep(step + 1);
        setCountDown(null);
      }, moreTime);
    }
  };

  const renderInputs = () => {
    return (
      <div>
        <Input
          value={input1}
          required={true}
          inputProps={{ maxLength: 1 }}
          onClick={() => setFocus(0)}
          inputRef={(input) => (input1Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, marginRight: 10, paddingLeft: 13 }}
        />
        <Input
          value={input2}
          required={true}
          onClick={() => setFocus(1)}
          inputProps={{ maxLength: 1 }}
          inputRef={(input) => (input2Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, paddingLeft: 13 }}
        />
        <Input
          value={input3}
          required={true}
          inputProps={{ maxLength: 1 }}
          onKeyDown={handleKeyEnter}
          inputRef={(input) => (input3Ref.current = input)}
          onChange={(e) => changeAnswer(e.target.value)}
          style={{ width: 40, marginLeft: 10, paddingLeft: 13 }}
        />
      </div>
    );
  };

  const renderKeys = () => {
    return (
      <div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("1")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            1
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("2")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            2
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("3")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            3
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("4")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            4
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("5")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            5
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("6")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            6
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => changeAnswer("7")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            7
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("8")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            8
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("9")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            9
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={handleDelete}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            <BackspaceIcon />
          </Fab>
          <Fab
            color="default"
            onClick={() => changeAnswer("0")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            0
          </Fab>
          <Fab
            color="default"
            onClick={checkAnswer}
            disabled={renderOKButton()}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            OK
          </Fab>
        </div>
      </div>
    );
  };

  const renderOKButton = () => {
    if (!!input1 && !!input2 && !!input3 && okButton) return false;
    return true;
  };

  const handleKeyEnter = (e) => {
    if (!!input1 && !!input2 && !!input3 && okButton && e.keyCode === 13) {
      checkAnswer();
    }
  };

  const renderCountDown = () => {
    switch (loadStage) {
      case LoadStage.LOADING:
        return (
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "10%",
            }}
          >
            <P1>Loading ...</P1>
          </div>
        );
      case LoadStage.READY:
        return (
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "10%",
            }}
          >
            <CountDown />
          </div>
        );
      case LoadStage.DONE:
        console.log("print dbs", dbs);
        return (
          <P1 style={{ marginTop: "8vh" }}>
            You have finished this part, please click on NEXT
          </P1>
        );
      default:
        return null;
    }
  };

  return (
    <Container style={{ textAlign: "center" }}>
      {loadStage === LoadStage.START ? (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            marginTop: "15%",
          }}
        >
          <br />
          <P1 className="font-weight-lighter">
            Block {numOfCurrBlock} of {numOfTotalBlocks}
          </P1>
          <P1 className="font-weight-lighter">
            Step {step} of {numOfTrial}
          </P1>
          {countDown !== null && (
            <div className="gapTimeDisplay">
              <p>Continues in {countDown} seconds</p>
            </div>
          )}
          {renderInputs()}
          <br />
          {renderKeys()}
        </div>
      ) : (
        renderCountDown()
      )}
      <br />
    </Container>
  );
};

export default DigitsInNoise;
