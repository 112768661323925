import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { P1, P2, P3 } from "assets/fonts";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    backgroundColor: "#e9ecef"
  },
});

export default function DenseTable() {
  const classes = useStyles();

  const getMockChoices = () => {
    return (
      <div className="row">
        <button style={{border: "solid", backgroundColor: "#e9ecef"}}><P3>A</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>B</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>C</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>D</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>E</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>F</P3></button>
        <button style={{border: "none", backgroundColor: "#e9ecef"}}><P3>G</P3></button>
      </div>  
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{width:"5%"}}></TableCell>
            <TableCell style={{width:"30%"}}></TableCell>
            <TableCell align="center"><P2><b>Without</b><br/>Hearing Aids</P2></TableCell>
            <TableCell align="center"><P2><b>With</b><br/>Hearing Aids</P2></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="row1">
            <TableCell align="center"><P2>(a)</P2></TableCell>
            <TableCell align="left"><P2>When I’m talking with a friend outdoors on a windy day, I miss a lot of the conversation.</P2></TableCell>
            <TableCell align="center">{getMockChoices()}</TableCell>
            <TableCell align="center">{getMockChoices()}</TableCell>
          </TableRow>
          <TableRow key="row2">
            <TableCell align="center"><P2>(b)</P2></TableCell>
            <TableCell align="left"><P2>When I am in a meeting with several other people, I can comprehend speech.</P2></TableCell>
            <TableCell align="center">{getMockChoices()}</TableCell>
            <TableCell align="center">{getMockChoices()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
