import React from "react";
import { Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';

export default (props) => {
    return (
        <div style={{textAlign: 'center', marginTop: '20%'}}>
            <P1 >Thanks for participating, please submit your test!</P1>
            <br />
            <P2>Your participant ID is: {props.id}</P2>
            <P2>You may contact the investigators at jcbhlab@ualberta.ca and use this number to withdraw your data up to one week after you have completed the experiment.</P2>
            <br />
            <Button size="large" style={{ width: "20%", marginLeft: "5%%", marginTop: "10%", color: "white", backgroundColor: "#107896"}} variant="contained" onClick={props.handleSubmit} >Submit</Button>
        </div>
    )
}