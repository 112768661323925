import React from "react";
import { CircularProgress, Avatar, Button } from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';


class TestMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            maskVolume: this.props.volume,
            sourceVolume: this.props.volume,
            questions: [],
            index: 0,
            dbs: [0],
            time: 0,
            timer: [],
            correct: [],
            answers: [],
            userStart: false,
            alert: false,
            once: false,
            traversals: 0,
            lastCorrectness: null,
            pause: false
        }
    }

    componentDidMount = async () => {
        for (var i = 1; i < 5; i++) {
            for (var j = 1; j < 9; j++) {
                const audio1 = new Audio(process.env.PUBLIC_URL + "/hearing/crm4-loss-audio/" + i.toString() + j.toString() + ".wav");
                const audio2 = new Audio(process.env.PUBLIC_URL + "/hearing/crm4-mask-audio/" + i.toString() + j.toString() + ".wav");
                audio1.volume = 0;
                audio2.volume = 0;
                try {
                    await audio1.play();
                    await audio2.play();
                    await audio1.pause();
                    await audio2.pause();
                } catch (e) { console.log(e, "for", i, j) }
            }
        }
        this.setState({ loading: false });
        this.playAudio();
    }

    startTimer = () => {
        this.setState({ time: new Date().getTime() });
    };
    stopTimer = () => {
        this.setState({ time: new Date().getTime() - this.state.time });
    };
    resetTimer = () => {
        this.setState({ time: 0, alert: false });
    };

    getTargetAndMask = () => {
        const {  questions } = this.state;
        const color = Math.ceil(Math.random() * 4).toString();
        const number = Math.ceil(Math.random() * 8).toString();
        const colorMask = Math.ceil(Math.random() * 4).toString();
        const numberMask = Math.ceil(Math.random() * 8).toString();
        const question = color + number;
        const questionMask = colorMask + numberMask;
        if (question == questionMask) {
            return this.getTargetAndMask();
        }
        questions.push(question);
        this.setState({ questions });
        return { question, questionMask };
    }

    playAudio = async () => {
        const { maskVolume, sourceVolume } = this.state;
        const { question, questionMask } = this.getTargetAndMask();
        let sourceAudio = new Audio(process.env.PUBLIC_URL + "/hearing/crm4-loss-audio/" + question + ".wav");
        let maskAudio = new Audio(process.env.PUBLIC_URL + "/hearing/crm4-mask-audio/" + questionMask + ".wav");
        sourceAudio.volume = sourceVolume;
        maskAudio.volume = maskVolume;
        await sourceAudio.play();
        await maskAudio.play();
        setTimeout(() => {
            maskAudio.pause();
            this.startTimer();
            this.setState({ userStart: true });
        }, sourceAudio.duration * 1000);
    }

    handleClick = async (num) => {
        const { questions, index, timer, lastCorrectness, traversals, correct, answers } = this.state;
        await this.setState({ userStart: false });
        await this.stopTimer();
        await timer.push(this.state.time);
        await this.resetTimer();
        await this.setState({ timer });
        answers.push(num);
        await this.setState({ answers });
        const right = questions[index] === num;
        let sourceVolume;
        if (right) {
            correct.push(true);
            if (lastCorrectness === null) {
                this.setState({ lastCorrectness: true })
            } else if (lastCorrectness === false) {
                console.log("RIGHT, Traversal", traversals + 1, "times!")
                this.setState({ lastCorrectness: true, traversals: traversals + 1 })
            }
            sourceVolume = this.goHarder();
        } else {
            correct.push(false);
            if (lastCorrectness === null) {
                this.setState({ lastCorrectness: false })
            } else if (lastCorrectness === true) {
                console.log("WRONG, Traversal", traversals + 1, "times!")
                this.setState({ lastCorrectness: false, traversals: traversals + 1 })
            }
            sourceVolume = this.goEasier();
        }
        await this.setState({ index: index + 1, sourceVolume, correct });
        if (this.state.traversals >= this.props.reversals) {
            const { dbs } = this.state;
            let sum = 0;
            if (dbs.length >= 10) {
                for (let i = dbs.length - 10; i < dbs.length; i++) {
                    sum += dbs[i];
                }
            } else {
                for (let i = 0; i < dbs.length; i++) {
                    sum += dbs[i];
                }
            }
            const SNR = Number(sum / 10).toFixed(2);
            this.props.handleClick(SNR, timer, dbs, this.state.questions, this.state.answers, correct);
        } else {
            setTimeout(() => {
                this.playAudio();
            }, 2000)
        }
    }

    goEasier = () => {
        const { sourceVolume, index, dbs } = this.state;
        dbs.push(dbs[index] + 3);
        this.setState({ dbs });
        if (sourceVolume * 10 ** (3 / 20) > 1) {
            return 1;
        } else {
            return sourceVolume * 10 ** (3 / 20);
        }
    };

    goHarder = () => {
        const { sourceVolume, index, dbs } = this.state;
        dbs.push(dbs[index] - 1);
        this.setState({ dbs });
        return sourceVolume * 10 ** (-1 / 20);
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") return;
        this.setState({ alert: false });
    };

    handlePause = () => {
        this.setState({ pause: true });
        if (this.state.userStart) {
            this.stopTimer();
        }
    }

    handleResume = () => {
        this.setState({ pause: false });
        if (this.state.userStart) {
            this.setState({ time: new Date().getTime() - this.state.time });
        }
    }

    render() {
        const { traversals, loading, userStart, pause } = this.state;
        const { reversals } = this.props;
        const fakeReversals = [];
        for (let i=0; i<reversals; i++) {
            fakeReversals.push("1");
        }
        const rows = ["", "", "", "", "", "", "", ""];
        return (
            <div>
                {
                    loading
                        ?
                        <div>
                            <CircularProgress />
                        </div>
                        :
                        <div>
                            <div id="buttons" style={{ position: "fixed", height: "80%", width: "100%", backgroundColor: "#C2CAD0" }}>
                                <div className="row" style={{ height: "25%" }}>
                                    {rows.map((e, i) => <button disabled={!userStart || pause} value={"1" + String(i+1)} onClick={(e) => this.handleClick(e.target.value)} style={{ color: "red", border: "none", background: "none", width: "12.5%", fontSize: 80 }}>{i+1}</button>)}
                                </div>
                                <div className="row" style={{ height: "25%" }}>
                                    {rows.map((e, i) => <button disabled={!userStart || pause} value={"2" + String(i+1)} onClick={(e) => this.handleClick(e.target.value)} style={{ color: "green", border: "none", background: "none", width: "12.5%", fontSize: 80 }}>{i+1}</button>)}
                                </div>
                                <div className="row" style={{ height: "25%" }}>
                                    {rows.map((e, i) => <button disabled={!userStart || pause} value={"3" + String(i+1)} onClick={(e) => this.handleClick(e.target.value)} style={{ color: "blue", border: "none", background: "none", width: "12.5%", fontSize: 80 }}>{i+1}</button>)}
                                </div>
                                <div className="row" style={{ height: "25%" }}>
                                    {rows.map((e, i) => <button disabled={!userStart || pause} value={"4" + String(i+1)} onClick={(e) => this.handleClick(e.target.value)} style={{ color: "white", border: "none", background: "none", width: "12.5%", fontSize: 80 }}>{i+1}</button>)}
                                </div>
                            </div>
                            <div id="progress" className="row" style={{ position: "fixed", left: "5%", right: "5%", bottom: "50px" }}>
                                <P3 style={{marginRight: "2%"}}>Progress</P3>
                                {fakeReversals.map((e, i) => <Avatar style={{ width: 20, height: 20, border: "solid", borderColor: "#107896", backgroundColor: traversals > i ? "#107896" : "white" }}>⠀</Avatar>)}
                                <P3 style={{marginLeft: "2%"}}>{this.props.block + 1}/4 Block</P3>
                                <div style={{marginLeft: "2%", marginBottom: "2%"}}>
                                {
                                    userStart ?
                                        pause ?
                                            <Button style={{ marginLeft: 10, color: "white", backgroundColor: "#107896" }} variant="outlined" onClick={this.handleResume}>Resume</Button>
                                            :
                                            <Button style={{ marginLeft: 10, color: "white", backgroundColor: "#107896" }} variant="outlined" onClick={this.handlePause}>Pause</Button>
                                        :
                                        <Button style={{ marginLeft: 10, color: "white", backgroundColor: "#107896" }} disabled variant="outlined" >Pause</Button>
                                }
                                </div>
                            </div>
                            <div style={{ position: "fixed", left: "5%", right: "5%", bottom: "10px" }}>
                                <P3>The blue dots on the progress bar may not advance with each trial. This is normal.</P3>
                                <P3>Please do not adjust your computer volume, or hearing aids (if applicable), during the test.</P3>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default TestMain;