import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper, Button, TextField} from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();
  const [editState, setEditState] = useState(-1);
  const [paragraph, setParagraph] = useState("");
  const [question, setQuestion] = useState("");
  const [choice1, setChoice1] = useState("");
  const [choice2, setChoice2] = useState("");
  const [choice3, setChoice3] = useState("");
  const [choice4, setChoice4] = useState("");
  const [answer, setAnswer] = useState("");

  const handleEdit = (index) => {
    const { paragraph, question, choices, answer } = props.rows[index];
    setParagraph(paragraph);
    setQuestion(question);
    setChoice1(choices[0]);
    setChoice2(choices[1]);
    setChoice3(choices[2]);
    setChoice4(choices[3]);
    setAnswer(answer);
    setEditState(index);
  }

  const handleConfirm = (id) => {
    const newRow = {
      paragraph,
      question,
      choices: [choice1, choice2, choice3, choice4],
      answer
    };
    props.onEdit(id, newRow);
    setEditState(-1);
  }

  return (
    <TableContainer component={Paper} >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Paragraph</TableCell>
            <TableCell align="left">Question</TableCell>
            <TableCell align="left">Choice1</TableCell>
            <TableCell align="left">Choice2</TableCell>
            <TableCell align="left">Choice3</TableCell>
            <TableCell align="left">Choice4</TableCell>
            <TableCell align="left">Answer</TableCell>
            <TableCell align="left">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            editState === index ?
            <TableRow key={index}>
              <TableCell align="left"><TextField value={paragraph} onChange={(e) => setParagraph(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={question} onChange={(e) => setQuestion(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={choice1} onChange={(e) => setChoice1(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={choice2} onChange={(e) => setChoice2(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={choice3} onChange={(e) => setChoice3(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={choice4} onChange={(e) => setChoice4(e.target.value)}/></TableCell>
              <TableCell align="left"><TextField value={answer} onChange={(e) => setAnswer(e.target.value)}/></TableCell>
              <TableCell align="left">
                <Button
                  style={{ color: "white", backgroundColor: "#107896", marginBottom: "10px" }}
                  key={row.id}
                  onClick={() => handleConfirm(row.id)}
                >
                  Confirm
                </Button>
                <Button
                  style={{ color: "white", backgroundColor: "#107896" }}
                  key={row.id}
                  onClick={() => props.handleDelete(row.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
            :
            <TableRow key={index}>
              <TableCell align="left">{row.paragraph}</TableCell>
              <TableCell align="left"><Markdown>{row.question}</Markdown></TableCell>
              <TableCell align="left">{row.choices[0]}</TableCell>
              <TableCell align="left">{row.choices[1]}</TableCell>
              <TableCell align="left">{row.choices[2]}</TableCell>
              <TableCell align="left">{row.choices[3]}</TableCell>
              <TableCell align="left">{row.answer}</TableCell>
              <TableCell align="left">
                <Button
                  style={{ color: "white", backgroundColor: "#107896", marginBottom: "10px" }}
                  onClick={() => handleEdit(index)}
                >
                  Edit
                </Button>
                <Button
                  style={{ color: "white", backgroundColor: "#107896" }}
                  key={row.id}
                  onClick={() => props.handleDelete(row.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
