import { FontActionTypes } from "./font-types";

const INITIAL_STATE = {
  currentFont: 4,
};

const fontReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FontActionTypes.INCREASE_FONT:
      return {
        ...state,
        currentFont:
          state.currentFont <= 1 ? state.currentFont : state.currentFont - 1,
      };
    case FontActionTypes.DECREASE_FONT:
      return {
        ...state,
        currentFont:
          state.currentFont >= 6 ? state.currentFont : state.currentFont + 1,
      };
    default:
      return {
        currentFont: 4,
      };
  }
};

export default fontReducer;
