import React from "react";
import InputTable from "./components/InputTable";
import AudiogramChart from "./components/AudiogramChart";
import { useState, useContext } from "react";
import { ChartDataContext } from "./states/ChartDataState";
import "./styles/style.css";
import { ChartDataProvider } from "./states/ChartDataState";

const Audiogram = () => {
  const chartOptions = {
    tooltips: {
      enabled: true, // Set tooltips enabled to true
    },
  };

  return (
    <ChartDataProvider>
      <div className="App">
        <div className="container">
          <AudiogramChart options={chartOptions} />
          <InputTable />
        </div>
      </div>
    </ChartDataProvider>
  );
};

export default Audiogram;
