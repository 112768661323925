import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap

export const Header = () => {
  const [active, setActive] = useState(null);
  const history = useHistory();

  const handleActive = (linkName, path) => {
    setActive(linkName);
    history.push(path);
  };

  const handleLogoClick = () => {
    setActive("home");
    history.push("/");
  };

  return (
    <header className="header container-fluid p-3">
      <div className="row align-items-center">
        {/* Logo Section */}
        <div
          className="col-md-4 col-sm-12 d-flex align-items-center logo-container"
          onClick={handleLogoClick}
          style={{ cursor: "pointer" }}
        >
          <img
            className="logo-image"
            src={require("../assets/images/bcal-logo-removebg-preview-1.png")}
            alt="logo"
          />
          <div className="logo-text">Rehab Science</div>
        </div>

        {/* Navigation Bar Section */}
        <div className="col-md-8 col-sm-12">
          <nav className="header-nav-bar d-flex justify-content-end">
            <button
              className={`btn btn-link ${active === "home" ? "active" : ""}`}
              onClick={() => handleActive("home", "/")}
            >
              Home
            </button>
            <button
              className={`btn btn-link ${
                active === "evaluation" ? "active" : ""
              }`}
              onClick={() => handleActive("evaluation", "/evaluation")}
            >
              Evaluation
            </button>
            <button
              className={`btn btn-link ${
                active === "research" ? "active" : ""
              }`}
              onClick={() => handleActive("research", "/research")}
            >
              Research
            </button>
            <button
              className={`btn btn-link ${active === "team" ? "active" : ""}`}
              onClick={() => handleActive("team", "/team")}
            >
              Team
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};
