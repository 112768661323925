import PhonemeHeader from "literacy/components/student/phoneme/assets/header";
import React from "react";
import keys from "literacy/assets/keys";
import { connect } from "react-redux";
import { Button, Container, InputLabel, Checkbox } from "@material-ui/core";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import axios from "axios";

class Materials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w1: null,
      w2: null,
      section: "w1",
    };
  }

  componentDidMount = async () => {
    const doc1 = await axios.get("/api/literacy/phoneme/materials/w1");
    const doc2 = await axios.get("/api/literacy/phoneme/materials/w2");
    if (doc1.data) {
      this.setState({
        w1: {
          video: doc1.data.video,
          desc: doc1.data.desc,
        },
      });
    } else {
      this.setState({ w1: null });
    }
    if (doc2.data) {
      this.setState({
        w2: {
          video: doc2.data.video,
          desc: doc2.data.desc,
        },
      });
    } else {
      this.setState({ w2: null });
    }
  };

  handleSectionChange = (e) => {
    this.setState({ section: e.target.value });
  };

  handleCheckChange = async (e) => {
    const { section } = this.state;
    switch (section) {
      case "w1":
        await axios.put("/api/literacy/phoneme/materials/w1");
        window.location = "/literacy/student/phoneme/learning";
        return null;
      case "w2":
        await axios.put("/api/literacy/phoneme/materials/w2");
        window.location = "/literacy/student/phoneme/learning";
        return null;
    }
  };

  renderContent = () => {
    const { section, w1, w2 } = this.state;
    switch (section) {
      case "w1":
        if (w1) {
          return (
            <div>
              <P3>{w1.desc}</P3>
              <iframe
                style={{
                  width: "80%",
                  height: "500px",
                }}
                allowFullScreen
                src={
                  "https://literacy-app-video.s3.ca-central-1.amazonaws.com/" +
                  w1.video
                }
              ></iframe>
            </div>
          );
        } else {
          return null;
        }
      case "w2":
        if (w2) {
          return (
            <div>
              <P3>{w2.desc}</P3>
              <iframe
                style={{
                  width: "80%",
                  height: "500px",
                }}
                allowFullScreen
                src={
                  "https://literacy-app-video.s3.ca-central-1.amazonaws.com/" +
                  w2.video
                }
              ></iframe>
            </div>
          );
        } else {
          return null;
        }
    }
  };

  render() {
    const { currentUser } = this.props;
    const { section } = this.state;
    return (
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
          fontFamily: "raven",
          height: window.innerHeight,
          overflowY: "scroll",
        }}
      >
        <PhonemeHeader
          part="Learning Materials"
          backURL="/literacy/student/phoneme"
        />
        <Container>
          <InputLabel id="label">
            <P2>Learning Section</P2>
          </InputLabel>
          <Button
            variant="contained"
            style={{ margin: "2px" }}
            onClick={() => {
              this.setState({ section: "w1" });
            }}
          >
            Week1
          </Button>
          <Button
            variant="contained"
            style={{ margin: "2px" }}
            onClick={() => {
              this.setState({ section: "w2" });
            }}
          >
            Week2
          </Button>
          {currentUser ? (
            <div style={{ display: "flex" }}>
              <Checkbox
                checked={currentUser.phoneme_materials[section]}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={this.handleCheckChange}
              />
              <P3 style={{ marginTop: 12 }}>I have viewed this material</P3>
            </div>
          ) : null}
        </Container>
        <br />
        <Container>
          {this.renderContent()}
          <br />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Materials);
