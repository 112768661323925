import React, { useState, useEffect, useCallback } from "react";
import { Container, Paper, Button, Snackbar } from "@material-ui/core";
import CheckBox from "GUI/user-components/checkbox";
import ConsentCheckbox from "GUI/user-components/consent-check";
import MultipleChoice from "GUI/user-components/multiple-choice";
import DatePicker from "GUI/user-components/date-picker";
import TextField from "GUI/user-components/text-field";
import PureText from "GUI/user-components/pure-text";
import Select from "GUI/user-components/select";
import PDF from "GUI/user-components/pdf";
import Image from "GUI/user-components/image";
import ImageRandom from "GUI/user-components/image-random";
import Slider from "GUI/user-components/slider";
import VolumeControl from "GUI/user-components/volume-control";
import DigitsDemo from "GUI/user-components/digits-demo";
import Digits from "GUI/user-components/digits";
import CRM from "GUI/user-components/crm";
import HrLine from "GUI/user-components/hrline";
import axios from "axios";
import { P1, P2, P3 } from "assets/fonts";
import Pagination from "./user-pagination";
import { Alert, AlertTitle } from "@material-ui/lab";
import OHHIS from "GUI/user-components/OHHIS";
import FontUI from "assets/font-ui";
import RowContainer from "GUI/user-components/row";
import ColumnContainer from "GUI/user-components/column";
import AlphabetExperiment from "GUI/tutor-components/alphabet-experiment";
import DfdExperiment from "GUI/tutor-components/dfd";
import RecoveryStimulus from "GUI/user-components/recovery-stimulus";
import StudyId from "GUI/user-components/study-id";
import { useDispatch } from "react-redux";
import { resetReadyComponents } from "redux/ready-components/readyComponent-reducer";
import {
  addMandatoryComponent,
  resetMandatoryComponents,
} from "redux/mandatory-components/mandatoryCom-reducer";
import { selectMandatoryComponents } from "redux/mandatory-components/mandatoryCom-selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectReadyComponents } from "redux/ready-components/readyComponent-selector";

// let readyComponents = [];
// let mandatoryCom = [];

const Page = (props) => {
  const {
    app,
    variable,
    currPage,
    finalPage,
    pages,
    participantId,
    mandatoryCom,
    readyComponents,
    onChange,
    onSubmit,
    updateAssign,
    updateBlocksOrder,
    blocksOrder,
  } = props;
  const [components, setComponents] = useState([]);
  const [alert, setAlert] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currPage) {
      (async () => {
        const doc = await axios.get("/api/gui/page/" + currPage);
        if (doc.data) {
          // mandatoryCom = [];
          // readyComponents = [];
          dispatch(resetReadyComponents());
          dispatch(resetMandatoryComponents());
          setComponents(doc.data.components);
        }
      })();
    }

    return () => setComponents([]);
  }, [currPage]);

  const readyToContinue = (id, name = null) => {
    if (!readyComponents.includes(id)) readyComponents.push(id);
  };

  // const addMandatoryCom = (id) => {
  //   if (!mandatoryCom.includes(id)) mandatoryCom.push(id);
  // };

  const renderComponent = (com) => {
    switch (com.type) {
      case "studyids":
        return <StudyId id={com.id} updateAssign={updateAssign} />;
      case "recoverystimulus":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <RecoveryStimulus
            id={com.id}
            participantId={participantId}
            volumecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "checkbox":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <CheckBox
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "consentcheck":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <ConsentCheckbox
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "radio":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <MultipleChoice
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "select":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <Select
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "ageselect":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <Select
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "textfield":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <TextField
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "puretext":
        return <PureText id={com.id} updateAssign={updateAssign} />;
      case "datepicker":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <DatePicker
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "pdf":
        return <PDF id={com.id} />;
      case "image":
        return <Image id={com.id} />;
      case "imagerandom":
        return <ImageRandom id={com.id} updateAssign={updateAssign} />;
      case "slider":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <Slider
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "volumecontrol":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <VolumeControl
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "hrline":
        return <HrLine id={com.id} />;
      case "digitsdemo":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <DigitsDemo
            id={com.id}
            volumecontrol={variable.volumecontrol}
            readyToContinue={readyToContinue}
          />
        );
      case "digits":
        // addMandatoryCom(com.id);
        // dispatch(addMandatoryComponent(com.id));
        return (
          <Digits
            id={com.id}
            volumecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "crm":
        return (
          <CRM
            id={com.id}
            volumecontrol={variable.volumecontrol}
            updateBlocksOrder={updateBlocksOrder}
            blocksOrder={blocksOrder}
            updateAssign={updateAssign}
            onNext={onChange}
          />
        );
      case "alphabetexperiment":
        return (
          <AlphabetExperiment
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "dfdexperiments":
        return (
          <DfdExperiment
            id={com.id}
            digits={variable.decibel}
            volumecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "ohhis":
        return <OHHIS app={app} id={com.id} updateAssign={updateAssign} />;
      case "row":
        return (
          <RowContainer
            app={app}
            id={com.id}
            onChange={onChange}
            participantId={participantId}
            readyToContinue={readyToContinue}
            updateAssign={updateAssign}
            variable={variable}
            updateBlocksOrder={updateBlocksOrder}
            blocksOrder={blocksOrder}
          />
        );
      case "column":
        return (
          <ColumnContainer
            app={app}
            id={com.id}
            onChange={onChange}
            participantId={participantId}
            readyToContinue={readyToContinue}
            updateAssign={updateAssign}
            variable={variable}
            updateBlocksOrder={updateBlocksOrder}
            blocksOrder={blocksOrder}
          />
        );
    }
  };

  const handleNext = (page) => {
    if (readyComponents.length < mandatoryCom.length) {
      setAlert(true);
    } else {
      onChange(page);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSubmit = (callback) => {
    if (readyComponents.length < mandatoryCom.length) {
      setAlert(true);
    } else {
      onSubmit();
      callback();
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setAlert(false);
  };

  const showPagination = () => {
    let show = true;
    components.map((com) => {
      if (com.type === "crm") show = false;
    });
    return show;
  };

  return (
    <div
      style={{
        height: window.innerHeight,
        width: "90%",
        overflowY: "scroll",
        paddingTop: "30px",
        paddingLeft: "5%",
        paddingBottom: "60px",
      }}
    >
      {components.map((com, i) => (
        <div key={i}>
          <Paper
            style={{
              margin: "5px",
              backgroundColor: "whitesmoke",
              paddingBottom: "40px",
            }}
          >
            {renderComponent(com)}
            <br />
          </Paper>
        </div>
      ))}
      <br />
      {showPagination() && (
        <Pagination
          key={`${readyComponents.length}-${mandatoryCom.length}`}
          pages={pages}
          currPage={currPage}
          finalPage={finalPage}
          onSubmit={handleSubmit}
          mandatoryCom={mandatoryCom}
          readyComponents={readyComponents}
          onNext={handleNext}
        />
      )}
      <FontUI />
      <Snackbar open={alert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert severity="info">
          <AlertTitle>
            <P2>Info</P2>
          </AlertTitle>
          <P2>Sorry, you have to complete all sections on current page!</P2>
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  mandatoryCom: selectMandatoryComponents,
  readyComponents: selectReadyComponents,
});

export default connect(mapStateToProps)(Page);
