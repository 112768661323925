import React from "react";
import { Input, Container, Fab, Button } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { P1, P2, P3 } from "assets/fonts";
import axios from "axios";
import { HearingAWS } from "assets/keys";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { readyToContinueRedux } from "redux/ready-components/readyComponent-reducer";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

class DigitsDemo extends React.Component {
  constructor(props) {
    super(props);
    const { volumecontrol, pages, currPage, finalPage, onNext, onSubmit } =
      this.props;
    this.state = {
      noise: null,
      noiseVolume: volumecontrol.answer / 100,
      audioVolume: volumecontrol.answer / 100,
      audio1: new Audio(
        process.env.PUBLIC_URL + "/hearing/digits-audio/" + 8 + ".wav"
      ),
      audio2: new Audio(
        process.env.PUBLIC_URL + "/hearing/digits-audio/" + 3 + ".wav"
      ),
      audio3: new Audio(
        process.env.PUBLIC_URL + "/hearing/digits-audio/" + 6 + ".wav"
      ),
      focus: 0,
      click: -1,
      input1: "",
      input2: "",
      input3: "",
      stage: 0,
      openDialog: false,
      demoFinished: false,
      continue: true,
      promps: [
        "Instruction: Use your keyboard to enter numbers, or click the number pad on the screen",
        "Instruction: Use the delete button if you entered a number by accident. Or press backspace on your keyboard.",
        "Instruction: When you have finished entering the three digits, click OK to move to the next item. Or press Enter on your keyboard",
      ],
    };
    this.input1 = React.createRef();
    this.input2 = React.createRef();
    this.input3 = React.createRef();
    this.repeat = setInterval(() => {
      this.setState(
        {
          input1: "",
          input2: "",
          input3: "",
          focus: 0,
          click: -1,
          stage: 1,
        },
        () => this.handlePlay()
      );
    }, 22000);
  }

  componentDidMount = async () => {
    this.props.addMandatoryComponent(this.props.id);
    const doc = await axios.get("/api/gui/digitsdemo/" + this.props.id);
    this.setState({ noise: new Audio(HearingAWS + doc.data.url) });
    this.setState({ stage: 1 });
    this.handlePlay();
  };

  componentDidUpdate = async () => {
    if (this.props.id && !this.state.noise) {
      const doc = await axios.get("/api/gui/digitsdemo/" + this.props.id);
      this.setState({ noise: new Audio(HearingAWS + doc.data.url) });
    }
  };

  handleFinish = () => {
    this.openDialog();
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    const { id } = this.props;
    this.props.readyToContinueRedux(id);
    const { noise, audio1, audio2, audio3 } = this.state;
    clearInterval(this.repeat);
    noise.pause();
    audio1.pause();
    audio2.pause();
    audio3.pause();
    this.setState({ continue: false, openDialog: false, demoFinished: true });
  };

  // if the dialog is closed with clickking yes then show the prompt saying the demo is finished

  dialogNoAction = () => {
    this.setState({ openDialog: false });
  };

  handlePlay = async () => {
    const { audioVolume, noiseVolume, noise, audio1, audio2, audio3 } =
      this.state;
    setTimeout(() => {
      noise.volume = noiseVolume;
      if (this.state.continue) noise.play();
      this.setState({ stage: 4 });
    }, 3100 + 3000);
    setTimeout(() => {
      audio1.volume = audioVolume;
      if (this.state.continue) audio1.play();
      else noise.pause();
    }, 3500 + 3000);
    setTimeout(() => {
      this.setState({ input1: "8", focus: 0, click: 8 });
    }, 4000 + 3000);
    setTimeout(() => {
      audio2.volume = audioVolume;
      if (this.state.continue) audio2.play();
      else noise.pause();
    }, 4700 + 3000);
    setTimeout(() => {
      this.setState({ input2: "3", focus: 1, click: 3 });
    }, 5200 + 3000);
    setTimeout(() => {
      audio3.volume = audioVolume;
      if (this.state.continue) audio3.play();
      else noise.pause();
    }, 5900 + 3000);
    setTimeout(() => {
      this.setState({ input3: "6", focus: 2, click: 6 });
    }, 6400 + 3000);
    setTimeout(() => {
      noise.pause();
      this.setState({ stage: 2 });
    }, 7100 + 3000);
    setTimeout(() => {
      this.setState({ click: -1, stage: 4 });
    }, 10500 + 3000);
    setTimeout(() => {
      this.setState({ click: "x" });
      this.handleDelete();
    }, 11000 + 3000);
    setTimeout(() => {
      this.setState({ click: -1 });
    }, 11600 + 3000);
    setTimeout(() => {
      this.setState({ click: "x" });
      this.handleDelete();
    }, 12200 + 3000);
    setTimeout(() => {
      this.setState({ click: -1 });
    }, 12800 + 3000);
    setTimeout(() => {
      this.setState({ click: "x", stage: 3 });
      this.handleDelete();
    }, 13400 + 3000);
    setTimeout(() => {
      this.setState({ click: -1, stage: 4 });
    }, 17000 + 3000);
    setTimeout(() => {
      this.setState({ input2: "3", focus: 1, click: 3 });
    }, 17500 + 3000);
    setTimeout(() => {
      this.setState({ input3: "6", focus: 2, click: 6 });
    }, 18200 + 3000);
    setTimeout(() => {
      this.setState({ click: "ok" });
    }, 19000 + 3000);
  };

  changeAnswer = (value) => {
    const { focus } = this.state;
    switch (focus) {
      case 0:
        if (value.length !== 1) {
          return this.setState({ input1: value });
        } else {
          return this.setState({ input1: value, focus: 1 });
        }
      case 1:
        if (value.length !== 1) {
          return this.setState({ input2: value });
        } else {
          return this.setState({ input2: value, focus: 2 });
        }
      case 2:
        return this.setState({ input3: value });
      default:
        return null;
    }
  };

  handleDelete = () => {
    const { focus, input2, input3 } = this.state;
    switch (focus) {
      case 0:
        return this.setState({ input1: "" });
      case 1:
        if (input2.length === 1) {
          return this.setState({ input2: "" });
        }
        return this.setState({ input2: "", focus: 0 });
      case 2:
        if (input3.length === 1) {
          return this.setState({ input3: "" });
        }
        return this.setState({ input3: "", focus: 1 });
      default:
        return null;
    }
  };

  renderInputs = () => {
    const { input1, input2, input3 } = this.state;
    return (
      <div>
        <Input
          value={input1}
          inputRef={(input) => {
            this.input1 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          style={{
            width: 40,
            marginRight: 10,
            paddingLeft: 13,
          }}
        />
        <Input
          value={input2}
          inputRef={(input) => {
            this.input2 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          style={{ width: 40, paddingLeft: 13 }}
        />
        <Input
          value={input3}
          inputRef={(input) => {
            this.input3 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          style={{ width: 40, marginLeft: 10, paddingLeft: 13 }}
        />
      </div>
    );
  };

  renderKeys = () => {
    const { stage, promps, click } = this.state;
    return (
      <div className="row">
        {
          <div className="col-4" style={{ marginLeft: "34%" }}>
            <div className="rows">
              <Fab
                color={click === 1 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6 }}
              >
                1
              </Fab>
              <Fab
                color={click === 2 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6 }}
              >
                2
              </Fab>
              <Fab
                color={click === 3 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6 }}
              >
                3
              </Fab>
            </div>
            <div className="rows">
              <Fab
                color={click === 4 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                4
              </Fab>
              <Fab
                color={click === 5 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                5
              </Fab>
              <Fab
                color={click === 6 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                6
              </Fab>
            </div>
            <div className="rows">
              <Fab
                color={click === 7 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                7
              </Fab>
              <Fab
                color={click === 8 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                8
              </Fab>
              <Fab
                color={click === 9 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                9
              </Fab>
            </div>
            <div className="rows">
              <Fab
                color={click === "x" ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                <BackspaceIcon />
              </Fab>
              <Fab
                color={click === 0 ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                0
              </Fab>
              <Fab
                color={click === "ok" ? "primary" : "default"}
                style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
              >
                OK
              </Fab>
            </div>
          </div>
        }
        <div className="col">
          <P2 className="font-weight-light">
            {stage === 0 ? null : promps[stage - 1]}
          </P2>
        </div>
      </div>
    );
  };

  render() {
    const { stage, demoFinished } = this.state;
    return (
      <Container style={{ textAlign: "center" }}>
        <div
          style={{
            textAlign: "center",
            position: "relative",
            marginTop: "5%",
            marginRight: "5%",
          }}
        >
          {stage === 0 ? (
            <P1>Loading ... </P1>
          ) : (
            <div>
              {!demoFinished && this.renderInputs()}
              <br />
              {!demoFinished && this.renderKeys()}
              <br />
            </div>
          )}
          {this.state.demoFinished && (
            <h1 style={{ marginBottom: "3vh" }}>
              The demo is finished. Press the NEXT in the bottom right of your
              screen to start the experiment.
            </h1>
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleFinish}
          style={{ position: "relative", right: "10px" }}
        >
          I have reviewed the demo
        </Button>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Finish Demo"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The demo is finished. Do you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.dialogNoAction} color="primary">
              No
            </Button>
            <Button onClick={this.closeDialog} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    readyToContinueRedux: (id) => dispatch(readyToContinueRedux(id)),
    addMandatoryComponent: (id) => dispatch(addMandatoryComponent(id)),
  };
};

export default connect(null, mapDispatchToProps)(DigitsDemo);
