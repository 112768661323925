import React from 'react';
import { P1, P2, P3 } from "assets/fonts";
import { TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Radio } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useCallback } from 'react';

const DenseTable = (props) => {
  const { order, categoryOptions, priorityOptions, charts, onChartsChange, onOrderChange, onCategoryOptionsChange, onPriorityOptionsChange } = props;

  const renderPriorityOptions = useCallback((num) => {
    const calculateOrder = () => {
      const newOrder = [];
      for (let i = 1; i <= 5; i++) {
        for (let num in charts) {
          if (charts[num].priority == i) {
            newOrder.push(num);
          }
        }
      }
      order.map((num) => {
        if (!newOrder.includes(num)) {
          newOrder.push(num);
        }
      });
      onOrderChange(newOrder);
    };

    const changePriorityOptions = (newPriority) => {
      // check if priority exists
      if (charts[num].priority) {
        // if so, add it back
        priorityOptions.push(charts[num].priority)
      }
      // remove newPriority in priorityOptions
      const newPriorityOptions = priorityOptions.filter(opt => opt !== newPriority ? opt : null);
      // assign the newPriority
      charts[num].priority = newPriority
      // update charts and priorityOptions
      onPriorityOptionsChange(newPriorityOptions.sort((a, b) => a-b));
      onChartsChange(charts);
      calculateOrder();
    }

    const removePriority = (oldPriorityNum) => {
      const oldPriority = charts[oldPriorityNum]?.priority;
      priorityOptions.push(oldPriority);  // add old priority back to priorityOptions
      charts[num].priority = null;        // clear the priority of the row
      onChartsChange(charts);
      calculateOrder();
    }

    return (
      <TableCell align="center">
        {
          charts[num].priority ? <div className="row" style={{paddingLeft: "20px", width: "80px"}}>
            <P2>{charts[num].priority}</P2>
            <ClearIcon color="secondary" fontSize="small" style={{marginTop: "5px"}} onClick={() => removePriority(num)}/>
          </div> :
          <Select onChange={(e) => changePriorityOptions(e.target.value)}>
            {priorityOptions.map((opt) => <MenuItem value={opt}><P3>{opt}</P3></MenuItem>)}
          </Select>
        }
      </TableCell>
    )
  }, [priorityOptions, charts, onPriorityOptionsChange, onOrderChange]);

  const renderText = useCallback((num) => {
    const handleTextChange = (text) => {
      charts[num].text = text;
      onChartsChange(charts);
    }

    return(
      <TableCell align="center">
        <TextField
          style={{width: "350px"}}
          onChange={(e) => handleTextChange(e.target.value)}
          value={charts[num].text}
        />
      </TableCell>
    )
  }, [charts, onChartsChange]);

  const renderChangeRadio = useCallback((num) => {
    const changes = ["Worse", "No Difference", "Slightly Better", "Better", "Much Better"];
    const handleChangeChange = (newChange) => {
      charts[num].change = newChange;
      onChartsChange(charts);
    }

    return changes.map(change => (
      <TableCell align="center">
        <Radio
          checked={charts[num].change === change}
          onChange={() => handleChangeChange(change)}
        />
      </TableCell>
    ))
  }, [charts, onChartsChange]);

  const renderCategoryOptions = useCallback((num) => {
    const changeCategoryOptions = (newCategory) => {
      if (charts[num].category) {
        categoryOptions.push(charts[num].category)
      }
      const newCategoryOptions = categoryOptions.filter(opt => opt !== newCategory ? opt : null);
      charts[num].category = newCategory
      onCategoryOptionsChange(newCategoryOptions.sort((a, b) => a - b));
      onChartsChange(charts);
    }

    const removeCategory = (oldCategoryNum) => {
      const oldCategory = charts[oldCategoryNum]?.category;
      categoryOptions.push(oldCategory);      // add old priority back to priorityOptions
      categoryOptions.sort((a, b) => a - b);  // sort the options
      charts[num].category = null;            // clear the priority of the row
      onChartsChange(charts);
    }

    return (
      <TableCell align="center">
        {
          charts[num].category ? <div className="row" style={{paddingLeft: "40px"}}>
            <P2>{charts[num].category}</P2>
            <ClearIcon fontSize="small" color="secondary" style={{marginTop: "5px"}} onClick={() => removeCategory(num)}/>
          </div> :
          <Select onChange={(e) => changeCategoryOptions(e.target.value)}>
            {categoryOptions.map((opt) => <MenuItem value={opt}><P3>{opt}</P3></MenuItem>)}
          </Select>
        }
      </TableCell>
    )
  }, [charts, onChartsChange]);

  const renderAbilityRadio = useCallback((num) => {
    const changes = ["Hardly Ever (10%)", "Occasionally (25%)", "Half the Time (50%)", "Most of Time (75%)", " Almost Always (95%)"];
    const handleAbilityChange = (newAbility) => {
      charts[num].ability = newAbility;
      onChartsChange(charts);
    }

    return changes.map(ability => (
      <TableCell align="center">
        <Radio
          checked={charts[num].ability === ability}
          onChange={() => handleAbilityChange(ability)}
        />
      </TableCell>
    ))
  }, [charts, onChartsChange]);

  const renderRow = useCallback(() => {
    return (
      <TableBody>
        {order.map(num => {
          return (
            <TableRow key={num} className="row">
              {renderPriorityOptions(num)}
              {renderText(num)}
              {renderCategoryOptions(num)}
              <div style={{border: "dotted", width: "1px", height: "50px", marginLeft:"15px"}}></div>
              {renderChangeRadio(num)}
              <div style={{border: "dotted", width: "1px", height: "50px", marginLeft:"15px"}}></div>
              {renderAbilityRadio(num)}
            </TableRow>
          )
        })}
      </TableBody>
    )
  }, [
    order,
    renderPriorityOptions,
    renderText,
    renderChangeRadio,
    renderCategoryOptions,
    renderAbilityRadio
  ]);

  return (
      <TableContainer className="row">
        <Table componet={Paper}  size="small" className="col">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={1}><P3>Indicate Order of Significance</P3></TableCell>
              <TableCell align="center" colSpan={1}><P2>Specific Needs</P2></TableCell>
              <TableCell align="center" colSpan={1}><P2>Category</P2></TableCell>
              <TableCell align="center" colSpan={6}><P2>Degree of Change</P2></TableCell>
              <TableCell align="center" colSpan={6}><P2>Final Ability (with hearing aid)</P2></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"><P3>Worse</P3></TableCell>
              <TableCell align="center"><P3>No Difference</P3></TableCell>
              <TableCell align="center"><P3>Slightly Better</P3></TableCell>
              <TableCell align="center"><P3>Better</P3></TableCell>
              <TableCell align="center"><P3>Much Better</P3></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"><P3>Hardly Ever (10%)</P3></TableCell>
              <TableCell align="center"><P3>Occasionally (25%)</P3></TableCell>
              <TableCell align="center"><P3>Half the Time (50%)</P3></TableCell>
              <TableCell align="center"><P3>Most of Time (75%)</P3></TableCell>
              <TableCell align="center"><P3>Almost Always (95%)</P3></TableCell>
            </TableRow>
          </TableHead>
          {renderRow()}
        </Table>
      </TableContainer>
  );
}

export default  DenseTable;