import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { P2 } from "assets/fonts";

const EyeDrawing = (props) => {
    const [array, setArray] = useState([]);
    const [index, setIndex] = useState(0);
    const fileReader = new FileReader();

    useEffect(() => {
        if (array.length !== 0) {
            drawing();
        }
    }, [array, index]);

    const handleOnSubmit = (e) => {
        const file = e.target.files[0];

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text)
            };
            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        const array = csvRows.map(row => {
            const _ = row.split("\"");
            return [parseInt(_[1]), parseInt(_[3]), parseInt(_[5]), _[7], _[9]];
        })

        seperateIntoEachFrame(array);
    };

    const seperateIntoEachFrame = (array) => {
        const allFrame = [];
        let singleFrame = [];
        let page = 1;
        array.map(data => {
            if (data[2] == page) singleFrame.push(data);
            else {
                page += 1;
                allFrame.push(singleFrame);
                singleFrame = [data];
            }
        });
        setArray(allFrame);
    };

    const drawPoint = (context, x, y, label, color, size) => {
        if (color == null) {
            color = '#000';
        }
        if (size == null) {
            size = 5;
        }
        
        var radius = 0.5 * size;

        // to increase smoothing for numbers with decimal part
        var pointX = Math.round(x - radius);
        var pointY = Math.round(y - radius);

        context.beginPath();
        context.fillStyle = color;
        context.fillRect(pointX, pointY, size, size);
        context.fill();
    
        if (label) {
            var textX = Math.round(x);
            var textY = Math.round(pointY - 5);
            
            context.font = 'Italic 14px Arial';
            context.fillStyle = color;
            context.textAlign = 'center';
            context.fillText(label, textX, textY);
        }
    }

    const drawing = () => {
        const canvas = document.getElementById("eye-track-canvas");
        const context = canvas.getContext('2d');

        array[index].map((data, i) => {
            drawPoint(context, data[0], data[1], i.toString(), "white", 5);
        });
    }

    const cleanCanvas = () => {
        const canvas = document.getElementById("eye-track-canvas");
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    }

    const handlePrevious = () => {
        if (index > 0) {
            setIndex(index - 1);
            cleanCanvas();
        }
    }

    const handleNext = () => {
        if (index < array?.length - 1) {
            setIndex(index + 1);
            cleanCanvas();
        }
    }

    return (
        <div style={{ textAlign: "center" }}>
            
            <div style={{ display: "flex", justifyContent: "center", margin: "5px"}}>
                <form><input type={"file"} accept={".csv"} onChange={handleOnSubmit} /></form>
                <Button variant="contained" color="default" onClick={handlePrevious}>Previous</Button>
                <P2 style={{marginLeft: "10px", marginRight: "10px"}}>Page {index + 1} / {array?.length}</P2>
                <Button variant="contained" color="default" onClick={handleNext}>Next</Button>
            </div>
            
            <canvas id="eye-track-canvas" width="800px" height="1035px" style={{backgroundColor: "black"}}></canvas>
        </div>
    );
}

export default EyeDrawing;