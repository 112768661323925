import React from "react";
import { Container, Button, Avatar } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';
import withMouseTrack from "../assets/mouse-track";
import { ExportReactCSV } from "appearance/assets/csv";


class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      wordAndPicture: props.wordAndPicture,
      realAnswer: props.realAnswer,
      userAnswer: [],
      currAnswer: [],
      currTimer: [],
      currZ: props.leftKey,
      currM: 1 - props.leftKey,
      time: null,
      timer: [],
      start: false,
      sent: false,
      trials: props.trials * 2,
      showX: false,
      restart: false,  // false: need to center the mouse
    };
  }

  componentDidMount = () => {
    for (let i = 1; i <= 11; i++) {
      const img = new Image();
      img.src = process.env.PUBLIC_URL + "/appearance/pictures/" + i + ".jpg";
    };
  };

  startTimer = () => {
    this.setState({ time: new Date().getTime() });
  };

  stopTimer = () => {
    const { currTimer, time } = this.state;
    currTimer.push(new Date().getTime() - time);
    this.setState({ currTimer, time: null });
  };

  handleMouseAnswer = (choice) => {
    this.props.onMouseEvent(new Date().getTime(), choice);
    this.checkAnswer(choice);
  }

  handleMouseStart = () => {
    setTimeout(() => this.startTimer(), 250);
    this.setState({ start: true });
  }

  handleMouseRestart = () => {
    this.props.onMouseEvent(new Date().getTime(), "start");
    setTimeout(() => this.startTimer(), 250);
    this.setState({ restart: true });
  }

  checkAnswer = async (choice) => {
    const { realAnswer, currAnswer, userAnswer, timer, index } = this.state;
    this.stopTimer();
    this.setState({ restart: false });

    if (choice === "left") {
      currAnswer.push("left");
      await this.setState({currAnswer});
      if (realAnswer[index] !== "left") {
        await this.setState({showX: true});
        setTimeout(() => this.setState({showX: false}), 1000)
        if (currAnswer.length < 3) {
          return this.startTimer();
        }
      }
      userAnswer.push(currAnswer);
      timer.push(this.state.currTimer);
      await this.setState({userAnswer, currAnswer: [], currTimer: [], index: index + 1, timer, currTimer: []});
    } else {
      currAnswer.push("right");
      await this.setState({currAnswer});
      if (realAnswer[index] !== "right") {
        await this.setState({showX: true});
        setTimeout(() => this.setState({showX: false}), 1000)
        if (currAnswer.length < 3) {
          return this.startTimer();
        }
      }
      userAnswer.push(currAnswer);
      timer.push(this.state.currTimer);
      await this.setState({userAnswer, currAnswer: [], currTimer: [], index: index + 1, timer, currTimer: []});
    }

    if (this.state.index < realAnswer.length) {
      setTimeout(() => this.startTimer(), 250);
    } else {
      this.handleNext();
    }
  }

  handleNext = () => {
    const { realAnswer, wordAndPicture, userAnswer, timer } = this.state;
    const { mouseTrack } = this.props;
    const block = {realAnswer, wordAndPicture, userAnswer, timer, mouseTrack};
    if (!this.state.sent) {
      this.setState({sent: true});
      this.props.handleTestClick(block);
    }
  }

  render() {
    const { restart, showX, wordAndPicture, index, start, currZ, currM } = this.state;
    const { boxSize, mouseTrack } = this.props;
    return (
      <div
        style={{
          textAlign: "center",
          position: "relative"
        }}
      >
        <ExportReactCSV headers={["X", "Y", "Timestamp", "Event"]} data={mouseTrack} fileName="MouseTrackingData" />
        {
          start ?
            <div>
              {
                restart ?
                <div>
                   <P2>Trial {index + 1} of {wordAndPicture.length}</P2>
                  <img
                    src={process.env.PUBLIC_URL + "/appearance/pictures/" + wordAndPicture[index]}
                    style={{ height: "70vh" }}
                  />
                </div>
                :
                <div
                  onMouseEnter={this.handleMouseRestart}
                  style={{ color:"green", position: "fixed", border: "dashed", left: window.innerWidth * 0.5 - boxSize[0] / 2, top: 0, width: boxSize[0], height: boxSize[1] }}
                >
                  <P1>Move mouse to here</P1>
                </div>
              }
              <div>
                <div
                  onMouseEnter={() => restart && this.handleMouseAnswer("left")}
                  style={{ color:"green", position: "fixed", border: "dashed", left: 0, bottom: 0, width: boxSize[0], height: boxSize[1] }}
                >
                  <P1>Z</P1>
                  {currZ === 0 ?
                  <P2>disfigured faces</P2>
                  : <P2>non-disfigured faces</P2>}
                </div>
                <div
                  onMouseEnter={() => restart && this.handleMouseAnswer("right")}
                  style={{ color:"green", position: "fixed", border: "dashed", right: 0, bottom: 0, width: boxSize[0], height: boxSize[1] }}
                >
                  <P1>M</P1>
                  {currM === 0 ?
                  <P2>disfigured faces</P2>
                  : <P2>non-disfigured faces</P2>}
                </div>
              </div>
              {
                showX ?
                <Avatar style={{backgroundColor: "red", position: "fixed", bottom: 30, left: "48%"}}>X</Avatar>
                : null
              }
            </div>
            :
            <div>
              <P1>Stage 1 of 7</P1>
              <P2>You are going to be shown a series of faces. </P2>
              <br />
              {currZ === 0 ?
              <P2>Moving mouse to Z for disfigured faces</P2>
              :
              <P2>Moving mouse to Z non-disfigured faces</P2>}
              {currM === 0 ?
              <P2>Moving mouse to M disfigured faces</P2>
              :
              <P2>Moving mouse to M non-disfigured faces</P2>}
              <br />
              <P2>Moving mouse to Start when you’re ready to start</P2>
              <div
                style={{ color:"green", position: "fixed", border: "dashed", left: 0, bottom: 0, width: boxSize[0], height: boxSize[1] }}
              >
                <P1>Z</P1>
                {currZ === 0 ?
                <P2>disfigured faces</P2>
                : <P2>non-disfigured faces</P2>}
              </div>
              <div
                onMouseEnter={this.handleMouseStart}
                style={{ color:"green", position: "fixed", border: "dashed", left: window.innerWidth * 0.5 - boxSize[0] / 2, bottom: 0, width: boxSize[0], height: boxSize[1] }}
              >
                <P1>Start</P1>
              </div>
              <div
                style={{ color:"green", position: "fixed", border: "dashed", right: 0, bottom: 0, width: boxSize[0], height: boxSize[1] }}
              >
                <P1>M</P1>
                {currM === 0 ?
                <P2>disfigured faces</P2>
                : <P2>non-disfigured faces</P2>}
              </div>
            </div >
        }
      </div>
    );
  }
}

export default withMouseTrack(Test);
