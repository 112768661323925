import React, { useState, useEffect } from "react";
import Page from "../utils/user-page";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Welcome from "GUI/user-components/welcome";
import { useParams } from "react-router-dom";

const GUIApp = () => {
  const initVariable = {
    volumecontrol: null,
    digits: null,
    randomid: [],
    imagerandom: [],
  };

  const [pages, setPages] = useState([]);
  const [currPage, setCurrPage] = useState(null);
  const [finalPage, setFinalPage] = useState(null);
  const [startAt, setStartAt] = useState(null);
  const [assigns, setAssigns] = useState([]);
  const [welcome, setWelcome] = useState(true);
  const [variable, setVariable] = useState(initVariable);
  const [blocksOrder, setBlocksOrder] = useState(null);
  const { app } = useParams();
  const [participantId] = useState(uuidv4().substring(0, 4));
  const [studyId, setStudyId] = useState(null);

  useEffect(() => {
    // get the current app's pages ids
    (async () => {
      const doc = await axios.get(`/api/gui/app/${app}`);
      if (doc.data) {
        setStudyId(doc.data.id);
        setPages(doc.data.pages);
        setCurrPage(doc.data.pages[0]);
        setFinalPage(doc.data.final_page);
      } else {
        setPages([]);
        setCurrPage(null);
      }
    })();

    // set start time
    setStartAt(new Date());
  }, []);

  // update global variable
  useEffect(() => {
    const newVariable = JSON.parse(JSON.stringify(variable));
    console.log(assigns);
    assigns.map((assign) => {
      switch (assign.type) {
        case "volumecontrol":
          newVariable.volumecontrol = assign;
          break;
        case "randomid":
          const randomIdArray = newVariable.randomid;
          let existing = false;
          randomIdArray.map((ele) => {
            if (ele.name == assign.name) {
              existing = true;
            }
          });
          if (!existing) {
            assign.randomId = Math.random().toString().substr(2, 4);
            newVariable.randomid.push(assign);
          }
          break;
        case "digits":
          newVariable.digits = assign;
          break;
      }
    });
    if (JSON.stringify(variable) != JSON.stringify(newVariable)) {
      setVariable(newVariable);
    }
  }, [assigns]);

  const updateAssign = (newAssign) => {
    const assign = assigns.find((assign) => newAssign?.id === assign.id);
    if (assign) {
      const newAssigns = assigns.map((assign) => {
        if (assign.id !== newAssign?.id) {
          return assign;
        } else {
          return newAssign;
        }
      });
      setAssigns(newAssigns);
    } else {
      const newAssigns = [...assigns];
      newAssigns.push(newAssign);
      setAssigns(newAssigns);
    }
  };

  const updateBlocksOrder = (types) => {
    if (!blocksOrder) {
      setBlocksOrder(types);
    }
  };

  const handleChange = (page = null) => {
    if (page) setCurrPage(page);
    else {
      const index = pages.indexOf(currPage);
      setCurrPage(pages[index + 1]);
    }
  };

  const handleSubmit = async (callback = () => {}) => {
    const doc = await axios.post("/api/gui/assign/" + participantId, {
      app,
      assigns,
      startAt,
    });
    if (doc.data) {
      callback();
    }
  };

  return welcome ? (
    <Welcome
      setWelcome={setWelcome}
      ParticipantId={participantId}
      StudyId={studyId}
    />
  ) : (
    <Page
      app={app}
      participantId={participantId}
      currPage={currPage}
      finalPage={finalPage}
      variable={variable}
      updateAssign={updateAssign}
      updateBlocksOrder={updateBlocksOrder}
      blocksOrder={blocksOrder}
      pages={pages}
      onSubmit={handleSubmit}
      onChange={handleChange}
    />
  );
};

export default GUIApp;
