import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { P1, P2, P3 } from 'assets/fonts';
import { HearingAWS } from 'assets/keys';
import { Container, Button, TextField } from '@material-ui/core';

const ImageRandom = (props) => {
  const { id } = props;
  const [files, setFiles] = useState(null);
  const [sections, setSections] = useState(null);
  const [sectionId, setSectionId] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/imagerandom/" + id);
        setSections(doc.data.sections);
        setSectionId(doc.data.sectionId);
      })();
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await axios.put("/api/gui/imagerandom/" + id, { sections });
    })();
  }, [sections]);

  const handleBlur = async () => await axios.put("/api/gui/imagerandom/" + id, { sectionId });

  const handleAddSection = () => setSections([...sections, []]);

  const handleUploadImages = async (index) => {
    if (files != null) {
      const imagePromises = files.map(async (file) => {
        const doc = await axios.get("/api/gui/imagerandom", { contentType: file.type });
        const uploadConfigs = doc.data;
        await axios.put(uploadConfigs.url, file, { headers: { "Content-type": file.type } });
        return uploadConfigs.key;
      });

      const url = await Promise.all(imagePromises);
      const newSections = [...sections];
      newSections[index] = url;
      setSections(newSections);
    };
  };

  return (
    <Container id="images">
      <P1>Images Randomization</P1>
      <P3>(You can add more sections, and select image(s) in each section, the program will randmoize one section to display)</P3>
      <div class="row"><P2 style={{ marginLeft: "10px" }}>Section ID: </P2><TextField value={sectionId} type="number" onChange={(e) => setSectionId(e.target.value)} onBlur={handleBlur} style={{ marginLeft: "10px" }} /></div>
      <P3>(If you want to have this randomization shows same image(s) at different positions in this page, give them same id here and make sure the images are in the same order, otherwise, leave it blank)</P3>
      <br />
      <div id="image-section">
        {
          sections?.map((section, i) => {
            return (
              <div key={i}>
                <P2>Section {i + 1}</P2>
                <div className="row">
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => setFiles(Array.from(e.target.files))}
                  />
                  <Button size="small" color="primary" variant="contained" onClick={() => handleUploadImages(i)}>Upload</Button>
                </div>
                <br />
                <div id="images-demo" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                  {section?.map(image => <img key={image} src={HearingAWS + image} style={{ maxHeight: "100%", maxWidth: "100%" }} />)}
                </div>
                <br />
              </div>
            )
          })
        }
        <Button color="primary" variant="contained" onClick={handleAddSection} >Add section</Button>
      </div>

      <br />
    </Container>
  )
};

export default ImageRandom;