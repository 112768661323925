import React from "react";
import { Container, Button, TextField } from "@material-ui/core";
import axios from "axios";
import Table from "appearance/assets/table";
import { ExportReactCSV } from "appearance/assets/csv";

class Database extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false,
            password: "",
            datas: [],
            headers: [],
            data: [],
            times: [0, 20, 20, 20, 40, 30, 20, 40],
            blocks: ["", "block1", "block2", "block3", "block4", "block5", "block6", "block7"]
        }
    }

    componentDidMount = async () => {
        const doc = await axios.get("/api/appearance/data");
        const headers = [
            "ID", "Email", "Modifying", "Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8",
            "Q9-1", "Q9-2", "Q9-3", "Q9-4", "Q9-5", "Q9-6", "Q9-7",
            "Q10-1", "Q10-2", "Q10-3", "Q10-4", "Q10-5", "Q10-6", "Q10-7", "Q10-8", "Q10-9",
            "Q10-10", "Q10-11", "Q10-12", "Q10-13", "Q10-14", "Q10-15", "Q10-16", "Q10-17",
            "Age", 'Gender', 'Education', 'Income', 'Country', 'Province', 'BigCity', 'Experience'
        ];
        const datas = [];
        for (let i = 1; i <= 7; i++) {
            let trials = this.state.times[i];
            for (let j = 1; j <= trials; j++) {
                headers.push(`b${i}-t${j}-realAnswer`);
                headers.push(`b${i}-t${j}-word/pic`);
                headers.push(`b${i}-t${j}-userAnswer`);
                headers.push(`b${i}-t${j}-timer1`);
                headers.push(`b${i}-t${j}-timer2`);
                headers.push(`b${i}-t${j}-timer3`);
                headers.push(`b${i}-t${j}-timeSum`);
            }
        }

        doc.data.map(d => {
            const q9 = Object.keys(d["q9"]).map(key => d["q9"][key]);
            const q10 = Object.keys(d["q10"]).map(key => d["q10"][key]);
            const data = [
                d["id"], d["email"], JSON.stringify(d["modifying"]),
                d["q1"], d["q2"], d["q3"], d["q4"], d["q5"], d["q6"], d["q7"], d["q8"],
                q9[0], q9[1], q9[2], q9[3], q9[4], q9[5], q9[6],
                q10[0], q10[1], q10[2], q10[3], q10[4], q10[5], q10[6], q10[7], q10[8], q10[9],
                q10[10], q10[11], q10[12], q10[13], q10[14], q10[15], q10[16],
                d['age'], d['gender'], d['education'], d['income'], d['country'], d['province'],
                d['city'], d['times']
            ];
            for (let i = 1; i <= 7; i++) {
                let block = this.state.blocks[i];
                let trials = this.state.times[i];
                for (let j = 0; j < trials; j++) {
                    if (d[block].timer[j]) {
                        data.push(d[block].realAnswer?.[j] ?? "N/A");
                        data.push(d[block].wordAndPicture?.[j] ?? "N/A");
                        data.push(d[block].userAnswer?.[j]?.toString() ?? "N/A");
                        data.push(d[block].timer?.[j]?.[0] ?? 0);
                        data.push(d[block].timer?.[j]?.[1] ?? 0);
                        data.push(d[block].timer?.[j]?.[2] ?? 0);
                        data.push(Number(d[block].timer?.[j]?.[0] ?? 0) + Number(d[block].timer?.[j]?.[1] ?? 0) + Number(d[block].timer?.[j]?.[2] ?? 0));
                    } else {
                        data.push("N/A", "N/A", "N/A", "N/A", "N/A", "N/A", "N/A");
                    }
                }
            }
            datas.push(data);
        })
        this.setState({ datas, headers, data: doc.data });
    }

    handleDelete = async (id) => {
        await axios.delete("/api/appearance/data/" + id);
        this.componentDidMount();
    }


    render() {
        const { login, data, headers, datas, password } = this.state;
        return (
            login ?
                <Container>
                    <div>

                        <h3>Export data in spreadsheet</h3>
                        {
                            datas.length !== 0 ?
                                <ExportReactCSV data={datas} headers={headers} fileName={"Appearance"} />
                                :
                                <h1>You do not have any data to extract.</h1>
                        }
                    </div>
                    <br />
                    <div>
                        <h3>Data Operation</h3>
                        {
                            datas.length !== 0 ?
                                <Table rows={data} handleDelete={this.handleDelete} />
                                :
                                <h1>You do not have any data to delete</h1>
                        }
                    </div>
                    <div>
                        <h3>Legend of labels</h3>
                        <ul>
                            <li>Modifying: is this test a modifying test? true or false</li>
                            <li>BigCity: Do you live in a city with a population greater than 100 000 people?</li>
                            <li>UserDisfigurement: Do you have a facial disfigurement?</li>
                            <li>OthersDisfigurement: Do you know someone who has a facial disfigurement?</li>
                            <li>Experience: Have you ever completed an Implicit Association Test (IAT) before? If yes, how many tests have you completed?</li>
                            <li>b1-t1-realAnswer: real answer in block 1 trial 1, 1 means positive and 0 means negative (disfigured image or negative)</li>
                            <li>b1-t1-word/pic: which word or picture presented in block 1 trial 1 (for ex. 1.jpg, you can refer to pictures listed below)</li>
                            <li>b1-t1-userAnswer: user can try 3 times at most, 1 means user chose the positive answer, 0 means negative</li>
                            <li>b1-t1-leftKey: leftKey is 0 means leftKey has the negative choice, rightKey has the positive choice. Vice versa</li>
                            <li>b1-t1-timer1: how much time in millisecond the user used in block 1 trial 1 at the first time, so time2 means at the second time, time3 is the last time</li>
                            <li>b1-t1-timeSum: how much time in millisecond the user used in block 1 trial 1 totally</li>
                            <li>Q1: How often have you encountered or do you interact with a person with a facial disfigurement (marks, rashes, scars, asymmetry, paralysis, etc.)?</li>
                            <li>Q2: Do you have a disability?</li>
                            <li>Q3: Do you have a facial disfigurement?</li>
                            <li>Q4: Do you have a close friend of family member with a disability?</li>
                            <li>Q5: Do you have a close friend of family member with a facial disfigurement?</li>
                            <li>Q6: Which statement best describes you?</li>
                            <li>Q7: How warm or cold do you feel towards people with facial disfigurements?</li>
                            <li>Q8: How warm or cold do you feel towards people without facial disfigurements?</li>
                            <li>Q9-1: They are more happy, confident, assured, and cheerful than others</li>
                            <li>Q9-2: They are more sad, shy, and miserable than others</li>
                            <li>Q9-3: They are more attractive, desirable, and eligible than others</li>
                            <li>Q9-4: They are more unattractive, undesirable, and unsuitable than others</li>
                            <li>Q9-5: They are more easy-going, approachable, likeable, and friendly than others</li>
                            <li>Q9-6: They are more awkward, unlikeable, unapproachable, and unfriendly than others</li>
                            <li>Q9-7: They are more successful, motivated, accomplished, and more likely to succeed than others</li>
                            <li>Q10-1: Sad - Happy</li>
                            <li>Q10-2: Unconfident – Confident</li>
                            <li>Q10-3: Incompetent – Competent</li>
                            <li>Q10-4: Shy – Assured</li>
                            <li>Q10-5: Miserable – Cheerful</li>
                            <li>Q10-6: Unattractive – Attractive</li>
                            <li>Q10-7: Undesirable – Desirable</li>
                            <li>Q10-8: Ugly – Gorgeous</li>
                            <li>Q10-9: Stupid – Intelligent</li>
                            <li>Q10-10: Unsuitable – Eligible</li>
                            <li>Q10-11: Awkward – Easy-going</li>
                            <li>Q10-12: Untrustworthy – Trustworthy</li>
                            <li>Q10-13: Unapproachable – Approachable</li>
                            <li>Q10-14: Unfriendly – Friendly</li>
                            <li>Q10-15: Non-Achiever – Achiever</li>
                            <li>Q10-16: Ordinary – Accomplished</li>
                            <li>Q10-17: Unmotivated - Motivated</li>
                        </ul>
                        <br />
                        <div id="pic">
                            <div className="row">
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/1.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/2.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/3.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/4.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/5.jpg"} style={{ width: "20%" }} />
                            </div>
                            <div className="row">
                                <div style={{ width: "20%", textAlign: "center" }}><h4>1.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>2.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>3.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>4.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>5.jpg</h4></div>
                            </div>
                            <div className="row">
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/6.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/7.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/8.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/9.jpg"} style={{ width: "20%" }} />
                                <img src={process.env.PUBLIC_URL + "/appearance/pictures/10.jpg"} style={{ width: "20%" }} />
                            </div>
                            <div className="row">
                                <div style={{ width: "20%", textAlign: "center" }}><h4>6.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>7.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>8.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>9.jpg</h4></div>
                                <div style={{ width: "20%", textAlign: "center" }}><h4>10.jpg</h4></div>
                            </div>
                        </div>
                    </div>
                </Container>
                :
                <Container style={{ marginTop: "25%", marginLeft: "25%" }}>
                    <TextField hint="password" type="password" value={password} onChange={e => this.setState({ password: e.target.value })} />
                    <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={() => password === "thisisthepassword" ? this.setState({ login: true }) : window.alert("Wrong password!")}>Login</Button>
                </Container>
        );
    }
}

export default Database;
