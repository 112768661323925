import React, { useState, useEffect, useCallback } from 'react';
import { Radio, FormGroup, FormControlLabel, Container, TextField } from '@material-ui/core';
import { P1, P2, P3 } from 'assets/fonts';
import axios from 'axios';

const AgeSelect = (props) => {
  const { id } = props;
  const [question, setQuestion] = useState("");
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);
  const [options, setOptions] = useState([]);
  const [optional, setOptional] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/ageselect/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
        if (!!doc.data.options.length && doc.data.options.length !== 0) {
          setMinAge(doc.data.options[0]);
          setMaxAge(doc.data.options[doc.data.options.length - 1]);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (!!minAge && !!maxAge && minAge <= maxAge) {
      const newOptions = [];
      for (let i = minAge; i <= maxAge; i++) {
        newOptions.push(i);
      }
      setOptions(newOptions);
    }
  }, [minAge, maxAge]);

  const handleBlur = async () => {
    await axios.put("/api/gui/ageselect/" + id, { question, options });
  }

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/ageselect/" + id, { optional: newBool });
  }

  const handleQuestionChange = useCallback((e) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
  }, []);

  return (
    <Container>
      <P1>Age Select</P1>
      <div className='row' style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={<Radio color="primary" checked={optional === true}
            onChange={e => handleOptionalChange(true)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={<Radio color="primary" checked={optional === false}
            onChange={e => handleOptionalChange(false)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
      </div>
      <div className="row">
        <P2 style={{ marginRight: "20px", marginLeft: "15px", fontWeight: "300" }}>Question/Text: </P2>
        <TextField value={question} style={{ width: "60%" }} onChange={handleQuestionChange} onBlur={handleBlur} />
      </div>
      <br />
      <div className="row">
        <TextField type="number" value={minAge} label="min" style={{ marginRight: "20px" }} onBlur={handleBlur} onChange={(e) => setMinAge(Number(e.target.value))} />
        <TextField type="number" value={maxAge} label="max" onBlur={handleBlur} onChange={(e) => setMaxAge(Number(e.target.value))} />
      </div>
      <br />
    </Container>
  );
}

export default AgeSelect;