import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import TrainCard from "literacy/assets/cards/tutorallassigncard";
import { ExportReactCSV } from "assets/csv";

class FluencyTutorAllTest extends React.Component {
  constructor() {
    super();
    this.state = { assignments: [], headers: [], data: [] };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/literacy/fluency/test");
    await this.setState({ assignments: doc.data });
    this.saveExportData();
  };

  saveExportData = () => {
    let headers = ["Student Name", "Student Email", "Time", "Average Speed"];
    for (let i = 1; i <= 15; i++) {
      headers.push(
        `Paragraph-${i}`,
        `Question-${i}`,
        `Choice-${i}-1`,
        `Choice-${i}-2`,
        `Choice-${i}-3`,
        `Choice-${i}-4`,
        `Answer-${i}`,
        `StudentAnswer-${i}`,
        `Correct-${i}`,
        `Speed-${i}`
      );
    }
    const datas = [];
    for (let _ of this.state.assignments) {
      let data = [];
      const { studentName, studentEmail, createAt, averageSpeed, assignment } =
        _;
      data.push(studentName, studentEmail, createAt, averageSpeed);
      for (let assignQuestion of assignment) {
        const { paragraph, question, choices, answer, studentAnswer, speed } =
          assignQuestion;
        data.push(
          paragraph,
          question,
          choices[0],
          choices[1],
          choices[2],
          choices[3],
          answer,
          studentAnswer,
          (answer == studentAnswer).toString(),
          speed
        );
      }
      datas.push(data);
    }
    this.setState({ headers, data: datas });
  };

  render() {
    const { assignments, headers, data } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Review Students' Access Assignment</h2>
          <br />
          <ExportReactCSV
            data={data}
            headers={headers}
            fileName="Literacy Access Assign"
          />
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#107896",
              marginLeft: "10px",
            }}
            href="/literacy/tutor/fluency"
          >
            Go back
          </Button>
        </div>
        <Container>
          <div className="row">
            {assignments.map((assign) => {
              return (
                <TrainCard
                  title={assign.studentName}
                  description={assign.createAt}
                  handleClick={() =>
                    (window.location =
                      "/literacy/tutor/fluency/test/" + assign._id)
                  }
                />
              );
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default FluencyTutorAllTest;
