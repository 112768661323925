import React from "react";
import axios from "axios";
import { Container } from "@material-ui/core";
import PrintHeader from "literacy/components/student/print/assets/header";
import Q1Table from "literacy/components/tutor/thread-assign-table/multiple-choice-table";
import Q2Table from "literacy/components/tutor/thread-assign-table/blank-table";
import Q3Table from "literacy/components/tutor/thread-assign-table/true-false-table";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

class printOneAssign extends React.Component {
  constructor() {
    super();
    this.state = { assignment: null };
  }

  componentDidMount = async () => {
    const doc = await axios.get(
      "/api/literacy/print/assign/" + this.props.match.params.id
    );
    if (doc.data) {
      this.setState({ assignment: doc.data });
    }
  };

  render() {
    const { assignment } = this.state;
    return (
      <div style={{ backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <PrintHeader part="Results" backURL="/literacy/student/print/results" />
        <Container>
          {assignment ? (
            <div>
              <br />
              <P2>Multiple Choice</P2>
              <Q1Table rows={assignment.q1Assign} />
              <br />
              <P2>Fill in the Blank</P2>
              <Q2Table rows={assignment.q2Assign} />
              <br />
              <P2>True and False</P2>
              <Q3Table rows={assignment.q3Assign} />
              <br />
              <div>
                <P3>Old Score: {assignment.oldScore}</P3>
                <P3>New Score: {assignment.newScore}</P3>
              </div>
              <br />
            </div>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default printOneAssign;
