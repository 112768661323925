import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user-reducer";
import fontReducer from "./font/font-reducer";
import readyComponentReducer from "./ready-components/readyComponent-reducer";
import mandatoryComReducer from "./mandatory-components/mandatoryCom-reducer";
import authorizedReducer from "./authorized/authorized-reducer";

const persistConfig = {
  key: "root", // from root
  storage,
  whitelist: ["font"], // which reducer to persist
};

const rootReducer = combineReducers({
  user: userReducer,
  font: fontReducer,
  readyComponent: readyComponentReducer,
  mandatoryCom: mandatoryComReducer,
  authorized: authorizedReducer,
});

export default persistReducer(persistConfig, rootReducer);
