import React, { useState, useEffect } from "react";
import axios from "axios";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';
import { ExportReactCSV } from "./csv";

const ClassificationDatabase = () => {
    const [assignments, setAssignments] = useState([]);
    const [indexOpen, setIndexOpen] = useState(null);
    const [csvData, setCsvData] = useState([]);

    useEffect(async () => {
        const doc = await axios.get("/api/gui/imageprocess/assignment");
        const data = [];
        doc.data?.map((assign) => {
            assign.assign?.map((image) => {
                const imageLink = image.image;
                const array = imageLink.split("/");
                const index = array[array.length - 1];
                const oldColor = array[array.length - 2];
                const newColor = image.color;
                const matched = String(oldColor === newColor);
                console.log([assign.name, index, oldColor, newColor, matched]);
                data.push([assign.name, index, oldColor, newColor, matched]);
            })
        });
        console.log(data);
        setAssignments(doc.data);
        setCsvData(data);
    }, []);

    const tableBody = (image) => {
        // https://image-process-bucket.s3.ca-central-1.amazonaws.com/classification/green/56.jpg
        const imageLink = image.image;
        const array = imageLink.split("/");
        const index = array[array.length - 1];
        const oldColor = array[array.length - 2];
        const newColor = image.color;

        return <TableRow>
            <TableCell align="center"><img src={imageLink} style={{ height: 200, width: 200 }} /></TableCell>
            <TableCell align="center">{index}</TableCell>
            <TableCell align="center">{oldColor}</TableCell>
            <TableCell align="center">{newColor}</TableCell>
            <TableCell align="center">{oldColor === newColor ? <CheckIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "red" }} />}</TableCell>
        </TableRow>
    }

    const tableHeader = (assign, index) => {
        return indexOpen === index ? <div>
            <Button variant="outlined" color="secondary" onClick={() => setIndexOpen(null)}>Close set {index + 1}</Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">image</TableCell>
                            <TableCell align="center">image index</TableCell>
                            <TableCell align="center">old color</TableCell>
                            <TableCell align="center">new color</TableCell>
                            <TableCell align="center">matched</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assign.assign?.map((image) => tableBody(image))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" color="secondary" onClick={() => setIndexOpen(null)}>Close set {index + 1}</Button>
        </div> : <Button variant="outlined" color="primary" onClick={() => setIndexOpen(index)}>Open set {index + 1}</Button>
    }

    return <div>
        {
            csvData.length > 0 && <ExportReactCSV
                headers={["participant", "image name", "old color", "new color", "matched"]}
                data={csvData}
                fileName="ImageClassification.csv"
            />
        }
        {
            assignments.map((assign, index) => <div style={{ margin: 100 }}>
                Name: {assign.name ?? "Not provided"}<br />
                {tableHeader(assign, index)}
                <br />
            </div>)
        }
    </div>
}

export default ClassificationDatabase;
