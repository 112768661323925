import React from "react";
import MeaningIntro from "literacy/components/student/meaning/assign/trainintro";
import MeaningTrain from "literacy/components/student/meaning/assign/trainpart";
import MeaningProgress from "literacy/components/student/meaning/assign/train-progress";
import MeaningHeader from "literacy/components/student/meaning/assets/header";
import { Container } from "@material-ui/core";

class MeaningPractise extends React.Component {
  state = {
    start: false,
    id: "",
    version: "w1"
  };

  render() {
    const { start, id, version } = this.state;
    return (
      <div style={{
        backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
        fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        <Container>
          {start ? (
            id === "" ? (
              <MeaningTrain version={version} />
            ) : (
                <MeaningProgress id={id} />
              )
          ) : (
              <div>
                <MeaningHeader part="Training Assignment" backURL="/literacy/student/meaning"/>
                <MeaningIntro
                  handleClick={(id, version) => this.setState({ start: !start, id, version })}
                />
              </div>
            )}
        </Container>
      </div>
    );
  }
}
export default MeaningPractise;
