import React from "react";
import { Button, Container } from "@material-ui/core";
import {P1, P2, P3} from 'assets/fonts';


export default (props) => {
  return <Container>
    <div style={{ marginTop: "20%", marginLeft: "10%", marginRight: "10%" }}>
      <P2>Take a short break before continuing the test. When you're ready click [START BUTTON] on screen.</P2><br />
      <Button
        variant="contained"
        style={{ color: "white", backgroundColor: "#107896", margin: 20, width: 150 }}
        onClick={props.handleClick}>Start</Button>
    </div>

  </Container>
}