import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import ScoreSlider from "GUI/assets/score-slider";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const Slider = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/slider/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  useEffect(() => {
    handleUpdateAssign();
    if (!!answer) {
      readyToContinue(id);
    }
  }, [answer]);

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "slider",
      question,
      options,
      answer,
    };
    updateAssign(assign);
  };

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>{question}</P2>
      <br />
      <div className="row">
        <div className="col-2">
          <P3 style={{ fontWeight: "300" }}>{options[0]}</P3>
        </div>
        <div className="col-8">
          <ScoreSlider key={id} handleScore={setAnswer} />
        </div>
        <div className="col-2">
          <P3 style={{ fontWeight: "300" }}>{options[1]}</P3>
        </div>
      </div>
      <br />
    </Container>
  );
};

export default Slider;
