import React from "react";
import { Container } from "@material-ui/core";
import Footer from "hearing/components/partials/footer";
import { P1, P2, P3 } from "assets/fonts";

export default ({ setWelcome, ParticipantId, StudyId }) => {
  return (
    <Container>
      <br />
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "24%",
        }}
      >
        <P1 style={{ fontSize: "10vh" }}>Welcome</P1>
        {/* <P2 style={{ marginTop: "2vh" }}>Participant ID: {ParticipantId}</P2>
        <P2>Study ID: {StudyId}</P2> */}
        <button
          className="button"
          onClick={() => setWelcome(false)}
          style={{ height: "8vh", marginTop: "5vh" }}
        >
          <span style={{ fontSize: "3vh" }} class="button-content">
            Start the test!
          </span>
        </button>
      </div>
      <Footer />
    </Container>
  );
};
