import PrintHeader from "literacy/components/student/print/assets/header";
import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import AssignCard from "literacy/assets/cards/student-assign-card";
import LineChart from "literacy/assets/assignchart";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import axios from "axios";

class Materials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assigns: [],
      score: [],
    };
  }

  componentDidMount = async () => {
    // assignments
    const doc1 = await axios.get("/api/literacy/print/assign");
    const allAssigns = doc1.data;
    const assigns = [];
    if (allAssigns) {
      allAssigns.forEach((assign) => {
        if (assign.studentId === this.props.currentUser?._id) {
          assigns.push(assign);
        }
      });
      this.setState({ assigns });
    }

    // score
    const doc2 = await axios.get(
      "/api/literacy/print/historyscore/" + this.props.currentUser?._id
    );
    if (doc2.data) {
      this.setState({ score: doc2.data.assignScore });
    }
  };

  render() {
    const { assigns, score } = this.state;
    return (
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
          fontFamily: "raven",
          height: window.innerHeight,
          overflowY: "scroll",
        }}
      >
        <PrintHeader part="Results" backURL="/literacy/student/print" />
        <Container>
          <P2>Assignments</P2>
          <div style={{ display: "flex" }}>
            {assigns.map((assign) => (
              <AssignCard
                assign={assign}
                handleClick={() =>
                  (window.location =
                    "/literacy/student/print/results/" + assign._id)
                }
              />
            ))}
          </div>
        </Container>
        <br />
        <Container>
          <P2>Performence</P2>
          {score.length !== 0 ? (
            <LineChart
              data={score}
              title="Print Assignment Performence"
              color="#3E517A"
            />
          ) : null}
        </Container>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Materials);
