import React from "react";
import TrainCard from "literacy/assets/cards/trainpagecard";
import { Container, Button } from "@material-ui/core";

class PrintTutorMain extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="jumbotron">
          <h2>Welcome to Print Session Tutor Main Page</h2>
          <br />
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#107896" }}
            href="/literacy"
          >
            Go back
          </Button>
        </div>
        <Container>
          <h3>Review Students' Assignments and Performance</h3>
          <div className="row">
            <TrainCard
              title="Students' Assignment"
              page="/literacy/tutor/print/assign"
              description="In this part, you can review students' testing assignment"
            />
            <TrainCard
              title="Students' Performance"
              page="/literacy/tutor/print/performance"
              description="In this part, you can check students' performance"
            />
          </div>
        </Container>
        <br />

        <Container>
          <h3>Modify Print Database</h3>
          <div className="row">
            <TrainCard
              title="Modify Learning Materials"
              page="/literacy/tutor/print/materials"
              description="In this part, you can modify learning materials"
            />
            <TrainCard
              title="Modify Print Multiple Choice Data"
              page="/literacy/tutor/print/multiple"
              description="You can modify Multiple Choice question here"
            />
            <TrainCard
              title="Modify Print Blank Question Data"
              page="/literacy/tutor/print/blank"
              description="You can modify Print blank Question data here"
            />
            <TrainCard
              title="Modify Print True False data"
              page="/literacy/tutor/print/truefalse"
              description="In this part, you can modify true false question data"
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default PrintTutorMain;
