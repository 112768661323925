import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import { P1, P2, P3 } from 'assets/fonts';


const useStyles = makeStyles({
  root: {
    width: "100%"
  },
});

export default function ContinuousSlider(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleScore(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Slider
            id={props.key}
            value={value ?? 50}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid item xs={2}>
          <P3 style={{ fontWeight: "300" }}>{value ?? <P3 style={{ color: "#FF6666" }}>* required</P3>}</P3>
        </Grid>
      </Grid>
    </div>
  );
}
