import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import Markdown from "markdown-to-jsx";
import axios from "axios";

const TextFieldArea = (props) => {
  const { id } = props;
  const [text, setText] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/puretext/" + id);
        setText(doc.data.text);
      })();
    }
  }, [id]);

  return (
    <Container>
      <br />
      <P2>
        <Markdown style={{ fontWeight: "300" }} children={text} />
      </P2>
      <br />
    </Container>
  );
};

export default TextFieldArea;
