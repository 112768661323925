import { Container, Paper, Button } from '@material-ui/core';
import { AddCircleOutline } from "@material-ui/icons";
import React, { useState, useEffect } from 'react';
import { P1, P2, P3 } from "assets/fonts";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const Pagination = (props) => {
  const { app, currPage, finalPage, onChange } = props;
  const [dragState, setDragState] = useState(false);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages(props.pages);
  }, [props.pages]);

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
    setDragState(true);
  }

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDragState(false);
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  const handleDrop = async (e, key) => {
    const id = e.dataTransfer.getData("id");
    // get newPages
    const newPages = [];
    let i = 0;
    while (i < pages.length) {
      if (i === key) {
        newPages.push(id);
      }
      if (pages[i] !== id) {
        newPages.push(pages[i]);
      }
      i++;
    }
    // check if the com was dragged to the last slot
    if (i === key) {
      newPages.push(id);
    }
    // change order in app in db
    await axios.put("/api/gui/app", { name: app, pages: newPages });
    setDragState(false);
    setPages(newPages);
  }

  const handleFinalPage = async (id) => {
    await axios.put("/api/gui/app", { name: app, final_page: id });
    window.location.reload();
  }

  const handleAddPage = async (id) => {
    // get new pages
    const newPages = [...pages];
    newPages.push(id);
    // add page into db
    await axios.post("/api/gui/page/" + id);
    // add page into app in db
    await axios.put("/api/gui/app", { name: app, pages: newPages });
    setPages(newPages);
  };

  const handleDuplicatePage = async (page) => {
    // build one new page
    const newPage = uuidv4();
    await handleAddPage(newPage);
    // get all old components
    const doc = await axios.get("/api/gui/page/" + page);
    const components = doc.data.components;
    // duplicate each components
    const promises = await components.map(async (component) => {
      const id = uuidv4();
      const type = component.type;
      // create new component
      const newDoc = await axios.post("/api/gui/" + type + "/" + id);
      // get old component content
      const comDoc = await axios.get("/api/gui/" + type + "/" + component.id);
      // update new component by old content
      await axios.put("/api/gui/" + type + "/" + id, { ...comDoc.data, id, _id: newDoc.data._id });
      return { id, type };
    })
    const newComponents = await Promise.all(promises);

    // update page components
    await axios.put("/api/gui/page/" + newPage, { components: newComponents });
  };

  const handleDelete = async (e) => {
    const id = e.dataTransfer.getData("id");
    // check if the page has any components
    const doc = await axios.get("/api/gui/page/" + id);
    if (doc.data?.components?.length !== 0) {
      return window.alert("You have to delete all components inside this page before you delete the page!");
    } else {
      // get new pages
      const oldPages = [...pages];
      const newPages = [];
      let i = 0;
      while (i < oldPages.length) {
        if (oldPages[i] !== id) {
          newPages.push(oldPages[i]);
        }
        i++;
      }
      // delete the page in database
      await axios.delete("/api/gui/page/" + id);
      // delete the page in app in db
      await axios.put("/api/gui/app", { name: app, pages: newPages });
      setPages(newPages);
    }
    setDragState(false);
  }

  return (
    <Container style={{ padding: 100 }}>
      <div className="row">
        {
          dragState
            ?
            <Paper
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 0)}
              style={{ border: "dotted", height: "100px", width: "50px", paddingTop: "20px" }}
            >
              <P3 style={{ fontWeight: "300" }}>Drop Here</P3>
            </Paper>
            :
            <div style={{ height: "100px", width: "50px" }}></div>
        }
        {pages.map((page, i) => (
          <div key={i + 1} className="row" style={{ width: "150px", height: "100px", margin: "0px" }}>
            <Paper
              style={{ width: "100px", height: "100px", border: "solid", backgroundColor: currPage === page ? "grey" : "white" }}
              draggable
              onDragStart={(e) => handleDragStart(e, page)}
              onDragEnd={handleDragEnd}
              onClick={() => onChange(page)}
            >
              {<Button variant="contained" color="primary" size="small" disabled={page == finalPage} style={{ marginLeft: "15px", height: "20px" }} onClick={() => handleFinalPage(page)}>Final</Button>}
              <center><P1 style={{ marginTop: "5px" }}>{i + 1}</P1></center>
              {/* <Button variant="outlined" color="primary" size="small" style={{ marginLeft: "15px", marginTop: -10, height: "20px" }} onClick={() => handleDuplicatePage(page)}>+</Button> */}
            </Paper>
            {
              dragState
                ?
                <Paper
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, i + 1)}
                  style={{ border: "dotted", height: "100px", width: "50px", paddingTop: "20px" }}
                >
                  <P3 key={i + 1} style={{ fontWeight: "300" }}>Drop Here</P3>
                </Paper>
                :
                <div style={{ height: "100px", width: "50px" }}></div>
            }
          </div>
        ))}
        {
          dragState
            ?
            <Paper
              onDragOver={handleDragOver}
              onDrop={(e) => handleDelete(e)}
              style={{ border: "dotted", color: "red", height: "100px", width: "50px", paddingTop: "30px", paddingLeft: "10px" }}
            >
              <DeleteForeverIcon size="large" />
            </Paper>
            :
            <div style={{ height: "100px", width: "50px" }}></div>
        }
        <Paper
          style={{
            width: "100px",
            height: "100px",
            paddingTop: "40px",
            margin: "0px"
          }}
          onClick={() => handleAddPage(uuidv4())}
        >
          <center>
            <AddCircleOutline color="primary" />
          </center>
        </Paper>
      </div>
      <P3>(you can drag the page around to change the order or delete the page, you can click on the plus "+" inside the page to duplicate one page, you can click on the "+"" within the blue circle to create a new page)</P3>
      <br />
    </Container>
  )
}

export default Pagination;