import React from "react";
import {
  Container,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField
} from "@material-ui/core";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { P1, P2, P3 } from 'assets/fonts';


class DemoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: null,
      gender: null,
      education: null,
      income: null,
      country: null,
      province: null,
      city: null,
      experience: null,
      agePicker: [],
      times: 0
    };
  }

  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    const agePicker = [];
    for (let i=18; i<=120; i++){
      agePicker.push(i);
    }
    this.setState({agePicker});
  }

  checkValidation = () => {
    const { age, gender, education, income, country, province, city, experience} = this.state;
    if (age == null || gender == null || education == null || income == null || country == null ||province == null || city == null || experience == null ) {
      return false;
    }
    return true;
  }

  renderButton = () => {
    const {
      age,
      gender,
      education,
      income,
      country,
      province,
      city,
      times
    } = this.state;
    if (this.checkValidation()) {
      return (
        <Button
          variant="contained"
          size="large"
          style={{
            width: 100,
            margin: 10,
            color: "white",
            backgroundColor: "#107896"
          }}
          onClick={() => this.props.handleClick(
            age,
            gender,
            education,
            income,
            country,
            province,
            city,
            times
          )}
        >
          Next
        </Button>
      );
    } else {
      return (
        <div>
          <Button
            variant="contained"
            size="large"
            style={{
              width: 100,
              margin: 10,
              color: "white",
              backgroundColor: "#107896"
            }}
            disabled={true}
          >
            Next
          </Button>
          <P3>(You have not completed the form)</P3>
        </div>
        
      );
    }
  };

  render() {
    const { agePicker, age, country, province } = this.state;
    return (
      <Container style={{margin: "5%"}}>
        <div id="age">
          <P2>How old are you?</P2>
          <FormControl>
          <InputLabel id="label">Age</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select gender"
            value={age}
            style={{ width: 100 }}
            onChange={(e) => this.setState({age: e.target.value})}
          >
            {
              agePicker.length !== 0 ?
              agePicker.map((age) => <MenuItem value={age}>{age}</MenuItem>)
              : null
            }
          </Select>
          </FormControl>
        </div>
        <br /><br />
        <div id="gender">
          <P2>What gender do you identify as?</P2>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.gender}
              style={{ width: 150 }}
              onChange={(e) => this.setState({ gender: e.target.value })}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"Non-binary"}>Non-binary</MenuItem>
              <MenuItem value={"Prefer not to say"}>Prefer not to say</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br /> <br />
        <div id="eduacation">
          <P2>What is your highest level of education?</P2>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Eduacation</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.education}
              style={{ width: 550 }}
              onChange={(e) => this.setState({ education: e.target.value })}
            >
              <MenuItem value={"Some high school"}>Some high school</MenuItem>
              <MenuItem value={"High school diploma"}>High school diploma</MenuItem>
              <MenuItem value={"Apprenticeship or trades certificate or diploma "}>Apprenticeship or trades certificate or diploma </MenuItem>
              <MenuItem value={"College, or other non-university certificate or diploma"}>College, or other non-university certificate or diploma</MenuItem>
              <MenuItem value={"Some university, no degree"}>Some university, no degree</MenuItem>
              <MenuItem value={"University certificate, diploma or degree at bachelor level or above"}>University certificate, diploma or degree at bachelor level or above</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br /> <br />
        <div id="income">
          <P2>What is your approximate annual household income?</P2>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Income</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.income}
              style={{ width: 200 }}
              onChange={(e) => this.setState({ income: e.target.value })}
            >
              <MenuItem value={"Less than $50 000"}>Less than $50,000</MenuItem>
              <MenuItem value={"$50 000 - $ 100 000"}>$50,000 - $100,000</MenuItem>
              <MenuItem value={"More than $100 000"}>More than $100,000</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br /> <br />
        <div id="country">
          <P2>Which country and region do you currently live?</P2>
          <P3>Country: </P3>
          <CountryDropdown
            value={country}
            onChange={(val) => this.setState({ country: val })} />
            <br /><br />
          <P3>Region: </P3>
          <RegionDropdown
            country={country}
            value={province}
            style={{width: 385}}
            onChange={(val) => this.setState({ province: val })} />
          </div>
          <br /><br />
        <div id="city">
          <P2>Do you live in a city with a population greater than 100,000 people?</P2>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.city}
              style={{ width: 80 }}
              onChange={(e) => this.setState({city: e.target.value})}
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br/><br/>
        <div id="experience">
          <P2>Have you ever completed an Implicit Association Test (IAT) before?</P2>
          <P2>If yes, how many tests have you completed?</P2>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.experience}
              style={{ width: 150 }}
              onChange={(e) => this.setState({experience: e.target.value})}
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"I don't know"}>I don't know</MenuItem>
            </Select>
          </FormControl>
          {
            this.state.experience === "Yes" ?
            <TextField type="number" value={this.state.times} onChange={(e) => this.setState({times: e.target.value})} style={{ width: 80 }} />
            : null
          }
        </div><br />
        <div id='button'>{this.renderButton()}</div>  
      </Container>
    );
  }
}

export default DemoPage;
