import React from "react";
import axios from "axios";
import { Button, Container, TextField } from "@material-ui/core";
import Table from "hearing/assets/signal-loss/data-table";
import { ExportReactCSV } from "hearing/components/partials/csv";

class Data extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newReversals: null,
      reversals: null,
      array: [],
      login: false,
      password: "",
      datas: [],
      headers: [],
      numOfRecords: 0
    };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/hearing/digits4/user");
    const array = [];
    const datas = [];
    const headers = ["participantId", "studyId", "Output", "Volume", "Date", "WithAids", "SNR-avg", "SNR-1", "SNR-2"];

    // wirte headers
    for (let j = 1; j <= 24; j++) {
      headers.push(`ReactTimer-1-${j}`);
      headers.push(`RealTimer-1-${j}`);
      headers.push(`Dbs-1-${j}`);
      headers.push(`Question-1-${j}`);
      headers.push(`Answer-1-${j}`);
      headers.push(`Correct-1-${j}`);
    };
    for (let j = 1; j <= 24; j++) {
      headers.push(`ReactTimer-2-${j}`);
      headers.push(`RealTimer-2-${j}`);
      headers.push(`Dbs-2-${j}`);
      headers.push(`Question-2-${j}`);
      headers.push(`Answer-2-${j}`);
      headers.push(`Correct-2-${j}`);
    };

    // write datas
    doc.data.map(data => {
      const row = [
        String(data["participantId"]), String(data["studyId"]),
        String(data["output"]), String(data["volume"]),
        String(data["date"])?.split('T')?.[0], String(data["withAids"]),
        ((parseFloat(data["SNR1"]) + parseFloat(data["SNR2"])) / 2).toFixed(3),
        String(data["SNR1"]), String(data["SNR2"])
      ];
      // add correct, questions, answers, etc.
      for (let j = 0; j < 24; j++) {
        row.push(data.reactTimer1[j]);
        row.push(data.realTimer1[j]);
        row.push(data.dbs1[j + 1]);
        row.push(data.questions1[j]);
        row.push(data.answers1[j]);
        row.push(JSON.stringify(data.questions1[j] === data.answers1[j]));
      }
      for (let j = 0; j < 24; j++) {
        row.push(data.reactTimer2[j]);
        row.push(data.realTimer2[j]);
        row.push(data.dbs2[j + 1]);
        row.push(data.questions2[j]);
        row.push(data.answers2[j]);
        row.push(JSON.stringify(data.questions2[j] === data.answers2[j]));
      }
      datas.push(row);
    });
    // write the array
    doc.data.map(data => {
      const obj = {};
      obj["_id"] = data._id;
      obj["studyId"] = String(data["studyId"]);
      obj["participantId"] = String(data["participantId"]);
      obj["SNR"] = ((parseInt(data["SNR1"]) + parseInt(data["SNR2"])) / 2).toFixed(3);
      array.push(obj);
    });
    this.setState({ array, headers, datas, numOfRecords: array.length });
  }

  deleteData = async (id) => {
    await axios.delete("/api/hearing/digits4/user/" + id);
    this.componentDidMount();
  }

  login = () => {
    if (this.state.password === "thisisthepassword") {
      this.setState({ login: true });
    } else {
      this.setState({ password: "" });
      window.alert("Wrong login information, retry please!");
    }
  }

  render() {
    const { array, login, password, datas, headers, numOfRecords } = this.state;
    return (
      login ?
        <Container>
          <br />
          <h3 style={{ textAlign: "center" }}>Welcome to Digits in Noise database</h3>
          <div>
            <br />
            <div id="spreadsheet">
              <h4>Spreadsheet Download</h4>
              <ExportReactCSV data={datas} headers={headers} fileName={"digits_in_noise_data"} />
            </div>
            <br /><br />
            <h5>Amount of records: {numOfRecords}</h5>
            <br /><br />
            <div id="table">
              <h4>Data Operation</h4>
              <Table data={array} deleteData={this.deleteData} />
              <br />
            </div>
            <br />
            <div id="legends">
              <h5>Legend for data label</h5>
              <p>output: headset or speaker</p>
              <p>volume: in 100 scale</p>
              <p>withAids: with hearing aids, true or false</p>
              <p>SNR-avg: average of SNR 1 and SNR 2</p>
              <p>SNR-1: SNR of block 1</p>
              <p>reactTimer-1-5: time between audio start and button push of trial 5 in block 1</p>
              <p>realTimer-1-5: time between button push and finish of trial 5 in block 1</p>
              <p>questions-1-5: digits presented in the audio of trial 5 in block 1</p>
              <p>dbs-1-5: how much decibels is at the end of trial 5 in block 1</p>
            </div>
          </div>
        </Container>
        :
        <Container>
          <div style={{ position: "fixed", left: "30%", right: "30%", top: "20%" }}>
            <TextField value={password} style={{ width: 300 }} onChange={(e) => this.setState({ password: e.target.value })} label="password" type="password" /><br /><br />
            <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={this.login} >Log In</Button>
          </div>
        </Container>
    );
  }
}

export default Data;
