import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import { Container, FormControlLabel, Radio } from "@material-ui/core";
import Markdown from "markdown-to-jsx";
import axios from "axios";

const AlphabetExperiment = (props) => {
  const { id } = props;
  const [optional, setOptional] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/alphabetexperiment/" + id);
        setOptional(doc.data.optional);
      })();
    }
  }, [id]);

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/alphabetexperiment/" + id, { optional: newBool });
  };

  return (
    <Container>
      <P1>Alphabet Experiment</P1>
      <div className="row" style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={
            <Radio
              color="primary"
              checked={optional === true}
              onChange={(e) => handleOptionalChange(true)}
            />
          }
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={
            <Radio
              color="primary"
              checked={optional === false}
              onChange={(e) => handleOptionalChange(false)}
            />
          }
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
      </div>
    </Container>
  );
};

export default AlphabetExperiment;
