import { P1, P2, P3 } from "assets/fonts";
import { Container } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";

const StudyId = (props) => {
  const { id } = props;
  const [studyId, setStudyId] = useState("");

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/studyids/" + id);
        setStudyId(doc.data.studyId);
      })();
    }
  }, [id]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setStudyId(newText);
  };

  const handleSubmit = async () => {
    await axios
      .put("/api/gui/studyids/" + id, { studyId })
      .then(window.alert("Study ID is updated!"));
  };

  return (
    <Container>
      <P1>Study id</P1>
      <div>
        <p>
          Please enter Study id: (Wait for alert saying the study ID is updated)
        </p>
        <TextField
          value={studyId}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
      <br />
      <br />
    </Container>
  );
};

export default StudyId;
