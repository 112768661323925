import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  LinearProgress,
} from "@material-ui/core";
import {
  Filter1, Filter2, Filter3, Filter4,
  Filter1TwoTone, Filter2TwoTone, Filter3TwoTone, Filter4TwoTone
} from "@material-ui/icons";
let timer;
let newTime = 0;


class FluencyTestPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 0,
      currentParaNum: 0,
      maxNumOfQues: 0,
      length: 0,
      currPara: null,
      currParaArray: [],
      paragraphs: [],
      questions: [],
      choices: [],
      answers: [],
      studentAnswers: [],
      yourAnswer: null,
      readDone: false,
      answerred: false,
      correctNum: 0,
      speeds: [],
      check: [],
      time: 0
    };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/literacy/fluency/student/test");
    const data = doc.data;
    await this.setState({
      paragraphs: data.paragraphs,
      questions: data.questions,
      choices: data.choices,
      answers: data.answers,
      maxNumOfQues: data.paragraphs.length - 1
    });
    await this.setState({
      currPara: this.state.paragraphs[this.state.currentParaNum],
    });
    const sentenceArray = await this.state.currPara.split("");
    await this.setState({ currParaArray: sentenceArray });
    await this.setState({
      length: this.state.currParaArray.length,
      readDone: true
    });
    this.toggleReading();
  };

  toggleReading = async () => {
    const { readDone, speeds, length } = this.state;
    if (readDone) {
      await this.setState({ readDone: false });
      timer = setInterval(() => (newTime += 1), 1);
    } else {
      clearInterval(timer);
      let newSpeeds = speeds;
      const newSpeed = Number((newTime / length).toFixed(3));
      newSpeeds.push(newSpeed);
      newTime = 0;
      this.setState({ readDone: true, speeds: newSpeeds });
    }
  };

  checkAnswer = async (e) => {
    const studentAnswer = e.target.value;
    const {
      studentAnswers,
      answers,
      currentParaNum,
      score,
      correctNum,
      check,
    } = this.state;
    let newStudentAnswers = studentAnswers;
    newStudentAnswers.push(e.target.value);
    this.setState({ studentAnswers: newStudentAnswers });
    let newCheck = check;
    if (studentAnswer === answers[currentParaNum]) {
      newCheck.push(true);
      this.setState({
        answerred: true,
        score: score + 1,
        correctNum: correctNum + 1,
        check: newCheck,
      });
    } else {
      newCheck.push(false);
      this.setState({ answerred: true, check: newCheck });
    }
  };

  changeQuestion = async () => {
    await this.setState({
      currentParaNum: this.state.currentParaNum + 1,
      currPara: this.state.paragraphs[this.state.currentParaNum + 1],
      answerred: false,
    });
    const sentenceArray = await this.state.currPara.split("");
    await this.setState({ currParaArray: sentenceArray });
    await this.setState({ length: this.state.currParaArray.length });
    this.toggleReading();
  };

  finishTrain = async () => {
    const {
      speeds,
      questions,
      paragraphs,
      answers,
      choices,
      studentAnswers,
      check,
    } = this.state;
    let assignment = [];
    for (let i = 0; i < questions.length; i++) {
      assignment.push({
        paragraph: paragraphs[i],
        question: questions[i],
        choices: choices[i],
        answer: answers[i],
        studentAnswer: studentAnswers[i],
        speed: speeds[i],
      });
    }
    // update student's current speed
    let speedSum = 0;
    let num = 0;
    for (let i = 0; i < speeds.length; i++) {
      if (check[i]) {
        speedSum += speeds[i];
        num += 1;
      }
    }
    const newSpeed = Number((speedSum / num).toFixed(3));
    await axios.put("/api/literacy/fluency/score", { newSpeed });
    // update the assignment
    await axios.post("/api/literacy/fluency/student/test", {
      assignment,
      averageSpeed: newSpeed,
    });
    window.location = "/literacy/student/fluency";
  };

  render() {
    const {
      readDone,
      answerred,
      currentParaNum,
      maxNumOfQues,
      questions,
      choices,
      currPara,
    } = this.state;
    const progress = Math.floor((currentParaNum / maxNumOfQues) * 100);
    return (
      <Container>
        {readDone ? (
          answerred ? (
            currentParaNum < maxNumOfQues ? (
              <div>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#107896" }}
                  onClick={this.changeQuestion}
                  size="large"
                >
                  Next sentence
                </Button>
                <br />
              </div>
            ) : (
              <div>
                <P1>You have finish all the testing questions!</P1>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#107896" }}
                  onClick={this.finishTrain}
                  size="large"
                >
                  Good Job!
                </Button>
              </div>
            )
          ) : (
            <div>
              <P1>{questions[currentParaNum]}</P1>
              <FormControlLabel
                value={choices[currentParaNum][0]}
                label={choices[currentParaNum][0]}
                control={<Radio icon={<Filter1 />} checkedIcon={<Filter1TwoTone />} />}
                onChange={this.checkAnswer}
              />
              <br />
              <FormControlLabel
                value={choices[currentParaNum][1]}
                label={choices[currentParaNum][1]}
                control={<Radio icon={<Filter2 />} checkedIcon={<Filter2TwoTone />} />}
                onChange={this.checkAnswer}
              />
              <br />
              <FormControlLabel
                value={choices[currentParaNum][2]}
                label={choices[currentParaNum][2]}
                control={<Radio icon={<Filter3 />} checkedIcon={<Filter3TwoTone />} />}
                onChange={this.checkAnswer}
              />
              <br />
              <FormControlLabel
                value={choices[currentParaNum][3]}
                label={choices[currentParaNum][3]}
                control={<Radio icon={<Filter4 />} checkedIcon={<Filter4TwoTone />} />}
                onChange={this.checkAnswer}
              />
            </div>
          )
        ) : (
          <div>
            <P2>{currPara}</P2>
            <Button
              size="large"
              style={{ color: "white", backgroundColor: "#107896" }}
              variant="contained"
              onClick={this.toggleReading}
            >
              Finish
            </Button>
          </div>
        )}
        <br />
        <LinearProgress variant="determinate" value={progress} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(FluencyTestPart);
