import React, { useEffect, useState } from 'react';
import { Radio, FormGroup, FormControlLabel, Container, TextField } from '@material-ui/core';
import { P1, P2, P3 } from 'assets/fonts';
import axios from 'axios';

const Slider = (props) => {
  const { id } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [left, setLeft] = useState("");
  const [right, setRight] = useState("");
  const [optional, setOptional] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/slider/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options);
        setOptional(doc.data.optional ?? false);
        if (!!doc.data.options.length && doc.data.options.length === 2) {
          setLeft(doc.data.options[0]);
          setRight(doc.data.options[1]);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (!!left && !!right) {
      setOptions([left, right]);
    }
  }, [left, right]);

  const handleBlur = async () => {
    await axios.put("/api/gui/slider/" + id, { question, options });
  }

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/slider/" + id, { optional: newBool });
  }

  return (
    <Container>
      <br />
      <P1>Slider</P1>
      <div className='row' style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={<Radio color="primary" checked={optional === true}
            onChange={e => handleOptionalChange(true)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={<Radio color="primary" checked={optional === false}
            onChange={e => handleOptionalChange(false)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
      </div>
      <div className="row"><P2 style={{ fontWeight: "300", marginTop: '5px' }}>Question: </P2><TextField value={question} style={{ marginLeft: 15, width: "60%" }} onBlur={handleBlur} onChange={(e) => setQuestion(e.target.value)} /></div>
      <div className="row">
        <P2 style={{ fontWeight: "300", marginTop: '5px' }}>Left label</P2><TextField value={left} style={{ marginLeft: 15 }} onBlur={handleBlur} onChange={(e) => setLeft(e.target.value)} />
        <P2 style={{ fontWeight: "300", marginTop: '5px', marginLeft: "10px" }}>Right label</P2><TextField value={right} style={{ marginLeft: 15 }} onBlur={handleBlur} onChange={(e) => setRight(e.target.value)} />
      </div>
      <br />
    </Container>
  )
}

export default Slider;