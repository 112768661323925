import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { CSVLink } from "react-csv";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();
  const [singleHeader, setSingleHeader] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const [tag, setTag] = useState({});

  const handleDownloadData = async (id, participantId, experimentId) => {
    const doc = await axios.get("/api/gui/assign/single/" + id);
    const docData = doc.data;
    const assigns = docData.assigns;

    // write header
    const header = [
      "study-name",
      "study-id",
      "experiment-id",
      "participant-id",
      "start-time",
      "end-time",
    ];
    const data = [
      docData.app,
      props.StudyId,
      experimentId,
      participantId,
      docData.startAt
        ? new Date(docData.startAt).toLocaleString("en-US", {
            timeZone: "America/Denver",
          })
        : "Not recorded",
      docData.endAt
        ? new Date(docData.endAt).toLocaleString("en-US", {
            timeZone: "America/Denver",
          })
        : "Not recorded",
    ];

    assigns.map((assign, i) => {
      switch (assign.type) {
        case "radio":
          header.push(`${i}-radio-question`, `${i}-radio-answer`);
          data.push(assign.question, assign.answer);
          break;
        case "select":
        case "ageselect":
          header.push(`${i}-select-question`, `${i}-select-answer`);
          data.push(assign.question, assign.answer);
          break;
        case "textfield":
          header.push(`${i}-textfield-question`, `${i}-textfield-answer`);
          data.push(assign.question, assign.answer);
          break;
        case "datepicker":
          header.push(`${i}-date-question`, `${i}-date-answer`);
          data.push(assign.question, assign.answer);
          break;
        case "imagerandom":
          header.push(`${i}-random-image`);
          data.push(assign.image);
          break;
        case "slider":
          header.push(`${i}-slider-question`, `${i}-slider-answer`);
          data.push(assign.question, assign.answer);
          break;
        case "ohhis":
          header.push(`${i}-OHHIS`);
          data.push(assign.code);
          break;
        case "checkbox":
          header.push(
            `${i}-checkbox-question`,
            `${i}-checkbox-choices`,
            `${i}-checkbox-answer`
          );
          data.push(
            assign.question,
            String(assign.options),
            String(assign.answer)
          );
          break;
        case "randomid":
          header.push(`${i}-random-id`);
          data.push(assign.answer + String.fromCharCode(8203));
          break;
        case "volumecontrol":
          header.push(`${i}-volumecontrol-title`, `${i}-volumecontrol-answer`);
          data.push(assign.title, assign.answer + String.fromCharCode(8203));
          break;
        case "recoverystimulus":
          header.push(`${i}-DIN-SNR`);
          for (let j = 1; j <= assign.answer?.sentenceAnswers.length; j++) {
            header.push(`${i}-${j}-recovery-name`, `${i}-${j}-recovery-answer`);
            header.push(
              `${i}-${j}-DIN-question`,
              `${i}-${j}-DIN-answer`,
              `${i}-${j}-audio-db`
            );
            header.push(
              `${i}-${j}-recovery-audio-start`,
              `${i}-${j}-recovery-target-start`,
              `${i}-${j}-recovery-target-end`,
              `${i}-${j}-recovery-response-start`,
              `${i}-${j}-recovery-response-end`
            );
            header.push(
              `${i}-${j}-DIN-audio-start`,
              `${i}-${j}-DIN-first-digit`,
              `${i}-${j}-DIN-second-digit`,
              `${i}-${j}-DIN-third-digit`,
              `${i}-${j}-DIN-response-end`
            );
          }
          const { sentenceAnswers, timestamps } = assign.answer;
          const SNR1 = assign.answer.SNR;
          const questions1 = assign.answer.questions;
          const answers1 = assign.answer.answers;
          const dbs1 = assign.answer.dbs;
          data.push(SNR1 + String.fromCharCode(8203));
          console.log(timestamps);
          for (let j = 0; j < answers1.length; j++) {
            const { name, answer } = sentenceAnswers[j];
            data.push(
              name,
              answer,
              questions1[j] + String.fromCharCode(8203),
              answers1[j] + String.fromCharCode(8203),
              dbs1[j + 1] + String.fromCharCode(8203)
            );
            const ts = timestamps[j];

            for (let _ of ts) {
              data.push(_ + String.fromCharCode(8203));
            }
          }
          break;
        case "digits":
          header.push(`${i}-DIN-SNR`);
          for (let j = 1; j <= assign.answer?.answers.length; j++) {
            header.push(
              `${i}-${j}-DIN-reacttime`,
              `${i}-${j}-DIN-realtime`,
              `${i}-${j}-DIN-question`,
              `${i}-${j}-DIN-answer`,
              `${i}-${j}-DIN-db`
            );
          }
          const { reactTimer, realTimer } = assign.answer;

          const SNR2 = assign.answer.SNR;
          const questions2 = assign.answer.questions;
          const answers2 = assign.answer.answers;
          const dbs2 = assign.answer.dbs;
          data.push(SNR2 + String.fromCharCode(8203));
          for (let j = 0; j < answers2.length; j++) {
            data.push(
              reactTimer[j] + String.fromCharCode(8203),
              realTimer[j] + String.fromCharCode(8203),
              questions2[j] + String.fromCharCode(8203),
              answers2[j] + String.fromCharCode(8203),
              dbs2[j + 1] + String.fromCharCode(8203)
            );
          }
          break;
        case "crm":
          header.push(`${i}-CRM-type`, `${i}-CRM-SNR`);
          for (let j = 1; j <= assign.answer?.answers.length; j++) {
            header.push(
              `${i}-${j}-DIN-timer`,
              `${i}-${j}-DIN-question`,
              `${i}-${j}-DIN-answer`,
              `${i}-${j}-DIN-correct`,
              `${i}-${j}-DIN-db`
            );
          }
          const { type, timer } = assign.answer;
          const SNR3 = assign.answer.SNR;
          const questions3 = assign.answer.questions;
          const answers3 = assign.answer.answers;
          const correct = assign.answer.correct;
          const dbs3 = assign.answer.dbs;
          data.push(type, SNR3 + String.fromCharCode(8203));
          for (let j = 0; j < answers3.length; j++) {
            data.push(
              timer[j] + String.fromCharCode(8203),
              questions3[j] + String.fromCharCode(8203),
              answers3[j] + String.fromCharCode(8203),
              correct[j] + String.fromCharCode(8203),
              dbs3[j + 1] + String.fromCharCode(8203)
            );
          }
          break;
        case "dfdexperiments":
          header.push(
            `${i}-Question`,
            `${i}-Answer`,
            `${i}-decibel`,
            `${i}-reactTimer`
          );
          const playedAudio = assign.answer.question;
          const clickedButton = assign.answer.answer; // Updated from 'clickedButton' to 'clickedButtons' according to the provided data structure
          const decibel = assign.answer.decibel;
          const ReactTimer = assign.answer.reactTimer; // 'ReactTimer' should be 'reactTimer' according to the provided data structure
          data.push(playedAudio, clickedButton, decibel, ReactTimer);
          break;
        case "alphabetexperiment":
          header.push(
            `${i}-Question`,
            `${i}-Answer`,
            `${i}-Score`,
            `${i}-letterTimings letter`,
            `${i}-letterTimings appearTime`,
            `${i}-letterTimings disappearTime`,
            `${i}-similarity`
          );
          const question = assign.question;
          const answer = assign.answer;
          const score = assign.score;
          const letterTimings = assign.letterTimings;
          const similarity = assign.similarity.current;
          data.push(
            question,
            answer,
            score,
            //TODO: the letterTiming is currently undefined remove it or fix it
            letterTimings.letter,
            letterTimings.appearTime,
            letterTimings.disappearTime,
            similarity
          );
          // map over the answer, question, resTime, similarity
          // letterTimings { letter: 'a', appearTime: 0, disappearTime: 0 }
          for (let j = 0; j < assign.answer.length; j++) {
            header.push(
              `${i}-${j}-Answer`,
              `${i}-${j}-Question`,
              `${i}-${j}-letterTimings letter`,
              `${i}-${j}-letterTimings appearTime`,
              `${i}-${j}-letterTimings disappearTime`,
              `${i}-${j}-similarity`
            );
            data.push(
              assign.answer[j],
              assign.question[j],
              assign.letterTimings[j].letter,
              assign.letterTimings[j].appearTime,
              assign.letterTimings[j].disappearTime,
              assign.similarity.current[j]
            );
          }
          break;
        default:
          break;
      }
    });
    setSingleHeader(header);
    setSingleData([data]);
  };

  const handleTagChange = (participantId, experimentId) => (e) => {
    const newTag = e.target.value;
    setTag((prevTag) => ({
      ...prevTag,
      [participantId + experimentId]: newTag,
    }));
    console.log("tag and participantId", newTag, participantId);
  };

  const handleSubmitTag = (participantId, experimentId) => async () => {
    const newTag = tag[participantId + experimentId];
    console.log("tag and participantId", newTag, participantId);
    await axios.put("/api/gui/tag/" + participantId + experimentId, {
      tag: newTag,
    });
  };

  useEffect(() => {
    if (props.data) {
      props.data.map((data) => {
        if (data.participantId + data.experimentId in tag) {
          return;
        }
        axios
          .get("/api/gui/tag/" + data.participantId + data.experimentId)
          .then((res) => {
            setTag((prevTag) => ({
              ...prevTag,
              [data.participantId + data.experimentId]: res.data.tag,
            }));
          });
      });
    }
  }, [props.data]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: "20px" }} align="left">
              Study (App) Name: {props.app}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontSize: "20px" }} align="left">
              Study ID: {props.StudyId}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Tag and Note</TableCell>
            <TableCell align="right">Experiment ID</TableCell>
            <TableCell align="right">Participant ID</TableCell>
            <TableCell align="right">Date and Time of record</TableCell>
            <TableCell align="right">Operation</TableCell>
            <TableCell align="right">Record</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((data) => {
            if (data.participantId == null) {
              data.participantId = data.id;
            }
            return (
              <TableRow>
                <TableCell align="right">
                  <TextField
                    style={{ width: "100%" }}
                    value={tag[data.participantId + data.experimentId] || ""}
                    onBlur={handleSubmitTag(
                      data.participantId,
                      data.experimentId
                    )}
                    onChange={handleTagChange(
                      data.participantId,
                      data.experimentId
                    )}
                    placeholder="Enter Tag"
                  />
                </TableCell>
                <TableCell align="right">{data.experimentId}</TableCell>
                <TableCell align="right">{data.participantId}</TableCell>
                <TableCell align="right">
                  {new Date(data.endAt).toLocaleString("en-US", {
                    timeZone: "America/Denver",
                  })}
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => props.deleteData(data.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
                {singleData &&
                singleHeader &&
                singleData[0][3] == data.participantId ? (
                  <TableCell align="right">
                    <CSVLink
                      data={singleData}
                      headers={singleHeader}
                      filename={data.participantId}
                    >
                      Download
                    </CSVLink>
                  </TableCell>
                ) : (
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        handleDownloadData(
                          data.id,
                          data.participantId,
                          data.experimentId
                        )
                      }
                    >
                      Request
                    </Button>
                  </TableCell>
                )}
                {/* <TableCell>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleSubsequentAudio}
                  >
                    Download Audios
                  </Button>
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
