import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Backdrop, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { Header } from "../../main-components/header.jsx";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import logo2 from "../../assets/img/ua-logo.svg";
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import Ellipse2 from "../../assets/images/Ellipse 2.svg";
import Ellipse3 from "../../assets/images/Ellipse 3.svg";
import TestTile from "./testTile";
import styles from "./indexPage.module.css";

const GUIUser = () => {
  const [open, setOpen] = useState(false);
  const [currCollection, setCurrCollection] = useState(null);
  const history = useHistory();

  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  useEffect(async () => {
    document.title = "Rehab Science";
    updateLayer({ id: "1" });
  }, []);

  const onAppClick = (app) => {
    setOpen(true);
    const appName = app.replace(/ /g, "");

    setTimeout(() => {
      setOpen(false);
      history.push(`/gui/${appName}`);
    }, 1500);
  };

  const updateLayer = async (ele) => {
    const doc = await axios.get("/api/gui/collection/" + ele.id);
    setCurrCollection(doc.data);
  };

  const handleBack = () => {
    updateLayer({ id: currCollection.prevCollection });
  };

  return (
    <div>
      <Header />
      <img className={styles.ellipse2} alt="Ellipse" src={Ellipse2} />
      <section className={styles.experimentSection}>
        <h1 className={styles.experimentTitle}>Audio Experiment</h1>
        <div className={styles.cardContainer}>
          {currCollection?.list.map((data, index) => (
            <TestTile
              key={index}
              name={data.name}
              type={data.type}
              onAppClick={() => onAppClick(data.name)}
              updateLayer={() => updateLayer(data)}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default GUIUser;
