import React from "react";
import { connect } from "react-redux";
import SignInHeader from "literacy/components/header/signinheader";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import { Button } from "@material-ui/core";

const FluencyHeader = (props) => {
  const { currentUser } = props;
  const renderPage = () => {
    switch (currentUser) {
      case null:
        return;
      case false:
        return <SignInHeader />;
      default:
        return (
          <p>
            <P1>{props.part}</P1>
            <br />
            <P2>{props.desc}</P2><br />
            {currentUser.fluency_score.length === 0 ? null : (
              <P3>
                Your current reading speed is{" "}
                {Number(currentUser.fluency_score[currentUser.fluency_score.length - 1]["value"])?.toFixed(2)}
                (ms / letter)
              </P3>
            )}
            <Button style={{ color: "white", backgroundColor: "#107896" }} onClick={() => window.location = props.backURL ?? "/literacy"} color="primary" variant="outlined">Back</Button>
          </p>
        );
    }
  };
  return <div className="jumbotron" style={{ backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)" }}>{renderPage()}</div>;
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(FluencyHeader);
