import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux"; // Make sure to import connect
import { setUserAuthorized } from "./redux/authorized/authorized-reducer"; // Adjust the path as necessary

class LoginPage extends Component {
  state = {
    password: "",
    error: false,
  };

  handleInputChange = (e) => {
    this.setState({ password: e.target.value, error: false });
  };

  handleLogin = () => {
    const { password } = this.state;
    const { history, location, setUserAuthorized } = this.props; // Destructure setUserAuthorized from props

    if (password === "thisisthepassword") {
      setUserAuthorized(true); // Call the action creator directly
      const redirectTo = location.state?.from || "/";
      history.push(redirectTo); // Redirect to the intended path or home
    } else {
      this.setState({ error: true });
      alert("Wrong credential, please retry!");
    }
  };

  render() {
    const { password, error } = this.state;
    return (
      <div style={{ textAlign: "center", paddingTop: "20vh" }}>
        <h3>Please input the password to access this experiment</h3>
        <TextField
          error={error}
          helperText={error ? "Incorrect password" : ""}
          type="password"
          value={password}
          onChange={this.handleInputChange}
          style={{ width: "300px" }}
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={this.handleLogin}
        >
          Login
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserAuthorized: (isAuthorized) =>
    dispatch(setUserAuthorized(isAuthorized)),
});

// Correctly apply withRouter and connect
export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
