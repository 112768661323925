import React from "react";
import { Button, Container, InputLabel } from "@material-ui/core";
import { connect } from "react-redux";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

class FluencyAssignIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: "w1",
    };
  }

  renderButtons = () => {
    const { currentUser } = this.props;
    const { section } = this.state;
    if (!currentUser) return null;
    if (section === "w1") {
      if (
        new Date().getTime() - new Date(currentUser.createdAt).getTime() <
        604800000 * 6
      ) {
        return (
          <P2 style={{ marginRight: 20 }}>
            You need to wait for 6 weeks to do speed assignment 1.
          </P2>
        );
      } else {
        switch (currentUser.fluency_progress.w1) {
          case "":
            return (
              <Button
                variant="contained"
                size="large"
                style={{
                  marginLeft: 20,
                  marginRight: 10,
                  color: "white",
                  backgroundColor: "#107896",
                }}
                onClick={() => this.props.handleClick("", "w1")}
              >
                Start
              </Button>
            );
          default:
            return (
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    marginLeft: 20,
                    marginRight: 10,
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#107896",
                  }}
                  onClick={() =>
                    this.props.handleClick(
                      currentUser.fluency_progress.w1,
                      "w1"
                    )
                  }
                >
                  Resume last assignment
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "#107896",
                    marginRight: 10,
                    textTransform: "none",
                  }}
                  size="large"
                  onClick={() => this.props.handleClick("", "w1")}
                >
                  Start new assignment
                </Button>
              </div>
            );
        }
      }
    } else {
      if (
        new Date().getTime() - new Date(currentUser.createdAt).getTime() <
        604800000 * 7
      ) {
        return (
          <P2 style={{ marginRight: 20 }}>
            You need to wait for 7 weeks to do speed assignment 2.
          </P2>
        );
      } else if (currentUser.fluency_score.length >= 1) {
        switch (currentUser.fluency_progress.w2) {
          case "":
            return (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  marginLeft: 20,
                  marginRight: 10,
                  color: "white",
                  backgroundColor: "#107896",
                }}
                onClick={() => this.props.handleClick("", "w2")}
              >
                Start
              </Button>
            );
          default:
            return (
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    marginLeft: 20,
                    marginRight: 10,
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#107896",
                  }}
                  onClick={() =>
                    this.props.handleClick(
                      currentUser.fluency_progress.w2,
                      "w2"
                    )
                  }
                >
                  Resume last assignment
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    marginRight: 10,
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#107896",
                  }}
                  onClick={() => this.props.handleClick("", "w2")}
                >
                  Start new assignment
                </Button>
              </div>
            );
        }
      } else if (currentUser.fluency_score.length < 1) {
        return (
          <div>
            <h4 style={{ marginRight: 20 }}>
              You need to do assignment 1 first!
            </h4>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <Container>
        <Container>
          <InputLabel id="label">
            <P2>Assignment Section</P2>
          </InputLabel>
          <Button
            variant="contained"
            style={{ margin: "3px" }}
            onClick={() => this.setState({ section: "w1" })}
          >
            Week 1
          </Button>
          <Button
            variant="contained"
            style={{ margin: "3px" }}
            onClick={() => this.setState({ section: "w2" })}
          >
            Week 2
          </Button>
        </Container>
        <br />
        <P3>
          Now, you will get to test and train your reading speed. You will see a
          sentence, from which letters will start to disappear one-by-one.
          Please read and answer the questions as best as you can.
        </P3>
        <br />
        <div style={{ display: "flex" }}>{this.renderButtons()}</div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(FluencyAssignIntro);
