import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

const useStyles = makeStyles({
  root: {
    width: 380,
    margin: 10,
  },
  media: {
    height: 170,
  },
});

export default function MainCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ backgroundColor: 'transparent', border: 'solid' }}>
      <CardActionArea href={props.page} style={{ textDecoration: "none" }}>
        <CardMedia
          className={classes.media}
          image={props.image}
        />
        <CardContent>
          <Typography gutterBottom>
            <P2>{props.title}</P2>
          </Typography>
          <Typography>
            <P3>{props.description}</P3>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
