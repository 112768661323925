import React from "react";
import axios from "axios";
import { Button, Container, TextField } from "@material-ui/core";
import Table from "iRSM/assets/data-table";
import { ExportReactCSV } from "hearing/components/partials/csv";

class Data extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newReversals: null,
      reversals: null,
      array: [],
      login: false,
      password: "",
      datas: [],
      headers: [],
      numOfRecords: 0
    };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/hearing/irsm/user");
    const array = [];
    const datas = [];
    const headers = [
      "participantId", "studyId", "Output", "Volume", "Date", "WithAids1", "SNR1", "WithAids2", "SNR2"
    ];

    // wirte headers
    for (let i = 1; i <= 2; i++) {
      for (let j = 1; j <= 23; j++) {
        headers.push(`ReactTimer${i}-t${j}`);
        headers.push(`RealTimer${i}-t${j}`);
        headers.push(`Dbs${i}-t${j}`);
        headers.push(`Question${i}-t${j}`);
        headers.push(`Answer${i}-t${j}`);
        headers.push(`Correct${i}-t${j}`);
      }
      for (let j = 1; j <= 6; j++) {
        headers.push(`TestQuestion-${i}-${j}`);
      }
    };

    // write datas
    doc.data.map(data => {
      const row = [
        String(data["participantId"]), String(data["studyId"]),
        String(data["output"]), String(data["volume"]),
        String(data["date"])?.split('T')?.[0],
        String(data["withAids1"]), String(data["SNR1"]),
        String(data["withAids2"]), String(data["SNR2"])
      ];
      // add correct, questions, answers, etc.
      for (let i = 1; i <= 2; i++) {
        let reactTimer;
        let realTimer;
        let dbs;
        let questions;
        let answers;
        let postTestQuestions;
        switch (i) {
          case 1:
            reactTimer = "reactTimer1";
            realTimer = "realTimer1";
            dbs = "dbs1";
            questions = "questions1";
            answers = "answers1";
            postTestQuestions = "postTestQuestions1";
            break;
          case 2:
            reactTimer = "reactTimer2";
            realTimer = "realTimer2";
            dbs = "dbs2";
            questions = "questions2";
            answers = "answers2";
            postTestQuestions = "postTestQuestions2"
            break;
        }
        for (let j = 0; j < 23; j++) {
          row.push(data[reactTimer][j]);
          row.push(data[realTimer][j]);
          row.push(data[dbs][j + 1]);
          row.push(data[questions][j]);
          row.push(data[answers][j]);
          row.push(JSON.stringify(data[questions][j] === data[answers][j]));
        }
        data[postTestQuestions].map((question) => {
          row.push(question.answer);
        });
      }
      datas.push(row);
    });

    // write the array
    const tobiHeaders = ["trial", "computer timestamp", "react time", "real time"];
    doc.data.map(data => {
      const tobiDatas = [];
      for (let j = 0; j < 23; j++) {
        tobiDatas.push([j + 1, data.timestamp1[j], data.reactTimer1[j], data.realTimer1[j]]);
      }
      for (let j = 0; j < 23; j++) {
        tobiDatas.push([j + 1, data.timestamp2[j], data.reactTimer2[j], data.realTimer2[j]]);
      }
      const obj = {};
      obj["_id"] = data._id;
      obj["studyId"] = String(data["studyId"]);
      obj["participantId"] = String(data["participantId"]);
      obj["tobiDatas"] = tobiDatas;
      obj["tobiHeaders"] = tobiHeaders;
      array.push(obj);
    });

    this.setState({ array, headers, datas, numOfRecords: array.length });
  }

  deleteData = async (id) => {
    await axios.delete("/api/hearing/irsm/user/" + id);
    this.componentDidMount();
  }

  login = () => {
    if (this.state.password === "thisisthepassword") {
      this.setState({ login: true });
    } else {
      this.setState({ password: "" });
      window.alert("Wrong login information, retry please!");
    }
  }

  render() {
    const { array, login, password, datas, headers, numOfRecords } = this.state;
    return (
      login ?
        <Container>
          <br />
          <h3 style={{ textAlign: "center" }}>Welcome to CRM2 database</h3>
          <div>
            <br />
            <div id="spreadsheet">
              <h4>Spreadsheet Download</h4>
              <ExportReactCSV data={datas} headers={headers} fileName={"digits_in_noise_data"} />
            </div>
            <br />
            <h5>Amount of records: {numOfRecords}</h5>
            <br /><br />
            <div id="table">
              <h4>Data Operation</h4>
              <Table data={array} deleteData={this.deleteData} />
              <br />
            </div>
            <br />
            <div id="legends">
              <h5>Legend for data label</h5>
              <p>output: headset or speaker</p>
              <p>volume: in 100 scale</p>
              <p>withAids: with hearing aids, true or false</p>
              <p>reactTimer-t5: time between audio start and button push of trial 5 in block 1</p>
              <p>realTimer-t5: time between button push and finish of trial 5 in block 1</p>
              <p>questions1-t5: digits presented in the audio of trial 5 in block 1</p>
              <p>dbs1-t5: how much decibels is at the end of trial 5 in block 1</p>
              <p>TestQuestion-1-2: the answer of the 2nd question in 1st post-test questions set</p>
              <p>Order of the TestQuestions: Mental Demand, Physical Demand, Temporal Demand, Performance, Effort, Frustration Level</p>
            </div>
          </div>
        </Container>
        :
        <Container>
          <div style={{ position: "fixed", left: "30%", right: "30%", top: "20%" }}>
            <TextField value={password} style={{ width: 300 }} onChange={(e) => this.setState({ password: e.target.value })} label="password" type="password" /><br /><br />
            <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} onClick={this.login} >Log In</Button>
          </div>
        </Container>
    );
  }
}

export default Data;
