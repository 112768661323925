import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Markdown from 'markdown-to-jsx';

const P = (props) => {
  const [para, setPara] = useState("");

  useEffect(() => {
    setPara(props.children);
  }, [props.children]);

  const renderFont = (content) => {
    switch (props.currentFont) {
      case 1:
        return <h1>{content}</h1>;
      case 2:
        return <h1>{content}</h1>;
      case 3:
        return <h2>{content}</h2>;
      case 4:
        return <h3>{content}</h3>;
      case 5:
        return <h4>{content}</h4>;
      case 6:
        return <h5>{content}</h5>;
      default:
        return <h2>{content}</h2>;
    }
  };

  return renderFont(<Markdown children={para}/>);
};

const mapStateToProps = (state) => ({
  currentFont: state.font.currentFont,
});

export default connect(mapStateToProps)(P);
