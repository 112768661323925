import React from "react";
import { Button, Container } from "@material-ui/core";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

const FluencyTestIntro = (props) => {
  return (
    <Container>
      <P2>
        We will first test your current reading speed. Read the sentences at your normal reading
        speed and answer questions. Click ‘start’ to start the test.
      </P2>
      <br />
      <Button
        variant="contained"
        style={{ color: "white", backgroundColor: "#107896", marginRight: "30px" }}
        size="large"
        onClick={props.handleClick}
      >
        Start
      </Button>
    </Container>
  );
};

export default FluencyTestIntro;
