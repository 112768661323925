import React from "react";
import FluencyIntro from "literacy/components/student/fluency/assign/assignintro";
import FluencyMain from "literacy/components/student/fluency/assign/assignpart";
import FluencyProgress from "literacy/components/student/fluency/assign/assign-progress";
import FluencyHeader from "literacy/components/student/fluency/assets/header";
import { Container } from "@material-ui/core";

class FluencyAssignment extends React.Component {
  state = {
    understand: false,
    id: "",
    version: "w1"
  };

  render() {
    const { understand, id, version } = this.state;
    return (
      <div style={{  backgroundColor: "white", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        {understand ? (
          <Container>
            {id === "" ? <FluencyMain version={version} /> : <FluencyProgress progress_id={id} />}
          </Container>
        ) : (
            <div>
              <FluencyHeader part="Speed Training Assignment" backURL="/literacy/student/fluency"/>
              <FluencyIntro
                handleClick={(id, version) =>
                  this.setState({ understand: !understand, id, version })
                }
              />
            </div>
          )}
      </div>
    );
  }
}
export default FluencyAssignment;
