import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField
} from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();
  const [editState, setEditState] = useState(-1);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleEdit = (index) => {
    const { question, answer } = props.rows[index];
    setQuestion(question);
    setAnswer(answer);
    setEditState(index);
  }

  const handleConfirm = (id) => {
    const newRow = {
      question,
      answer
    };
    props.onEdit(id, newRow);
    setEditState(-1);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Question</TableCell>
            <TableCell align="left">Answer</TableCell>
            <TableCell align="left">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            editState === index ?
            <TableRow key={index}>
              <TableCell align="left"><TextField value={question} onChange={(e) => setQuestion(e.target.value)}/></TableCell>
              <TableCell align="left">
                <RadioGroup row value={answer} onChange={(e) => setAnswer(e.target.value)}>
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="True" />
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="False" />                   
                </RadioGroup>
              </TableCell>
              <TableCell align="left">
                <Button
                  key={row._id}
                  style={{ color: "white", backgroundColor: "#107896", marginRight: "10px" }}
                  onClick={() => handleConfirm(row._id)}
                >
                  Confirm
                </Button>
                <Button
                  key={row._id}
                  style={{ color: "white", backgroundColor: "#107896" }}
                  onClick={() => props.handleDelete(row._id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
            :
            <TableRow key={index}>
              <TableCell align="left"><Markdown>{row.question}</Markdown></TableCell>
              <TableCell align="center">
                <RadioGroup row value={row.answer}>
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="True" disabled />
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="False" disabled />                   
                </RadioGroup>  
              </TableCell>
              <TableCell align="left">
                <Button
                  style={{ color: "white", backgroundColor: "#107896", marginRight: "10px" }}
                  onClick={() => handleEdit(index)}
                >
                  Edit
                </Button>
                <Button
                  key={row._id}
                  style={{ color: "white", backgroundColor: "#107896" }}
                  onClick={() => props.handleDelete(row._id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
