import React from "react";
import { TextField, Button, Container, Checkbox, Grid, Slider } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';
import FontComponent from 'assets/font-ui';
import Modal from 'hearing/components/partials/modal';

class SSQCom extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ParticipantID: null,
      StudyID: null,
      date: null,
      notes: null,
      checkboxQuestion: null,
      modalOpen: false,
      scaleQuestion1: {
        "You are talking with one other person and there is a TV on in the same room. Without turning the TV down, can you follow what the person you’re talking to says?": null,
        "You are talking with one other person in a quiet, carpeted lounge- room. Can you follow what the other person says?": null,
        "You are in a group of about five people, sitting round a table. It is an otherwise quiet place. You can see everyone else in the group. Can you follow the conversation?": null,
        "You are in a group of about five people in a busy restaurant. You can see everyone else in the group. Can you follow the conversation?": null,
        "You are talking with one other person. There is continuous background noise, such as a fan or running water. Can you follow what the person says?": null,
        "You are in a group of about five people in a busy restaurant. You CANNOT see everyone else in the group. Can you follow the conversation?": null,
        "You are talking to someone in a place where there are a lot of echoes, such as a church or railway terminus building. Can you follow what the other person says?": null,
        "Can you have a conversation with someone when another person is speaking whose voice is the same pitch as the person you’re talking to?": null,
        "Can you have a conversation with someone when another person is speaking whose voice is different in pitch from the person you’re talking to?": null,
        "You are listening to someone talking to you, while at the same time trying to follow the news on TV. Can you follow what both people are saying?": null,
        "You are in conversation with one person in a room where there are many other people talking. Can you follow what the person you are talking to is saying?": null,
        "You are with a group and the conversation switches from one person to another. Can you easily follow the conversation without missing the start of what each new speaker is saying?": null,
        "Can you easily have a conversation on the telephone?": null,
        "You are listening to someone on the telephone and someone next to you starts talking. Can you follow what’s being said by both speakers?": null
      },
      scaleQuestion2: {
        "You are outdoors in an unfamiliar place. You hear someone using a lawnmower. You can’t see where they are. Can you tell right away where the sound is coming from?": null,
        "You are sitting around a table or at a meeting with several people. You can’t see everyone. Can you tell where any person is as soon as they start speaking?": null,
        "You are sitting in between two people. One of them starts to speak. Can you tell right away whether it is the person on your left or your right, without having to look?": null,
        "You are in an unfamiliar house. It is quiet. You hear a door slam. Can you tell right away where that sound came from?": null,
        "You are in the stairwell of a building with floors above and below you. You can hear sounds from another floor. Can you readily tell where the sound is coming from?": null,
        "You are outside. A dog barks loudly. Can you tell immediately where it is, without having to look?": null,
        "You are standing on the footpath of a busy street. Can you hear right away which direction a bus or truck is coming from before you see it?": null,
        "In the street, can you tell how far away someone is, from the sound of their voice or footsteps?": null,
        "Can you tell how far away a bus or a truck is, from the sound?": null,
        "Can you tell from the sound which direction a bus or truck is moving, for example, from your left to your right or right to left?": null,
        "Can you tell from the sound of their voice or footsteps which direction a person is moving, for example, from your left to your right or right to left?": null,
        "Can you tell from their voice or footsteps whether the person is coming towards you or going away?": null,
        "Can you tell from the sound whether a bus or truck is coming towards you or going away?": null,
        "Do the sounds of things you are able to hear seem to be inside your head rather than out there in the world?": null,
        "Do the sounds of people or things you hear, but cannot see at first, turn out to be closer than expected when you do see them?": null,
        "Do the sounds of people or things you hear, but cannot see at first, turn out to be further away than expected when you do see them?": null,
        "Do you have the impression of sounds being exactly where you would expect them to be?": null
      },
      scaleQuestion3: {
        "Think of when you hear two things at once, for example, water running into a basin and, at the same time, a radio playing. Do you have the impression of these as sounding separate from each other?": null,
        "When you hear more than one sound at a time, do you have the impression that it seems like a single jumbled sound?": null,
        "You are in a room and there is music on the radio. Someone else in the room is talking. Can you hear the voice as something separate from the music?": null,
        "Do you find it easy to recognise different people you know by the sound of each one’s voice?": null,
        "Do you find it easy to distinguish different pieces of music that you are familiar with?": null,
        "Can you tell the difference between different sounds, for example, a car versus a bus; water boiling in a pot versus food cooking in a frypan?": null,
        "When you listen to music, can you make out which instruments are playing?": null,
        "When you listen to music, does it sound clear and natural?": null,
        "Do everyday sounds that you can hear easily seem clear to you (not blurred)?": null,
        "Do other people’s voices sound clear and natural?": null,
        "Do everyday sounds that you hear seem to have an artificial or unnatural quality?": null,
        "Does your own voice sound natural to you?": null,
        "Can you easily judge another person’s mood from the sound of their voice?": null,
        "Do you have to concentrate very much when listening to someone or something?": null,
        "Do you have to put in a lot of effort to hear what is being said in conversation with others?": null,
        "When you are the driver in a car can you easily hear what someone is saying who is sitting alongside you?": null,
        "When you are a passenger can you easily hear what the driver is saying sitting alongside you?": null,
        "Can you easily ignore other sounds when trying to listen to something?": null,
      },
      errorText: []
    }
  }

  componentDidMount = async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire/ssq/comparison' });
  };

  handleChoiceClick = (question, answer) => {
    const { scaleQuestion } = this.state;
    scaleQuestion[question] = answer;
    this.setState({ scaleQuestion });
  }
  
  checkValidation = () => {
    const { scaleQuestion1, scaleQuestion2, scaleQuestion3, checkboxQuestion, ParticipantID, StudyID, date} = this.state;
   
    const errorText = [];
    if (!date) {
      errorText.push("Date");
    }
    if (!ParticipantID) {
      errorText.push("Participant ID");
    }
    if (!StudyID) {
      errorText.push("Study ID");
    }
    if (!checkboxQuestion) {
      errorText.push("Checkbox question");
    }

    Object.keys(scaleQuestion1).map((key, i) => {
      if (scaleQuestion1[key] === null) {
        errorText.push("#"+ (i+1) +" question in part 1");
      }
    });

    Object.keys(scaleQuestion2).map((key, i) => {
      if (scaleQuestion2[key] === null) {
        errorText.push("#"+ (i+1) +" question in part 2");
      }
    });

    Object.keys(scaleQuestion3).map((key, i) => {
      if (scaleQuestion3[key] === null) {
        errorText.push("#"+ (i+1) +" question in part 3");
      }
    });

    this.setState({errorText});
  }

  handleModalClose = () => {
    window.location = "/hearing/questionnaire";
  }

  handleSubmit = async () => {
    await this.checkValidation();
    if (this.state.errorText.length === 0) {
      await axios.post("/api/hearing/ssqcom/user", this.state);
      this.setState({ modalOpen: true });
    }
  }


  render(){
    const { notes, modalOpen, errorText, ParticipantID, StudyID, date, scaleQuestion1, scaleQuestion2, scaleQuestion3, checkboxQuestion } = this.state;
    const marks = [
      { value: -5, label: '-5' },
      { value: -4, label: '-4' },
      { value: -3, label: '-3' },
      { value: -2, label: '-2' },
      { value: -1, label: '-1' },
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' }
    ];
    const values = [null];
    for (let i=-5; i<5; i+=0.1) {
      values.push(Number(i.toFixed(1)));
    }

    return(
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll", paddingRight: "5%"}}>
        <div style={{margin: "3%"}}>
          <div id="instruction" style={{ border: "solid", borderColor: "#add8e6", backgroundColor: "#EEEEEE"}}>
            <center><P2 style={{ marginBottom: "15px"}}>Speech Spatial Qualities -C</P2></center>
            <P2 style={{ margin: "1%" }}>Advice about answering the questions</P2>
            <P2 style={{ margin: "1%" }}>
              The following questions inquire about aspects of your ability and experience hearing and listening 
              in different situations. You answered these questions before, after you had worn the previous hearing 
              aid for a while (or aids, if you have been fitted with two hearing aids).
            </P2>
            <br />
            <P2 style={{ margin: "1%" }}>
              We would like you to answer the same questions, but comparing your ability and experience now, 
              using the hearing aid/s you are currently wearing, with your ability and experience using the previous aid/s.
            </P2>
            <br />
            <P2 style={{ margin: "1%" }}><b>
              For each question, put a mark anywhere on the scale that runs from -5 through 0 to +5, shown against each question.
            </b></P2>
            <br />
            <P2 style={{ margin: "1%" }}>
              <ul>
                <li>If you are doing better now, with the current hearing aid/s compared with the previous ones, 
                  in the situation described in a particular question, 
                  then put the mark somewhere to the right of the 0 point on the scale.</li>
                <li>If you are doing worse, then put the mark somewhere to the left of the 0.</li>
                <li>If things are no different, put the mark at around the 0 point.</li>
                <li>If things are much worse mark the scale at around -5; if things are much better mark it around +5.</li>
              </ul>
            </P2>
            <br/>
            <P2 style={{ margin: "1%" }}>
              <b>
              We expect that all the questions are relevant to your everyday experience, 
              but if a question describes a situation that does not apply to you, select the "not applicable" box.
              </b> {" "}
              Please also write a note next to that question explaining why it does not apply in your case.
            </P2>
          </div>
          <br />
          <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly"  }}>
              <div style={{border: "solid", width: "32%", padding: "1%", margin: "1%"}}><P2>Participant ID: </P2><TextField id="ID" value={ParticipantID} onChange={e => this.setState({ParticipantID: e.target.value})} style={{width: "40%", marginBottom: "10px", marginLeft: "5px"}}/></div>
              <div style={{border: "solid", width: "32%", padding: "1%", margin: "1%"}}><P2>Study ID: </P2><TextField id="ID" value={StudyID} onChange={e => this.setState({StudyID: e.target.value})} style={{width: "40%", marginBottom: "10px", marginLeft: "5px"}}/></div>
              <div style={{border: "solid", width: "32%", padding: "1%", margin: "1%"}}><P2>Today's date: </P2><DatePicker selected={date} onChange={date => this.setState({date})} />  </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly"  }}>
              <div id="checkbox" style={{border: "solid", width: "30%", padding: "1%", margin: "1%"}}>
                  <P2><i>Please check one of these options:</i></P2>
                  <div className="row" >
                    <P3 className="col-6">I have no hearing aid/s</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I have no hearing aid/s"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I have no hearing aid/s" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
                  <div className="row" >
                    <P3 className="col-6">I use one hearing aid (left ear)</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use one hearing aid (left ear )"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use one hearing aid (left ear )" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
                  <div className="row" >
                    <P3 className="col-6">I use one hearing aid (right ear)</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use one hearing aid (right ear)"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use one hearing aid (right ear)" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
                  <div className="row" >
                    <P3 className="col-6">I use two hearing aids (both ears)</P3>
                    <div className="col-6">
                    <Checkbox
                      checked={checkboxQuestion === "I use two hearing aids (both ears)"}
                      onChange={() => {
                        this.setState({ checkboxQuestion: "I use two hearing aids (both ears)" });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                  </div>
              </div>
              <div id="notes" style={{border: "solid", width: "30%", padding: "1%", margin: "1%"}}>
                <P2>Notes</P2>
                <TextField multiline value={notes} onChange={(e) => this.setState({notes: e.target.value})} style={{width: "90%"}} />
              </div>
            </div>
          </div>
          <div style={{marginTop: "5%"}}>
            <P2>Speech Spatial Qualities -C (Part 1: Speech hearing)</P2>
          </div>
          <div id="section1" style={{ borderStyle: "solid" }}>
            {Object.keys(scaleQuestion1).map((q, i) => {
              return (
                <div className="row" style={{ borderBottom: "solid", marginLeft: 0, marginRight: 0}}>
                  <div className="col-3" style={{ borderRight: "solid" }}>
                    <P2>{i+1}. {q}</P2>
                  </div>
                  <div className="col-9" style={{paddingTop: "3%"}}>
                    <div className="col">
                      <P2><b>Comparing your ability now with your ability wearing the previous hearing aid/s</b></P2>
                      <div className="row">
                        <P2 className="col-4"><i>Much worse</i></P2>  
                        <P2 className="col-4"><center><i>Unchanged</i></center></P2>  
                        <P2 className="col-4" style={{ textAlign: "right" }}><i>Much better</i></P2>  
                      </div>
                    </div>
                    <Slider
                      value={scaleQuestion1[q]}
                      onChange={(e, value) => {
                        scaleQuestion1[q] = value;
                        this.setState({ scaleQuestion1 });
                      }}
                      aria-labelledby="continuous-slider"
                      style={{ width: "90%", marginLeft: "5%"}}
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks={marks}
                      min={-5}
                      max={5}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={3} className="row">  
                        <P3 style={{marginTop: "35px"}}>Not applicable</P3>
                        <Checkbox checked={!values.includes(scaleQuestion1[q])} color="primary" onChange={(e) => {
                          if (e.target.checked) {
                            scaleQuestion1[q] = " ";
                          } else {
                            scaleQuestion1[q] = null;
                          }
                          this.setState({ scaleQuestion1 });
                        }}/>
                      </Grid>
                      <Grid item xs={9} className="row" style={{marginTop: "10px"}}>
                        <P3 style={{marginTop: "25px", marginRight: "5px"}}>Explanation:</P3>
                        <TextField
                          label="Please explain why this situation is not applicable to you here."
                          value={!values.includes(scaleQuestion1[q]) ? scaleQuestion1[q] : ""}
                          style={{width: "80%", marginBottom: "5%"}}
                          onChange={(e) => {
                            scaleQuestion1[q] = e.target.value;
                            this.setState({ scaleQuestion1 });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{marginTop: "5%"}}>
              <P2>Speech Spatial Qualities -C (Part 2: Spatial hearing)</P2>
          </div>  
          <div id="section2" style={{ borderStyle: "solid" }}>
            {Object.keys(scaleQuestion2).map((q, i) => {
              return (
                <div className="row" style={{ borderBottom: "solid", marginLeft: 0, marginRight: 0}}>
                  <div className="col-3" style={{ borderRight: "solid" }}>
                    <P2>{i+1}. {q}</P2>
                  </div>
                  <div className="col-9" style={{paddingTop: "3%"}}>
                    <div className="col">
                      <P2><b>Comparing your ability now with your ability wearing the previous hearing aid/s</b></P2>
                      <div className="row">
                        {
                          i === 13 ?
                          <P2 className="col-4"><i>Much inside head</i></P2>  
                          :
                          i === 14 ?
                          <P2 className="col-4"><i>Much closer</i></P2>  
                          :
                          i === 15 ?
                          <P2 className="col-4"><i>Much further</i></P2> 
                          :
                          i === 16 ?
                          <P2 className="col-4"><i>Much worse impression</i></P2>  
                          :
                          <P2 className="col-4"><i>Much worse</i></P2>  
                        }
                        <P2 className="col-4"><center><i>Unchanged</i></center></P2>  
                        {
                          i === 13 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Much out there</i></P2>  
                          :
                          i === 14 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Less close</i></P2>  
                          :
                          i === 15 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Less far</i></P2>  
                          :
                          i === 16 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Much better impression</i></P2>  
                          :
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Much better</i></P2>  
                        }
                        
                      </div>
                    </div>
                    <Slider
                      value={scaleQuestion2[q]}
                      onChange={(e, value) => {
                        scaleQuestion2[q] = value;
                        this.setState({ scaleQuestion2 });
                      }}
                      aria-labelledby="continuous-slider"
                      style={{ width: "90%", marginLeft: "5%"}}
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks={marks}
                      min={-5}
                      max={5}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={3} className="row">  
                      <P3 style={{marginTop: "35px"}}>Not applicable</P3>
                        <Checkbox checked={!values.includes(scaleQuestion2[q])} color="primary" onChange={(e) => {
                          if (e.target.checked) {
                            scaleQuestion2[q] = " ";
                          } else {
                            scaleQuestion2[q] = null;
                          }
                          this.setState({ scaleQuestion2 });
                        }}/>
                      </Grid>
                      <Grid item xs={9} className="row" style={{marginTop: "10px"}}>
                        <P3 style={{marginTop: "25px", marginRight: "5px"}}>Explanation:</P3>
                        <TextField
                          label="Please explain why this situation is not applicable to you here."
                          value={!values.includes(scaleQuestion2[q]) ? scaleQuestion2[q] : ""}
                          style={{width: "80%", marginBottom: "5%"}}
                          onChange={(e) => {
                            scaleQuestion2[q] = e.target.value;
                            this.setState({ scaleQuestion2 });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{marginTop: "5%"}}>
              <P2>Speech Spatial Qualities -C (Part 3: Qualities of hearing)</P2>
          </div>  
          <div id="section3" style={{ borderStyle: "solid" }}>
            {Object.keys(scaleQuestion3).map((q, i) => {
              return (
                <div className="row" style={{ borderBottom: "solid", marginLeft: 0, marginRight: 0}}>
                  <div className="col-3" style={{ borderRight: "solid" }}>
                    <P2>{i+1}. {q}</P2>
                  </div>
                  <div className="col-9" style={{paddingTop: "3%"}}>
                    <div className="col">
                      <P2><b>Comparing your ability now with your ability wearing the previous hearing aid/s</b></P2>
                      <div className="row">
                        {
                          i === 0 ?
                          <P2 className="col-4"><i>Less separate</i></P2>  
                          :
                          i === 1 ?
                          <P2 className="col-4"><i>More jumbled</i></P2>  
                          :
                          i === 2 ?
                          <P2 className="col-4"><i>Less separate</i></P2> 
                          :
                          i === 7 || i === 9 ?
                          <P2 className="col-4"><i>Less clear and natural</i></P2>  
                          :
                          i === 8 ?
                          <P2 className="col-4"><i>Less clear</i></P2>  
                          :
                          i === 10 || i === 11 ?
                          <P2 className="col-4"><i>Less natural</i></P2>  
                          :
                          i === 13 ?
                          <P2 className="col-4"><i>More need to concentrate</i></P2>  
                          :
                          i === 14 ?
                          <P2 className="col-4"><i>More effort</i></P2>  
                          :
                          <P2 className="col-4"><i>Much worse</i></P2>  
                        }
                        <P2 className="col-4"><center><i>Unchanged</i></center></P2>  
                        {
                          i === 0 || i === 2 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>More separate</i></P2>  
                          :
                          i === 1 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Less jumbled</i></P2>
                          :
                          i === 7 || i === 9 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>More clear and natural</i></P2>  
                          :
                          i === 8 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>More clear</i></P2>  
                          :
                          i === 10 || i === 11 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>More natural</i></P2>  
                          :
                          i === 13 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Less need to concentrate</i></P2>  
                          :
                          i === 14 ?
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Less effort</i></P2>  
                          :
                          <P2 className="col-4" style={{ textAlign: "right" }}><i>Much worse</i></P2>  
                        }
                      </div>
                    </div>
                    <Slider
                      value={scaleQuestion3[q]}
                      onChange={(e, value) => {
                        scaleQuestion3[q] = value;
                        this.setState({ scaleQuestion3 });
                      }}
                      aria-labelledby="continuous-slider"
                      style={{ width: "90%", marginLeft: "5%"}}
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks={marks}
                      min={-5}
                      max={5}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={3} className="row">  
                      <P3 style={{marginTop: "35px"}}>Not applicable</P3>
                        <Checkbox checked={!values.includes(scaleQuestion3[q])} color="primary" onChange={(e) => {
                          if (e.target.checked) {
                            scaleQuestion3[q] = " ";
                          } else {
                            scaleQuestion3[q] = null;
                          }
                          this.setState({ scaleQuestion3 });
                        }}/>
                      </Grid>
                      <Grid item xs={9} className="row" style={{marginTop: "10px"}}>
                        <P3 style={{marginTop: "25px", marginRight: "5px"}}>Explanation:</P3>
                        <TextField
                          label="Please explain why this situation is not applicable to you here."
                          value={!values.includes(scaleQuestion3[q]) ? scaleQuestion3[q] : ""}
                          style={{width: "80%", marginBottom: "5%"}}
                          onChange={(e) => {
                            scaleQuestion3[q] = e.target.value;
                            this.setState({ scaleQuestion3 });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            })}
          </div>
          <div id="error" style={{color: "red", margin: "5%"}}>
            {errorText.length !== 0 && <P2>These questions are not completed.</P2>}
            <ul>
              {
                errorText.map(error => <li><P3>{error}</P3></li>)
              }
            </ul>
          </div>
          <div id="submit" style={{margin: "5%"}}>
            <Button style={{ color: "white", backgroundColor: "#107896" }} variant="contained" size="large" onClick={this.handleSubmit}>Submit</Button>
          </div>
        </div>
        <FontComponent />
        <Modal
          open={modalOpen}
          onClose={this.handleModalClose}
        />
      </div>
    )
  }
}

export default SSQCom;