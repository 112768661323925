import React, { useState, useEffect } from 'react';
import {Checkbox, FormControlLabel, Icon, Container, TextField} from '@material-ui/core';
import { AddCircleOutline, IndeterminateCheckBox } from '@material-ui/icons';
import {P1, P2, P3} from 'assets/fonts';
import axios from 'axios';

export default function CheckBox(props) {
  const { id } = props;
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/consentcheck/" + id );
        setQuestions(doc.data.questions);
      })();
    }
  }, [id]);

  const handleQuestionChange = (e, index) => {
    const newQuestions = [...questions];
    newQuestions[index] = e.target.value;
    setQuestions(newQuestions);
  }

  const handleAddQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.push("");
    setQuestions(newQuestions);
  }

  const handleDeleteQuestion = (index) => {
    if (index < questions.length - 1) {
      setQuestions(questions.slice(0, index).concat(questions.slice(index+1)));
    } else {
      setQuestions(questions.slice(0, index));
    }
  }

  const handleBlur = async () => {
    await axios.put("/api/gui/consentcheck/" + id, { questions });
  }

  return (
    <Container>
      <P1>Consent Checkbox</P1>
      <P2 style={{fontWeight: "300"}}>Please add consent text below:</P2><br />
      {questions.map((q, i) => (
        <div>
          <FormControlLabel
            control={<Checkbox color="primary" disabled/>}
          />
          <TextField value={q} onChange={(e) => handleQuestionChange(e, i)} onBlur={handleBlur} style={{ width: "60%" }} />
          <IndeterminateCheckBox color="secondary" onClick={() => handleDeleteQuestion(i)} style={{marginRight: "10px"}} />
        </div>
      ))}
      <AddCircleOutline color="primary" onClick={handleAddQuestion}/>
      <br /><br />
    </Container>
  );
}
