import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import { Container, TextField } from "@material-ui/core";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const TextFieldArea = (props) => {
  const { id, updateAssign, readyToContinue } = props;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [optional, setOptional] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addMandatoryComponent(id));
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/textfield/" + id);
        setQuestion(doc.data.question);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (optional) {
      handleUpdateAssign();
      readyToContinue(id);
    }
  }, [optional]);

  const handleChange = (e) => {
    const answer = e.target.value;
    setAnswer(answer);
  };

  const handleBlur = () => {
    handleUpdateAssign();
    if (!!answer) {
      readyToContinue(id);
    }
  };

  const handleUpdateAssign = () => {
    const assign = {
      id,
      type: "textfield",
      question,
      answer,
    };
    updateAssign(assign);
  };

  return (
    <Container>
      <br />
      <P2 style={{ fontWeight: "300" }}>{question}</P2>
      <TextField
        value={answer}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ width: "60%" }}
      />
      <br />
      <br />
    </Container>
  );
};

export default TextFieldArea;
