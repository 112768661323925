import React, { useState, useEffect } from "react";
import "../styles.css";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import {P1, P2, P3} from 'assets/fonts';
import FontComponent from 'assets/font-ui';

const DecoratedQuestionnaire = (props) => {

  const [open, setOpen] = useState(false);

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => {props.history.push(page)}, 2000);
  };

  useEffect(async () => {
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire/ssq' });
  });

  return (
    <div>
      <div style={{  backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)", height: window.innerHeight * 0.45, width: "100%", position: "relative"}}>
        <div style={{ height: "40%"}}>
        </div>
        <div style={{ color: "white", paddingLeft: "10%", fontFamily: "sans-serif"}}>
          <P1><b>Welcome to our Collection of SSQ Questionnaires!</b></P1>
        </div>
      </div>
      <div className="row" style={{height: window.innerHeight * 0.3, width: "100%", marginLeft:0, marginRight: 0}}>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/questionnaire/ssq/short')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >SSQ Short</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick("/hearing/questionnaire/ssq/long")}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >SSQ Long</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/questionnaire/ssq/prepost')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >SSQ Pre-post</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/questionnaire/ssq/comparison')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><P1 >SSQ Comparison</P1></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
      </div>
      <div style={{height: window.innerHeight * 0.25, backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)" }}>
      </div>
      <Backdrop style={{zIndex: 2}} open={open}>
        <CircularProgress color="primary" style={{width: "100px", height: "100px"}} />
      </Backdrop>
      <FontComponent />
    </div>
  )
}

export default DecoratedQuestionnaire;