import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FooterCom = () => {
  return (
    <div className="footer-component">
      <div className="center-container">
        {/* Logo, Clinical Sciences, and Institute for Reconstructive side by side */}
        <div className="side-by-side">
          {/* Logo and Faculty Information */}
          <div className="logo">
            <div className="university-of">University Of Alberta</div>
            <p className="faculty-of-medicine">
              Faculty Of Medicine And Rehabilitation Science
            </p>
          </div>

          {/* Clinical Sciences Building Map and Description */}
          <div className="clinical-sciences-building">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2372.1279869915948!2d-113.52601638676674!3d53.51977106161678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a021f675cd0de5%3A0xd4c508c8cb2a4629!2sClinical%20Sciences%20Building%2C%208440%20112%20St%20NW%2C%20Edmonton%2C%20AB%20T6G%202B7!5e0!3m2!1sen!2sca!4v1690523625675!5m2!1sen!2sca"
              width="400"
              height="200"
              style={{ borderRadius: 20 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <p className="div">
              University Of Alberta, Clinical Science Building, Edmonton, Canada
            </p>
          </div>

          {/* Institute for Reconstructive Sciences in Medicine Map and Description */}
          <div className="institute-for-reconstructive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d2372.05455171365!2d-113.6149744!3d53.5210826!3m2!1i1024!2i768!4f13.1!2m1!1sInstitute%20for%20Reconstructive%20Sciences%20in%20Medicine%20-%20Misericordia%20Community%20Hospital%2C%20Edmonton%2C%20Canada!5e0!3m2!1sen!2sca!4v1690523052615!5m2!1sen!2sca"
              width="400"
              height="200"
              style={{ borderRadius: 20 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <p className="p">
              Institute for Reconstructive Sciences in Medicine - Misericordia
              Community Hospital, Edmonton, Canada
            </p>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="text-wrapper">©2024 University of Alberta</div>
      </div>
    </div>
  );
};

FooterCom.propTypes = {
  screenshot: PropTypes.string,
  img: PropTypes.string,
};
