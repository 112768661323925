import React, { useState, useEffect } from "react";
import axios from "axios";

const MailListPage = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/gui/emails");
      setEmails(response.data);
      setLoading(false);
    } catch (err) {
      setError(
        "Failed to fetch emails: " + (err.response?.data?.error || err.message)
      );
      setLoading(false);
    }
  };

  const deleteEmail = async (email, dateReceived) => {
    try {
      await axios.delete("/api/gui/emails", {
        data: {
          email,
          dateReceived: dateReceived,
        },
      });

      // Update the emails state by filtering out the deleted email
      setEmails((prevEmails) =>
        prevEmails.filter(
          (e) =>
            !(
              e.email === email &&
              new Date(e.dateReceived).getTime() ===
                new Date(dateReceived).getTime()
            )
        )
      );
    } catch (err) {
      setError(
        "Failed to delete email: " + (err.response?.data?.error || err.message)
      );
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={styles.error}>{error}</div>;

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Mail List</h1>
      {emails.length === 0 ? (
        <p style={styles.noEmails}>No emails to display</p>
      ) : (
        emails.map((email, index) => (
          <div key={index} style={styles.emailItem}>
            <div style={styles.emailContent}>
              <p style={styles.emailAddress}>{email.email}</p>
              <p style={styles.dateReceived}>
                {formatDate(email.dateReceived)}
              </p>
            </div>
            <button
              onClick={() => deleteEmail(email.email, email.dateReceived)}
              style={styles.deleteButton}
            >
              Delete
            </button>
          </div>
        ))
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  emailItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    borderBottom: "1px solid #eee",
    transition: "background-color 0.3s ease",
  },
  emailContent: {
    flex: 1,
  },
  emailAddress: {
    margin: "0 0 5px 0",
    fontSize: "16px",
    color: "#333",
  },
  dateReceived: {
    margin: 0,
    fontSize: "14px",
    color: "#666",
  },
  deleteButton: {
    backgroundColor: "#ff4d4d",
    color: "white",
    border: "none",
    padding: "8px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default MailListPage;
