import React, { Component } from "react";
import Process from "literacy/assets/process";
import { connect } from "react-redux";
import FluencyHeader from "literacy/components/student/fluency/assets/header";
import FluencyTestPart from "literacy/components/student/fluency/test/testpart";
import FluencyTestIntro from "literacy/components/student/fluency/test/testintro";
import { Container, Button } from "@material-ui/core";
import Paper from "literacy/assets/paper";
import TrainCard from "literacy/assets/cards/trainpagecard";

class FluencyTrain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTrain: false
    };
  }

  render() {
    const { currentUser } = this.props;
    const { startTrain } = this.state;
    return (
      <div style={{  backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll"}}>
        {currentUser && (
          currentUser.fluency_score.length === 0
          ?
          <FluencyHeader part="Test of reading speed" desc="Read these sentences at your normal reading speed and answer questions. Click ‘start’ to start the test."/>
          :
          <FluencyHeader part="Speed Training Main Page" />
        )}
        
        <Container style={{ paddingBottom: 50 }}>
          {currentUser ? (
            <div>
              {currentUser.fluency_score.length === 0 ? (
                  startTrain
                  ?
                  <Paper component={FluencyTestPart} />
                  :
                  <FluencyTestIntro handleClick={() => this.setState({ startTrain: true})}/>
              ) : (
                <div className="row">
                  <TrainCard
                    title="Learning"
                    page="/literacy/student/fluency/learning"
                    description="In this section, you will learn about the speed training process in English."
                  />
                  <TrainCard
                    title="Assignment"
                    page="/literacy/student/fluency/assignment"
                    description="In this section, we will train your reading speed."
                  />
                  <TrainCard
                title="Results"
                page="/literacy/student/fluency/results"
                description="In this section, you can take a look at the results of your assignments."
              />
                </div>
              )}
            </div>
          ) : (
            <Process />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(FluencyTrain);
