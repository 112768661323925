import React, { useState } from "react";
import "./styles.css";
import { Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Main = (props) => {
  const [open, setOpen] = useState(false);

  const handleApplication = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/imageprocess/application/");
    }, 2000);
  };

  const handleClassification = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/imageprocess/classification/");
    }, 2000);
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
          height: window.innerHeight * 0.4,
          width: "100%",
          position: "relative",
        }}
      >
        <div style={{ height: "40%" }}></div>
        <div
          style={{
            color: "white",
            paddingLeft: "10%",
            fontFamily: "sans-serif",
          }}
        >
          <h1>
            <b>BAHA Classification Application</b>
          </h1>
        </div>
      </div>
      <div
        className="row"
        style={{
          height: window.innerHeight * 0.4,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <div
          id="Application-card"
          className="col"
          style={{ backgroundColor: "#7CA295" }}
          onClick={handleApplication}
        >
          <div style={{ marginTop: "5%" }}>
            <center style={{ color: "white" }}>
              <h2>Application</h2>
            </center>
          </div>
        </div>
        <div
          id="Classification-card"
          className="col"
          style={{ backgroundColor: "#C7D28A" }}
          onClick={handleClassification}
        >
          <div style={{ marginTop: "5%" }}>
            <center style={{ color: "white" }}>
              <h2>Classification</h2>
            </center>
          </div>
        </div>
      </div>
      <div
        style={{
          height: window.innerHeight * 0.2,
          backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)",
        }}
      >
        <div id="logo">
          <img
            src={process.env.PUBLIC_URL + "/UA.png"}
            style={{
              position: "absolute",
              width: "50%",
              right: "2%",
              bottom: "2%",
            }}
          />
        </div>
      </div>
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <CircularProgress
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
      </Backdrop>
    </div>
  );
};

export default Main;
