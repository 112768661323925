import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axios from "axios";
import { ToggleButton } from "react-bootstrap";

const RecoveryStimulus = (props) => {
  const { id } = props;
  const [numOfTrial, setNumOfTrial] = useState(null);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [numOfSNR, setNumOfSNR] = useState(null);
  const [audio, setAudio] = useState(null);
  const [noise, setNoise] = useState(null);
  const [sentenceAudios, setSentenceAudios] = useState([]);
  const [digitsAudios, setDigitsAudios] = useState([]);
  const [easier, setEasier] = useState({
    select: null,
    numOfTrial: null,
    num: null,
    before: null,
    after: null,
  });
  const [harder, setHarder] = useState({
    select: null,
    numOfTrial: null,
    num: null,
    before: null,
    after: null,
  });
  const [useTriplets, setUseTriples] = useState(false);
  const [stimulusName, setStimulusName] = useState(null);
  const [stimulusSentence, setStimulusSentence] = useState(null);
  const [stimulusTarget, setStimulusTarget] = useState(null);
  const [stimulusMp, setStimulusMp] = useState(null);
  const [stimulusLength, setStimulusLength] = useState(null);
  const [stimulusTargetStart, setStimulusTargetStart] = useState(null);
  const [stimulusTargetEnd, setStimulusTargetEnd] = useState(null);
  const [digitEnable, setDigitEnable] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/recoverystimulus/" + id);
        setNumOfTrial(doc.data.numOfTrial);
        setNumOfCurrBlock(doc.data.numOfCurrBlock);
        setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
        setNumOfSNR(doc.data.numOfSNR);
        setSentenceAudios(doc.data.sentenceAudios);
        setDigitsAudios(doc.data.digitsAudios);
        setNoise(doc.data.noise);
        doc.data.easier && setEasier(doc.data.easier);
        doc.data.harder && setHarder(doc.data.harder);
        setUseTriples(doc.data.useTriplets);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (!!numOfTrial) {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { numOfTrial });
      })();
    }
  }, [numOfTrial]);

  useEffect(() => {
    if (!!numOfCurrBlock && !!numOfTotalBlocks) {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, {
          numOfCurrBlock,
          numOfTotalBlocks,
        });
      })();
    }
  }, [numOfCurrBlock, numOfTotalBlocks]);

  useEffect(() => {
    if (!!numOfSNR) {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { numOfSNR });
      })();
    }
  }, [numOfSNR]);

  useEffect(() => {
    if (!!easier) {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { easier });
      })();
    }
  }, [easier]);

  useEffect(() => {
    if (!!harder) {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { harder });
      })();
    }
  }, [harder]);

  useEffect(() => {
    if (typeof useTriplets == "boolean") {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { useTriplets });
      })();
    }
  }, [useTriplets]);

  useEffect(() => {
    if (typeof digitEnable == "boolean") {
      (async () => {
        await axios.put("/api/gui/recoverystimulus/" + id, { digitEnable });
      })();
    }
  }, [digitEnable]);

  const handleUploadDigitAudio = async (num) => {
    const uploadConfigs = await createAudio(num);
    const newDigitsAudios = [...digitsAudios];
    newDigitsAudios[num] = uploadConfigs.key;
    setDigitsAudios(newDigitsAudios);
    await axios.put("/api/gui/recoverystimulus/" + id, {
      digitsAudios: newDigitsAudios,
    });
  };

  const handleUploadNoiseAudio = async () => {
    const uploadConfigs = await createAudio("noise");
    setNoise(uploadConfigs.key);
    await axios.put("/api/gui/recoverystimulus/" + id, {
      noise: uploadConfigs.key,
    });
  };

  const handleUploadSentenceAudio = async () => {
    if (
      !!stimulusName &&
      !!stimulusSentence &&
      !!stimulusTarget &&
      !!stimulusMp &&
      !!stimulusLength &&
      !!stimulusTargetStart &&
      !!stimulusTargetEnd
    ) {
      const uploadConfigs = await createAudio(stimulusName);
      const newSentenceAudios = [...sentenceAudios];
      newSentenceAudios.push({
        url: uploadConfigs.key,
        name: stimulusName,
        sentence: stimulusSentence,
        target: stimulusTarget,
        mp: stimulusMp,
        length: stimulusLength,
        targetStart: stimulusTargetStart,
        targetEnd: stimulusTargetEnd,
      });
      await axios.put("/api/gui/recoverystimulus/" + id, {
        sentenceAudios: newSentenceAudios,
      });
      setSentenceAudios(newSentenceAudios);
      // handleCleanNewStimulus();
    }
  };

  const handleRemoveSentenceAudio = async (index) => {
    const newSentenceAudios = [
      ...sentenceAudios.slice(0, index),
      ...sentenceAudios.slice(index + 1),
    ];
    setSentenceAudios(newSentenceAudios);
    await axios.put("/api/gui/recoverystimulus/" + id, {
      sentenceAudios: newSentenceAudios,
    });
  };

  const createAudio = async (num) => {
    const doc = await axios.get(
      "/api/gui/recoverystimulus/audio/" + id + "/" + num
    );
    const uploadConfigs = doc.data;
    await axios
      .put(uploadConfigs.url, audio, {
        headers: {
          "Content-type": "audio/wav",
        },
      })
      .then(() => window.alert(`Audio ${num}.wav update successfully!`))
      .catch((err) => window.alert("Something wrong:", err));
    setAudio(null);
    return uploadConfigs;
  };

  const handleCleanNewStimulus = () => {
    setStimulusName("");
    setStimulusSentence("");
    setStimulusTarget("");
    setStimulusMp("");
    setStimulusLength(0);
    setStimulusTargetStart(0);
    setStimulusTargetEnd(0);
    setAudio(null);
  };

  useEffect(() => {
    if (!digitEnable) {
      setDigitsAudios([]);
    }
  }, [digitEnable]);

  const handleDigitEnableChange = (event) => {
    setDigitEnable(event.target.checked);
    console.log(event.target.checked);
  };

  return (
    <Container>
      <br />
      <P1>Recovery Stimulus</P1>
      <br />
      <div id="environment">
        <P2>Environment</P2>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}># of current block</P2>
          <TextField
            type="number"
            value={numOfCurrBlock}
            onChange={(e) => setNumOfCurrBlock(parseInt(e.target.value))}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100px",
            }}
          />
          <P2 style={{ fontWeight: 300 }}># of total blocks</P2>
          <TextField
            type="number"
            value={numOfTotalBlocks}
            onChange={(e) => setNumOfTotalBlocks(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}># of trials</P2>
          <TextField
            type="number"
            value={numOfTrial}
            onChange={(e) => setNumOfTrial(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div style={{ paddingLeft: "25px" }}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={useTriplets} />}
            onChange={(e) => setUseTriples(!useTriplets)}
            label={
              <P2
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  fontWeight: 300,
                }}
              >
                use preset triplets?
              </P2>
            }
          />
        </div>
      </div>
      <br />
      <div id="paramsters">
        <P2>Specific parameters</P2>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}>SNR calculation from #th trial</P2>
          <TextField
            type="number"
            value={numOfSNR}
            onChange={(e) => setNumOfSNR(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div id="easier" style={{ paddingLeft: "40px" }}>
          <div className="row">
            <P2 style={{ fontWeight: 300 }}>Go easier method</P2>
            <Select
              value={easier.select}
              onChange={(e) =>
                setEasier((obj) => {
                  return { ...obj, select: e.target.value };
                })
              }
              style={{ minWidth: "100px", marginLeft: "10px" }}
            >
              <MenuItem value="linear">
                <P3 style={{ fontWeight: 300 }}>Linear</P3>
              </MenuItem>
              <MenuItem value="change">
                <P3 style={{ fontWeight: 300 }}>Change at #th Trial</P3>
              </MenuItem>
            </Select>
            {easier.select === "change" && (
              <TextField
                type="number"
                value={easier.numOfTrial}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, numOfTrial: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            )}
          </div>
          {!easier.select ? null : easier.select === "linear" ? (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Increase # dbs</P2>
              <TextField
                type="number"
                value={easier.num}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, num: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          ) : (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Increase # dbs before change</P2>
              <TextField
                type="number"
                value={easier.before}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, before: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
              <P2 style={{ fontWeight: 300 }}>after change</P2>
              <TextField
                type="number"
                value={easier.after}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, after: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          )}
        </div>
        <div id="harder" style={{ paddingLeft: "40px" }}>
          <div className="row">
            <P2 style={{ fontWeight: 300 }}>Go harder method</P2>
            <Select
              value={harder.select}
              onChange={(e) =>
                setHarder((obj) => {
                  return { ...obj, select: e.target.value };
                })
              }
              style={{ minWidth: "100px", marginLeft: "10px" }}
            >
              <MenuItem value="linear">
                <P3 style={{ fontWeight: 300 }}>Linear</P3>
              </MenuItem>
              <MenuItem value="change">
                <P3 style={{ fontWeight: 300 }}>Change at #th Trial</P3>
              </MenuItem>
              <MenuItem value="incorrect">
                <P3 style={{ fontWeight: 300 }}>Change at First Incorrect</P3>
              </MenuItem>
            </Select>
            {harder.select === "change" && (
              <TextField
                type="number"
                value={harder.numOfTrial}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, numOfTrial: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            )}
          </div>
          {!harder.select ? null : harder.select === "linear" ? (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Decrease # dbs</P2>
              <TextField
                type="number"
                value={harder.num}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, num: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          ) : (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Decrease # dbs before change</P2>
              <TextField
                type="number"
                value={harder.before}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, before: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
              <P2 style={{ fontWeight: 300 }}>after change</P2>
              <TextField
                type="number"
                value={harder.after}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, after: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          )}
        </div>
      </div>

      <br />
      <P2>Recovery stimulus audios</P2>
      <Container
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
      >
        {sentenceAudios.map((ele, i) => {
          return (
            <div
              style={{
                margin: 2,
                border: "solid",
                borderWidth: 2,
                position: "relative",
              }}
            >
              <P3 style={{ fontWeight: 300 }}>Name: {ele.name}</P3>
              <P3 style={{ fontWeight: 300 }}>Sentence: {ele.sentence}</P3>
              <P3 style={{ fontWeight: 300 }}>target: {ele.target}</P3>
              <P3 style={{ fontWeight: 300 }}>MP: {ele.mp}</P3>
              <P3 style={{ fontWeight: 300 }}>Length: {ele.length}</P3>
              <P3 style={{ fontWeight: 300 }}>
                target start: {ele.targetStart}
              </P3>
              <P3 style={{ fontWeight: 300 }}>target end: {ele.targetEnd}</P3>
              <DeleteForeverIcon
                onClick={() => handleRemoveSentenceAudio(i)}
                style={{
                  position: "absolute",
                  right: 20,
                  top: 10,
                  color: "red",
                  width: 30,
                  height: 30,
                  border: "solid",
                }}
              />
            </div>
          );
        })}
        <Card>
          <CardHeader>Add new stimulus</CardHeader>
          <CardContent
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            <P3>Name</P3>{" "}
            <TextField
              value={stimulusName}
              onChange={(e) => setStimulusName(e.target.value)}
            />
            <P3>Sentence</P3>{" "}
            <TextField
              value={stimulusSentence}
              onChange={(e) => setStimulusSentence(e.target.value)}
            />
            <P3>target</P3>{" "}
            <TextField
              value={stimulusTarget}
              onChange={(e) => setStimulusTarget(e.target.value)}
            />
            <P3>MP</P3>{" "}
            <TextField
              value={stimulusMp}
              onChange={(e) => setStimulusMp(e.target.value)}
            />
            <P3>Length</P3>{" "}
            <TextField
              value={stimulusLength}
              type="number"
              onChange={(e) => setStimulusLength(parseFloat(e.target.value))}
            />
            <P3>target start</P3>{" "}
            <TextField
              value={stimulusTargetStart}
              type="number"
              onChange={(e) =>
                setStimulusTargetStart(parseFloat(e.target.value))
              }
            />
            <P3>target end</P3>{" "}
            <TextField
              value={stimulusTargetEnd}
              type="number"
              onChange={(e) => setStimulusTargetEnd(parseFloat(e.target.value))}
            />
            <input
              type="file"
              accept="audio/wav"
              onChange={(e) => setAudio(e.target.files[0])}
              style={{}}
            />
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={handleCleanNewStimulus}>
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadSentenceAudio}
            >
              Add
            </Button>
          </CardActions>
        </Card>
      </Container>
      <br />
      <P2>Digits in Noise audios</P2>
      <Switch
        checked={digitEnable}
        onChange={handleDigitEnableChange}
        name="digitEnableSwitch"
      ></Switch>
      {digitEnable && (
        <>
          <P3>(Please upload audio 1 by 1 'Choose File -{">"} Upload')</P3>
          <Container
            style={{
              margin: 2,
              border: "solid",
              borderWidth: 2,
              width: "50%",
              marginLeft: "25px",
            }}
          >
            <P3>Noise</P3>
            <P3 style={{ fontWeight: 300 }}>{noise}</P3>
            <input
              type="file"
              accept="audio/wav"
              onChange={(e) => setAudio(e.target.files[0])}
              style={{ marginBottom: 10 }}
            />
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => handleUploadNoiseAudio()}
            >
              Upload
            </Button>
          </Container>
          <Container
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            {Array(10)
              .fill("")
              .map((ele, i) => {
                return (
                  <div style={{ margin: 2, border: "solid", borderWidth: 2 }}>
                    <P3>{i}.wav</P3>
                    <P3 style={{ fontWeight: 300 }}>{digitsAudios?.[i]}</P3>
                    <input
                      type="file"
                      accept="audio/wav"
                      onChange={(e) => setAudio(e.target.files[0])}
                      style={{ marginBottom: 10 }}
                    />
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={() => handleUploadDigitAudio(i)}
                    >
                      Upload
                    </Button>
                  </div>
                );
              })}
          </Container>
        </>
      )}

      <br />
    </Container>
  );
};

export default RecoveryStimulus;
