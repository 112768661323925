import React from "react";
import axios from 'axios';
import { P1, P2, P3 } from 'assets/fonts';
import FontComponent from 'assets/font-ui';
import ChartTable from 'hearing/components/questionnaire/COSI/chart-table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, Button, TextField } from "@material-ui/core";
import Modal from 'hearing/components/partials/modal';

class COSI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participantID: "",
      studyID: "",
      dateNeedsEstablished: props.dateNeedsEstablished,
      dateOutcomeAssessed: props.dateOutcomeAssessed,
      newOrReturn: "",
      order: ["1", "2", "3", "4", "5"],
      categoryOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      priorityOptions: [1, 2, 3, 4, 5],
      modalOpen: false,
      charts: {
        "1": {
          priority: null,
          text: null,
          change: null,
          category: null,
          ability: null
        },
        "2": {
          priority: null,
          text: null,
          change: null,
          category: null,
          ability: null
        },
        "3": {
          priority: null,
          text: null,
          change: null,
          category: null,
          ability: null
        },
        "4": {
          priority: null,
          text: null,
          change: null,
          category: null,
          ability: null
        },
        "5": {
          priority: null,
          text: null,
          change: null,
          category: null,
          ability: null
        }
      }
    }
  };

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    await axios.post('/api/hearing/visit', { page: 'hearing/questionnaire/cosi' });
    const doc = await axios.get('/api/hearing/cosi/' + id);
    const { dateNeedsEstablished, dateOutcomeAssessed } = doc.data;
    if (doc.data) {
      this.setState({
        ...doc.data,
        dateNeedsEstablished: dateNeedsEstablished ? new Date(dateNeedsEstablished) : null,
        dateOutcomeAssessed: dateOutcomeAssessed ? new Date(dateOutcomeAssessed) : null
      });
    } else {
      window.location = "/hearing/questionnaire";
    }
  };

  getStNdRdOrTh = (num) => {
    switch (num) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  handleModalClose = () => {
    window.location = "/hearing/questionnaire";
  }

  handleSubmit = async () => {
    await axios.post('/api/hearing/cosi/return', this.state);
    this.setState({ modalOpen: true });
  }

  render() {
    const { participantID, modalOpen, studyID, dateNeedsEstablished, dateOutcomeAssessed, newOrReturn, charts, order, categoryOptions, priorityOptions } = this.state;
    return (
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <div style={{ margin: "3%" }}>
          <center id="title">
            <P1><b>NAL</b></P1><br />
            <P1><b>CLIENT ORIENTED SCALE OF IMPROVEMENT</b></P1>
          </center>
          <br />
          <div className="row" id="demo-info">
            <div className="col-6">
              <div id="participantID" className="row">
                <P2 style={{ marginLeft: "16px", marginRight: "20px", paddingTop: "5px" }}>
                  <b>Participant ID : </b>
                </P2>
                <TextField value={participantID} disabled />
              </div>
              <div id="studyID" className="row">
                <P2 style={{ marginLeft: "16px", marginRight: "77px", paddingTop: "5px" }}>
                  <b>Study ID : </b>
                </P2>
                <TextField value={studyID} disabled />
              </div>
              <div id="date">
                <P2><b>Date:</b></P2>
                <div className="row">
                  <P2 style={{ marginLeft: "50px", marginRight: "20px" }}><b>1. Needs Established</b></P2>
                  <DatePicker selected={dateNeedsEstablished} onChange={date => this.setState({ dateNeedsEstablished: date })} />
                </div>
                <div className="row">
                  <P2 style={{ marginLeft: "50px", marginRight: "20px" }}><b>2. Outcome Assessed</b></P2>
                  <DatePicker selected={dateOutcomeAssessed} onChange={date => this.setState({ dateOutcomeAssessed: date })} />
                </div>
              </div>
            </div>
            <div className="col-6">
              <P2><b>Category:</b></P2>
              <div className="row">
                <P2 style={{ marginLeft: "50px", marginRight: "32px", marginTop: "8px" }}><b>New</b></P2>
                <Checkbox value="new" color="primary" checked={newOrReturn === "new"} onChange={e => this.setState({ newOrReturn: "new" })} />
              </div>
              <div className="row">
                <P2 style={{ marginLeft: "50px", marginRight: "5px", marginTop: "8px" }}><b>Return</b></P2>
                <Checkbox value="return" color="primary" checked={newOrReturn === "return"} onChange={e => this.setState({ newOrReturn: "return" })} />
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <ChartTable
              order={order}
              charts={charts}
              categoryOptions={categoryOptions}
              priorityOptions={priorityOptions}
              onOrderChange={(order) => this.setState({ order })}
              onChartsChange={(charts) => this.setState({ charts })}
              onCategoryOptionsChange={(categoryOptions) => this.setState({ categoryOptions })}
              onPriorityOptionsChange={(priorityOptions) => this.setState({ priorityOptions })}
            />
          </div>
          <br />
          <div id="categories" >
            <P3><b>Categories</b></P3>
            <div className="row" style={{ marginLeft: "100px" }}>
              <div className="col-6">
                <div className="row"><P3>1.  Conversation with 1 or 2 in quiet</P3></div>
                <div className="row"><P3>2.  Conversation with 1 or 2 in noise</P3></div>
                <div className="row"><P3>3.  Conversation with group in quiet</P3></div>
                <div className="row"><P3>4.  Conversation with group in noise</P3></div>
                <div className="row"><P3>5.  Television/Radio @ normal volume</P3></div>
                <div className="row"><P3>6.  Familiar speaker on phone</P3></div>
                <div className="row"><P3>7.  Unfamiliar speaker on phone</P3></div>
                <div className="row"><P3>8.  Hearing phone ring from another room</P3></div>
              </div>
              <div className="col-6">
                <div className="row"><P3>9.  Hear front door bell or knock</P3></div>
                <div className="row"><P3>10. Hear traffic</P3></div>
                <div className="row"><P3>11. Increased social contact</P3></div>
                <div className="row"><P3>12. Feel embarrassed or stupid</P3></div>
                <div className="row"><P3>13. Feeling left out</P3></div>
                <div className="row"><P3>14. Feeling upset or angry</P3></div>
                <div className="row"><P3>15. Church or meeting</P3></div>
                <div className="row"><P3>16. Other</P3></div>
              </div>
            </div>
          </div>
          <br />
          <div id="submit">
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={this.handleSubmit}
              style={{ marginLeft: "100px" }}
            >
              Submit
            </Button>
          </div>
        </div>
        <FontComponent />
        <Modal
          open={modalOpen}
          onClose={this.handleModalClose}
        />
      </div>
    )
  }
}

export default COSI;