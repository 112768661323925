import React from "react";
import axios from "axios";
import keys from "literacy/assets/keys";
import { Button, Container, TextField, InputLabel, Select, MenuItem } from "@material-ui/core";

class TutorMaterials extends React.Component {
  constructor() {
    super();
    this.state = {
      w1: null,
      w2: null,
      file: null,
      w1Desc: null,
      w2Desc: null,
      section: "w1"
    };
  }

  componentDidMount = async () => {
    const doc1 = await axios.get("/api/literacy/print/materials/w1");
    const doc2 = await axios.get("/api/literacy/print/materials/w2");
    if (doc1.data) {
      this.setState({
        w1: {
          video: doc1.data.video,
          desc: doc1.data.desc
        }
      });
    } else {
      this.setState({ w1: null })
    }
    if (doc2.data) {
      this.setState({
        w2: {
          video: doc2.data.video,
          desc: doc2.data.desc
        }
      });
    } else {
      this.setState({ w2: null })
    }
  };

  handleMaterialsAdd = async () => {
    const { file, section, w1Desc, w2Desc } = this.state;
    if (!!file) {
      const doc = await axios.get("/api/literacy/print/video");
      const uploadConfigs = doc.data;
      await axios
        .put(uploadConfigs.url, file, {
          headers: {
            "Content-type": "video/mp4",
          },
        })
        .catch((err) => console.log(err));
      if (section === "w1") {
        await axios.post("/api/literacy/print/materials/w1",
          { desc: w1Desc, video: uploadConfigs.key });
      } else if (section === "w2") {
        await axios.post("/api/literacy/print/materials/w2",
          { desc: w2Desc, video: uploadConfigs.key });
      }
      this.componentDidMount();
    }
  };

  handleMaterialsDelete = async () => {
    const { section } = this.state;
    if (section === "w1") {
      await axios.delete("/api/literacy/print/materials/w1");
    } else if (section === "w2") {
      await axios.delete("/api/literacy/print/materials/w2");
    }
    this.componentDidMount();
  };

  handleSectionChange = (e) => {
    this.setState({ section: e.target.value });
  }

  renderContent = () => {
    const { section, w1, w2 } = this.state;
    switch (section) {
      case "w1":
        if (w1) {
          return <div>
            <h5>{w1.desc}</h5>
            <iframe
              style={{
                width: "80%",
                height: "500px"
              }}
              allowFullScreen
              src={"https://literacy-app-video.s3.ca-central-1.amazonaws.com/" + w1.video}></iframe>
            <Button variant="outlined" style={{ color: "white", backgroundColor: "#107896" }} onClick={this.handleMaterialsDelete}>Delete</Button>
          </div>;
        } else {
          return <div>
            <TextField
              style={{ width: "50%", marginRight: 10 }}
              multiline={true}
              placeholder="description"
              onChange={(e) => this.setState({ w1Desc: e.target.value })}
            />
            <br /><br />
            <input
              type="file"
              accept="video/*"
              onChange={(e) => this.setState({ file: e.target.files[0] })}
            />
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#107896" }}
              onClick={this.handleMaterialsAdd}
            >
              Add
            </Button>
          </div>
        }

      case "w2":
        if (w2) {
          return <div>
            <h5>{w2.desc}</h5>
            <iframe
              style={{
                width: "80%",
                height: "500px"
              }}
              allowFullScreen
              src={"https://literacy-app-video.s3.ca-central-1.amazonaws.com/" + w2.video}></iframe>
            <Button variant="outlined" style={{ color: "white", backgroundColor: "#107896" }} onClick={this.handleMaterialsDelete}>Delete</Button>
          </div>;
        } else {
          return <div>
            <TextField
              style={{ width: "50%", marginRight: 10 }}
              multiline={true}
              placeholder="description"
              onChange={(e) => this.setState({ w2Desc: e.target.value })}
            />
            <br /><br />
            <input
              type="file"
              accept="video/*"
              onChange={(e) => this.setState({ file: e.target.files[0] })}
            />
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#107896" }}
              onClick={this.handleMaterialsAdd}
            >
              Add
            </Button>
          </div>
        }
    }
  }

  render() {
    const { section } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Modify Learning Materials</h2>
          <br />
          <Button variant="contained" style={{ color: "white", backgroundColor: "#107896" }} href="/literacy/tutor/print">
            Go back
          </Button>
        </div>
        <div>
          <Container>
            <InputLabel id="label">Learning Section</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={section}
              onChange={this.handleSectionChange}
            >
              <MenuItem value="w1">Week1</MenuItem>
              <MenuItem value="w2">Week2</MenuItem>
            </Select>
          </Container>
          <br />
          <Container>
            {this.renderContent()}
          </Container>
        </div>
      </div>
    );
  }
}

export default TutorMaterials;
