import React from "react";
import styles from "./evaluation.module.css";
import Ellipse2 from "../assets/images/Ellipse 2.svg";
import Ellipse3 from "../assets/images/Ellipse 3.svg";
import { UofaBranding } from "../main-components/uofa-branding";
import { Header } from "../main-components/header";
import { FooterCom } from "../main-components/footer";
import { Tile } from "../main-components/tile";
import chartDynamicColor from "../assets/images/chart-dynamic-color.png";
import computerDynamicColor from "../assets/images/computer-dynamic-color.png";
import folderDynamicColor from "../assets/images/folder-dynamic-color.png";
import notebookDynamicColor from "../assets/images/notebook-dynamic-color.png";
import pictureDynamicColor from "../assets/images/picture-dynamic-color.png";
import playDynamicColor from "../assets/images/play-dynamic-color.png";
import settingDynamicColor from "../assets/images/setting-dynamic-color.png";
import zoomDynamicColor from "../assets/images/zoom-dynamic-color.png";

export const Evaluation = () => {
  const tileItems = [
    { id: 1, title: "ADMIN", icon: settingDynamicColor, nav: "/gui/admin" },
    { id: 2, title: "DATABASE", icon: folderDynamicColor, nav: "/database" },
    { id: 3, title: "AUDIO EXPERIMENT", icon: playDynamicColor, nav: "/gui" },
    { id: 4, title: "APPEARANCE", icon: zoomDynamicColor, nav: "/appearance/" },
    {
      id: 5,
      title: "IMAGE PROCESSING",
      icon: pictureDynamicColor,
      nav: "/imageprocess",
    },
    { id: 6, title: "LITERACY", icon: notebookDynamicColor, nav: "/literacy/" },
    {
      id: 7,
      title: "CRAIG'S APPLICATION",
      icon: computerDynamicColor,
      nav: "/hearing-test",
    },
    { id: 8, title: "AUDIOGRAM", icon: chartDynamicColor, nav: "/audiogram" },
  ];

  return (
    <div className="main">
      <div className={styles.background}>
        <div className="overlap">
          <img className="ellipse" alt="Ellipse" src={Ellipse3} />
        </div>
        <div className={styles.evaluation}>
          <UofaBranding
            UA={require("../assets/images/ua-1.png")}
            covenant={require("../assets/images/covenant-1.png")}
            irsm={require("../assets/images/irsm-1.png")}
          />
        </div>
        <div className="overlap-2">
          <img className="ellipse-2" alt="Ellipse" src={Ellipse2} />
          <div className="header-instance">
            <Header
              LOGOBcalLogoRemovebg={require("../assets/images/bcal-logo-removebg-preview-1.png")}
            />
          </div>
        </div>
        <b className={styles.rehabilitationScienceEvaluat}>
          Rehabilitation Science Evaluation
        </b>
        <div className={styles.gridContainer}>
          {tileItems.map((item) => (
            <Tile
              key={item.id}
              title={item.title}
              icon={item.icon}
              nav={item.nav}
            />
          ))}
        </div>
        <div className="footer-instance">
          <FooterCom
            img={require("../assets/images/screenshot-20240423-at-9021.png")}
            screenshot={require("../assets/images/screenshot-20240423-at-9031.png")}
          />
        </div>
      </div>
    </div>
  );
};

export default Evaluation;
