import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { HearingAWS } from 'assets/keys';

const Image = (props) => {
  const { id } = props;
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/image/" + id);
        setUrl(doc.data.url);
      })();
    }
  }, [id]);

  return (
    <Container id="image">
      <br />
      {url?.map(image => <img key={image} src={HearingAWS + image} style={{ maxHeight: "100%", maxWidth: "100%" }} />)}
      <br /><br />
    </Container>
  )
};

export default Image;