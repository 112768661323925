import { Container, Button } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';

const CRM1TutorPage = () => {
  const [ICF, setICF] = useState(null);
  const [targetAudio11, setTargetAudio11] = useState(null);
  const [targetAudio12, setTargetAudio12] = useState(null);
  const [targetAudio13, setTargetAudio13] = useState(null);
  const [targetAudio14, setTargetAudio14] = useState(null);
  const [targetAudio15, setTargetAudio15] = useState(null);
  const [targetAudio16, setTargetAudio16] = useState(null);
  const [targetAudio17, setTargetAudio17] = useState(null);
  const [targetAudio18, setTargetAudio18] = useState(null);
  const [targetAudio21, setTargetAudio21] = useState(null);
  const [targetAudio22, setTargetAudio22] = useState(null);
  const [targetAudio23, setTargetAudio23] = useState(null);
  const [targetAudio24, setTargetAudio24] = useState(null);
  const [targetAudio25, setTargetAudio25] = useState(null);
  const [targetAudio26, setTargetAudio26] = useState(null);
  const [targetAudio27, setTargetAudio27] = useState(null);
  const [targetAudio28, setTargetAudio28] = useState(null);
  const [targetAudio31, setTargetAudio31] = useState(null);
  const [targetAudio32, setTargetAudio32] = useState(null);
  const [targetAudio33, setTargetAudio33] = useState(null);
  const [targetAudio34, setTargetAudio34] = useState(null);
  const [targetAudio35, setTargetAudio35] = useState(null);
  const [targetAudio36, setTargetAudio36] = useState(null);
  const [targetAudio37, setTargetAudio37] = useState(null);
  const [targetAudio38, setTargetAudio38] = useState(null);
  const [targetAudio41, setTargetAudio41] = useState(null);
  const [targetAudio42, setTargetAudio42] = useState(null);
  const [targetAudio43, setTargetAudio43] = useState(null);
  const [targetAudio44, setTargetAudio44] = useState(null);
  const [targetAudio45, setTargetAudio45] = useState(null);
  const [targetAudio46, setTargetAudio46] = useState(null);
  const [targetAudio47, setTargetAudio47] = useState(null);
  const [targetAudio48, setTargetAudio48] = useState(null);
  const [lossAudio11, setLossAudio11] = useState(null);
  const [lossAudio12, setLossAudio12] = useState(null);
  const [lossAudio13, setLossAudio13] = useState(null);
  const [lossAudio14, setLossAudio14] = useState(null);
  const [lossAudio15, setLossAudio15] = useState(null);
  const [lossAudio16, setLossAudio16] = useState(null);
  const [lossAudio17, setLossAudio17] = useState(null);
  const [lossAudio18, setLossAudio18] = useState(null);
  const [lossAudio21, setLossAudio21] = useState(null);
  const [lossAudio22, setLossAudio22] = useState(null);
  const [lossAudio23, setLossAudio23] = useState(null);
  const [lossAudio24, setLossAudio24] = useState(null);
  const [lossAudio25, setLossAudio25] = useState(null);
  const [lossAudio26, setLossAudio26] = useState(null);
  const [lossAudio27, setLossAudio27] = useState(null);
  const [lossAudio28, setLossAudio28] = useState(null);
  const [lossAudio31, setLossAudio31] = useState(null);
  const [lossAudio32, setLossAudio32] = useState(null);
  const [lossAudio33, setLossAudio33] = useState(null);
  const [lossAudio34, setLossAudio34] = useState(null);
  const [lossAudio35, setLossAudio35] = useState(null);
  const [lossAudio36, setLossAudio36] = useState(null);
  const [lossAudio37, setLossAudio37] = useState(null);
  const [lossAudio38, setLossAudio38] = useState(null);
  const [lossAudio41, setLossAudio41] = useState(null);
  const [lossAudio42, setLossAudio42] = useState(null);
  const [lossAudio43, setLossAudio43] = useState(null);
  const [lossAudio44, setLossAudio44] = useState(null);
  const [lossAudio45, setLossAudio45] = useState(null);
  const [lossAudio46, setLossAudio46] = useState(null);
  const [lossAudio47, setLossAudio47] = useState(null);
  const [lossAudio48, setLossAudio48] = useState(null);

  const handleUploadICF = async () => {
    const doc = await axios.get("/api/hearing/crm1/icf");
    const uploadConfigs = doc.data;
    await axios
      .put(uploadConfigs.url, ICF, {
        headers: {
          "Content-type": 'application/pdf',
        },
      })
      .then(() => window.alert('ICF PDF update successfully!'))
      .catch((err) => window.alert('Something wrong:', err));
  }

  const handleUploadAudio = async (type, name) => {
    return null;
  }

  return (
    <Container>
      <h1>CRM1 Tutor Page</h1>
      <br />
      <Container id="icf">
        <h2>Update CRM1 ICF.pdf</h2>
        <h6>(Make sure the file in PDF format, otherwise, it cannot display on the web.)</h6>
        <input
          type="file"
          accept="pdf"
          onChange={(e) => setICF(e.target.files[0])}
        />
        <Button size="small" color="primary" variant="outlined" onClick={handleUploadICF}>Upload</Button>
      </Container>
      <br />
      <Container id="audio">
        <h2>Update CRM1 audios</h2><br />
        <h4>Target audios</h4>
        <div id="target" style={{ height: "500px", width: "700px", overflowY: "scroll", border: "solid", padding: "20px 0 20px 50px" }}>
          <div className="row"><h6>11.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio11(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "11")}>Upload</Button></div><br />
          <div className="row"><h6>12.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio12(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "12")}>Upload</Button></div><br />
          <div className="row"><h6>13.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio13(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "13")}>Upload</Button></div><br />
          <div className="row"><h6>14.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio14(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "14")}>Upload</Button></div><br />
          <div className="row"><h6>15.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio15(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "15")}>Upload</Button></div><br />
          <div className="row"><h6>16.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio16(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "16")}>Upload</Button></div><br />
          <div className="row"><h6>17.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio17(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "17")}>Upload</Button></div><br />
          <div className="row"><h6>18.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio18(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "18")}>Upload</Button></div><br />
          <div className="row"><h6>21.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio21(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "21")}>Upload</Button></div><br />
          <div className="row"><h6>22.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio22(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "22")}>Upload</Button></div><br />
          <div className="row"><h6>23.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio23(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "23")}>Upload</Button></div><br />
          <div className="row"><h6>24.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio24(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "24")}>Upload</Button></div><br />
          <div className="row"><h6>25.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio25(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "25")}>Upload</Button></div><br />
          <div className="row"><h6>26.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio26(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "26")}>Upload</Button></div><br />
          <div className="row"><h6>27.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio27(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "27")}>Upload</Button></div><br />
          <div className="row"><h6>28.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio28(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "28")}>Upload</Button></div><br />
          <div className="row"><h6>31.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio31(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "31")}>Upload</Button></div><br />
          <div className="row"><h6>32.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio32(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "32")}>Upload</Button></div><br />
          <div className="row"><h6>33.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio33(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "33")}>Upload</Button></div><br />
          <div className="row"><h6>34.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio34(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "34")}>Upload</Button></div><br />
          <div className="row"><h6>35.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio35(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "35")}>Upload</Button></div><br />
          <div className="row"><h6>36.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio36(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "36")}>Upload</Button></div><br />
          <div className="row"><h6>37.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio37(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "37")}>Upload</Button></div><br />
          <div className="row"><h6>38.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio38(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "38")}>Upload</Button></div><br />
          <div className="row"><h6>41.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio41(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "41")}>Upload</Button></div><br />
          <div className="row"><h6>42.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio42(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "42")}>Upload</Button></div><br />
          <div className="row"><h6>43.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio43(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "43")}>Upload</Button></div><br />
          <div className="row"><h6>44.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio44(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "44")}>Upload</Button></div><br />
          <div className="row"><h6>45.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio45(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "45")}>Upload</Button></div><br />
          <div className="row"><h6>46.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio46(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "46")}>Upload</Button></div><br />
          <div className="row"><h6>47.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio47(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "47")}>Upload</Button></div><br />
          <div className="row"><h6>48.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setTargetAudio48(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("target", "48")}>Upload</Button></div><br />
        </div><br />
        <h4>Loss audios</h4>
        <div id="target" style={{ height: "500px", width: "700px", overflowY: "scroll", border: "solid", padding: "20px 0 20px 50px" }}>
          <div className="row"><h6>11.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio11(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "11")}>Upload</Button></div><br />
          <div className="row"><h6>12.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio12(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "12")}>Upload</Button></div><br />
          <div className="row"><h6>13.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio13(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "13")}>Upload</Button></div><br />
          <div className="row"><h6>14.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio14(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "14")}>Upload</Button></div><br />
          <div className="row"><h6>15.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio15(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "15")}>Upload</Button></div><br />
          <div className="row"><h6>16.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio16(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "16")}>Upload</Button></div><br />
          <div className="row"><h6>17.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio17(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "17")}>Upload</Button></div><br />
          <div className="row"><h6>18.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio18(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "18")}>Upload</Button></div><br />
          <div className="row"><h6>21.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio21(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "21")}>Upload</Button></div><br />
          <div className="row"><h6>22.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio22(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "22")}>Upload</Button></div><br />
          <div className="row"><h6>23.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio23(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "23")}>Upload</Button></div><br />
          <div className="row"><h6>24.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio24(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "24")}>Upload</Button></div><br />
          <div className="row"><h6>25.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio25(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "25")}>Upload</Button></div><br />
          <div className="row"><h6>26.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio26(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "26")}>Upload</Button></div><br />
          <div className="row"><h6>27.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio27(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "27")}>Upload</Button></div><br />
          <div className="row"><h6>28.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio28(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "28")}>Upload</Button></div><br />
          <div className="row"><h6>31.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio31(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "31")}>Upload</Button></div><br />
          <div className="row"><h6>32.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio32(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "32")}>Upload</Button></div><br />
          <div className="row"><h6>33.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio33(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "33")}>Upload</Button></div><br />
          <div className="row"><h6>34.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio34(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "34")}>Upload</Button></div><br />
          <div className="row"><h6>35.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio35(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "35")}>Upload</Button></div><br />
          <div className="row"><h6>36.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio36(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "36")}>Upload</Button></div><br />
          <div className="row"><h6>37.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio37(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "37")}>Upload</Button></div><br />
          <div className="row"><h6>38.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio38(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "38")}>Upload</Button></div><br />
          <div className="row"><h6>41.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio41(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "41")}>Upload</Button></div><br />
          <div className="row"><h6>42.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio42(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "42")}>Upload</Button></div><br />
          <div className="row"><h6>43.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio43(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "43")}>Upload</Button></div><br />
          <div className="row"><h6>44.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio44(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "44")}>Upload</Button></div><br />
          <div className="row"><h6>45.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio45(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "45")}>Upload</Button></div><br />
          <div className="row"><h6>46.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio46(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "46")}>Upload</Button></div><br />
          <div className="row"><h6>47.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio47(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "47")}>Upload</Button></div><br />
          <div className="row"><h6>48.wav</h6><input type="file" accept="audio/wav" onChange={(e) => setLossAudio48(e.target.files[0])} /><Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", "48")}>Upload</Button></div><br />
        </div><br />
      </Container>
    </Container>
  )
}

export default CRM1TutorPage;