import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import TrainCard from "literacy/assets/cards/tutorallassigncard";
import { ExportReactCSV } from "assets/csv";

class MeaningTutorTrainAllAssign extends React.Component {
  constructor() {
    super();
    this.state = { assignments: [], headers: [], data: [] };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/literacy/meaning/assign");
    if (doc.data) {
      this.setState({ assignments: doc.data });
    }
    this.saveExportData();
  };

  getMaxLengthOfAssign = () => {
    let maxQ1Assign = 0;
    let maxQ2Assign = 0;
    let maxQ3Assign = 0;
    for (let _ of this.state.assignments) {
      const { q1Assign, q2Assign, q3Assign } = _;
      maxQ1Assign = Math.max(maxQ1Assign, q1Assign.length);
      maxQ2Assign = Math.max(maxQ2Assign, q2Assign.length);
      maxQ3Assign = Math.max(maxQ3Assign, q3Assign.length);
    }
    return [maxQ1Assign, maxQ2Assign, maxQ3Assign];
  };

  saveExportData = () => {
    const [maxQ1Assign, maxQ2Assign, maxQ3Assign] = this.getMaxLengthOfAssign();

    // headers
    let headers = [
      "Student Name",
      "Student Email",
      "Time",
      "New Score",
      "Total-Accuracy",
      "Q1-Accuracy",
      "Q2-Accuracy",
      "Q3-Accuracy",
    ];
    for (let i = 1; i <= maxQ1Assign; i++) {
      headers.push(
        `Q1-Question-${i}`,
        `Q1-Choices-${i}`,
        `Q1-Answer-${i}`,
        `Q1-StudentAnswer-${i}`,
        `Q1-Correct-${i}`,
        `Q1-hint-${i}`
      );
    }
    for (let i = 1; i <= maxQ2Assign; i++) {
      headers.push(
        `Q2-Question-${i}`,
        `Q2-Answer-${i}`,
        `Q2-StudentAnswer-${i}`,
        `Q2-Correct-${i}`,
        `Q2-hint-${i}`
      );
    }
    for (let i = 1; i <= maxQ3Assign; i++) {
      headers.push(
        `Q3-Question-${i}`,
        `Q3-Answer-${i}`,
        `Q3-StudentAnswer-${i}`,
        `Q3-Correct-${i}`,
        `Q3-hint-${i}`
      );
    }

    // datas
    const datas = [];
    for (let _ of this.state.assignments) {
      let data = [];
      const {
        studentName,
        studentEmail,
        createAt,
        oldScore,
        newScore,
        q1Assign,
        q2Assign,
        q3Assign,
      } = _;
      // 2022-05-17 calculate accuracy
      let totalAccuracy = 0;
      let q1Accuracy = 0;
      let q2Accuracy = 0;
      let q3Accuracy = 0;
      q1Assign.map((assign) => {
        if (
          JSON.stringify(assign?.studentAnswer?.sort()) ===
          JSON.stringify(assign?.realAnswer?.sort())
        ) {
          totalAccuracy += 1;
          q1Accuracy += 1;
        }
      });
      q2Assign.map((assign) => {
        if (
          assign?.studentAnswer?.toLowerCase().replace(/\s/g, "") ===
          assign?.realAnswer?.toLowerCase().replace(/\s/g, "")
        ) {
          totalAccuracy += 1;
          q2Accuracy += 1;
        }
      });
      q3Assign.map((assign) => {
        if (assign?.studentAnswer === assign?.realAnswer) {
          totalAccuracy += 1;
          q3Accuracy += 1;
        }
      });
      // end
      data.push(
        studentName,
        studentEmail,
        createAt,
        newScore,
        totalAccuracy,
        q1Accuracy,
        q2Accuracy,
        q3Accuracy
      );
      const questions = {};
      let score = 0;
      for (let i = 0; i < maxQ1Assign; i++) {
        if (i < q1Assign.length) {
          const assignQuestion = q1Assign[i];
          const { question, choices, realAnswer, studentAnswer, hint } =
            assignQuestion;
          if (!!question) {
            // re-calculate new score (only add 1,0.7,0.5)
            questions[question] = questions[question]
              ? questions[question] + 1
              : 1;
            const correctness =
              JSON.stringify(studentAnswer?.sort()) ===
              JSON.stringify(realAnswer?.sort());
            if (correctness) {
              if (questions[question] == 1) score += 1;
              else if (questions[question] == 2) score += 0.7;
              else if (questions[question] == 3) score += 0.5;
            }
            // end
            data.push(
              question,
              choices.join(","),
              realAnswer.join(","),
              studentAnswer.join(","),
              String(correctness),
              hint
            );
          } else data.push("", "", "", "", "", "");
        } else {
          data.push("", "", "", "", "", "");
        }
      }
      for (let i = 0; i < maxQ2Assign; i++) {
        if (i < q2Assign.length) {
          const assignQuestion = q2Assign[i];
          const { question, realAnswer, studentAnswer, hint } = assignQuestion;
          // re-calculate new score (only add 1,0.7,0.5)
          questions[question] = questions[question]
            ? questions[question] + 1
            : 1;
          const correctness =
            realAnswer.toLowerCase().replace(/\s/g, "") ==
            studentAnswer.toLowerCase().replace(/\s/g, "");
          if (correctness) {
            if (questions[question] == 1) score += 1;
            else if (questions[question] == 2) score += 0.7;
            else if (questions[question] == 3) score += 0.5;
          }
          // end
          data.push(
            question,
            realAnswer,
            studentAnswer,
            String(correctness),
            hint
          );
        } else {
          data.push("", "", "", "", "");
        }
      }
      for (let i = 0; i < maxQ3Assign; i++) {
        if (i < q3Assign.length) {
          const assignQuestion = q3Assign[i];
          const { question, realAnswer, studentAnswer, hint } = assignQuestion;
          // re-calculate new score (only add 1,0.7,0.5)
          questions[question] = questions[question]
            ? questions[question] + 1
            : 1;
          const correctness = realAnswer == studentAnswer;
          if (correctness) {
            if (questions[question] == 1) score += 1;
            else if (questions[question] == 2) score += 0.7;
            else if (questions[question] == 3) score += 0.5;
          }
          // end
          data.push(
            question,
            realAnswer,
            studentAnswer,
            String(correctness),
            hint
          );
        } else {
          data.push("", "", "", "", "");
        }
      }
      data[3] = score;
      datas.push(data);
    }
    this.setState({ headers, data: datas });
  };

  render() {
    const { assignments, headers, data } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Review Students' Assignment</h2>
          <br />
          <ExportReactCSV
            data={data}
            headers={headers}
            fileName="Hearing Assign"
          />
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#107896",
              marginLeft: "10px",
            }}
            href="/literacy/tutor/meaning"
          >
            Go back
          </Button>
        </div>
        <Container>
          <div className="row">
            {assignments.map((assign, index) => {
              return (
                <TrainCard
                  title={assign.studentName}
                  description={assign.createAt}
                  handleClick={() =>
                    (window.location =
                      "/literacy/tutor/meaning/assign/" + assign._id)
                  }
                />
              );
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default MeaningTutorTrainAllAssign;
