import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { P1, P2, P3 } from 'assets/fonts';
import { HearingAWS } from 'assets/keys';
import { Container, Button, TextField } from '@material-ui/core';

const Image = (props) => {
  const { id } = props;
  const [url, setUrl] = useState(null);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/image/" + id);
        setUrl(doc.data.url);
      })();
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await axios.put("/api/gui/image/" + id, { url });
    })();
  }, [url]);

  const handleUploadImages = async () => {
    if (files != null) {
      const imagePromises = files.map(async (file) => {
        const doc = await axios.get("/api/gui/image", { contentType: file.type });
        const uploadConfigs = doc.data;
        await axios.put(uploadConfigs.url, file, { headers: { "Content-type": file.type } });
        return uploadConfigs.key;
      });

      const url = await Promise.all(imagePromises);
      setUrl(url);
    };
  };

  return (
    <Container id="images">
      <P1>Image(s)</P1>
      <div className="row">
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={(e) => setFiles(Array.from(e.target.files))}
        />
        <Button size="small" color="primary" variant="contained" onClick={handleUploadImages}>Upload</Button>
      </div>
      <br />
      <div id="images-demo" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
        {url?.map(image => <img key={image} src={HearingAWS + image} style={{ maxHeight: "100%", maxWidth: "100%" }} />)}
      </div>
      <br />
    </Container>
  )
};

export default Image;