import React from "react";
import { P1, P2, P3 } from 'assets/fonts';
import { Container } from "@material-ui/core";
import { ExportReactCSV } from 'hearing/components/partials/csv';
import axios from "axios";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      longHeader: [],
      longData: [],
      longScaleQuestion1: [],
      longScaleQuestion2: [],
      longScaleQuestion3: [],

      shortHeader: [],
      shortData: [],
      shortScaleQuestion: [],

      prepostHeader: [],
      prepostData: [],
      prepostScaleQuestion1: [],
      prepostScaleQuestion2: [],
      prepostScaleQuestion3: [],

      comHeader: [],
      comData: [],
      comScaleQuestion1: [],
      comScaleQuestion2: [],
      comScaleQuestion3: [],
    }
  }

  componentDidMount = async () => {
    const longDoc = await axios.get('/api/hearing/ssqlong/user');
    const shortDoc = await axios.get('/api/hearing/ssqshort/user');
    const prepostDoc = await axios.get('/api/hearing/ssqprepost/user');
    const comDoc = await axios.get('/api/hearing/ssqcom/user');

    // Long
    const longHeader = ['Participant ID', 'Study ID', 'Age', 'Date', 'Years', "Months", 'Weeks', 'Checkbox', 'Notes'];
    const longScaleQuestion1L = Object.keys(longDoc.data?.[0]?.scaleQuestion1 ?? {}).length;
    const longScaleQuestion2L = Object.keys(longDoc.data?.[0]?.scaleQuestion2 ?? {}).length;
    const longScaleQuestion3L = Object.keys(longDoc.data?.[0]?.scaleQuestion3 ?? {}).length;
    for (let i = 1; i <= longScaleQuestion1L; i++) {
      longHeader.push("Scale-1-" + String(i));
    }
    for (let i = 1; i <= longScaleQuestion2L; i++) {
      longHeader.push("Scale-2-" + String(i));
    }
    for (let i = 1; i <= longScaleQuestion3L; i++) {
      longHeader.push("Scale-3-" + String(i));
    }
    const longData = [];
    const longScaleQuestion1 = [];
    const longScaleQuestion2 = [];
    const longScaleQuestion3 = [];
    longDoc.data?.map(data => {
      const row = [data.ParticipantID, data.StudyID, data.age, data.date?.split('T')?.[0], data.years, data.months, data.weeks, data.checkboxQuestion, data.notes];
      Object.keys(data.scaleQuestion1).map(key => {
        row.push(data.scaleQuestion1[key]);
        longScaleQuestion1.push(key);
      });
      Object.keys(data.scaleQuestion2).map(key => {
        row.push(data.scaleQuestion2[key]);
        longScaleQuestion2.push(key);
      });
      Object.keys(data.scaleQuestion3).map(key => {
        row.push(data.scaleQuestion3[key]);
        longScaleQuestion3.push(key);
      });
      longData.push(row);
    });

    // Short
    const shortHeader = ['Participant ID', 'Study ID', 'Age', 'Date', 'LeftYears', "LeftMonths", 'LeftWeeks', 'RightYears', "RightMonths", 'RightWeeks', 'Checkbox', 'Notes'];
    const shortScaleQuestionL = Object.keys(shortDoc.data?.[0]?.scaleQuestion ?? {}).length;
    for (let i = 1; i <= shortScaleQuestionL; i++) {
      shortHeader.push("Scale-" + String(i));
    }
    const shortData = [];
    const shortScaleQuestion = [];
    shortDoc.data?.map(data => {
      const row = [data.ParticipantID, data.StudyID, data.age, data.date?.split('T')?.[0], data.leftYears, data.leftMonths, data.leftWeeks, data.rightYears, data.rightMonths, data.rightWeeks, data.checkboxQuestion, data.notes];
      Object.keys(data.scaleQuestion).map(key => {
        row.push(data.scaleQuestion[key]);
        shortScaleQuestion.push(key);
      });
      shortData.push(row);
    });

    // Prepost
    const prepostHeader = ['Participant ID', 'Study ID', 'Age', 'Date', 'Years', "Months", 'Weeks', 'Checkbox', 'Notes'];
    const prepostScaleQuestion1L = Object.keys(prepostDoc.data?.[0]?.scaleQuestion1 ?? {}).length;
    const prepostScaleQuestion2L = Object.keys(prepostDoc.data?.[0]?.scaleQuestion2 ?? {}).length;
    const prepostScaleQuestion3L = Object.keys(prepostDoc.data?.[0]?.scaleQuestion3 ?? {}).length;
    for (let i = 1; i <= prepostScaleQuestion1L; i++) {
      prepostHeader.push("Scale-1-" + String(i));
    }
    for (let i = 1; i <= prepostScaleQuestion2L; i++) {
      prepostHeader.push("Scale-2-" + String(i));
    }
    for (let i = 1; i <= prepostScaleQuestion3L; i++) {
      prepostHeader.push("Scale-3-" + String(i));
    }
    const prepostData = [];
    const prepostScaleQuestion1 = [];
    const prepostScaleQuestion2 = [];
    const prepostScaleQuestion3 = [];
    prepostDoc.data?.map(data => {
      const row = [data.ParticipantID, data.StudyID, data.age, data.date?.split('T')?.[0], data.years, data.months, data.weeks, data.checkboxQuestion, data.notes];
      Object.keys(data.scaleQuestion1).map(key => {
        row.push(data.scaleQuestion1[key]);
        prepostScaleQuestion1.push(key);
      });
      Object.keys(data.scaleQuestion2).map(key => {
        row.push(data.scaleQuestion2[key]);
        prepostScaleQuestion2.push(key);
      });
      Object.keys(data.scaleQuestion3).map(key => {
        row.push(data.scaleQuestion3[key]);
        prepostScaleQuestion3.push(key);
      });
      prepostData.push(row);
    });

    // Comparison
    const comHeader = ['Participant ID', 'Study ID', 'Age', 'Date', 'Checkbox', 'Notes'];
    const comScaleQuestion1L = Object.keys(comDoc.data?.[0]?.scaleQuestion1 ?? {}).length;
    const comScaleQuestion2L = Object.keys(comDoc.data?.[0]?.scaleQuestion2 ?? {}).length;
    const comScaleQuestion3L = Object.keys(comDoc.data?.[0]?.scaleQuestion3 ?? {}).length;
    for (let i = 1; i <= comScaleQuestion1L; i++) {
      comHeader.push("Scale-1-" + String(i));
    }
    for (let i = 1; i <= comScaleQuestion2L; i++) {
      comHeader.push("Scale-2-" + String(i));
    }
    for (let i = 1; i <= comScaleQuestion3L; i++) {
      comHeader.push("Scale-3-" + String(i));
    }
    const comData = [];
    const comScaleQuestion1 = [];
    const comScaleQuestion2 = [];
    const comScaleQuestion3 = [];
    comDoc.data?.map(data => {
      const row = [data.ParticipantID, data.StudyID, data.age, data.date?.split('T')?.[0], data.checkboxQuestion, data.notes];
      Object.keys(data.scaleQuestion1).map(key => {
        row.push(data.scaleQuestion1[key]);
        comScaleQuestion1.push(key);
      });
      Object.keys(data.scaleQuestion2).map(key => {
        row.push(data.scaleQuestion2[key]);
        comScaleQuestion2.push(key);
      });
      Object.keys(data.scaleQuestion3).map(key => {
        row.push(data.scaleQuestion3[key]);
        comScaleQuestion3.push(key);
      });
      comData.push(row);
    });

    this.setState({
      longHeader, longData, longScaleQuestion1, longScaleQuestion2, longScaleQuestion3,
      shortHeader, shortData, shortScaleQuestion,
      prepostHeader, prepostData, prepostScaleQuestion1, prepostScaleQuestion2, prepostScaleQuestion3,
      comHeader, comData, comScaleQuestion1, comScaleQuestion2, comScaleQuestion3,
    });
  }


  render() {
    const {
      longHeader, longData, longScaleQuestion1, longScaleQuestion2, longScaleQuestion3,
      shortHeader, shortData, shortScaleQuestion,
      prepostHeader, prepostData, prepostScaleQuestion1, prepostScaleQuestion2, prepostScaleQuestion3,
      comHeader, comData, comScaleQuestion1, comScaleQuestion2, comScaleQuestion3,
    } = this.state;
    return (
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <Container>
          <P1>SSQ Long database</P1>
          <ExportReactCSV data={longData} headers={longHeader} fileName="SSQ_Long_Data" />
          <br /><br />
          {longScaleQuestion1.map((q, i) => <P3>Scale-1-{i + 1}: {q}</P3>)}
          {longScaleQuestion2.map((q, i) => <P3>Scale-2-{i + 1}: {q}</P3>)}
          {longScaleQuestion3.map((q, i) => <P3>Scale-3-{i + 1}: {q}</P3>)}
        </Container>
        <br />
        <Container>
          <P1>SSQ Short database</P1>
          <ExportReactCSV data={shortData} headers={shortHeader} fileName="SSQ_Short_Data" />
          <br /><br />
          {shortScaleQuestion.map((q, i) => <P3>Scale-{i + 1}: {q}</P3>)}
        </Container>
        <br />
        <Container>
          <P1>SSQ Prepost database</P1>
          <ExportReactCSV data={prepostData} headers={prepostHeader} fileName="SSQ_Prepost_Data" />
          <br /><br />
          {prepostScaleQuestion1.map((q, i) => <P3>Scale-1-{i + 1}: {q}</P3>)}
          {prepostScaleQuestion2.map((q, i) => <P3>Scale-2-{i + 1}: {q}</P3>)}
          {prepostScaleQuestion3.map((q, i) => <P3>Scale-3-{i + 1}: {q}</P3>)}
        </Container>
        <br />
        <Container>
          <P1>SSQ Comparison database</P1>
          <ExportReactCSV data={comData} headers={comHeader} fileName="SSQ_Comparison_Data" />
          <br /><br />
          {comScaleQuestion1.map((q, i) => <P3>Scale-1-{i + 1}: {q}</P3>)}
          {comScaleQuestion2.map((q, i) => <P3>Scale-2-{i + 1}: {q}</P3>)}
          {comScaleQuestion3.map((q, i) => <P3>Scale-3-{i + 1}: {q}</P3>)}
        </Container>
      </div>
    )
  }
}

export default Questionnaire;