import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import {P1, P2, P3} from 'assets/fonts';


const useStyles = makeStyles({
  root: {
    width: "100%",
    marginLeft: 5
  },
});

export default function ContinuousSlider(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(null);
  }, [props.question]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onScore(newValue);
  };

  const marks = [
    {
      value: 0,
      label: 0
    },
    // {
    //   value: 5,
    //   label: 5
    // },
    {
      value: 10,
      label: 10
    },
    // {
    //   value: 15,
    //   label: 15
    // },
    {
      value: 20,
      label: 20
    },
    // {
    //   value: 25,
    //   label: 25
    // },
    {
      value: 30,
      label: 30
    },
    // {
    //   value: 35,
    //   label: 35
    // },
    {
      value: 40,
      label: 40
    },
    // {
    //   value: 45,
    //   label: 45
    // },
    {
      value: 50,
      label: 50
    },
    // {
    //   value: 55,
    //   label: 55
    // },
    {
      value: 60,
      label: 60
    },
    // {
    //   value: 65,
    //   label: 65
    // },
    {
      value: 70,
      label: 70
    },
    // {
    //   value: 75,
    //   label: 75
    // },
    {
      value: 80,
      label: 80
    },
    // {
    //   value: 85,
    //   label: 85
    // },
    {
      value: 90,
      label: 90
    },
    // {
    //   value: 95,
    //   label: 95
    // },
    {
      value: 100,
      label: 100
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={2}><P3><b>{props.question.title}</b></P3></Grid>
      <Grid container spacing={2}><P3>{props.question.question}</P3></Grid>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Slider
            value={props.question.answer ?? value}
            onChange={handleChange}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            marks={marks}
            step={1}
            min={0}
            max={100}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}><P3>{props.question.leftLabel}</P3></Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}><P3>{props.question.rightLabel}</P3></Grid>
      </Grid>
    </div>
  );
}
