const initialState = {
  mandatoryComponents: [],
};

const mandatoryComReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MANDATORY_COMPONENT":
      return {
        ...state,
        mandatoryComponents: [...state.mandatoryComponents, action.payload],
      };
    case "RESET_MANDATORY_COMPONENTS":
      return {
        ...state,
        mandatoryComponents: [],
      };
    default:
      return state;
  }
};

export const addMandatoryComponent = (payload) => ({
  type: "ADD_MANDATORY_COMPONENT",
  payload,
});

export const resetMandatoryComponents = () => ({
  type: "RESET_MANDATORY_COMPONENTS",
});

export default mandatoryComReducer;
