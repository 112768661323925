import React from "react";
import { Button, Container, Select, MenuItem } from "@material-ui/core";
import VolumeSlider from "hearing/assets/digits/volume-slider";
import {P1, P2, P3} from 'assets/fonts';


class VolumeAdjustment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: new Audio(process.env.PUBLIC_URL + "/hearing/functional-audio/adjust.wav"),
      audioPlay: false,
      volume: 0,
      output: null
    };
  }
  componentDidMount = () => {
    const { audio, volume } = this.state;
    audio.volume = volume / 100;
  };

  componentDidUpdate = () => {
    const { audio, volume } = this.state;
    audio.volume = volume / 100;
  };

  handlePlay = async () => {
    const { audio } = this.state;
    await audio.play();
    this.setState({ audioPlay: true });
  };

  handleStop = async () => {
    const { audio } = this.state;
    await audio.pause();
    this.setState({ audioPlay: false });
  };

  handleNext = () => {
    const { volume, output } = this.state;
    this.state.audio.pause();
    this.props.handleClick(volume, output);
  };

  handleChangeOutput = (e) => {
    this.setState({ output: e.target.value });
  }

  renderNextButton = () => {
    const { volume, output } = this.state;
    if (volume !== 0 && output !== null) {
      return <Button
        variant="contained"
        style={{ margin: 5, width: 150, color: "white", backgroundColor: "#107896" }}
        onClick={this.handleNext}
      >
        Next
      </Button>
    } else {
      return (
        <div>
          <Button
            variant="contained"
            disabled
            style={{ margin: 5, width: 150, color: "white", backgroundColor: "#107896" }}
          >
            Next
          </Button>
          <P3>You must answer or complete all of the above steps before progressing to the next step in the experiment.</P3>
        </div>
      )
    }
  }

  render() {
    const { audioPlay, output } = this.state;
    return (
      <Container>
        <div
          style={{
            marginTop: "5%",
            marginRight: "5%",
          }}
        >
          <Container>
            <div id="source">
              <P2>You can use either headphones or speakers to complete this experiment. If you aren't wearing hearing aids, headphones will work best. If using speakers, please keep a consistent distance from your computer during the entire experiment.</P2>
              <P2>Using the dropbox below, select what you will be using to complete this experiment.</P2>
              <Select
                value={output}
                id="demo-controlled-open-select"
                labelId="demo-controlled-open-select-label"
                onChange={this.handleChangeOutput}
                style={{ minWidth: "150px" }}
              >
                <MenuItem value="headphones"><P3>headphones</P3></MenuItem>
                <MenuItem value="speakers"><P3>speakers</P3></MenuItem>
              </Select>
            </div>
            <br />
            <br />
            <div>
              <P2>Set your device's volume to the 50%. Click PLAY to listen to an audio sample.</P2>
              <P2>Then, move the slider below to a comfortable listening level. After that, click NEXT to begin the test.</P2>
              <P2>Please do not adjust your computer (or hearing aid, if applicable) volume during the experiment.</P2>
            </div>
          </Container>
          <VolumeSlider
            handleVolume={(volume) => this.setState({ volume })}
            style={{ marginLeft: "30%" }}
          />
          {audioPlay ? (
            <Button
              variant="contained"
              onClick={this.handleStop}
              style={{ margin: 5, width: 150, color: "white", backgroundColor: "#107896" }}
            >
              Stop
            </Button>
          ) : (
              <Button
                variant="contained"
                onClick={this.handlePlay}
                style={{
                  margin: 5,
                  width: 150,
                  color: "white", backgroundColor: "#107896"
                }}
              >
                Play
              </Button>
            )}
            <br />
          {this.renderNextButton()}
        </div>
      </Container>
    );
  }
}

export default VolumeAdjustment;
