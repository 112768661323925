import { createSelector } from "reselect";

//readyComponent is a list of components that are completed by the user

const selectReadyComponent = (state) => state.readyComponent;

export const selectReadyComponents = createSelector(
  [selectReadyComponent],
  (readyComponent) => readyComponent.readyComponents
);
