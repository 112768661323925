import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const UofaBranding = ({
  UA = "UA-1.png",
  irsm = "irsm-1.png",
  covenant = "covenant-1.png",
}) => {
  return (
    <div className="uofa-branding">
      <img className="UA" alt="Ua" src={UA} />
      <img className="irsm" alt="Irsm" src={irsm} />
      <img className="covenant" alt="Covenant" src={covenant} />
    </div>
  );
};

UofaBranding.propTypes = {
  UA: PropTypes.string,
  irsm: PropTypes.string,
  covenant: PropTypes.string,
};
