import React from "react";
import { Input, Container, Fab } from "@material-ui/core";
import CountDown from "hearing/assets/digits/count-down";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { P1, P2, P3 } from 'assets/fonts';


class SpeechInNoise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noise: new Audio(process.env.PUBLIC_URL + "/hearing/functional-audio/noise.wav"),
      noiseVolume: this.props.volume / 100,
      audioVolume: this.props.volume / 100,
      realAnswer: "",
      focus: 0,
      input1: "",
      input2: "",
      input3: "",
      step: 1,
      loadStage: "loading",
      time: 0,
      reactTimer: [],
      realTimer: [],
      dbs: [0],
      questions: [],
      answers: [],
      countDown: 3,
      okButton: true,
      initStart: true
    };
    this.input1 = React.createRef();
    this.input2 = React.createRef();
    this.input3 = React.createRef();
    this.numOfQues = 23;
  }

  componentDidMount = async () => {
    await this.loadAudios();
    await this.setState({ loadStage: "ready" });
    await this.showCountDown();
  };

  componentDidUpdate = () => {
    if (this.state.loadStage === "start") {
      this._focus();
    }
  };

  loadAudios = async () => {
    const noise = new Audio(process.env.PUBLIC_URL + "/hearing/functional-audio/noise.wav");
    noise.volume = 0;
    await noise.play();
    await noise.pause();
    await noise.remove();
    for (let i = 1; i < 10; i++) {
      const audio = new Audio(process.env.PUBLIC_URL + "/hearing/digits-audio/" + i + ".wav");
      audio.volume = 0;
      await audio.play();
      await audio.pause();
      await audio.remove();
    }
  }

  showCountDown = () => {
    setTimeout(() => {
      this.setState({ loadStage: "start" });
      this.handlePlay();
    }, 3000);
  }

  _focus = () => {
    const { focus } = this.state;
    switch (focus) {
      case 0:
        return this.input1.focus();
      case 1:
        return this.input2.focus();
      case 2:
        return this.input3.focus();
      default:
        return null;
    }
  };

  startTimer = () => {
    this.setState({ time: new Date().getTime() });
  };

  stopReactTimer = () => {
    const { time, reactTimer } = this.state;
    const reactTime = new Date().getTime() - time;
    reactTimer.push(reactTime);
    this.setState({ reactTimer });
    this.startTimer();
  };

  stopRealTimer = () => {
    const { time, realTimer } = this.state;
    const realTime = new Date().getTime() - time;
    realTimer.push(realTime);
    this.setState({ realTimer });
    this.startTimer();
  };

  handlePlay = async () => {
    const { audioVolume, noiseVolume, noise, questions } = this.state;
    this.startTimer();
    const audios = [];
    const nums = ['1', '2', '3', '4', '5', '6', '8', '9'];
    let question = "";
    for (let i = 0; i < 3; i++) {
      const index = Math.floor(Math.random() * 8);
      question += nums[index];
      await this.setState({
        realAnswer: this.state.realAnswer + nums[index],
      });
      audios.push(
        new Audio(process.env.PUBLIC_URL + "/hearing/digits-audio/" + nums[index] + ".wav")
      );
    };
    questions.push(question);
    await this.setState({ questions });

    setTimeout(() => {
      noise.volume = noiseVolume;
      this.startTimer();
      noise.play();
    }, 100);
    setTimeout(() => {
      const audio = audios[0];
      audio.volume = audioVolume;
      audio.play();
    }, 500);
    setTimeout(() => {
      const audio = audios[1];
      audio.volume = audioVolume;
      audio.play();
    }, 1700);
    setTimeout(() => {
      const audio = audios[2];
      audio.volume = audioVolume;
      audio.play();
    }, 2900);
    setTimeout(() => {
      noise.pause();
    }, 4100);
  };

  checkAnswer = async () => {
    const { realAnswer, input1, input2, input3, step, answers, questions, dbs } = this.state;
    // close the okay button when button is triggered once
    this.setState({ okButton: false });
    // stop the real timer
    this.stopRealTimer();
    // record user answer
    const userAnswer = input1 + input2 + input3;
    answers.push(userAnswer);
    await this.setState({ answers });
    // adjust volume
    if (realAnswer !== userAnswer) {
      const audioVolume = this.goEasier(step);
      await this.setState({ audioVolume });
      console.log(
        "WRONG, increasing audio volume to " + this.state.audioVolume
      );
    } else {
      const audioVolume = this.goHarder(step);
      await this.setState({ audioVolume });
      console.log(
        "RIGHT, decreasing audio volume to " + this.state.audioVolume
      );
    }
    if (step !== this.numOfQues) {
      // calculate time and then decide when to start the next trial
      const { reactTimer, realTimer } = this.state;
      const usedTime = reactTimer[reactTimer.length - 1] + realTimer[realTimer.length - 1];
      const gaps = [1000, 1500, 2000];
      const randomGap = Math.floor(Math.random() * 3);
      const realGap = gaps[randomGap];
      const moreTime = usedTime <= 4100 ? 4100 - usedTime + realGap : realGap;
      this.setState({
        realAnswer: "",
        input1: "",
        input2: "",
        input3: "",
        step: step + 1,
        focus: 0,
        initStart: true,
        okButton: true
      });
      if (step % 6 === 0) {
        setTimeout(() => {
          this.setState({ loadStage: "pause" });
        }, moreTime);
        setTimeout(() => {
          this.setState({ loadStage: "start" });
          this.handlePlay();
        }, 2000 + moreTime);
      } else {
        setTimeout(() => {
          this.handlePlay();
        }, moreTime);
      }
    } else {
      // SNR
      let sum = 0;
      for (let i = this.numOfQues - 5; i < this.numOfQues; i++) {
        sum += this.state.dbs[i];
      }
      const SNR = Number(sum / 5).toFixed(3);
      const { reactTimer, realTimer } = this.state;
      this.props.handleClick(SNR, reactTimer, realTimer, questions, answers, dbs);
    }
  };

  goEasier = () => {
    const { audioVolume, step, dbs } = this.state;
    if (step <= 4) {
      dbs.push(dbs[step - 1] + 4);
      this.setState({ dbs });
      if (audioVolume * 10 ** (4 / 20) > 1) {
        return 1;
      } else {
        return audioVolume * 10 ** (4 / 20);
      }
    } else {
      dbs.push(dbs[step - 1] + 2);
      this.setState({ dbs });
      if (audioVolume * 10 ** (2 / 20) > 1) {
        return 1;
      } else {
        return audioVolume * 10 ** (2 / 20);
      }
    }
  };

  goHarder = () => {
    const { audioVolume, step, dbs } = this.state;
    if (step <= 4) {
      dbs.push(dbs[step - 1] - 4);
      this.setState({ dbs });
      return audioVolume * 10 ** (-4 / 20);
    } else {
      dbs.push(dbs[step - 1] - 2);
      this.setState({ dbs });
      return audioVolume * 10 ** (-2 / 20);
    }
  };

  changeAnswer = (value) => {
    const { focus, initStart } = this.state;
    const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!nums.includes(value)) return null;
    if (initStart) {
      this.stopReactTimer();
      this.setState({ initStart: false });
    }

    switch (focus) {
      case 0:
        if (value.length !== 1) {
          return this.setState({ input1: value });
        } else {
          return this.setState({ input1: value, focus: 1 });
        }
      case 1:
        if (value.length !== 1) {
          return this.setState({ input2: value });
        } else {
          return this.setState({ input2: value, focus: 2 });
        }
      case 2:
        return this.setState({ input3: value });
      default:
        return null;
    }
  };

  handleDelete = () => {
    const { focus, input2, input3 } = this.state;
    switch (focus) {
      case 0:
        return this.setState({ input1: "" });
      case 1:
        if (input2.length === 1) {
          return this.setState({ input2: "" });
        }
        return this.setState({ input2: "", focus: 0 });
      case 2:
        if (input3.length === 1) {
          return this.setState({ input3: "" });
        }
        return this.setState({ input3: "", focus: 1 });
      default:
        return null;
    }
  };

  renderInputs = () => {
    const { input1, input2, input3 } = this.state;
    return (
      <div>
        <Input
          value={input1}
          onClick={() => this.setState({ focus: 0 })}
          inputRef={(input) => {
            this.input1 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          onChange={(e) => this.changeAnswer(e.target.value)}
          style={{ width: 40, marginRight: 10, paddingLeft: 13 }}
        />
        <Input
          value={input2}
          onClick={() => this.setState({ focus: 1 })}
          inputRef={(input) => {
            this.input2 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          onChange={(e) => this.changeAnswer(e.target.value)}
          style={{ width: 40, paddingLeft: 13 }}
        />
        <Input
          value={input3}
          onClick={() => this.setState({ focus: 2 })}
          inputRef={(input) => {
            this.input3 = input;
          }}
          inputProps={{
            maxLength: 1,
          }}
          required={true}
          onKeyDown={this.handleKeyEnter}
          onChange={(e) => this.changeAnswer(e.target.value)}
          style={{ width: 40, marginLeft: 10, paddingLeft: 13 }}
        />
      </div>
    );
  };

  renderKeys = () => {
    return (
      <div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => this.changeAnswer("1")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            1
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("2")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            2
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("3")}
            style={{ marginLeft: 6, marginRight: 6 }}
          >
            3
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => this.changeAnswer("4")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            4
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("5")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            5
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("6")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            6
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={() => this.changeAnswer("7")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            7
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("8")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            8
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("9")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            9
          </Fab>
        </div>
        <div className="rows">
          <Fab
            color="default"
            onClick={this.handleDelete}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            <BackspaceIcon />
          </Fab>
          <Fab
            color="default"
            onClick={() => this.changeAnswer("0")}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            0
          </Fab>
          <Fab
            color="default"
            onClick={this.checkAnswer}
            disabled={this.renderOKButton()}
            style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}
          >
            OK
          </Fab>
        </div>
      </div>
    );
  };

  renderOKButton = () => {
    const { input1, input2, input3, okButton } = this.state;
    if (!!input1 && !!input2 && !!input3 && okButton) return false;
    return true;
  };

  handleKeyEnter = (e) => {
    const { input1, input2, input3, okButton } = this.state;
    if (!!input1 && !!input2 && !!input3 && okButton && e.keyCode === 13) {
      this.checkAnswer();
    }
  };

  renderCountDown = () => {
    const { loadStage } = this.state;
    switch (loadStage) {
      case "loading":
        return (
          <div style={{
            textAlign: "center",
            position: "relative",
            marginTop: "10%",
          }}><P1>Loading ...</P1></div>
        );
      case "ready":
        return (
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "10%",
            }}
          >
            <CountDown />
          </div>
        );
      case "pause":
        return (
          <div style={{
            textAlign: "center",
            position: "relative",
            marginTop: "10%",
          }}><P1>Please take a break</P1></div>
        )
      default:
        return null;
    }
  }

  render() {
    const { step, loadStage } = this.state;
    return (
      <Container style={{ textAlign: "center" }}>
        {loadStage === "start" ? (
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "15%",
            }}
          >
            <P1 className="font-weight-light">
              Enter the three digits you hear using the displayed keypad and your mouse OR the number keys on your keyboard.
              If you aren't sure, make a guess! Click OK or press enter/return to submit.
            </P1>
            {this.renderInputs()}
            <P1 className="font-weight-lighter" style={{ marginTop: 10 }}>
              Step {step} of {this.numOfQues} / Block 1 of 2
            </P1>
            {this.renderKeys()}
          </div>
        )
          :
          this.renderCountDown()
        }
      </Container>
    );
  }
}

export default SpeechInNoise;
