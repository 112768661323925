import React from "react";
import {
  TextField,
  Button,
  LinearProgress,
  Container,
} from "@material-ui/core";
import Markdown from 'literacy/assets/fonts/markdown';
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: this.props.rows,
      index: this.props.index ?? 0,
      studentAnswer: "",
      assign: this.props.assignment ?? [],
      score: this.props.score ?? 0,
      times: {},
    };
  }

  componentDidMount = () => {
    const times = {};
    this.state.questions.map(question => {
      times[JSON.stringify(question)] = 1;
    });
    this.setState({ times });
  }

  calculateScore = () => {
    const { index, questions, times } = this.state;
    const currentTimes = times[JSON.stringify(questions[index])];
    switch(currentTimes){
      case 1:
        return 1;
      case 2:
        return 0.7;
      case 3:
        return 0.5;
      case 4:
        return 0.3;
    }
  }

  handleNext = () => {
    const {
      index,
      questions,
      assign,
      score,
      times,
    } = this.state;
    let { studentAnswer } = this.state;
    studentAnswer = studentAnswer.split(",");
    let addScore = 0;
    if (JSON.stringify(studentAnswer.sort()) === JSON.stringify(questions[index].answers.sort())) {
      addScore += this.calculateScore();
    } else if (!!questions[index].hint && times[JSON.stringify(questions[index])] < 3) {
      times[JSON.stringify(questions[index])] = times[JSON.stringify(questions[index])]+1;
      questions.push(questions[index]);
      this.setState({ questions, times });
    }
    assign.push({
      level: questions[index].level,
      question: questions[index].question,
      realAnswer: questions[index].answers,
      studentAnswer,
    });
    this.setState({
      score: score + addScore,
      index: index + 1,
      assign,
      studentAnswer: ""
    });
  };

  render() {
    const {
      questions,
      index,
      assign,
      score,
      studentAnswer,
    } = this.state;
    const progress = Math.floor((index / questions.length) * 100);
    return (
      <div>
        <Container style={{ marginTop: "5%" }}>
          <Button
            variant="outlined"
            style={{ marginRight: 20, color: "white", backgroundColor: "#107896" }}
            onClick={() =>
              this.props.handleSaveAssignment(index, questions, assign, score)
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{ color: "white", backgroundColor: "#107896" }}
            onClick={() => (window.location = this.props.backURL ?? "/literacy")}
          >
            Quit
          </Button>
        </Container>
        <Container style={{ marginTop: "10%" }}>
          {index !== questions.length ? (
            <div>
              <Markdown className="font-weight-light">{questions[index].question}</Markdown>
              <TextField
                value={studentAnswer}
                label="Answers"
                autoComplete="off"
                style={{ marginLeft: 10 }}
                onChange={(e) => this.setState({studentAnswer: e.target.value})}
              />
              <P3>using commas to separate answers, the order does not matter, like a,b,c</P3>
              <Button
                variant="contained"
                style={{ marginLeft: 10, marginTop: 5, color: "white", backgroundColor: "#107896" }}
                onClick={this.handleNext}
              >
                Next
              </Button>
              <br />
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#107896" }}
                onClick={() => this.props.handleNext(score, assign)}
              >
                Go to true and false questions!
              </Button>
              <br />
            </div>
          )}
          <br />
          <LinearProgress variant="determinate" value={progress} />
        </Container>
      </div>
    );
  }
}
