import React from "react";
import { Container, Button, Avatar } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';


class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      wordAndPicture: props.wordAndPicture,
      realAnswer: props.realAnswer,
      userAnswer: [],
      currAnswer: [],
      currTimer: [],
      time: null,
      timer: [],
      start: false,
      currWordZ: props.wordLeftKey,
      currWordM: 1 - props.wordLeftKey,
      currImageZ: props.imageLeftKey,
      currImageM: 1 - props.imageLeftKey,
      sent: false,
      trials: props.trials * 4,
      showX: false
    };
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyPress, false);
  };

  checkAnswer = async (e) => {
    const { realAnswer, currAnswer, userAnswer, timer, index } = this.state;
    this.stopTimer();
    if (e.keyCode === 90) {
      currAnswer.push("left");
      await this.setState({currAnswer});
      if (realAnswer[index] !== "left") {
        await this.setState({showX: true});
        setTimeout(() => this.setState({showX: false}), 1000)
        if (currAnswer.length < 3) {
          return this.startTimer();
        }
      }
    } else if (e.keyCode === 77) {
      currAnswer.push("right");
      await this.setState({currAnswer});
      if (realAnswer[index] !== "right") {
        await this.setState({showX: true});
        setTimeout(() => this.setState({showX: false}), 1000)
        if (currAnswer.length < 3) {
          return this.startTimer();
        }
      }
    }
    userAnswer.push(currAnswer);
    timer.push(this.state.currTimer);
    await this.setState({userAnswer, currAnswer: [], currTimer: [], index: index + 1, timer, currTimer: []});
    if (this.state.index < realAnswer.length) {
      setTimeout(() => this.startTimer(), 250);
    } else {
      this.handleNext();
    }
  }

  startTimer = () => {
    this.setState({ time: new Date().getTime() });
  };

  stopTimer = () => {
    const { currTimer, time } = this.state;
    currTimer.push(new Date().getTime() - time);
    this.setState({ currTimer, time: null });
  };

  handleKeyPress = (e) => {
    const { timer, start, index } = this.state;
    if (timer.length > index) return null;
    if (e.keyCode === 32 && !start) {
      setTimeout(() => this.startTimer(), 250);
      this.setState({ start: true });
    } else if ((e.keyCode === 77 || e.keyCode === 90) && start) {
      this.checkAnswer(e);
    }
  }

  handleNext = () => {
    const { realAnswer, wordAndPicture, userAnswer, timer } = this.state;
    const block = {realAnswer, wordAndPicture, userAnswer, timer};
    if (!this.state.sent) {
      this.setState({sent: true});
      this.props.handleTestClick(block);
    }
  }

  renderInstruction = () => {
    const { currImageM, currImageZ, currWordM, currWordZ } = this.state;
    const returns = [];
    if (currImageZ === 0 && currWordZ === 0) {
      returns.push(<P2>Use the Z key for disfigured faces or negative words</P2>);
    } else if (currImageZ === 0 && currWordZ === 1) {
      returns.push(<P2>Use the Z key for disfigured faces or positive words</P2>);
    } else if (currImageZ === 1 && currWordZ === 0) {
      returns.push(<P2>Use the Z key for non-disfigured faces or negative words</P2>);
    } else {
      returns.push(<P2>Use the Z key for non-disfigured faces or positive words</P2>);
    }
    if (currImageM === 0 && currWordM === 0) {
      returns.push(<P2>Use the M key for disfigured faces or negative words</P2>);
    } else if (currImageM === 0 && currWordM === 1) {
      returns.push(<P2>Use the M key for disfigured faces or positive words</P2>);
    } else if (currImageM === 1 && currWordM === 0) {
      returns.push(<P2>Use the M key for non-disfigured faces or negative words</P2>);
    } else {
      returns.push(<P2>Use the M key for non-disfigured faces or positive words</P2>);
    }
    return returns;
  }

  render() {
    const { showX, wordAndPicture, index, start, currWordZ, currWordM, currImageZ, currImageM } = this.state;
    return (
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "5%",
        }}
      >
        {
          start ?
              <div>
                <P2>
                  Trial {index + 1} of {wordAndPicture.length}
                </P2>
                {
                  index % 2 === 0 ?
                    <div style={{ marginTop: "20%" }}>
                      <P1>{wordAndPicture[index]}</P1>
                    </div>
                    :
                    <img
                      src={process.env.PUBLIC_URL + "/appearance/pictures/" + wordAndPicture[index]}
                      style={{ height: "70vh" }}
                    />
                }

                <div>
                  <div

                    style={{ color: "green", position: "fixed", left: 0, bottom: 0, width: "30%", height: "15%" }}
                  >
                     <P1>Z</P1>
                    {
                      index % 2 === 0 ?
                        currWordZ === 0 ?
                          <P2>negative</P2>
                          :
                          <P2>positive</P2>
                        :
                        currImageZ === 0 ?
                          <P2>disfigured faces</P2>
                          :
                          <P2>non-disfigured faces</P2>
                    }
                  </div>
                  <div
                    style={{ color: "green", position: "fixed", right: 0, bottom: 0, width: "30%", height: "15%" }}
                  >
                    <P1>M</P1>
                    {
                      index % 2 === 0 ?
                        currWordM === 0 ?
                          <P2>negative</P2>
                          :
                          <P2>positive</P2>
                        :
                        currImageM === 0 ?
                          <P2>disfigured faces</P2>
                          :
                          <P2>non-disfigured faces</P2>
                    }
                  </div>
                </div>
                {
                  showX ?
                  <Avatar style={{backgroundColor: "red", position: "fixed", bottom: 30, left: "48%"}}>X</Avatar>
                  : null
                }
              </div>
            :
            <div>
              <P1>Stage 4 of 7</P1>
              <P2>We would now like you to do the same again.</P2>
              <br />
              {this.renderInstruction().map(d => d)}
              <br />
              <P2>Press the space bar when you’re ready to start</P2>
              <div
                style={{ color: "green", position: "fixed", left: 0, bottom: 0, width: "30%", height: "15%" }}
              >
                <P1>Z</P1>
                {currWordZ === 0 ?
                <P2>negative</P2>
                :
                <P2>positive</P2>}
              </div>
              <div
                style={{ color: "green", position: "fixed", right: 0, bottom: 0, width: "30%", height: "15%" }}
              >
                <P1>M</P1>
                {currWordM === 0 ?
                <P2>negative</P2>
                :
                <P2>positive</P2>}
              </div>
            </div >
        }
      </div>
    );
  }
}

export default Test;
