import React, { useEffect, useState } from "react";
import "./styles.css";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";


const DecoratedMain = (props) => {
  const [open, setOpen] = useState(false);
  const [visit, setVisit] = useState(0);

  useEffect(async () => {
    const doc = await axios.post('/api/hearing/visit', { page: 'hearing/demo' });
    setVisit(doc.data.length);
  }, []);

  const handleClick = (page) => {
    setOpen(true);
    setTimeout(() => {props.history.push(page)}, 2000);
  }

  return (
    <div className="container-fluid">
      <div style={{backgroundColor: "#333", padding:"2rem"}} className="row first-row">
          <div id="logo">
            <img src={process.env.PUBLIC_URL + "/UA.png"} />
          </div>
        </div>

        <div className="row title-background">
          <div className="col">
          <h1><b>Welcome to a Short Demonstration of our Hearing Experiments</b></h1>
          <h4>Instructions: Please use a desktop or laptop computer with
          Chrome, Edge, Firefox, Opera or Safari to complete any of these demonstrations.</h4>
        </div>
      </div>
      <div className="row">
        <div id="card" className="col" style={{ backgroundColor: "#E0D760" }} onClick={() => handleClick('/hearing/demo/digits/')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><h2 >Digits in Noise Demo</h2></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#C7D28A" }} onClick={() => handleClick('/hearing/demo/crm/')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><h2 >Coordinate Response Measure Demo</h2></center>
            <hr  style={{ backgroundColor: "white", width: "50%", height: "2px"}} />
          </div>
        </div>
        <div id="card" className="col" style={{ backgroundColor: "#7CA295" }} onClick={() => handleClick('/hearing/demo/questionnaire/')}>
          <div style={{marginTop: "10%"}}>
            <center style={{ color: "white" }}><h2 >Questionnaire Demo</h2></center>
            <hr  style={{ backgroundColor: "white", width: "50%",}} />
          </div>
        </div>
      </div>

      <div className="row footer">
        <div id="logo">
          <img src={process.env.PUBLIC_URL + "/UA.png"} />
        </div>
      </div>
      {/* <Backdrop style={{zIndex: 2}} open={open}>
        <CircularProgress color="primary" style={{width: "100px", height: "100px"}} />
      </Backdrop> */}
    </div>
  )
}

export default DecoratedMain;
