import React from "react";
import { Container } from "@material-ui/core";
import SliderCard from "iRSM/assets/slider-card";
import {P1, P2, P3} from 'assets/fonts';


class PostTestQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          title: "Mental Demand",
          question: "How much mental and perceptual activity was required (e.g. thinking, deciding, calculating, remembering, looking, searching, etc.)? Was the task easy or demanding, simple or complex, exacting or forgiving?",
          leftLabel: "Low",
          rightLabel: "High",
          answer: null
        },
        {
          title: "Physical Demand",
          question: "How much physical activity was required (e.g. pushing, pulling, turning, controlling, activating, etc.)? Was the task easy or demanding, simple or complex, exacting or forgiving?",
          leftLabel: "Low",
          rightLabel: "High",
          answer: null
        },
        {
          title: "Temporal Demand",
          question: "How much time pressure did you feel due to the rate or pace at which the tasks or task elements occurred? Was the pace slow and leisurely or rapid and frantic?",
          leftLabel: "Low",
          rightLabel: "High",
          answer: null
        },
        {
          title: "Performance",
          question: "How successful do you think you were in accomplishing the goals of the task set by the experimenter (or yourself)? How satisfied were you with your performance in accomplishing these goals? (Note that 'own performance' goes from 'Good' on the left to 'Bad' on the right. This order has been confusing for some people.)",
          leftLabel: "Good",
          rightLabel: "Poor",
          answer: null
        },
        {
          title: "Effort",
          question: "How hard did you have to work (mentally and physically) to accomplish your level of performance?",
          leftLabel: "Low",
          rightLabel: "High",
          answer: null
        },
        {
          title: "Frustration Level",
          question: "How insecure, discouraged, irritated, stressed, and annoyed versus secure, gratified, content, relaxed and complacent did you feel during the task?",
          leftLabel: "Low",
          rightLabel: "High",
          answer: null
        }
      ]
    };
  }

  handleFinish = (questions) => {
    this.props.handleClick(questions)
  }

  render() {
    const { questions } = this.state;
    return (
      <Container>
        <div
          style={{
            position: "relative",
            marginTop: "3%",
            marginBottom: "5%",
            marginLeft: "10%",
            marginRight: "10%"
          }}
        >
          <P2>
            Hart and Staveland’s NASA Task Load Index (TLX) method assesses
            work load on five 7-point scales. Increments of high, medium and low
            estimates for each point result in 21 gradations on the scales.
          </P2>
          <br />
          <SliderCard
            questions={questions}
            handleFinish={this.handleFinish}
          />
          <br />
        </div>
      </Container>
    );
  }
}

export default PostTestQuestions;
