import React, { useState } from "react";

const RehabappScreen = () => {
  // State variables for title and body
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  // Function to handle changes in title input
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // Function to handle changes in body input
  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  // Function to handle send button click
  const handleSendButton = () => {
    // Here you can use title and body
    console.log("Title:", title);
    console.log("Body:", body);

    // Implement your send logic here
  };

  return (
    <div>
      <h1>RehabappScreen</h1>
      <input
        type="text"
        placeholder="title"
        value={title}
        onChange={handleTitleChange}
      />
      <input
        type="text"
        placeholder="body"
        value={body}
        onChange={handleBodyChange}
      />
      <button onClick={handleSendButton}>Send</button>
    </div>
  );
};

export default RehabappScreen;
