import React from "react";
import { Container, Button, TextField } from "@material-ui/core";
import axios from "axios";

class Id1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }

  randomPage = async () => {
    const { id } = this.state;
    const index = Number(id) % 2;
    await axios.post("/api/hearingtest/data/id", { id, version: index + 1 });

    if (index === 0) {
      this.props.history.push("/hearing-test/version1/" + id);
    } else if (index === 1) {
      this.props.history.push("/hearing-test/version2/" + id);
    }
  };

  render() {
    const { id } = this.state;
    return (
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <h5>Please enter the Study ID # you see at the top of the screen</h5>
          <h5>Once you have entered the number, please click "Submit"</h5>
          <TextField
            value={id}
            type="number"
            onChange={(e) => this.setState({ id: e.target.value })}
          />
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={this.randomPage}
            style={{
              backgroundColor: "black",
              marginLeft: 10,
            }}
          >
            Submit
          </Button>
        </div>
      </Container>
    );
  }
}

export default Id1;
