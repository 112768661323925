import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import FluencyHeader from "literacy/components/student/fluency/assets/header";
import AssignTable from "literacy/components/tutor/fluency/testassigntable";
import LineChart from "literacy/assets/assignchart";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

class FluencyTutorOneAssign extends React.Component {
  constructor(props) {
    super(props);
    this.state = { assignment: null };
  }

  componentDidMount = async () => {
    const doc1 = await axios.get(
      "/api/literacy/fluency/assign/" + this.props.match.params.id
    );
    if (doc1.data) {
      this.setState({ assignment: doc1.data });
    } else {
      const doc2 = await axios.get(
        "/api/literacy/fluency/test/" + this.props.match.params.id
      );
      this.setState({ assignment: doc2.data });
    }
  };

  renderChart = () => {
    const { assignment } = this.state;
    let data = [];
    assignment.assignment.forEach((assign, index) => {
      data.push({
        label: index,
        value: assign.speed,
      });
    });
    return (
      <LineChart
        data={data}
        title="Reading Speed Chart"
        color="#3E517A"
      />
    );
  };

  render() {
    const { assignment } = this.state;
    return (
      <div style={{ backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <FluencyHeader part="Result" backURL="/literacy/student/fluency/results" />
        {assignment ? (
          <Container>
            <AssignTable rows={assignment.assignment} />
            <br />
            <div className="main chart-wrapper">{this.renderChart()}</div>
            {assignment.averageSpeed
              ?
              <P3>Reading speed is {Number(assignment.averageSpeed).toFixed(2)} millisec / letter</P3>
              :
              <div>
                <P2>Old Speed is {Number(assignment.oldSpeed).toFixed(2)} millisec / letter</P2>
                <P2>New Speed is {Number(assignment.newSpeed).toFixed(2)} millisec / letter</P2>
              </div>
            }
          </Container>
        ) : null}
      </div>
    );
  }
}

export default FluencyTutorOneAssign;
