import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TestTile from "../GUI/user-app/testTile";
import styles from "../GUI/user-app/indexPage.module.css";

const DatabaseMain = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [currCollection, setCurrCollection] = useState(null);

  const updateLayer = async (page) => {
    const doc = await axios.get("/api/gui/collection/" + page.id);
    setCurrCollection(doc.data);
  };

  useEffect(async () => {
    document.title = "Rehab Science";
    updateLayer({ id: "1" });
    await console.log("currCollection: ", currCollection);
  }, []);

  const onAppClick = (app) => {
    setOpen(true);
    const appName = app.replace(/ /g, "");

    setTimeout(() => {
      setOpen(false);
      history.push(`/gui/${appName}/database`);
    }, 1500);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // This assumes that the parent of this component takes up the full screen height.
      }}
    >
      <div style={{ fontSize: "50px", fontWeight: "bold", padding: "20px 0" }}>
        Welcome to database
      </div>
      <div>Please select the database you want to access:</div>
      <div className={styles.cardContainer}>
        {currCollection?.list.map((data, index) => (
          <TestTile
            key={index}
            name={data.name}
            type={data.type}
            onAppClick={() => onAppClick(data.name)}
            updateLayer={() => updateLayer(data)}
          />
        ))}
      </div>
    </div>
  );
};

export default DatabaseMain;
