import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";

const Digits = (props) => {
  const { id } = props;
  const [numOfTrial, setNumOfTrial] = useState(null);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [numOfSNR, setNumOfSNR] = useState(null);
  const [audio, setAudio] = useState(null);
  const [noise, setNoise] = useState(null);
  const [digitsAudios, setDigitsAudios] = useState([]);
  const [easier, setEasier] = useState({
    select: null,
    numOfTrial: null,
    num: null,
    before: null,
    after: null,
  });
  const [harder, setHarder] = useState({
    select: null,
    numOfTrial: null,
    num: null,
    before: null,
    after: null,
  });
  const [useTriplets, setUseTriples] = useState(false);
  const [gapTime, setGapTime] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/digits/" + id);
        setNumOfTrial(doc.data.numOfTrial);
        setNumOfCurrBlock(doc.data.numOfCurrBlock);
        setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
        setNumOfSNR(doc.data.numOfSNR);
        setDigitsAudios(doc.data.digitsAudios);
        setNoise(doc.data.noise);
        doc.data.easier && setEasier(doc.data.easier);
        doc.data.harder && setHarder(doc.data.harder);
        setUseTriples(doc.data.useTriplets);
        setGapTime(doc.data.gapTime);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (!!numOfTrial) {
      (async () => {
        await axios.put("/api/gui/digits/" + id, { numOfTrial });
      })();
    }
  }, [numOfTrial]);

  useEffect(() => {
    if (!!numOfCurrBlock && !!numOfTotalBlocks) {
      (async () => {
        await axios.put("/api/gui/digits/" + id, {
          numOfCurrBlock,
          numOfTotalBlocks,
        });
      })();
    }
  }, [numOfCurrBlock, numOfTotalBlocks]);

  useEffect(() => {
    if (!!numOfSNR) {
      (async () => {
        await axios.put("/api/gui/digits/" + id, { numOfSNR });
      })();
    }
  }, [numOfSNR]);

  useEffect(() => {
    if (!!easier) {
      (async () => {
        await axios.put("/api/gui/digits/" + id, { easier });
      })();
    }
  }, [easier]);

  useEffect(() => {
    if (!!harder) {
      (async () => {
        await axios.put("/api/gui/digits/" + id, { harder });
      })();
    }
  }, [harder]);

  useEffect(() => {
    if (typeof useTriplets == "boolean") {
      (async () => {
        await axios.put("/api/gui/digits/" + id, { useTriplets });
      })();
    }
  }, [useTriplets]);

  useEffect(() => {
    (async () => {
      await axios.put("/api/gui/digits/" + id, { gapTime });
    })();
  }, [gapTime]);

  const handleUploadAudio = async (num) => {
    const doc = await axios.get("/api/gui/digits/audio/" + id + "/" + num);
    const uploadConfigs = doc.data;
    await axios
      .put(uploadConfigs.url, audio, {
        headers: {
          "Content-type": "audio/wav",
        },
      })
      .then(() => window.alert(`Audio ${num}.wav update successfully!`))
      .catch((err) => window.alert("Something wrong:", err));

    if (num === "noise") {
      // update noise
      setNoise(uploadConfigs.key);
      await axios.put("/api/gui/digits/" + id, { noise: uploadConfigs.key });
    } else {
      // update lossAudios
      const newDigitsAudios = [...digitsAudios];
      newDigitsAudios[num] = uploadConfigs.key;
      setDigitsAudios(newDigitsAudios);
      await axios.put("/api/gui/digits/" + id, {
        digitsAudios: newDigitsAudios,
      });
    }
  };

  return (
    <Container>
      <br />
      <P1>Digits in Noise</P1>
      <br />
      <div id="environment">
        <P2>Environment</P2>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}># of current block</P2>
          <TextField
            type="number"
            value={numOfCurrBlock}
            onChange={(e) => setNumOfCurrBlock(parseInt(e.target.value))}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100px",
            }}
          />
          <P2 style={{ fontWeight: 300 }}># of total blocks</P2>
          <TextField
            type="number"
            value={numOfTotalBlocks}
            onChange={(e) => setNumOfTotalBlocks(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}># of trials</P2>
          <TextField
            type="number"
            value={numOfTrial}
            onChange={(e) => setNumOfTrial(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div style={{ paddingLeft: "25px" }}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={useTriplets} />}
            onChange={(e) => setUseTriples(!useTriplets)}
            label={
              <P2
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  fontWeight: 300,
                }}
              >
                use preset triplets?
              </P2>
            }
          />
        </div>
      </div>
      <br />
      <div id="paramsters">
        <P2>Specific parameters</P2>
        <div className="row" style={{ paddingLeft: "40px" }}>
          <P2 style={{ fontWeight: 300 }}>SNR calculation from #th trial</P2>
          <TextField
            type="number"
            value={numOfSNR}
            onChange={(e) => setNumOfSNR(parseInt(e.target.value))}
            style={{ paddingLeft: "10px", width: "100px" }}
          />
        </div>
        <div id="easier" style={{ paddingLeft: "40px" }}>
          <div className="row">
            <P2 style={{ fontWeight: 300 }}>Go easier method</P2>
            <Select
              value={easier.select}
              onChange={(e) =>
                setEasier((obj) => {
                  return { ...obj, select: e.target.value };
                })
              }
              style={{ minWidth: "100px", marginLeft: "10px" }}
            >
              <MenuItem value="linear">
                <P3 style={{ fontWeight: 300 }}>Linear</P3>
              </MenuItem>
              <MenuItem value="change">
                <P3 style={{ fontWeight: 300 }}>Change at #th Trial</P3>
              </MenuItem>
            </Select>
            {easier.select === "change" && (
              <TextField
                type="number"
                value={easier.numOfTrial}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, numOfTrial: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            )}
          </div>
          {!easier.select ? null : easier.select === "linear" ? (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Increase # dbs</P2>
              <TextField
                type="number"
                value={easier.num}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, num: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          ) : (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Increase # dbs before change</P2>
              <TextField
                type="number"
                value={easier.before}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, before: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
              <P2 style={{ fontWeight: 300 }}>after change</P2>
              <TextField
                type="number"
                value={easier.after}
                onChange={(e) =>
                  setEasier((obj) => {
                    return { ...obj, after: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          )}
        </div>
        <div id="harder" style={{ paddingLeft: "40px" }}>
          <div className="row">
            <P2 style={{ fontWeight: 300 }}>Go harder method</P2>
            <Select
              value={harder.select}
              onChange={(e) =>
                setHarder((obj) => {
                  return { ...obj, select: e.target.value };
                })
              }
              style={{ minWidth: "100px", marginLeft: "10px" }}
            >
              <MenuItem value="linear">
                <P3 style={{ fontWeight: 300 }}>Linear</P3>
              </MenuItem>
              <MenuItem value="change">
                <P3 style={{ fontWeight: 300 }}>Change at #th Trial</P3>
              </MenuItem>
              <MenuItem value="incorrect">
                <P3 style={{ fontWeight: 300 }}>Change at First Incorrect</P3>
              </MenuItem>
            </Select>
            {harder.select === "change" && (
              <TextField
                type="number"
                value={harder.numOfTrial}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, numOfTrial: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            )}
          </div>
          {!harder.select ? null : harder.select === "linear" ? (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Decrease # dbs</P2>
              <TextField
                type="number"
                value={harder.num}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, num: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          ) : (
            <div
              className="row"
              style={{ marginLeft: "170px", marginTop: "10px" }}
            >
              <P2 style={{ fontWeight: 300 }}>Decrease # dbs before change</P2>
              <TextField
                type="number"
                value={harder.before}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, before: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
              <P2 style={{ fontWeight: 300 }}>after change</P2>
              <TextField
                type="number"
                value={harder.after}
                onChange={(e) =>
                  setHarder((obj) => {
                    return { ...obj, after: parseInt(e.target.value) };
                  })
                }
                style={{ paddingLeft: "10px", width: "100px" }}
              />
            </div>
          )}
        </div>
        <div id="gapTime" style={{ paddingLeft: "40px" }}>
          <div className="row">
            <P2 style={{ fontWeight: 300 }}>Gap time (seconds)</P2>
            <TextField
              type="number"
              value={gapTime}
              onChange={(e) => setGapTime(parseInt(e.target.value))}
              style={{ paddingLeft: "10px", width: "100px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <P2>Audios</P2>
      <P3 style={{ paddingLeft: "20px" }}>
        (Please upload audio 1 by 1 'Choose File -> Upload')
      </P3>
      <Container style={{ margin: 2, border: "solid", borderWidth: 2 }}>
        <P3>Noise</P3>
        <P3 style={{ fontWeight: 300 }}>{noise}</P3>
        <input
          type="file"
          accept="audio/wav"
          onChange={(e) => setAudio(e.target.files[0])}
          style={{ marginBottom: 10 }}
        />
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => handleUploadAudio("noise")}
        >
          Upload
        </Button>
      </Container>
      <Container
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
      >
        {Array(10)
          .fill("")
          .map((ele, i) => {
            return (
              <div style={{ margin: 2, border: "solid", borderWidth: 2 }}>
                <P3>{i}.wav</P3>
                <P3 style={{ fontWeight: 300 }}>{digitsAudios?.[i]}</P3>
                <input
                  type="file"
                  accept="audio/wav"
                  onChange={(e) => setAudio(e.target.files[0])}
                  style={{ marginBottom: 10 }}
                />
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => handleUploadAudio(i)}
                >
                  Upload
                </Button>
              </div>
            );
          })}
      </Container>
      <br />
    </Container>
  );
};

export default Digits;
