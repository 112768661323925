import React from "react";
import { Button, Container, InputLabel } from "@material-ui/core";
import { connect } from "react-redux";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";

class PhonemeAssignIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: "w1",
    };
  }

  renderButtons = () => {
    const { currentUser } = this.props;
    const { section } = this.state;
    if (!currentUser) return null;
    if (section === "w1") {
      switch (currentUser.phoneme_progress.w1) {
        case "":
          return (
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginLeft: 20, marginRight: 10 }}
              onClick={() => this.props.handleClick("", "w1")}
            >
              Start
            </Button>
          );
        default:
          return (
            <div>
              <Button
                variant="outlined"
                size="large"
                style={{
                  marginLeft: 20,
                  marginRight: 10,
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#107896",
                }}
                onClick={() =>
                  this.props.handleClick(currentUser.phoneme_progress.w1, "w1")
                }
              >
                Resume last assignment
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{
                  marginRight: 10,
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#107896",
                }}
                onClick={() => this.props.handleClick("", "w1")}
              >
                Start new assignment
              </Button>
            </div>
          );
      }
    } else {
      if (
        new Date().getTime() - new Date(currentUser.createdAt).getTime() <
        604800000
      ) {
        return (
          <P2 style={{ marginRight: 20 }}>
            You need to wait for 1 week to do sound assignment 2.
          </P2>
        );
      } else if (currentUser.phoneme_score.length >= 1) {
        switch (currentUser.phoneme_progress.w2) {
          case "":
            return (
              <Button
                variant="contained"
                size="large"
                style={{
                  marginLeft: 20,
                  marginRight: 10,
                  color: "white",
                  backgroundColor: "#107896",
                }}
                onClick={() => this.props.handleClick("", "w2")}
              >
                Start
              </Button>
            );
          default:
            return (
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    marginLeft: 20,
                    marginRight: 10,
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#107896",
                  }}
                  onClick={() =>
                    this.props.handleClick(
                      currentUser.phoneme_progress.w2,
                      "w2"
                    )
                  }
                >
                  Resume last assignment
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    marginRight: 10,
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#107896",
                  }}
                  onClick={() => this.props.handleClick("", "w2")}
                >
                  Start new assignment
                </Button>
              </div>
            );
        }
      } else if (currentUser.phoneme_score.length < 1) {
        return (
          <div>
            <h4 style={{ marginRight: 20 }}>
              You need to do assignment 1 first!
            </h4>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <Container>
        <Container>
          <InputLabel id="label">
            <P2>Assignment Section</P2>
          </InputLabel>
          <Button
            variant="contained"
            style={{ margin: "3px" }}
            onClick={() => this.setState({ section: "w1" })}
          >
            Week 1
          </Button>
          <Button
            variant="contained"
            style={{ margin: "3px" }}
            onClick={() => this.setState({ section: "w2" })}
          >
            Week 2
          </Button>
        </Container>
        <br />
        <P3>
          Now, you will get to practice your knowledge about sound patterns by
          completing exercises. There are different types of exercises, like
          fill in the blanks, multiple choice questions. Please read carefully
          and answer as best as you can.
        </P3>
        <br />
        <div style={{ display: "flex" }}>{this.renderButtons()}</div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(PhonemeAssignIntro);
