import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";
import {
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter1TwoTone,
  Filter2TwoTone,
  Filter3TwoTone,
  Filter4TwoTone,
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import P1 from "literacy/assets/fonts/p1";
import P2 from "literacy/assets/fonts/p2";
import P3 from "literacy/assets/fonts/p3";
import Process from "literacy/assets/process";
import $ from "jquery";
let time;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class FluencyAssignPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: [],
      score: 0,
      index: 0,
      length: 0,
      currPara: "",
      currParaArray: [],
      paragraphs: [],
      questions: [],
      choices: [],
      answers: [],
      studentAnswers: [],
      yourAnswer: "",
      readDone: false,
      answerred: false,
      alert: false,
      version: "",
    };
  }

  componentDidMount = async () => {
    const { progress_id } = this.props;
    const doc = await axios.get(
      "/api/literacy/fluency/student/progress/" + progress_id
    );
    const {
      speed,
      score,
      index,
      length,
      currPara,
      currParaArray,
      paragraphs,
      questions,
      choices,
      answers,
      studentAnswers,
      version,
    } = doc.data;
    this.setState({
      speed,
      score,
      index,
      length,
      currPara,
      currParaArray,
      paragraphs,
      questions,
      choices,
      answers,
      studentAnswers,
      version,
    });
    this.startReading();
  };

  startReading = async () => {
    const { speed, length, index, currParaArray } = this.state;
    let currSpeed = speed[index];
    let pointer = 0;
    const classNames = [];
    const numOfSpace = currParaArray.length - 1;
    currParaArray.map((word, i) => {
      word.split("").map((letter, j) => {
        classNames.push(String(i) + String(j));
      });
    });
    await setTimeout(() => {
      time = setInterval(async () => {
        if (pointer < length - numOfSpace) {
          $(`.${classNames[pointer]}`).css("color", "white"); // "#e9ecef"
          pointer += 1;
        } else {
          await this.setState({ readDone: true });
          clearInterval(time);
        }
      }, currSpeed);
    }, 1000);
  };

  checkAnswer = async (e) => {
    const { answers, index, speed, studentAnswers } = this.state;
    const answer = e.target.value;
    const newSpeed = speed;
    const oldSpeed = newSpeed[index];
    if (answer === answers[index]) {
      const addNewSpeed = Number((oldSpeed * 0.94).toFixed(3));
      newSpeed.push(addNewSpeed);
      this.setState({
        answerred: true,
        score: this.state.score + 1,
        speed: newSpeed,
      });
    } else {
      const addNewSpeed = Number((oldSpeed * 1.06).toFixed(3));
      newSpeed.push(addNewSpeed);
      this.setState({
        answerred: true,
        speed: newSpeed,
      });
    }
    const newStudentAnsers = studentAnswers;
    newStudentAnsers.push(answer);
    this.setState({
      studentAnswers: newStudentAnsers,
    });
  };

  changeQuestion = async () => {
    const { index, paragraphs } = this.state;
    await this.setState({ index: index + 1 });
    await this.setState({
      currPara: paragraphs[this.state.index],
      readDone: false,
      answerred: false,
    });
    const currParaArray = await this.state.currPara.split(" ");
    await this.setState({
      currParaArray,
      length: this.state.currPara.split("").length,
    });

    this.startReading();
  };

  handleSaveAssignment = async () => {
    // 1. Clean the student last progress and delete the old progress
    const doc1 = await axios.put("/api/literacy/fluency/student/progress", {
      newProgress: "",
      version: this.state.version,
    });
    if (doc1.data !== "") {
      await axios.delete("/api/literacy/fluency/student/progress/" + doc1.data);
    }

    // 2. save progress into database and save progress_id into student database
    const doc2 = await axios.post(
      "/api/literacy/fluency/student/progress",
      this.state
    );
    await axios.put("/api/literacy/fluency/student/progress", {
      newProgress: doc2.data._id,
      version: this.state.version,
    });

    // show alert bar
    this.setState({ alert: true });
  };

  finishTrain = async () => {
    const {
      speed,
      questions,
      paragraphs,
      answers,
      choices,
      studentAnswers,
      version,
    } = this.state;
    const newSpeed = speed.pop();
    let assignment = [];
    for (let i = 0; i < questions.length; i++) {
      assignment.push({
        paragraph: paragraphs[i],
        question: questions[i],
        choices: choices[i],
        answer: answers[i],
        studentAnswer: studentAnswers[i],
        speed: speed[i],
      });
    }
    // create the practice assignment
    await axios.post("/api/literacy/fluency/student/assign", {
      assignment,
      version,
      newSpeed,
    });
    // update fluency practice history score
    await axios.put("/api/literacy/fluency/score", { newSpeed });
    window.location = "/literacy/student/fluency";
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ alert: false });
  };

  render() {
    const {
      readDone,
      score,
      answerred,
      index,
      questions,
      choices,
      currParaArray,
    } = this.state;
    const progress = ((index + 1) / questions.length) * 100;
    return (
      <div>
        <Container style={{ marginTop: "5%" }}>
          <Button
            variant="outlined"
            style={{
              color: "white",
              backgroundColor: "#107896",
              marginRight: 20,
            }}
            onClick={this.handleSaveAssignment}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{ color: "white", backgroundColor: "#107896" }}
            onClick={() =>
              (window.location = "/literacy/student/fluency/assignment")
            }
          >
            Quit
          </Button>
        </Container>
        <Container style={{ marginTop: "10%" }}>
          {readDone ? (
            answerred ? (
              index < questions.length - 1 ? (
                <div>
                  <Button
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#107896" }}
                    onClick={this.changeQuestion}
                    size="large"
                  >
                    Next sentence
                  </Button>
                  <br />
                </div>
              ) : (
                <div>
                  <P1>You have finish all the training questions!</P1>
                  <Button
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#107896" }}
                    onClick={this.finishTrain}
                    size="large"
                  >
                    Good Job!
                  </Button>
                </div>
              )
            ) : (
              <div>
                <P1>{questions[index]}</P1>
                <FormControlLabel
                  value={choices[index][0]}
                  label={choices[index][0]}
                  control={
                    <Radio
                      icon={<Filter1 />}
                      checkedIcon={<Filter1TwoTone />}
                    />
                  }
                  onChange={this.checkAnswer}
                />
                <br />
                <FormControlLabel
                  value={choices[index][1]}
                  label={choices[index][1]}
                  control={
                    <Radio
                      icon={<Filter2 />}
                      checkedIcon={<Filter2TwoTone />}
                    />
                  }
                  onChange={this.checkAnswer}
                />
                <br />
                <FormControlLabel
                  value={choices[index][2]}
                  label={choices[index][2]}
                  control={
                    <Radio
                      icon={<Filter3 />}
                      checkedIcon={<Filter3TwoTone />}
                    />
                  }
                  onChange={this.checkAnswer}
                />
                <br />
                <FormControlLabel
                  value={choices[index][3]}
                  label={choices[index][3]}
                  control={
                    <Radio
                      icon={<Filter4 />}
                      checkedIcon={<Filter4TwoTone />}
                    />
                  }
                  onChange={this.checkAnswer}
                />
              </div>
            )
          ) : (
            <div>
              {currParaArray && currParaArray.length !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingLeft: "30px",
                  }}
                >
                  {currParaArray.map((word, i) => {
                    return (
                      <div style={{ display: "flex" }}>
                        {word.split("").map((letter, j) => {
                          return (
                            <P1 className={String(i) + String(j)}>{letter}</P1>
                          );
                        })}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Process />
              )}
            </div>
          )}
          <br />
          <LinearProgress variant="determinate" value={progress} />
        </Container>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
        >
          <Alert onClose={this.handleClose} severity="success">
            Saved Successfully!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(FluencyAssignPart);
