import { Paper, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { P1, P2, P3 } from "assets/fonts";
import Dialog from "GUI/assets/dialog";
import { selectReadyComponents } from "redux/ready-components/readyComponent-selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const Pagination = (props) => {
  const {
    currPage,
    finalPage,
    onNext,
    onSubmit,
    mandatoryCom,
    // readyComponents,
  } = props;
  const [pages, setPages] = useState([]);
  const [finished, setFinished] = useState(false);
  const [allMandatoryCompleted, setAllMandatoryCompleted] = useState(false);

  const readyComponents = props.readyComponents;

  useEffect(() => {
    console.log("readycom length", readyComponents.length);
    console.log("readycom", readyComponents);
    console.log("mandatorycom length", mandatoryCom.length);
    console.log("mandatorycom", mandatoryCom);
    setAllMandatoryCompleted(readyComponents.length >= mandatoryCom.length);
  }, [readyComponents, mandatoryCom]);

  // const allMandatoryCompleted = readyComponents.length >= mandatoryCom.length;

  useEffect(() => {
    setPages(props.pages);
  }, [props.pages]);

  const getIndex = () => {
    let index;
    pages.map((page, i) => {
      if (page === currPage) {
        index = i;
      }
    });
    return index;
  };

  const handleNextClick = () => {
    // increase currPage by 1
    const index = getIndex();
    if (index === pages.length - 1) {
      setFinished(true);
    } else {
      onNext(pages[index + 1]);
    }
  };

  const handleSubmitClick = () => {
    onSubmit(handleNextClick);
  };

  return (
    <Paper
      style={{
        position: "fixed",
        height: "60px",
        width: "85%",
        bottom: 0,
        paddingTop: "10px",
        backgroundColor: "whitesmoke",
      }}
    >
      <center>
        <P2 style={{ fontWeight: 300 }}>
          Page {getIndex() + 1} / {pages?.length}
        </P2>
      </center>
      {currPage === finalPage ? (
        <Button
          color={allMandatoryCompleted ? "primary" : "grey"}
          disabled={allMandatoryCompleted ? false : true}
          // color="primary"
          size="large"
          variant="contained"
          onClick={handleSubmitClick}
          style={{
            // backgroundColor: "green",
            position: "absolute",
            right: "40px",
            bottom: "10px",
          }}
        >
          Submit
        </Button>
      ) : (
        <Button
          color={allMandatoryCompleted ? "primary" : "grey"}
          disabled={!allMandatoryCompleted}
          size="large"
          variant="contained"
          onClick={handleNextClick}
          style={{
            position: "absolute",
            right: "40px",
            bottom: "10px",
          }}
        >
          Next
        </Button>
      )}
      <Dialog finished={finished} />
    </Paper>
  );
};

const mapStateToProps = createStructuredSelector({
  readyComponents: selectReadyComponents,
});

export default connect(mapStateToProps)(Pagination);
