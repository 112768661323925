const triples = [
    "698",
    "126",
    "539",
    "315",
    "863",
    "254",
    "941",
    "482",
    "935",
    "689",
    "496",
    "524",
    "149",
    "851",
    "268",
    "346",
    "513",
    "291",
    "154",
    "813",
    "632",
    "982",
    "368",
    "425"
];

export default triples;