import React from "react";
import { connect } from "react-redux";
import { selectCurrentFont } from "redux/font/font-selector";
import { createStructuredSelector } from "reselect";

const P3 = (props) => {
  const font = props.currentFont;
  const renderFont = () => {
    switch (font) {
      case 1:
        return <h2 {...props}>{props.children}</h2>;
      case 2:
        return <h3 {...props}>{props.children}</h3>;
      case 3:
        return <h4 {...props}>{props.children}</h4>;
      case 4:
        return <h5 {...props}>{props.children}</h5>;
      case 5:
        return <h6 {...props}>{props.children}</h6>;
      case 6:
        return <p {...props}>{props.children}</p>;
      case 7:
        return <p {...props}>{props.children}</p>;
      default:
        return <h5 {...props}>{props.children}</h5>;
    }
  };

  return renderFont();
};

const mapStateToProps = createStructuredSelector({
  currentFont: selectCurrentFont,
});

export default connect(mapStateToProps)(P3);
