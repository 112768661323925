import { P1, P2, P3 } from 'assets/fonts';
import React, { useState, useEffect, useCallback } from "react";
import { Radio, FormGroup, FormControlLabel, Container, TextField } from "@material-ui/core";
import axios from 'axios';

const TextFieldArea = (props) => {
  const { id } = props;
  const [question, setQuestion] = useState("");
  const [optional, setOptional] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/textfield/" + id);
        setQuestion(doc.data.question);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  const handleChange = useCallback((e) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
  }, []);

  const handleBlur = async () => {
    await axios.put("/api/gui/textfield/" + id, { question });
  }

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/textfield/" + id, { optional: newBool });
  }

  return (
    <Container>
      <P1>Text Field</P1>
      <div className='row' style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={<Radio color="primary" checked={optional === true}
            onChange={e => handleOptionalChange(true)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={<Radio color="primary" checked={optional === false}
            onChange={e => handleOptionalChange(false)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
      </div>
      <P2 style={{ fontWeight: "300" }}>Question/Text:</P2>
      <TextField multiline value={question} onChange={handleChange} onBlur={handleBlur} style={{ width: "60%" }} />
      <br /><br />
    </Container>
  )
}

export default TextFieldArea;