import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ImageProcessingAWS } from 'assets/keys';

const Index = () => {
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [confirmTime, setConfirmTime] = useState(null);
    const [timeGap, setTimeGap] = useState(0);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        setImage(URL.createObjectURL(file));
        setResult(null);
        setProcessing(false);
    }

    const handleConfirm = async () => {
        if (file) {
            await setProcessing(true)
            await setConfirmTime(new Date().getTime());
            const doc = await axios.get("/api/gui/imageprocess", { contentType: file.type });
            const uploadConfigs = doc.data;
            await axios.put(uploadConfigs.url, file, { headers: { "Content-type": file.type } });

            const bodyFormData = new FormData();
            bodyFormData.append('image_path', ImageProcessingAWS + uploadConfigs.key)
            const res = await axios({
                method: "post",
                url: "/api/imageprocess/predict/",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            setResult(res.data);
        }
    }

    const handleConfirm2 = async () => {
        if (file) {
            await setProcessing(true)
            await setConfirmTime(new Date().getTime());
            const doc = await axios.get("/api/gui/imageprocess", { contentType: file.type });
            const uploadConfigs = doc.data;
            await axios.put(uploadConfigs.url, file, { headers: { "Content-type": file.type } });

            const bodyFormData = new FormData();
            bodyFormData.append('image_path', ImageProcessingAWS + uploadConfigs.key)
            const res = await axios({
                method: "post",
                url: "/api/imageprocess/predict2/",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            setResult(res.data);
        }
    }

    const handleConfirm3 = async () => {
        if (file) {
            await setProcessing(true)
            await setConfirmTime(new Date().getTime());
            const doc = await axios.get("/api/gui/imageprocess", { contentType: file.type });
            const uploadConfigs = doc.data;
            await axios.put(uploadConfigs.url, file, { headers: { "Content-type": file.type } });

            const bodyFormData = new FormData();
            bodyFormData.append('image_path', ImageProcessingAWS + uploadConfigs.key)
            const res = await axios({
                method: "post",
                url: "/api/imageprocess/predict3/",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            setResult(res.data);
        }
    }

    useEffect(() => {
        if (confirmTime) {
            const interval = setInterval(() => {
                setTimeGap(Math.floor((new Date().getTime() - confirmTime) / 1000));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timeGap, confirmTime]);

    return (
        <div>
            <center>
                <h2>Bone Anchored Skin Response Classification Application</h2>
                <br />
                <h3>Step 1: choose an image (.jpg or .png)</h3>
                <input type='file' accept='image/*' onChange={handleImageChange} />
                <br /><br />
                <img width={512} height={512} src={image || "https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"} alt="" />
                <br /><br />
                <h3>Step 2: click CONFIRM after upload the image</h3>
                <Button variant='contained' color='primary' disabled={!image} onClick={handleConfirm}>Confirm</Button>

                <Button variant='contained' color='primary' disabled={!image} onClick={handleConfirm2}>Green Yellow Red</Button>

                <Button variant='contained' color='primary' disabled={!image} onClick={handleConfirm3}>Model_3 V_5</Button>
                <br /><br />
                <h3>Step 3: wait for about 30 seconds to get the result</h3>
                <h5>(if 1 minute passed, and no response, please CONFIRM again.)</h5>
                {processing && (!!result ? <h3>Result: {result}</h3> : <div><h3>Processing: {timeGap} seconds</h3></div>)}
            </center>
        </div>
    )
}

export default Index;