import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { P3 } from "assets/fonts";

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box minWidth={100}>
                <P3 style={{ marginRight: "2%" }}>Progress:</P3>
            </Box>
            <Box width="80%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body1" color="textPrimary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginLeft: "2%"
    },
});

export default function Progress(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgressWithLabel value={props.value} />
        </div>
    );
}
