import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect, useCallback } from "react";
import { Container, Button } from "@material-ui/core";
// common
import CheckBox from "GUI/user-components/checkbox";
import ConsentCheckbox from "GUI/user-components/consent-check";
import MultipleChoice from "GUI/user-components/multiple-choice";
import DatePicker from "GUI/user-components/date-picker";
import TextField from "GUI/user-components/text-field";
import PureText from "GUI/user-components/pure-text";
import Select from "GUI/user-components/select";
import PDF from "GUI/user-components/pdf";
import Image from "GUI/user-components/image";
import ImageRandom from "GUI/user-components/image-random";
import Slider from "GUI/user-components/slider";
import VolumeControl from "GUI/user-components/volume-control";
import DigitsDemo from "GUI/user-components/digits-demo";
import Digits from "GUI/user-components/digits";
import RecoveryStimulus from "GUI/user-components/recovery-stimulus";
import CRM from "GUI/user-components/crm";
import HrLine from "GUI/user-components/hrline";
import RandomId from "GUI/user-components/random-id";
import AlphabetExperiment from "GUI/user-components/alphabet-experiment";
import DfdExperiment from "GUI/user-components/dfd-experiment";
// others
import OHHIS from "GUI/tutor-components/OHHIS";
import StudyId from "./study-id";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addMandatoryComponent } from "redux/mandatory-components/mandatoryCom-reducer";

const RowContainer = (props) => {
  const {
    id,
    variable,
    app,
    onChange,
    participantId,
    readyToContinue,
    updateAssign,
    updateBlocksOrder,
    blocksOrder,
  } = props;
  const [children, setChildren] = useState([]);
  const [amount, setAmount] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/row/" + id);
        setChildren(doc.data.children);
        setAmount(doc.data.amount);
      })();
    }
  }, [id]);

  const renderComponent = (com) => {
    switch (com.type) {
      case "checkbox":
        // addMandatoryCom(com.id);
        return (
          <CheckBox
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "consentcheck":
        // addMandatoryCom(com.id);
        return (
          <ConsentCheckbox
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "radio":
        // addMandatoryCom(com.id);
        return (
          <MultipleChoice
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "select":
        // addMandatoryCom(com.id);
        return (
          <Select
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "ageselect":
        // addMandatoryCom(com.id);
        return (
          <Select
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "textfield":
        // addMandatoryCom(com.id);
        return (
          <TextField
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "puretext":
        return <PureText id={com.id} updateAssign={updateAssign} />;
      case "datepicker":
        // addMandatoryCom(com.id);
        return (
          <DatePicker
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "pdf":
        return <PDF id={com.id} />;
      case "image":
        return <Image id={com.id} />;
      case "imagerandom":
        return <ImageRandom id={com.id} updateAssign={updateAssign} />;
      case "slider":
        // addMandatoryCom(com.id);
        return (
          <Slider
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "volumecontrol":
        // addMandatoryCom(com.id);
        return (
          <VolumeControl
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "hrline":
        return <HrLine id={com.id} />;
      case "randomid":
        return (
          <RandomId
            id={com.id}
            randomid={variable.randomid}
            updateAssign={updateAssign}
          />
        );
      case "digitsdemo":
        // addMandatoryCom(com.id);
        return (
          <DigitsDemo
            id={com.id}
            volumecontrol={variable.volumecontrol}
            readyToContinue={readyToContinue}
          />
        );
      case "digits":
        // addMandatoryCom(com.id);
        return (
          <Digits
            id={com.id}
            volumecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "recoverystimulus":
        // addMandatoryCom(com.id);
        return (
          <RecoveryStimulus
            id={com.id}
            participantId={participantId}
            volumecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "crm":
        return (
          <CRM
            id={com.id}
            volumecontrol={variable.volumecontrol}
            updateBlocksOrder={updateBlocksOrder}
            blocksOrder={blocksOrder}
            updateAssign={updateAssign}
            onNext={onChange}
          />
        );
      case "alphabetexperiment":
        // addMandatoryCom(com.id);
        return (
          <AlphabetExperiment
            id={com.id}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "dfdexperiments":
        // addMandatoryCom(com.id);
        return (
          <DfdExperiment
            id={com.id}
            digits={variable.digits}
            voluemecontrol={variable.volumecontrol}
            updateAssign={updateAssign}
            readyToContinue={readyToContinue}
          />
        );
      case "studyids":
        return <StudyId id={com.id} updateAssign={updateAssign} />;
      case "ohhis":
        return <OHHIS app={app} id={com.id} updateAssign={updateAssign} />;
    }
  };

  return (
    <div className="row">
      {children.map((child) => (
        <div className={"col-" + 12 / amount}>{renderComponent(child)}</div>
      ))}
    </div>
  );
};

export default RowContainer;
