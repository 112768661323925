import React from "react";
import { Container, Button } from "@material-ui/core";
import { P1, P2, P3 } from 'assets/fonts';


export default (props) => {
  return (
    <Container>
      <div
        className="jumbotron"
        style={{
          marginTop: "5%",
        }}
      >
        <P2>Your participant ID is: {props.id}</P2>
        <P3>You may contact the investigators at jcbhlab@ualberta.ca and use this number to withdraw your data up to one week after you have completed the experiment.</P3>
        <br />
        <P3>
          We would now like you to do a task that involves pressing the computer
          keys in response to words and images that will appear in the centre of
          the screen. Please familiarise yourself with them, then press NEXT.
        </P3>
        <br />
        <div className="row">
          <div className="col-3">
            <P3>Positive words:</P3>
            <ul>
              <li>Easy-going</li>
              <li>Approachable</li>
              <li>Like</li>
              <li>Friendly</li>
              <li>Happy</li>
            </ul>
          </div>
          <div className="col-3">
            <P3>Negative words:</P3>
            <ul>
              <li>Awkward</li>
              <li>Misfit</li>
              <li>Dislike</li>
              <li>Unfriendly</li>
              <li>Grumpy</li>
            </ul>
          </div>
          <div className="col-6"></div>
        </div>
        <div className="row">
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/1.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/3.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/6.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/8.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/9.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
        </div>
        <div className="row">
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/2.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/4.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/5.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/7.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
          <img
            src={process.env.PUBLIC_URL + "/appearance/pictures/10.jpg"}
            style={{ width: "20%", maxHeight: "350px" }}
          />
        </div>
        <br />
        <Button
          variant="contained"
          size="large"
          style={{ color: "white", backgroundColor: "#107896" }}
          onClick={props.handleClick}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};
