import { Button, Container, TextField } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { green, yellow, red } from "./image-name";
import { ImageProcessingAWS } from 'assets/keys';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Classification = (props) => {
    const [index, setIndex] = useState(-1);
    const [displayImages, setDisplayImages] = useState([]);
    const [assign, setAssign] = useState([]);
    const [start, setStart] = useState(false);
    const [name, setName] = useState("");
    const [isZoomed, setIsZoomed] = useState(false)

    useEffect(() => {
        const greenImageUrls = randomize_20_green_image();
        const yellowImageUrls = randomize_20_yellow_image();
        const redImageUrls = randomize_20_red_image();
        const imageUrls = [...greenImageUrls, ...yellowImageUrls, ...redImageUrls];
        setDisplayImages(imageUrls.sort(() => Math.random() - 0.5));
        setIndex(0);
    }, []);

    useEffect(() => {
        // prevent right click on the image
        start && document.getElementById("classification-image").addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    }, [start]);

    useEffect(async () => {
        if (index >= displayImages.length) {
            await axios.post("/api/gui/imageprocess/assignment", { assign, name });
            await window.alert("Thank you!")
            props.history.push("/imageprocess/")
        }
    });

    const randomize_20_green_image = () => {
        const sortedGreen = green.sort(() => Math.random() - 0.5);
        const greenImages = sortedGreen.slice(0, 20);
        const greenImageUrls = [];
        for (let image of greenImages) {
            greenImageUrls.push(`${ImageProcessingAWS}classification/green/${image}`)
        };
        return greenImageUrls
    }

    const randomize_20_yellow_image = () => {
        const sortedYellow = yellow.sort(() => Math.random() - 0.5);
        const yellowImages = sortedYellow.slice(0, 20);
        const yellowImageUrls = [];
        for (let image of yellowImages) {
            yellowImageUrls.push(`${ImageProcessingAWS}classification/yellow/${image}`)
        };
        return yellowImageUrls
    }

    const randomize_20_red_image = () => {
        const sortedRed = red.sort(() => Math.random() - 0.5);
        const redImages = sortedRed.slice(0, 20);
        const redImageUrls = [];
        for (let image of redImages) {
            redImageUrls.push(`${ImageProcessingAWS}classification/red/${image}`)
        };
        return redImageUrls
    }

    const handleClick = async (color) => {
        const data = {
            image: displayImages[index],
            color: color
        };
        await setAssign([...assign, data]);
        setIndex(index + 1);
    }

    const handleZoomChange = shouldZoom => {
        setIsZoomed(shouldZoom)
    }

    return (
        start
            ?
            <div style={{ height: window.innerHeight }}>
                <center><h1>Classification</h1></center>
                <br />
                <center>
                    {/* <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}> */}
                    <img id="classification-image" height={window.innerHeight * 0.8} src={displayImages[index] || "https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"} alt="" />
                    {/* <img id="classification-image" height={window.innerHeight * 0.8} src="https://images.pexels.com/photos/53594/blue-clouds-day-fluffy-53594.jpeg" alt="" /> */}
                    {/* </ControlledZoom> */}
                </center>
                <br />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <Button disabled={!displayImages[index]} style={{ width: "100px", height: "50px", backgroundColor: "green" }} onClick={() => handleClick("green")}></Button>
                    <Button disabled={!displayImages[index]} style={{ width: "100px", height: "50px", backgroundColor: "yellow" }} onClick={() => handleClick("yellow")}></Button>
                    <Button disabled={!displayImages[index]} style={{ width: "100px", height: "50px", backgroundColor: "red" }} onClick={() => handleClick("red")}></Button>
                </div>
            </div>
            :
            <div>
                <div style={{ backgroundImage: "linear-gradient(to right, #C7D28A, #7CA295)", height: window.innerHeight * 0.4, width: "100%", position: "relative" }}>
                    <div style={{ height: "40%" }}>
                    </div>
                    <div style={{ color: "white", paddingLeft: "10%", fontFamily: "sans-serif" }}>
                        <h1><b>Bone Anchored Skin Response Classification Application</b></h1>
                    </div>
                </div>
                <Container style={{ backgroundColor: "#FFFFF0", height: window.innerHeight * 0.6, paddingLeft: 50 }}>
                    <br />
                    <center><div className='row' style={{ marginLeft: 0 }}>
                        <h3>Your name please: </h3>
                        <TextField value={name} style={{ marginLeft: 10, marginTop: 5 }} onChange={(e) => setName(e.target.value)} />
                    </div></center>
                    <br />
                    <h3>For each Baha tissue response image, please classify it as either:</h3>
                    <h4> - green: no concerns</h4>
                    <h4> - yellow: possible concerns - continue to monitor</h4>
                    <h4> - red: medical follow-up needed</h4>
                    <br />
                    <h3>by selecting the appropriate colour below the image. This is NOT a timed exercise. </h3>
                    <br />
                    <Button variant='contained' color="primary" onClick={() => setStart(true)}>Start</Button>
                </Container>
            </div>
    )
}

export default Classification;