import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import TrainCard from "literacy/assets/cards/tutorallassigncard";

class MeaningTutorTestAllAssign extends React.Component {
  constructor() {
    super();
    this.state = { assignments: [] };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/literacy/meaning/test");
    this.setState({ assignments: doc.data });
  };

  render() {
    const { assignments } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Review Students' Access Assignment</h2>
          <br />
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#107896" }}
            href="/literacy/tutor/meaning"
          >
            Go back
          </Button>
        </div>
        <Container>
          <div className="row">
            {assignments.map((assign, index) => {
              return (
                <TrainCard
                  title={assign.studentName}
                  description={assign.createAt}
                  handleClick={() =>
                    (window.location =
                      "/literacy/tutor/meaning/test/" + assign._id)
                  }
                />
              );
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default MeaningTutorTestAllAssign;
