import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { increaseFont, decreaseFont } from "redux/font/font-actions";
import { P1, P2, P3 } from "assets/fonts";
import axios from "axios";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const Header = ({ currentUser, increaseFont, decreaseFont }) => {
  let [userType, setUserType] = React.useState("Skill");
  const [googleId, setGoogleId] = useState(null);
  const [goalClicked, setGoalClicked] = useState(
    sessionStorage.getItem("goalClicked") === "true"
  );
  const [skillClicked, setSkillClicked] = useState(
    sessionStorage.getItem("skillClicked") === "true"
  );

  const classes = useStyles();

  const handleLogout = async () => {
    await axios.post("/api/literacy/logout");
    window.location = "/literacy";
  };

  const handleDemoLogin = async () => {
    await axios.post("/api/literacy/demo/login", {
      email: "example@gmail.com",
      password: "password",
    });
    window.location = "/literacy";
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const googleId = urlParams.get("googleId");
    setGoogleId(googleId);
    // Now you can use the googleId to handle your goals or other functionalities
  }, []);

  const handleGoal = async () => {
    try {
      const response = await axios.put("/api/literacy/student/goalUpdate", {
        studentId: googleId, // Use the googleId stored in state
        userType: "Goal",
      });
      setUserType("Goal");
      console.log("Student updated successfully:", response.data);
    } catch (error) {
      console.error("Update failed:", error.response || error.message);
    }
  };

  useEffect(() => {
    if (goalClicked && googleId) {
      handleGoal().finally(() => {
        setGoalClicked(false);
        sessionStorage.removeItem("goalClicked");
      });
    } else if (skillClicked && googleId) {
      handleSkill().finally(() => {
        setSkillClicked(false);
        sessionStorage.removeItem("skillClicked");
      });
    }
  }, [goalClicked, skillClicked, googleId]);

  const handleGoalClick = () => {
    setGoalClicked(true);
    setSkillClicked(false); // Reset skill clicked state
    sessionStorage.setItem("goalClicked", true);
    sessionStorage.removeItem("skillClicked");
  };

  const handleSkillClick = () => {
    setSkillClicked(true);
    setGoalClicked(false); // Reset goal clicked state
    sessionStorage.setItem("skillClicked", true);
    sessionStorage.removeItem("goalClicked");
  };

  const handleSkill = async () => {
    try {
      const response = await axios.put("/api/literacy/student/skillUpdate", {
        studentId: googleId, // Use the googleId stored in state
        userType: "Skill",
      });
      setUserType("Skill");
      console.log("Student updated successfully:", response.data);
    } catch (error) {
      console.error("Update failed:", error.response || error.message);
    }
  };

  const getHeader = () => {
    switch (currentUser) {
      case null:
        return;
      case false:
        return (
          <div style={{ display: "flex" }}>
            {/* <P2 style={{ marginTop: "20px" }}>Please sign-in</P2>
            <ArrowRightAltIcon style={{ fontSize: 80 }} color="secondary" /> */}

            <P2
              style={{
                marginRight: "15px",
                marginTop: "20px",
                marginBottom: "15px",
              }}
            >
              Sign in as
            </P2>
            <button
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              onClick={handleDemoLogin}
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Demo User
            </button>
            <a
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              href="/api/literacy/student/login"
              onClick={handleSkillClick}
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Skill Based Learner
            </a>
            <a
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              href="/api/literacy/student/login"
              onClick={handleGoalClick}
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Goal Based Learner
            </a>
            <a
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              href="/literacy/tutor/signin"
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Tutor
            </a>
          </div>
        );
      default:
        return (
          <div style={{ display: "flex" }}>
            {/* <select
              style={{ marginRight: "20px" }}
              onChange={handleUserType}
              value={userType}
            >
              <option value="Skill">Skill Based Program</option>
              <option value="Goal" onChange={handleGoal}>
                Goal Based Program
              </option>
            </select> */}
            <Typography
              style={{
                marginRight: "50px",
                alignContent: "center",
                fontSize: "25px",
              }}
            >
              {userType == "Goal"
                ? "Goal Based Program"
                : "Skill Based Program"}
            </Typography>
            <div style={{ marginRight: 10 }}>
              <Typography variant="h6">Font Size</Typography>
              <Button
                variant="outlined"
                style={{ color: "white", backgroundColor: "#107896" }}
                size="small"
                onClick={increaseFont}
              >
                <AddIcon />
              </Button>
              <Button
                variant="outlined"
                style={{ color: "white", backgroundColor: "#107896" }}
                size="small"
                onClick={decreaseFont}
              >
                <RemoveIcon />
              </Button>
            </div>
            <a
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              href="/literacy"
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Home
            </a>
            <button
              role="button"
              aria-pressed="true"
              className="btn btn-info btn-lg active"
              onClick={handleLogout}
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Log Out
            </button>
          </div>
        );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            <a
              href="/literacy"
              style={{ textDecoration: "none", color: "white" }}
            >
              Literacy Training
            </a>
          </Typography>
          {getHeader()}
        </Toolbar>
      </AppBar>
    </div>
  );
};

// How to get data from Redux State?
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  decreaseFont: () => dispatch(decreaseFont()),
  increaseFont: () => dispatch(increaseFont()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
