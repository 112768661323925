import { P1, P2, P3 } from 'assets/fonts';
import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Paper, TableBody, TableContainer, TableHead, Table, TableRow, TableCell } from "@material-ui/core";
import Markdown from 'markdown-to-jsx';
import axios from 'axios';

const TextFieldArea = (props) => {
  const { id } = props;
  const [text, setText] = useState("");
  const [instructionOpen, setInstructionOpen] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/puretext/" + id);
        setText(doc.data.text);
      })();
    }
  }, [id]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
  }

  const handleBlur = async () => {
    await axios.put("/api/gui/puretext/" + id, { text });
  }

  return (
    <Container>
      <P1>Pure Text</P1>
      <TextField multiline value={text} onChange={handleChange} onBlur={handleBlur} style={{ width: "60%" }} /><br /><br />
      <Markdown children={text} />
      <br /><br />
      {
        instructionOpen ?
          <div>
            <Button variant="outlined" color="secondary" onClick={() => setInstructionOpen(false)}>{"Close Instructions"}</Button>
            <br /><br />
            <P2>Instructions:</P2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">property</TableCell>
                    <TableCell align="center">code</TableCell>
                    <TableCell align="center">result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{"Bold"}</TableCell>
                    <TableCell align="center">{"<b>Bold</b>"}</TableCell>
                    <TableCell align="center"><b>Bold</b></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"Italian"}</TableCell>
                    <TableCell align="center">{"<i>Italian</i>"}</TableCell>
                    <TableCell align="center"><i>Italian</i></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"Font color"}</TableCell>
                    <TableCell align="center">{"<font color='blue'>Text here</font>"}</TableCell>
                    <TableCell align="center"><font color="blue">Blue Text</font></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"Font size"}</TableCell>
                    <TableCell align="center">{"<h1>Largest font</h1><h6>Smallest font</h6>"}</TableCell>
                    <TableCell align="center"><h1>Largest text</h1><h6>Smallest text</h6></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"New line"}</TableCell>
                    <TableCell align="center">{"<br>"}</TableCell>
                    <TableCell align="center"><br /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"Horizontal line"}</TableCell>
                    <TableCell align="center">{"<hr>"}</TableCell>
                    <TableCell align="center"><hr /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{"List"}</TableCell>
                    <TableCell align="center">{"<li>Text here</li>"}</TableCell>
                    <TableCell align="center"><li>Text here</li></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">{"Downloadable link"}</TableCell>
                    <TableCell align="center">{"<a href='path_to_file'>Download PDF</a>"}</TableCell>
                    <TableCell align="center"><a href='path_to_file'>Download PDF</a></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          :
          <Button variant="outlined" color="primary" onClick={() => setInstructionOpen(true)}>{"Open Instructions"}</Button>
      }
      <br /><br />
    </Container>
  )
}

export default TextFieldArea;