import { P1, P2, P3 } from 'assets/fonts';
import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Switch, Radio, FormGroup, FormControlLabel } from "@material-ui/core";
import axios from 'axios';

const CRM = (props) => {
  const { id } = props;
  const [numOfReversal, setNumOfReversal] = useState(null);
  const [numOfRow, setNumOfRow] = useState(null);
  const [numOfColumn, setNumOfColumn] = useState(null);
  const [numOfCurrBlock, setNumOfCurrBlock] = useState(null);
  const [numOfTotalBlocks, setNumOfTotalBlocks] = useState(null);
  const [colors, setColors] = useState(null);
  const [numOfUp, setNumOfUp] = useState(null);
  const [numOfDown, setNumOfDown] = useState(null);
  const [enableAudioUpload, setEnableAudioUpload] = useState(false);
  const [audio, setAudio] = useState(null);
  const [lossAudios, setLossAudios] = useState([]);
  const [originalAudios, setOriginalAudios] = useState([]);
  const [maskAudios, setMaskAudios] = useState([]);
  const [isSingleMask, setIsSingleMask] = useState(false);
  const [singleAudio, setSingleAudio] = useState(null);
  const [blockTypes, setBlockTypes] = useState(null);
  const [withPause, setWithPause] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/crm/" + id);
        setSingleAudio(doc.data.singleAudio);
        setIsSingleMask(doc.data.isSingleMask);
        setLossAudios(doc.data.lossAudios);
        setOriginalAudios(doc.data.originalAudios);
        setMaskAudios(doc.data.maskAudios);
        setNumOfColumn(doc.data.numOfColumn);
        setNumOfRow(doc.data.numOfRow);
        setColors(doc.data.colors);
        setNumOfUp(doc.data.numOfUp);
        setNumOfDown(doc.data.numOfDown);
        setNumOfReversal(doc.data.numOfReversal);
        setNumOfCurrBlock(doc.data.numOfCurrBlock);
        setNumOfTotalBlocks(doc.data.numOfTotalBlocks);
        setBlockTypes(doc.data.blockTypes);
        setWithPause(doc.data.withPause ?? false);
      })();
    }
  }, [id]);

  useEffect(async () => {
    await await axios.put("/api/gui/crm/" + id, { isSingleMask });
  }, [isSingleMask]);

  useEffect(() => {
    if (!!numOfRow && !!numOfColumn) {
      // show audio ui
      setEnableAudioUpload(true);
      // update row and col
      (async () => {
        await axios.put("/api/gui/crm/" + id, { numOfRow, numOfColumn });
      })();
    } else {
      setEnableAudioUpload(false);
    }

    if (!!numOfRow && !!numOfColumn && lossAudios.length === 0) {
      const audios = [];
      for (let i = 0; i < numOfRow; i++) {
        audios.push(Array(numOfColumn).fill(null));
      }
      setOriginalAudios(audios);
      setLossAudios(audios);
      setMaskAudios(audios);
      (async () => {
        await axios.put("/api/gui/crm/" + id, { originalAudios: audios, lossAudios: audios, maskAudios: audios });
      })();
    }
  }, [numOfRow, numOfColumn]);

  useEffect(() => {
    if (!!numOfUp && !!numOfDown) {
      (async () => {
        await axios.put("/api/gui/crm/" + id, { numOfUp, numOfDown });
      })();
    }
  }, [numOfUp, numOfDown]);

  useEffect(() => {
    if (!!numOfReversal) {
      (async () => {
        await axios.put("/api/gui/crm/" + id, { numOfReversal });
      })();
    }
  }, [numOfReversal]);

  useEffect(() => {
    if (!!numOfCurrBlock && !!numOfTotalBlocks && !!blockTypes) {
      (async () => {
        await axios.put("/api/gui/crm/" + id, { numOfCurrBlock, numOfTotalBlocks, blockTypes });
      })();
    }
  }, [numOfCurrBlock, numOfTotalBlocks, blockTypes]);

  const saveColors = async () => {
    await axios.put("/api/gui/crm/" + id, { colors });
  };

  const saveWithPause = async (withPause) => {
    await axios.put("/api/gui/crm/" + id, { withPause });
    setWithPause(withPause);
  };

  const handleUploadAudio = async (source, row, col) => {
    switch (source) {
      case "loss":
        const name1 = String(row + 1) + String(col + 1);
        const doc1 = await axios.get("/api/gui/crm/audio/" + id + "/loss/" + name1);
        const uploadConfigs1 = doc1.data;
        await axios
          .put(uploadConfigs1.url, audio, {
            headers: {
              "Content-type": 'audio/wav'
            }
          })
          .then(() => window.alert(`Audio ${name1}.wav update successfully!`))
          .catch((err) => window.alert('Something wrong:', err));
        // update lossAudios
        const newLossAudios = [...lossAudios];
        newLossAudios[row][col] = uploadConfigs1.key;
        setLossAudios(newLossAudios);
        await axios.put("/api/gui/crm/" + id, { lossAudios: newLossAudios });
        break;
      case "original":
        const name3 = String(row + 1) + String(col + 1);
        const doc3 = await axios.get("/api/gui/crm/audio/" + id + "/original/" + name3);
        const uploadConfigs3 = doc3.data;
        await axios
          .put(uploadConfigs3.url, audio, {
            headers: {
              "Content-type": 'audio/wav'
            }
          })
          .then(() => window.alert(`Audio ${name3}.wav update successfully!`))
          .catch((err) => window.alert('Something wrong:', err));
        // update originalAudios
        const newOriginalAudios = [...originalAudios];
        newOriginalAudios[row][col] = uploadConfigs3.key;
        setOriginalAudios(newOriginalAudios);
        await axios.put("/api/gui/crm/" + id, { originalAudios: newOriginalAudios });
        break;
      case "mask":
        const name2 = String(row + 1) + String(col + 1);
        const doc2 = await axios.get("/api/gui/crm/audio/" + id + "/mask/" + name2);
        const uploadConfigs2 = doc2.data;
        await axios
          .put(uploadConfigs2.url, audio, {
            headers: {
              "Content-type": 'audio/wav'
            }
          })
          .then(() => window.alert(`Audio ${name2}.wav update successfully!`))
          .catch((err) => window.alert('Something wrong:', err));
        // update targetAudios
        const newMaskAudios = [...maskAudios];
        newMaskAudios[row][col] = uploadConfigs2.key;
        setMaskAudios(newMaskAudios);
        await axios.put("/api/gui/crm/" + id, { maskAudios: newMaskAudios });
        break;
      case "single":
        const doc4 = await axios.get("/api/gui/crm/audio/" + id + "/single/single");
        const uploadConfigs4 = doc4.data;
        await axios
          .put(uploadConfigs4.url, audio, {
            headers: {
              "Content-type": 'audio/wav'
            }
          })
          .then(() => window.alert(`Audio single.wav update successfully!`))
          .catch((err) => window.alert('Something wrong:', err));
        // update SingleAudio
        setSingleAudio(uploadConfigs4.key);
        await axios.put("/api/gui/crm/" + id, { singleAudio: uploadConfigs4.key });
        break;
    }
  };

  return (
    <Container>
      <br />
      <P1>CRM</P1>
      <br />
      <P2>Environment</P2>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}># of row</P2><TextField disabled={!!numOfRow} type="number" value={numOfRow} onChange={(e) => setNumOfRow(Number(e.target.value))} style={{ paddingLeft: "10px", paddingRight: "10px", width: "100px" }} />
        <P2 style={{ fontWeight: 300 }}># of column</P2><TextField disabled={!!numOfColumn} type="number" value={numOfColumn} onChange={(e) => setNumOfColumn(Number(e.target.value))} style={{ paddingLeft: "10px", width: "100px" }} />
        <P3>(You cannot change # of row and column after you input numbers, if you want to change the numbers, you have to delete this component completely and create another component.)</P3>
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}>Colors</P2><TextField value={colors} onChange={(e) => setColors(e.target.value)} onBlur={saveColors} style={{ paddingLeft: "10px", width: "300px" }} />
        <P3>(You need to input # of color as same as the # of row, and separate each color by comma "," without space, for example, "blue,red,yellow,white")</P3>
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}>Up # DBs</P2><TextField type="number" value={numOfUp} onChange={(e) => setNumOfUp(Number(e.target.value))} style={{ paddingLeft: "10px", paddingRight: "10px", width: "100px" }} />
        <P2 style={{ fontWeight: 300 }}>Down # DBs</P2><TextField type="number" value={numOfDown} onChange={(e) => setNumOfDown(Number(e.target.value))} style={{ paddingLeft: "10px", width: "100px" }} />
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}># of current block</P2><TextField type="number" value={numOfCurrBlock} onChange={(e) => setNumOfCurrBlock(Number(e.target.value))} style={{ paddingLeft: "10px", paddingRight: "10px", width: "100px" }} />
        <P2 style={{ fontWeight: 300 }}># of total blocks</P2><TextField type="number" value={numOfTotalBlocks} onChange={(e) => setNumOfTotalBlocks(Number(e.target.value))} style={{ paddingLeft: "10px", width: "100px" }} />
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}>Block types</P2><TextField value={blockTypes} onChange={(e) => setBlockTypes(e.target.value)} style={{ paddingLeft: "10px", width: "300px" }} />
        <P3>(You need to indicate all block types and separate them by comma "," for example, "loss,loss,original", if you would like the order be randomized, please add "?" at the end, for example, "loss,loss,original?")</P3>
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}># of reversals</P2><TextField type="number" value={numOfReversal} onChange={(e) => setNumOfReversal(Number(e.target.value))} style={{ paddingLeft: "10px", width: "100px" }} />
      </div>
      <div className="row" style={{ paddingLeft: "40px" }}>
        <P2 style={{ fontWeight: 300 }}>with 12-sec pause every 10 trials</P2>
        <FormGroup row style={{ marginLeft: 10 }}>
          {[true, false].map((opt, i) => (
            <FormControlLabel
              control={<Radio color="primary" checked={withPause == opt} />}
              label={String(opt)}
              onChange={() => saveWithPause(opt)}
            />
          ))}
        </FormGroup>
      </div>
      <br />
      <P2>Audios</P2>
      <div>
        <P3 style={{ paddingLeft: "20px" }}>Please upload audio 1 by 1 'Choose File -{'>'} Upload'</P3>
        {enableAudioUpload && (
          <div>
            <Container style={{ overflowX: "scroll" }}>
              <P2 style={{ fontWeight: 400 }}>Loss Audios</P2>
              {(Array(numOfRow).fill("")).map((ele, row) => {
                return <div style={{ display: "flex", flexDirection: "row" }}>
                  {(Array(numOfColumn).fill("")).map((ele, col) => {
                    return <div style={{ margin: 2, border: "solid", borderWidth: 2 }}>
                      <P3>{row + 1}{col + 1}</P3>
                      <P3 style={{ fontWeight: 300 }}>{lossAudios?.[row]?.[col]}</P3>
                      <input type="file" accept="audio/wav" onChange={(e) => setAudio(e.target.files[0])} style={{ marginBottom: 10 }} />
                      <Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("loss", row, col)}>Upload</Button>
                    </div>;
                  })}
                </div>;
              })}
            </Container>
            <br />
            <Container style={{ overflowX: "scroll" }}>
              <P2 style={{ fontWeight: 400 }}>Original Audios</P2>
              {(Array(numOfRow).fill("")).map((ele, row) => {
                return <div style={{ display: "flex", flexDirection: "row" }}>
                  {(Array(numOfColumn).fill("")).map((ele, col) => {
                    return <div style={{ margin: 2, border: "solid", borderWidth: 2 }}>
                      <P3>{row + 1}{col + 1}</P3>
                      <P3 style={{ fontWeight: 300 }}>{originalAudios?.[row]?.[col]}</P3>
                      <input type="file" accept="audio/wav" onChange={(e) => setAudio(e.target.files[0])} style={{ marginBottom: 10 }} />
                      <Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("original", row, col)}>Upload</Button>
                    </div>;
                  })}
                </div>;
              })}
            </Container>
            <br />
            <div className="row">
              <P3 style={{ fontWeight: 300, marginLeft: 40, marginTop: 5 }}>Single mask audio? </P3>
              <Switch
                checked={isSingleMask}
                onChange={e => setIsSingleMask(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            {
              isSingleMask ?
                <div style={{ margin: 25, border: "solid", borderWidth: 2 }}>
                  <P3 style={{ fontWeight: 300 }}>SingleMask</P3>
                  <P3>{singleAudio}</P3>
                  <input type="file" accept="audio/wav" onChange={(e) => setAudio(e.target.files[0])} style={{ marginBottom: 10 }} />
                  <Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("single")}>Upload</Button>
                </div>
                :
                <Container style={{ overflowX: "scroll" }}>
                  <P2 style={{ fontWeight: 400 }}>Mask Audios</P2>
                  {(Array(numOfRow).fill("")).map((ele, row) => {
                    return <div style={{ display: "flex", flexDirection: "row" }}>
                      {(Array(numOfColumn).fill("")).map((ele, col) => {
                        return <div style={{ margin: 2, border: "solid", borderWidth: 2 }}>
                          <P3>{row + 1}{col + 1}</P3>
                          <P3 style={{ fontWeight: 300 }}>{maskAudios?.[row]?.[col]}</P3>
                          <input type="file" accept="audio/wav" onChange={(e) => setAudio(e.target.files[0])} style={{ marginBottom: 10 }} />
                          <Button size="small" color="primary" variant="outlined" onClick={() => handleUploadAudio("mask", row, col)}>Upload</Button>
                        </div>;
                      })}
                    </div>;
                  })}
                </Container>
            }
          </div>
        )}
      </div>
      <br />
    </Container>
  )
}

export default CRM;