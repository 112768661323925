import React, {useState, useEffect} from "react";
import { Button } from "@material-ui/core";
import { P1, P2, P3 } from "assets/fonts";
import { ExportReactCSV } from "appearance/assets/csv";

const withEyeTracking = (WrappedComponent) => {
    return (props) => {
        const [isAligning, setIsAligning] = useState(true);
        const [clickTimes, setClickTimes] = useState(0);
        const [eyeTrackData, setEyeTrackData] = useState([]);
        const [page, setPage] = useState(1);
        const webgazer = window.webgazer;

        useEffect(() => {
            if (webgazer) {
                webgazer.setGazeListener((data, elapsedTime) => {
                    if (!isAligning) {
                        setEyeTrackData(array => [...array, [
                            data ? data.x - 325 : null,
                            data ? data.y - 5 : null,
                            page,
                            new Date().getTime(),
                            elapsedTime
                        ]]);
                    }
                }).begin();
            };
        }, [webgazer, isAligning, page]);

        const handleMouseClick = () => {
            if (isAligning) {
                setClickTimes(clickTimes + 1);
            };
        };

        const handleNext = () => {
            while (document.getElementById("webgazerVideoContainer")) {
                document.getElementById("webgazerVideoContainer").remove();
            };
            while (document.getElementById("webgazerGazeDot")) {
                document.getElementById("webgazerGazeDot").remove();
            };
            webgazer.clearGazeListener();
            webgazer.pause();
            try {
                webgazer.stopVideo();
                props.handleClick(eyeTrackData);
            }
            catch (err) {
                props.handleClick(eyeTrackData);
                return;
            }
        }

        return (
            <div>
                <div style={{ position: "fixed", width: "300px", top: "240px" }}>
                    {
                        isAligning?
                        <div style={{ margin: "5% "}}>
                            <P2>Mouse click times: {clickTimes}</P2>
                            <P3>Please look at the cursor and click your mouse inside the box as much as you can (100 times recommended)</P3>
                            <P3>When you are ready to start, click "START"</P3>
                            <Button variant="outlined" color="primary" onClick={() => setIsAligning(false)}>Start</Button>
                        </div>
                        :
                        <div>
                            <Button
                            variant="contained"
                            size="large"
                            style={{ color: "white", backgroundColor: "#107896", margin: "5%" }}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                        <ExportReactCSV headers={["X", "Y", "Page", "Timestamp", "ElapsedTime"]} data={eyeTrackData} fileName="EyeTrackingData" />
                        </div>
                        
                    }
                </div>
                <div
                    onClick={handleMouseClick}
                    style={{borderColor: "black", borderStyle: "dashed", left: "325px", top: "5px", right: "5px", bottom: "5px", position: "fixed"}}
                >
                    {!isAligning && <WrappedComponent {...props} setPage={setPage} />}
                </div>
            </div>
        )

    }
}

export default withEyeTracking;