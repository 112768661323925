import { P1, P2, P3 } from "assets/fonts";
import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import axios from "axios";

const OHHIS = (props) => {
  const { app, id, updateAssign } = props;
  const [code, setCode] = useState(null);

  useEffect(() => {
    const updateCodeInterval = setInterval(() => {
      updateCode();
    }, 2000);
    return () => clearInterval(updateCodeInterval);
  }, [id]);

  const updateCode = async () => {
    const doc = await axios.get(`/api/gui/assign/${app}`);
    const length = doc.data.length;
    const codeNumber = ("000" + (length + 1)).slice(-4);
    const newCode = `OHHIS-${codeNumber}`;
    if (newCode !== code) {
      setCode(newCode);
    }
  };

  useEffect(
    (e) => {
      const assign = {
        id,
        type: "ohhis",
        code,
      };
      updateAssign(assign);
    },
    [code]
  );

  return (
    <Container>
      <br />
      <center>
        <h1 style={{ fontWeight: "300", fontSize: "3em" }}>
          {code ?? "Loading ..."}
        </h1>
      </center>
      <br />
      <br />
    </Container>
  );
};

export default OHHIS;
