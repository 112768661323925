import React, { useState, useEffect, useCallback } from 'react';
import { Radio, FormGroup, FormControlLabel, Container, TextField } from '@material-ui/core';
import { AddCircleOutline, IndeterminateCheckBox } from '@material-ui/icons';
import { P1, P2, P3 } from 'assets/fonts';
import axios from 'axios';

const DropdownSelect = (props) => {
  const { id } = props;
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [optional, setOptional] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        const doc = await axios.get("/api/gui/select/" + id);
        setQuestion(doc.data.question);
        setOptions(doc.data.options ?? []);
        setOptional(doc.data.optional ?? false);
      })();
    }
  }, [id]);

  const handleQuestionChange = useCallback((e) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
  }, []);

  const handleOptionChange = useCallback((e, index) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  }, [options]);

  const handleAddOption = useCallback(() => {
    const newOptions = [...options];
    newOptions.push("");
    setOptions(newOptions);
  }, [options]);

  const handleDeleteOption = useCallback((index) => {
    if (index < options.length - 1) {
      setOptions(options.slice(0, index).concat(options.slice(index + 1)));
    } else {
      setOptions(options.slice(0, index));
    }
  }, [options]);

  const handleBlur = async () => {
    await axios.put("/api/gui/select/" + id, { question, options });
  }

  const handleOptionalChange = async (newBool) => {
    setOptional(newBool);
    await axios.put("/api/gui/select/" + id, { optional: newBool });
  }

  return (
    <Container>
      <P1>Dropdown Select</P1>
      <div className='row' style={{ paddingLeft: "10px" }}>
        <FormControlLabel
          control={<Radio color="primary" checked={optional === true}
            onChange={e => handleOptionalChange(true)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Optional"}</P3>}
        />
        <FormControlLabel
          control={<Radio color="primary" checked={optional === false}
            onChange={e => handleOptionalChange(false)} />}
          label={<P3 style={{ marginTop: "10px" }}>{"Mandatory"}</P3>}
        />
      </div>
      <div className="row">
        <P2 style={{ marginRight: "20px", marginLeft: "15px", fontWeight: "300" }}>Question/Text: </P2>
        <TextField value={question} style={{ width: "60%" }} onChange={handleQuestionChange} onBlur={handleBlur} />
      </div><br />
      <FormGroup row>
        {options.map((opt, i) => (
          <div>
            <TextField value={opt} onChange={(e) => handleOptionChange(e, i)} onBlur={handleBlur} style={{ marginLeft: "-10px" }} />
            <IndeterminateCheckBox color="secondary" onClick={() => handleDeleteOption(i)} style={{ marginRight: "10px" }} />
          </div>
        ))}
        <AddCircleOutline color="primary" onClick={handleAddOption} />
      </FormGroup>
      <br />
    </Container>
  );
}

export default DropdownSelect;