import React from "react";
import axios from "axios";
import { Button, Container } from "@material-ui/core";
import TrainCard from "literacy/assets/cards/tutorallassigncard";

class MeaningTutorAllPerformance extends React.Component {
  constructor() {
    super();
    this.state = { students: [] };
  }

  componentDidMount = async () => {
    const doc = await axios.get("/api/literacy/student");
    if (doc.data) {
      this.setState({ students: doc.data });
    }
  };

  render() {
    const { students } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <h2>Review Students' Meaning Performance</h2>
          <br />
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#107896" }}
            href="/literacy/tutor/meaning"
          >
            Go back
          </Button>
        </div>
        <Container>
          <div className="row">
            {students.map((student, index) => {
              return (
                <TrainCard
                  key={index}
                  title={student.displayName}
                  description={student.email}
                  handleClick={() =>
                    (window.location =
                      "/literacy/tutor/meaning/performance/" + student._id)
                  }
                />
              );
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default MeaningTutorAllPerformance;
