import React from "react";
import { P1, P2, P3 } from 'assets/fonts';
import { Container } from "@material-ui/core";
import { ExportReactCSV } from 'hearing/components/partials/csv';
import DataTable from 'hearing/assets/questionnaire/data-table';
import axios from "axios";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [],
      data: [],
      array: []
    }
  }

  componentDidMount = async () => {
    const doc = await axios.get('/api/hearing/cosi/user');

    const header = ['Participant ID', 'Study ID', 'Submit Date', 'Needs Established Date', 'Outcome Assessed Date', 'Category'];
    for (let i = 1; i <= 5; i++) {
      header.push("Order-" + String(i));
      header.push("Needs-" + String(i));
      header.push("Change-" + String(i));
      header.push("Category-" + String(i));
      header.push("Ability-" + String(i));
    }

    const datas = [];
    doc.data?.map(data => {
      const row = [
        data.participantID, data.studyID, data.date?.split('T')?.[0],
        data.dateNeedsEstablished?.split('T')?.[0],
        data.dateOutcomeAssessed?.split('T')?.[0], data.newOrReturn
      ];

      const charts = Object.values(data.charts);
      for (let i = 1; i <= 5; i++) {
        charts.map(obj => {
          if (obj.priority === i) {
            row.push(obj.priority);
            row.push(obj.text);
            row.push(obj.change);
            row.push(obj.category);
            row.push(obj.ability);
          }
        })
      }

      datas.push(row);
    });

    // write the id table
    const array = [];
    doc.data.map(data => {
      const obj = {};
      obj["_id"] = data._id;
      obj["participantID"] = String(data["participantID"]);
      obj["studyID"] = String(data["studyID"]);
      array.push(obj);
    });

    this.setState({
      header, data: datas, array
    });
  }

  handleEditData = (id) => {
    window.location = '/hearing/questionnaire/cosi-return/' + id;
  }

  handleDeleteData = async (id) => {
    await axios.delete('/api/hearing/cosi/' + id);
    this.componentDidMount();
  }


  render() {
    const { header, data, array } = this.state;
    return (
      <div style={{ backgroundColor: "rgb(240,240,240)", fontFamily: "raven", height: window.innerHeight, overflowY: "scroll" }}>
        <Container>
          <P1>COSI database</P1>
          <ExportReactCSV data={data} headers={header} fileName="COSI_Data" />
          <br /><br />
          <P1>Modify / Delete data</P1>
          <DataTable data={array} onEditData={this.handleEditData} onDeleteData={this.handleDeleteData} />
          <br /><be />
          <P3>Order-1: Indicate Order of Significance</P3>
          <P3>Needs-1: SPECIFIC NEEDS in Order 1</P3>
          <P3>Change-1: Degree of Change in Order 1</P3>
          <P3>Ability-3: Final Ability (with hearing aid) in Order 3</P3>
          <P3><b>Categories</b></P3>
          <div className="col" style={{ marginLeft: "100px" }}>
            <P3>1.  Conversation with 1 or 2 in quiet</P3>
            <P3>2.  Conversation with 1 or 2 in noise</P3>
            <P3>3.  COnversation with group in quiet</P3>
            <P3>4.  COnversation with group in noise</P3>
            <P3>5.  Television/Radio @ normal volumn</P3>
            <P3>6.  Familair speaker on phone</P3>
            <P3>7.  Unfamiliar speaker on phone</P3>
            <P3>8.  Hearing phone ring from another room</P3>
            <P3>9.  Hear front door bell or knock</P3>
            <P3>10. Hear traffic</P3>
            <P3>11. Increased social contact</P3>
            <P3>12. Feel embarrassed or stupid</P3>
            <P3>13. Feeling left out</P3>
            <P3>14. Feeling upset or angry</P3>
            <P3>15. Church or meeting</P3>
            <P3>16. Other</P3>
          </div>
        </Container>
        <br />
      </div>
    )
  }
}

export default Questionnaire;
