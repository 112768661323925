import React, {useState} from "react";
import { Container, Button, TextField } from "@material-ui/core";
import Footer from "hearing/components/partials/footer";
import {P1, P2, P3} from 'assets/fonts';


export default (props) => {
  const [participantId, setParticipantId] = useState("");
  const [studyId, setStudyId] = useState("");

  return (
    <Container>
      <div
        className="row"
        style={{
          position: "relative",
          marginTop: "12%",
        }}
      >
        <div className="col-3"></div>
        <div className="col-9">
          <P1>Welcome</P1>
          <br />
          <div className="row">
            <P2>Please enter the Study ID:</P2>
            <TextField value={studyId} style={{marginLeft: "70px"}} onChange={e => setStudyId(e.target.value)}/>
          </div>
          <br />
          <div className="row">
            <P2>Please enter your Participant ID:</P2>
            <TextField value={participantId} style={{marginLeft: "8px"}} onChange={e => setParticipantId(e.target.value)}/><br /><br />
          </div>
          <br />
          <Button size="large" variant="contained" style={{color: "white", backgroundColor: "#107896"}} onClick={() => props.onChangeId(participantId, studyId)}>Confirm</Button>
        </div>
      </div>
      <Footer />
    </Container>
  );
};
